@import "../_variables.scss";

.item {
  padding: 96px 0 0;

  @include x {
    padding: 80px 0 0;
  }

  @include m {
    padding: 64px 0 0;
  }

  &__center {
    display: flex;
    @include t {
      display: block;
    }

    &.landscape {
      display: block;
      @include t {
        display: block;
      }

      .item__details_row .item__details_box {
        flex: 1 0 23%;
      }
    }
  }

  &__top {
    display: flex;

    &_start {
      margin-right: auto;
    }

    @include m {
      display: block;
    }

    &.portrait {
      @include m {
        display: flex;
      }
    }
  }

  &__train {
    display: flex;
    align-items: center;

    img {
      margin-right: 12px;
    }

    span {
      @include button-1;
      cursor: pointer;
    }
  }

  &__bg {
    position: relative;
    flex-grow: 1;
    align-self: flex-start;
    margin-right: 32px;

    @include x {
      margin-right: 64px;
    }

    @include d {
      margin-right: 32px;
    }

    @include t {
      margin: 0 0 32px;
    }
  }

  &_center-nft {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 80px;

    @include rmin(1600) {
      max-width: 1480px;
    }

    @include d {
      padding: 0 30px !important;
    }

    @include t {
      padding: 0 30px !important;
    }

    @include m {
      padding: 0 24px;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 500px;

    @include d {
      width: 410px;
    }

    @include t {
      width: 100%;
    }

    &_head {
      display: flex;
      gap: 8px;
      align-items: center;

      @include dark {
        @include filter-primary2;
      }
    }

    &_box {
      border: 1px solid $neutrals6;
      border-radius: 8px;
      padding: 20px;

      @include dark {
        border: 1px solid $neutrals3;
      }

      .story {
        white-space: pre-line;
        margin-top: 15px;
        color: $neutrals4;
      }
    }

    &_row {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      margin-top: 20px;

      .item__details_box {
        flex: 1 0 45%;
      }
    }
  }

  &__preview {
    position: relative;

    img {
      width: 100%;
      border-radius: 0px;
    }

    video {
      width: 100%;
      border-radius: 0px;
    }
  }

  &__effect {
    position: absolute;
    top: 0;
    width: 100%;
    height: 12%;
  }

  &__categories {
    position: absolute;
    top: 24px;
    left: 24px;
    display: flex;
    flex-wrap: wrap;
    margin: -8px 0 0 -8px;

    @include m {
      margin: 0;
      top: 6px;
      left: 6px;
    }
  }

  &__categories-week {
    // display: none !important;
    position: absolute;
    top: 7px;
    right: 10px;
    display: flex;
    flex-wrap: wrap;
    // margin: -8px 0 0 -8px;

    @include m {
      display: block !important;
      margin: 0;
    }
  }

  &__categories-week2 {
    // display: none !important;
    position: absolute;
    top: 7px;
    right: 8px;
    display: flex;
    flex-wrap: wrap;
    // margin: -8px 0 0 -8px;

    @include m {
      display: block !important;
      margin: 0;
    }
  }

  &__box-start-price {
    position: relative;
  }

  &__start-price {
    display: none;
    position: absolute;
    bottom: 10px;
    width: 100%;
    justify-content: center;
    border-radius: 5px;

    @include m {
      display: flex !important;
      justify-content: center;
    }
  }

  &__start-price-background {
    display: none;
    width: 90%;
    background: #ffffff42;
    backdrop-filter: blur(10px);
    border-radius: 9px;

    @include m {
      display: flex !important;
    }
  }

  &__profileImg-mobile {
    display: none;
    @include m {
      display: flex !important;
      left: 12px !important;
    }
  }

  &__profileImg-mobile2 {
    display: none;
    @include m {
      display: flex !important;
      left: 32px !important;
    }
  }

  &__category {
    margin: 8px 0 0 8px;

    @include m {
      margin: 8px 4px 0 4px;
    }
  }

  &__show__controls {
    &:hover {
      .options {
        opacity: 1;
        visibility: visible;
        transform: translateY(-12px);

        @include m {
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
        }
      }
    }
  }

  &__history {
    display: flex;

    &__desc {
      font-weight: 500;
    }

    &__col {
      flex: 0 0 50%;

      &:first-child {
        flex-grow: 1;
      }

      &:last-child {
        text-align: end;
      }
    }
  }

  .options {
    position: relative;
    left: 0;
    bottom: 0;
    width: 33%;
    margin: -110px auto 85px;
    visibility: hidden;
    opacity: 0;
    transform: translateY(0);
    transition: transform 0.3s;

    @include m {
      position: relative;
      transform: translateX(0);
      left: 0;
      bottom: 0;
      margin: 0;
      width: auto;
      opacity: 1;
      visibility: visible;
    }
  }

  .landscape {
    .item__title {
      margin-bottom: 28px;
      margin-top: 0;
    }

    .options2 {
      margin-top: 0;
      position: inherit;
    }

    .actions {
      .actions__body {
        margin-left: -9%;

        @include w {
          margin-left: -13%;
        }

        @include x {
          margin-left: -15%;
        }

        @include d {
          margin-left: -16%;
        }

        @include t {
          margin-left: -21%;
        }

        @include m {
          margin-left: -26%;
        }

        @include a {
          margin-left: -36%;
        }

        @include s {
          margin-left: -42%;
        }
      }
    }

    .item__bg {
      margin-right: 0;
    }

    .item__details {
      width: 100%;
      margin-top: 25px;
    }

    .item__nav2 {
      justify-content: space-between;
    }

    .item__link2 {
      @include rmin(768) {
        width: 20%;
      }

      text-align: center;
    }

    .item__main-users {
      .item__users {
        flex: none;

        &:first-child {
          padding-right: 30px;
        }
      }
    }
  }

  &__title {
    margin-bottom: 28px;
  }

  &__cost {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    @include t {
      flex-wrap: wrap;
      gap: 5px 0;
    }

    &_chain {
      width: 30px;
      height: 30px;
      margin-right: 8px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__chain {
    width: 50px;
    height: 50px;
    margin-right: 8px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__cost &__price {
    @include main-font;
    font-size: 16px;
    line-height: 30px;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  &__counter {
    @include button-1;
    color: $neutrals4;
  }

  &__text {
    margin-bottom: 40px;
    @include body-2;
    color: $neutrals4;

    a {
      text-decoration: underline;
      font-weight: 500;
      color: $neutrals2;

      @include dark {
        color: $neutrals8;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__bio {
    margin-bottom: 15px;
    font-size: 15px;
    line-height: 1.5;
    text-align: justify;
    color: #777e90;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    @include wrap-text2(3);
    @include m {
      @include wrap-text2(2);
    }
  }

  &__nav {
    display: flex;
    margin-bottom: 32px;
    padding: 6px;
    border-radius: 20px;
    box-shadow: inset 0 0 0 2px $neutrals6;

    @include t {
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    @include dark {
      box-shadow: inset 0 0 0 2px $neutrals3;
    }
  }

  &__link {
    padding: 6px 12px;
    border-radius: 14px;
    background: none;
    @include button-2;
    color: $neutrals4;
    transition: all 0.2s;

    &:hover {
      color: $neutrals3;

      @include dark {
        color: $neutrals6;
      }
    }

    &.active {
      background: $neutrals3;
      color: $neutrals8;

      @include dark {
        background: $neutrals8;
        color: $neutrals2;
      }
    }

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  &__nav2 {
    display: flex;
    margin-bottom: 32px;
    padding: 6px;
    border-radius: 5px;
    background: $neutrals7;
    justify-content: space-around;
    @include t {
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
    @include m {
      justify-content: unset;
    }

    @include dark {
      background: $primary1;
    }
  }

  &__link2 {
    padding: 6px 12px;
    border-radius: 5px;
    background: none;
    @include button-3;
    color: $neutrals4;
    transition: all 0.2s;

    &:hover {
      color: $neutrals3;

      @include dark {
        color: $neutrals6;
      }
    }

    &.active {
      background: $neutrals1;
      color: $neutrals8;

      @include dark {
        background: $primary2;
        color: $neutrals1;
      }
    }

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  &__main-users {
    display: flex;
    gap: 30px;
    margin-bottom: 30px;

    .item__users {
      flex: 0 0 50%;

      &.owner {
        border-right: 1px solid $neutrals6;
      }
    }
  }

  &__user {
    display: flex;
    align-items: center;
    padding-bottom: 16px;

    @include dark {
      border-color: $neutrals3;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__avatar {
    position: relative;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    margin-right: 16px;

    & > img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__avatar_2 {
    position: relative;
    flex-shrink: 0;
    width: 60px;
    height: 60px;
    margin-right: 16px;

    & > img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__reward {
    position: absolute;
    right: -5px;
    bottom: -5px;

    img {
      max-width: 25px;
    }
  }

  &__description {
    flex-grow: 1;
    text-align: start;
  }

  &__description-info {
    display: flex;
    flex-wrap: wrap;
  }

  &__time {
    flex-shrink: 0;
    align-self: flex-start;
    margin-left: 12px;
    @include caption-2;
    color: $neutrals4;
  }

  &__action {
    color: $neutrals4;

    span {
      font-weight: 500;
      color: $neutrals2;

      @include dark {
        color: $neutrals8;
      }

      &.item__money {
        color: $primary3;
      }
    }
  }

  &__position {
    color: $neutrals4;
    margin-right: 5px;
  }

  &__value {
    font-weight: 500;
    text-align: start;
    margin-right: 8px;
    color: $primary1;
    transition: all 0.2s;

    @include dark {
      color: $primary2;
    }
  }

  a {
    &.item__value {
      &:hover {
        color: $primary3;
      }
    }
  }

  &__name {
    font-weight: 500;
    text-align: start;

    a {
      color: $primary1;
      @include wrap-text;

      @include dark {
        color: $primary2;
      }
    }
  }

  &__name-pc {
    a {
      @include m {
        display: none;
      }
    }
  }

  &__box {
    display: none;
  }

  &__control {
    margin-top: 32px;
    padding: 24px;
    border-radius: 0px;
    box-shadow: 0px 64px 64px -24px rgba(31, 47, 70, 0.12);
    border: 1px solid $neutrals6;

    @include m {
      margin-left: -16px;
      margin-right: -16px;
      padding: 16px;
    }

    @include dark {
      background: $neutrals2;
      border-color: $neutrals3;
    }
  }

  &__holder {
    background: $neutrals6;
    padding: 24px;
    position: sticky;
    bottom: 0;
    width: 100%;
    z-index: 11;
    margin-top: 50px;

    .tooltip1 {
      position: relative;
      cursor: pointer;
      width: 100%;
    }

    .list-lock {
      display: flex;
      gap: 5px;

      @include m {
        display: grid;
        width: inherit;
      }
    }

    @include dark {
      background: $primary1;
    }

    @include rmin(3000) {
      display: block !important;
    }
  }

  &__ending {
    flex-direction: column;

    @include t {
      margin: 20px 0;
    }
  }

  &__control2 {
    display: flex;
    gap: 10px;
    padding: 24px;
    border-radius: 0px;
    border: 1px solid $neutrals6;
    background-color: $primary2;
    align-items: center;

    @include t {
      /*display: grid;*/
      flex-wrap: wrap;
      justify-content: center;
    }

    @include m {
      padding: 16px;
    }

    @include dark {
      background: $neutrals2;
      border-color: $neutrals3;
    }

    &__title {
      @include control-title;
      color: $neutrals2;

      @include dark {
        color: $neutrals8;
      }
    }
  }

  &__auction_time {
    flex: 0 0 20%;
    text-align: center;
  }

  &__release {
    background: $primary3;
    border-radius: 50px;
    padding: 16px 24px;
    color: $primary2;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  &__sections {
    &_release {
      display: flex;
      gap: 18px;
      align-items: center;

      .title {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
    }

    &.more-insight {
      @include m {
        flex: 0 0 48%;

        .item__user {
          flex-wrap: wrap;
        }

        &:nth-child(2) {
          align-items: flex-start;
          border-left: 1px solid $neutrals5;
        }
      }
    }

    &.is-musuem {
      display: flex;
      gap: 16px;
      align-items: center;
      border-left: 0 !important;

      .timer {
        display: flex;
        justify-content: center;
        gap: 5px;
      }
    }

    &_timer {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      padding: 12px 16px;
      background: $primary1;
      border-radius: 50px;

      @include m {
        padding: 10px 10px;
      }

      @include dark {
        background: $primary2;
      }

      img {
        @include dark {
          @include filter-primary1;
        }
      }
    }

    .item__user {
      padding-bottom: 0;
    }
    /*flex: 0 0 19%;*/
    align-items: center;

    @include rmin(1023) {
      min-width: 170px;
    }

    @include rmin(1250) {
      min-width: 200px;
    }

    @include t {
      border-right: 0 !important;
      justify-self: center;
      text-align: center;

      &:last-child {
        width: 100%;
      }
    }

    &:not(:last-child) {
      border-left: 1px solid $neutrals5;
      padding-left: 10px;

      @include m {
        border: none;
      }
    }

    &:nth-child(4) {
      border: none;
    }

    &:first-child {
      border: none;
      padding-left: 0;
      /*flex-grow: 1;*/
    }

    &:last-child {
      display: flex;
      justify-content: flex-end;
      gap: 20px;

      @include t {
        flex-wrap: wrap;
        justify-content: center;
        gap: 6px 0;
      }
    }

    &.controls {
      transition: display 0.2s;
    }

    &.flex {
      display: flex;
    }

    &.nbrd {
      border: 0;
      justify-content: flex-end;
      margin-right: 35px;
      flex-grow: 1;

      @include t {
        justify-content: center;
        margin-right: 0;
      }
    }

    &.nbrd2 {
      border: 0;
    }
  }

  &__price_btn {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-left: 5px;
  }

  &__btn_action {
    display: flex;
    gap: 12px;

    @include t {
      display: block;
      width: 100%;

      .complete {
        width: 100%;
      }
    }
  }

  &__currency_price {
    text-align: center;
    display: inline-flex;
    margin-top: 10px;
    align-items: baseline;
    margin-right: auto;
  }

  &__head {
    display: flex;
    align-items: center;
  }

  &__info {
    @include body-bold-2;
    color: $neutrals4;

    span {
      color: $neutrals2;

      @include dark {
        color: $neutrals8;
      }
    }
  }

  &__currency {
    display: flex;
  }

  .similar {
    margin-top: 120px;
  }

  &__price {
    font-size: 20px;
    line-height: 1.3333333333;
    font-weight: 600;
    letter-spacing: -0.01em;

    @include m {
      font-size: 18px;
    }

    &:not(:last-child) {
      margin-right: 12px;
    }
  }

  &__usdprice {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $neutrals4;
  }

  &__btns {
    display: flex;
    gap: 20px;

    @include t {
      gap: 6px;
    }

    @include m {
      flex-wrap: wrap;
    }

    .button {
      min-width: 130px;

      @include t {
        min-width: calc(50% - 3px);
        gap: 6px 0;
      }

      @include m {
        min-width: calc(50% - 3px);
        &:not(&.half_smart) {
          min-width: calc(100% - 0px);
        }
      }
    }
  }

  &__btns &__button {
    flex: 0 0 calc(50% - 8px);
    width: calc(50% - 8px);
    // margin: 0 4px;
    margin: 0;
    padding: 0 8px;

    &.smaller {
      flex: 0 0 calc(25% - 8px);
      width: calc(25% - 8px);

      @include t {
        width: 100%;
        margin: 0;
      }
    }

    &.item__w100 {
      flex: 0 0 calc(100% - 2px);
      width: 100%;
    }
  }

  .want_cancle {
    transition: 0.2s all;

    &:hover {
      span {
        display: none;
      }

      &:before {
        content: "Cancle and Mint Now" !important;
      }
    }
  }

  &__w100 {
    @include t {
      width: 100%;
    }
  }

  &__variants {
    margin-top: 32px;
    font-weight: 500;
    color: $neutrals4;

    span {
      margin-left: 12px;
      font-weight: 400;
    }
  }

  &__percent {
    font-weight: 500;
    color: $neutrals2;

    @include dark {
      color: $neutrals8;
    }
  }

  &__foot {
    margin-top: 32px;
  }

  &__foot &__button {
    width: 100%;
  }

  &__note {
    margin-top: 32px;
    color: $neutrals4;
  }

  &__slider {
    position: static;
    visibility: hidden;

    &.slick-initialized {
      visibility: visible;
    }
  }

  .slick-list {
    overflow: hidden;
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    display: flex;
    height: auto;
    margin: 0 16px;

    @include m {
      margin: 0 8px;
    }
    /*&.slick-active {
            opacity: 1;
        }*/
  }

  .slick-arrow {
    top: 4px;
  }

  .slick-prev {
    right: 48px;
  }

  .slick-next {
    right: 0;
  }

  .slick-dots {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    /*margin-right: 21px;*/
    padding: 1rem 0;
    list-style-type: none;

    @include t {
      display: flex;
      margin-right: 0;
      margin-bottom: 40px;
      height: 80px;
    }

    li {
      margin: 0.25rem 0.25rem;

      @include t {
        margin: 0 0.25rem;
      }
    }

    button {
      display: block;
      width: 12px;
      height: 12px;
      padding: 0;
      border: none;
      border-radius: 100%;
      background-color: $neutrals6;
      text-indent: -9999px;
    }

    li.slick-active button {
      background-color: $black;

      @include dark {
        background-color: $neutrals3;
      }
    }
  }

  &__Transactions {
    &_name {
      @include main-font;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $neutrals2;

      @include dark {
        color: $neutrals7;

        &:hover {
          color: $primary3;
        }
      }

      :hover {
        color: $primary3;
      }
    }

    &_user {
      @include main-font;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      color: $neutrals4 !important;

      :hover {
        color: $neutrals4 !important;
      }
    }

    &_CreatedAt {
      @include main-font;
      font-size: 12px;
      color: $neutrals4;
    }
  }
}
