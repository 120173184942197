﻿.feed {
  position: fixed;
  max-width: 448px;
  margin: 15px auto;
  padding: 32px;
  /*background: #fcfcfd;*/
  border-radius: 0;
  right: 0;
  top: 81px;
  width: 450px;
  z-index: 2;
  height: 90vh;
  box-shadow: -6px 1px 12px #0000001a;
  overflow-y: auto;
  background: $neutrals8;
  z-index: 20;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  @include m {
    width: 100%;
    padding: 32px;
  }

  @include dark {
    box-shadow: 0px 16px 32px 0 rgba($neutrals2, 0.3);
    background: $neutrals1;
  }

  &__image {
    margin-bottom: 40px;
  }

  &__row {
    display: flex;
    padding: 12px 0;
    align-items: center;

    &:not(:last-child) {
      border-bottom: 1px solid $neutrals6;
    }

    &:last-child {
      padding-bottom: 0px;
    }
  }

  &__name {
    margin-right: auto;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: $neutrals4;
  }

  &__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $neutrals4;
    margin: 40px 0;
  }

  &__tips {
    &_title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $neutrals2;
      margin-bottom: 20px;

      @include dark {
        color: $neutrals7;
      }
    }

    &_row {
      margin: 5px 0;
      display: flex;
      align-items: flex-start;

      span {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: $neutrals4;
      }

      img {
        width: 24px;
      }
    }

    &_text {
      margin-top: 4px;
    }
  }
}

.feed-padding {
  @include m {
    padding: 20px !important;
  }
}
