.profile {
  &__head {
    @media (min-width: 767px) {
      border-bottom: 1px solid $neutrals6;

      @include dark {
        border-bottom: 1px solid $neutrals3;
      }
    }

    &__banner {
      position: relative;
      display: flex;
      align-items: flex-end;
      padding: 32px 0;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
      overflow: hidden;
      background-image: url("../../images/Frame\ 2188.png");
      transition: height 0.3s;

      @media (min-width: 767px) {
        border-bottom: 1px solid $neutrals6;

        @include dark {
          border-bottom: 1px solid $neutrals3;
        }
      }

      @include m {
        background-image: none;
      }
    }

    @include t {
      padding-bottom: 64px;
    }

    @include m {
      padding-bottom: 56px;
      background-image: none;
      border: 1px solid #e6e8ec;
      margin: 40px 20px;
      box-shadow: 0 64px 64px rgba(31, 47, 70, 20%);
    }

    .seed-container {
      display: flex;
      justify-content: flex-end;
    }

    &.active {
      .profile__btns {
        opacity: 0;
      }

      .profile__file {
        opacity: 1;
        visibility: visible;
      }
    }

    .categories {
      width: 35%;

      @include m {
        margin: 0 auto;
        width: 100%;
      }
    }
  }

  &__btns {
    display: flex;
    justify-content: flex-end;
    transition: opacity 0.2s;
    padding: 15px;

    @include t {
    }

    .profile__button {
      color: $neutrals8;

      @include m {
        flex-grow: 1;
        padding: 0 10px;
      }

      .icon {
        width: 16px;
        height: 16px;
        fill: $neutrals8;

        @include dark {
          fill: $neutrals1;
        }
      }

      @include dark {
        &:hover {
          box-shadow: 0 0 0 2px $black inset;
        }
      }

      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }

  &__file {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: $neutrals8;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s;

    @include m {
      align-items: flex-start;
      padding-top: 40px;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
    }

    &:before {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($neutrals1, 0.6);
    }

    &:after {
      top: 8px;
      left: 8px;
      right: 8px;
      bottom: 8px;
      border: 2px dashed $neutrals6;
      border-radius: 12px;
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      font-size: 1400px;
      opacity: 0;
    }

    .icon {
      margin-bottom: 24px;
      width: 48px;
      height: 48px;
      fill: $neutrals8;

      @include m {
        width: 24px;
        height: 24px;
        margin-bottom: 12px;
      }
    }

    .profile__button {
      position: absolute;
      right: 16px;
      bottom: 16px;
      z-index: 4;

      @include t {
        bottom: 48px;
      }

      @include m {
        right: 50%;
        transform: translateX(50%);
      }
    }
  }

  &__wrap {
    position: relative;
    z-index: 2;
  }

  &__info {
    font-size: 24px;
    line-height: (32/24);
    font-weight: 600;

    @include m {
      font-size: 16px;
    }
  }

  &__text {
    @include body-2;

    @include m {
      font-size: 12px;
    }
  }

  &__body {
    position: relative;
    z-index: 3;
    padding: 80px 0;

    @include t {
      padding-top: 0;
    }

    @include m {
      padding: 0 0 64px;
    }

    .profile__center {
      display: flex;
      align-items: flex-start;

      @include t {
        display: block;
      }
    }
  }

  .user {
    flex-shrink: 0;
    width: 256px;
    margin-top: -193px;

    @include t {
      width: 100%;
      margin: -32px 0 48px;
    }
  }

  &__wrapper {
    flex: 0 0 calc(100% - 256px);
    width: calc(100% - 256px);
    padding-left: 64px;

    @include x {
      padding-left: 32px;
    }

    @include t {
      width: 100%;
      padding-left: 0;
    }
  }

  &__nav {
    display: flex;
    margin: 0 -6px 32px;
    overflow-x: auto;
    padding-bottom: 10px;

    @include m {
      margin: 0 -32px 32px;
      overflow: auto;
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        display: none;
      }

      &:before,
      &:after {
        content: "";
        flex-shrink: 0;
        width: 32px;
        height: 1px;
      }
    }
    /* width */
    &::-webkit-scrollbar {
      height: 7px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  &__link {
    flex-shrink: 0;
    margin: 0 6px;
    padding: 6px 12px;
    border-radius: 14px;
    background: none;
    @include button-2;
    color: $neutrals4;
    transition: all 0.2s;

    @include m {
      margin: 0;
    }

    &:hover {
      color: $neutrals3;

      @include dark {
        color: $neutrals6;
      }
    }

    &.active {
      background: $neutrals3;
      color: $neutrals8;

      @include dark {
        background: $neutrals8;
        color: $neutrals2;
      }
    }

    &:not(:last-child) {
      @include m {
        margin-right: 12px;
      }
    }
  }

  &__item {
    display: none;
    min-height: 300px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: -32px -16px 0;

    @include x {
      margin: -32px -10px 0;
    }

    @include d {
      margin: -32px -16px 0;
    }

    @include m {
      display: block;
      margin: 0;
    }

    .no-items {
      margin: 100px auto;
    }
  }

  .card {
    flex: 0 0 calc(33.333% - 32px);
    width: calc(33.333% - 32px);
    margin: 32px 16px 0;

    @include x {
      flex: 0 0 calc(33.333% - 20px);
      width: calc(33.333% - 20px);
      margin: 32px 10px 0;
    }

    @include d {
      flex: 0 0 calc(50% - 32px);
      width: calc(50% - 32px);
      margin: 32px 16px 0;
    }

    @include m {
      width: 100%;
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 32px;
      }
    }
  }

  .loader {
    margin: 32px auto 10px;
  }

  .followers {
    padding-top: 16px;

    @include m {
      padding-top: 0;
    }
  }

  .end {
    text-align: end;

    @include m {
      text-align: center;
      justify-content: center;
    }
  }
  //progress
  &__grow {
    width: 165px;
    box-sizing: border-box;
    margin: 50px auto 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @include m {
      width: 100%;
      padding: 0 25px;
    }

    [code-softprogress] {
      height: 10px;
      border-radius: 25px;
      overflow: hidden;
      background-color: #efece6;

      &:after {
        content: "";
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background: #baa97a;
        width: 0;
        height: 100%;
        box-sizing: border-box;
        font-size: 16px;
        color: $primary2;
        border-radius: 10px 0 0 10px;
        padding: 0 3px;
        transition: 2s;
      }

      &.run-softprogress::after {
        width: var(--run-softprogress);
      }
    }
  }

  &__progress {
    position: relative;
    height: 25px;
    display: inline-block;
    width: 100%;

    &_status {
      width: 20px;
      height: 20px;
      background-color: #efece6;
      border-radius: 50px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 25%);
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -6px;
      left: calc(var(--progress-badge) - 2%);

      img {
        width: 9px;
      }
    }

    &_tooltip {
      position: relative;
      top: -36px;
      left: calc(var(--progress-badge) - 5px);

      @include m {
        left: calc(var(--progress-badge) + -10px);
      }
    }

    &_num {
      color: $primary2;
      display: inline-block;
      position: absolute;
      font-size: 12px;
      padding: 3px 6px;
      border-radius: 4px;
      min-width: 22px;
      height: 23px;
      background-color: #e7b052;
      display: flex;
      align-items: center;
      justify-content: center;

      &::before {
        content: "";
        background: url("../../images/icons/icon-polygon-gold.svg") no-repeat 100% 50%/100% auto;
        position: absolute;
        bottom: -6px;
        width: 10px;
        height: 10px;
        left: 30%;
      }
    }

    &_dest {
      position: absolute;
      top: -7px;
      left: 96%;
      display: flex;
      align-items: center;
    }

    &_already {
      font-weight: 400;
      font-size: 10px;
      line-height: 20px;
      color: #bababa;

      @include m {
        font-size: 12px;
      }
    }
  }
}

.profile2 {
  padding-bottom: 80px;

  &__seed-container {
    margin-top: 79px;
  }

  &__seed-container2 {
    margin-top: 30px;
  }

  &__tab {
    display: flex;
    margin-top: -56px;
    width: 100%;

    &_title {
      margin-right: auto;
      color: $neutrals2;
      @include hairline-3;
      margin-bottom: 22px;

      @include dark {
        color: $neutrals7;
      }
    }

    &_icon {
      padding-top: 114px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 30px;
    }
    &_Title {
      @include hairline-3;
      color: $primary3;
    }
    &_Guide {
      @include caption-1;
      color: $neutrals4;
      margin-top: -16px;
    }
    &_btn {
      @include button-1;
      display: flex;
      gap: 12px;
      img {
        width: 16px;
        height: 16px;
        @include dark {
          @include filter-primary1;
        }
      }
      &:hover {
        img {
          @include filter-primary2;
        }
      }
    }
  }

  &__head {
    &__section {
      display: flex;
      justify-content: space-between;
      padding: 0 24px;
      align-items: center;
    }

    &__banner {
      display: flex;
      align-items: flex-end;
      background-repeat: repeat;
      background-size: auto;
      overflow: hidden;
      background-image: url("../../../assets/images/seed-back-fram.png");
      height: 280px;

      &.expand {
        height: 710px;
      }

      @include dark {
        background-image: url("../../../assets/images/seed-back-frame.png");
      }

      @media (min-width: 767px) {
        border-bottom: 1px solid $neutrals6;

        @include dark {
          border-bottom: 1px solid $neutrals3;
        }
      }
      @include t {
        height: 200px;

        &.expand {
          height: 400px;
        }
      }
    }

    .seed-container {
      display: flex;
      justify-content: flex-end;
    }

    &.active {
      .profile__btns {
        opacity: 0;
      }

      .profile__file {
        opacity: 1;
        visibility: visible;
      }
    }

    .user__avatar3 {
      margin: -64px auto 0;

      @include t {
        margin: -30px 0 0;
      }
    }

    &__control {
      position: absolute;
      right: 0;
      z-index: 1;
      margin-top: -70px;

      .profile2__btns {
        padding-right: 15px;
      }

      @media screen and (max-width: 1023px) {
        margin-top: -70px;
      }
    }
  }

  &__topNav {
    display: flex;
    align-items: center;
    margin: 53px -6px 32px;

    @include m {
      display: block;
    }

    .profile2__nav1 {
      width: 100%;
      padding-top: 27px;
    }
  }

  &__gallery {
    margin-top: 0px;
    display: flex;
    overflow: hidden;

    @include t {
      flex-wrap: wrap;
    }

    &_cell {
      flex: 1;
      transition: all 0.3s;
      transition-delay: 0.2s;
      height: 280px;
      position: relative;

      @include m {
        .card2__creator {
          display: none;
        }
      }

      @include rmin(1023) {
        &:hover {
          flex: 4;

          &.landscape {
            flex: 8;
          }
        }
      }

      &.expand {
        height: 710px;
      }

      @include t {
        height: 100px;
        flex: 0 0 25%;

        &.expand {
          height: 100px;
        }

        &:nth-child(3) {
          flex: 0 0 50%;
        }

        &:nth-child(4) {
          flex: 0 0 50%;
        }

        &:nth-child(9) {
          flex: 0 0 50%;
        }

        &:nth-child(10) {
          flex: 0 0 50%;
        }

        &:last-child {
          flex-grow: 1;
        }
      }

      img,
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &_cell2 {
      width: 210px;
      transition: width 0.3s;
      height: 280px;

      &:hover {
        width: 60%;
      }

      img,
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__note {
      color: $neutrals2;
      background-color: $primary2;
      border: 1px solid $neutrals6;
      padding: 0px 50px 0px 100px;
      position: absolute;
      top: 200px;

      @include dark {
        color: $neutrals8;
        background-color: $primary1;
      }

      span {
        color: $primary3;
        font-weight: 700;
        font-size: 20px;
        line-height: 48px;
      }
    }
  }

  &__categories {
    margin-top: 20px;

    span {
      margin: 2px;

      &:first-child {
        margin: 2px 2px 2px 0;
      }
    }

    @include m {
      width: 100%;
    }
  }

  &__referred {
    margin: 20px 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: $neutrals4;

    a {
      font-weight: 700;
      color: $primary1;

      @include dark {
        color: $primary2;
      }
    }
  }

  .options2 {
    margin: 0;

    &__list {
      padding: 0;
      justify-content: flex-end;
    }

    &.pc {
      @include t {
        display: none;
      }
    }
  }

  &__h-btn {
    height: 40px;
    margin: 0;
  }

  &__option_btn {
    width: 40px;
    height: 40px;
  }

  &__referral {
    display: flex;
    height: 40px;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 0 2px $neutrals6 inset;
    min-width: 174px;
    cursor: pointer;
    color: $neutrals4 !important;
    font-weight: 700;
    font-size: 14px;

    &.user__copy {
      margin-left: 0;
    }

    @include t {
      width: -webkit-fill-available;
    }

    &:hover {
      box-shadow: 0 0 0 2px $primary3 inset;
    }

    @include dark {
      box-shadow: 0 0 0 2px $neutrals3 inset;

      &:hover {
        box-shadow: 0 0 0 2px $primary3 inset;
      }
    }

    span {
      font-weight: 400;
      font-size: 10px;
    }

    img {
      margin-right: 5px;
      width: 20px;
      height: 20px;
      filter: brightness(0) saturate(100%) invert(52%) sepia(13%) saturate(461%) hue-rotate(185deg) brightness(92%)
        contrast(88%);
    }

    .icon {
      fill: $neutrals4 !important;
      width: 24px !important;
      height: 24px !important;
      margin-right: 15px;
    }
  }

  &__navigation {
    display: flex;

    &__col {
      flex: 0 0 35%;

      &:first-child {
        @include t {
          display: none;
        }
      }

      &:nth-child(2) {
        flex: 0 0 30%;
        flex-grow: 1;
        justify-content: center;
        text-align: center;

        @include t {
          justify-content: flex-start;
          text-align: start;
          display: flex;

          .profile2__smart {
            &:first-child {
              flex: 0 0 80%;
              flex-grow: 1;
            }

            &:last-child {
              display: block;
              flex: 0 0 20%;
            }
          }
        }
      }

      &:last-child {
        @include t {
          align-items: center;
          margin-top: 30px;
          display: flex;
          gap: 20px;
          justify-content: space-between;
        }

        @include m {
          display: block;
          align-items: center;
          margin-top: 30px;
        }
      }
    }

    @include t {
      display: block;
    }
  }

  .options2__list {
    .actions__body {
      right: 0;
    }
  }

  &__follow {
    font-size: 12px;
    line-height: 12px;
    color: $primary1;

    span {
      font-weight: 700;
      margin-right: 3px;
    }

    &:hover {
      color: $primary3;
    }

    @include dark {
      color: $primary2;

      &:hover {
        color: $primary3;
      }
    }
  }

  &__btns {
    display: flex;
    justify-content: flex-end;
    transition: opacity 0.2s;
    padding: 15px 0 15px 15px;
    gap: 16px;

    @include t {
      justify-content: space-between;
      padding: 15px 0;
      flex-grow: 1;
    }

    .profile2__button {
      color: $neutrals8;

      @include m {
        flex-grow: 1;
        padding: 0 10px;
      }

      .icon {
        width: 16px;
        height: 16px;
        fill: $neutrals8;

        @include dark {
          fill: $neutrals1;
        }
      }

      @include dark {
        &:hover {
          box-shadow: 0 0 0 2px $black inset;
        }
      }

      &:not(:last-child) {
        margin-right: 16px;
      }
    }

    .edit-btns {
      background: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(40px);
      border-radius: 8px;
      color: $primary1;
      padding: 12px 16px;
      display: flex;
      gap: 12px;
      align-items: center;
      transition: 0.2s;
      height: 100%;

      span {
        @include main-font;
      }

      &:hover {
        color: $primary3;

        .icon {
          fill: $primary3;
        }
      }

      .icon {
        width: 16px;
        height: 16px;
        fill: $primary1;

        @include dark {
          fill: $primary2;
        }
      }

      @include dark {
        color: $primary2;
      }

      &-light {
        color: $primary2;

        .icon {
          width: 16px;
          height: 16px;
          fill: $primary2;
        }
      }
    }
  }

  &__file {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: $neutrals8;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s;

    @include m {
      align-items: flex-start;
      padding-top: 40px;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
    }

    &:before {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($neutrals1, 0.6);
    }

    &:after {
      top: 8px;
      left: 8px;
      right: 8px;
      bottom: 8px;
      border: 2px dashed $neutrals6;
      border-radius: 12px;
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      font-size: 1400px;
      opacity: 0;
    }

    .icon {
      margin-bottom: 24px;
      width: 48px;
      height: 48px;
      fill: $neutrals8;

      @include m {
        width: 24px;
        height: 24px;
        margin-bottom: 12px;
      }
    }

    .profile__button {
      position: absolute;
      right: 16px;
      bottom: 16px;
      z-index: 4;

      @include t {
        bottom: 48px;
      }

      @include m {
        right: 50%;
        transform: translateX(50%);
      }
    }
  }

  &__wrap {
    position: relative;
    z-index: 2;
  }

  &__bioWrap {
    @include wrap-text2(3);
    -webkit-box-orient: vertical;
  }

  &__bioWrap-btn {
    color: $primary3;
    cursor: pointer;

    @include dark {
      color: #ae881e;
    }
  }

  &__info {
    font-size: 24px;
    line-height: (32/24);
    font-weight: 600;

    @include m {
      font-size: 16px;
    }
  }

  &__text {
    @include body-2;

    @include m {
      font-size: 12px;
    }
  }

  &__body {
    position: relative;
    z-index: 3;
    padding: 80px 0;

    @include t {
      padding-top: 0;
    }

    @include m {
      padding: 0 0 64px;
    }

    .profile__center {
      display: flex;
      align-items: flex-start;

      @include t {
        display: block;
      }
    }
  }

  .user {
    flex-shrink: 0;
    width: 256px;
    margin-top: -193px;

    @include t {
      width: 100%;
      margin: -32px 0 48px;
    }
  }

  &__wrapper {
    flex: 0 0 calc(100% - 256px);
    width: calc(100% - 256px);
    padding-left: 64px;

    @include x {
      padding-left: 32px;
    }

    @include t {
      width: 100%;
      padding-left: 0;
    }
  }

  &__nav {
    display: flex;
    margin: 0 -6px 32px;
    overflow-x: auto;
    padding-bottom: 10px;

    @include m {
      margin: 0 -32px 32px;
      overflow: auto;
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        display: none;
      }

      &:before,
      &:after {
        content: "";
        flex-shrink: 0;
        width: 32px;
        height: 1px;
      }
    }
    /* width */
    &::-webkit-scrollbar {
      height: 7px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  &__link {
    flex-shrink: 0;
    margin: 0 6px;
    padding: 6px 12px;
    border-radius: 14px;
    background: none;
    @include button-2;
    color: $neutrals4;
    transition: all 0.2s;

    @include m {
      margin: 0;
    }

    &:hover {
      color: $neutrals3;

      @include dark {
        color: $neutrals6;
      }
    }

    &.active {
      background: $neutrals3;
      color: $neutrals8;

      @include dark {
        background: $neutrals8;
        color: $neutrals2;
      }
    }

    &:not(:last-child) {
      @include m {
        margin-right: 12px;
      }
    }
  }

  &__item {
    display: none;
    min-height: 300px;
  }

  &__nav1 {
    display: flex;
    overflow: auto;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      display: none;
    }

    @include m {
      margin: 0 -32px 32px;
      overflow: auto;
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        display: none;
      }

      &:before,
      &:after {
        content: "";
        flex-shrink: 0;
        width: 32px;
        height: 1px;
      }
    }
    /* width */
    &::-webkit-scrollbar {
      height: 7px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  &__link_r-group {
    margin-left: auto;
    display: flex;
  }

  &__link1 {
    flex-shrink: 0;
    margin: 0 6px;
    padding: 6px 12px;
    border-radius: 4px;
    background: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: $neutrals4;
    transition: all 0.2s;
    display: flex;
    align-items: center;
    height: 48px;
    border: 1px solid $neutrals6;
    @include main-font;

    @include m {
      margin: 0;
    }

    @include dark {
      border: 1px solid $neutrals3;
    }

    &:hover {
      color: $neutrals3;

      @include dark {
        color: $neutrals6;
      }

      .icon {
        fill: $neutrals3;

        @include dark {
          fill: $neutrals6;
        }
      }

      img {
        @include filter-neutrals3;

        @include dark {
          @include filter-neutrals6;
        }
      }
    }

    &.active {
      background: $primary1;
      color: $primary2;

      @include dark {
        background: $primary2;
        color: $primary1;
      }

      .icon {
        fill: $primary2;

        @include dark {
          fill: $primary1;
        }
      }

      img {
        @include filter-primary2;

        @include dark {
          @include filter-primary1;
        }
      }
    }

    &:not(:last-child) {
      @include m {
        margin-right: 12px;
      }
    }

    &.separated {
      margin-left: auto;
    }

    .icon {
      fill: $neutrals4;
      width: 20px;
      height: 20px;
    }

    img {
      width: 20px;
      height: 20px;
      //add jsut for museum icon
      @include filter-neutrals4;
    }
  }

  &__item1 {
    display: none;
    min-height: 300px;

    .museum {
      padding: 0;
    }
  }

  &__nav2 {
    display: flex;
    /*margin: 0 -6px 32px;*/
    overflow-x: auto;
    padding-bottom: 10px;
    justify-content: center;

    @include m {
      margin: 0 -32px 32px;
      overflow: auto;
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      -webkit-overflow-scrolling: touch;
      // padding-left: 35px;

      &::-webkit-scrollbar {
        display: none;
      }

      &:before,
      &:after {
        content: "";
        flex-shrink: 0;
        width: 32px;
        height: 1px;
      }
    }
    /* width */
    &::-webkit-scrollbar {
      height: 7px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  &__nav3 {
    display: flex;
    /*margin: 0 -6px 32px;*/
    overflow-x: auto;
    padding-bottom: 10px;
    justify-content: center;

    @include m {
      flex-wrap: wrap;
      margin: 0 -32px 32px;
      overflow: auto;
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      -webkit-overflow-scrolling: touch;
      // padding-left: 35px;

      &::-webkit-scrollbar {
        display: none;
      }

      // &:before {
      //   content: "";
      //   flex-shrink: 0;
      //   width: 32px;
      //   height: 1px;
      // }
    }
    /* width */
    &::-webkit-scrollbar {
      height: 7px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  &__link2 {
    flex-shrink: 0;
    margin: 0 6px;
    padding: 6px 12px;
    border-radius: 14px;
    background: none;
    @include button-2;
    color: $neutrals4;
    transition: all 0.2s;
    border: 1px solid $neutrals6;
    width: 95px;
    text-align: center;

    @include dark {
      border: 1px solid $neutrals3;
    }

    @include m {
      margin: 0;
    }

    &:hover {
      color: $neutrals3;

      @include dark {
        color: $neutrals6;
      }
    }

    &.active {
      background: $primary1;
      color: $primary2;

      @include dark {
        background: $primary2;
        color: $primary1;
      }
    }

    &:not(:last-child) {
      @include m {
        margin-right: 12px;
      }
    }
  }

  &__link3 {
    cursor: pointer;
    flex-shrink: 0;
    margin: 0 6px;
    padding: 12px 24px;
    border-radius: 90px;
    background: none;
    @include button-2;
    color: $neutrals4;
    transition: all 0.2s;
    border: 1px solid $neutrals6;
    text-align: center;

    @include dark {
      border: 1px solid $neutrals3;
    }

    @include m {
      margin: 0;
      margin-top: 10px;
    }

    &:hover {
      color: $neutrals3;

      @include dark {
        color: $neutrals6;
      }
    }

    &.active {
      background: $primary1;
      color: $primary2;

      @include dark {
        background: $primary2;
        color: $primary1;
      }
    }

    &:not(:last-child) {
      @include m {
        margin-right: 12px;
      }
    }
  }

  &__item2 {
    display: none;
    text-align: center;
    white-space: inherit !important;
    margin: 30px 0;
  }

  &__mobile {
    display: none;

    @include m {
      display: block;
    }
  }

  &__pc {
    display: block;

    @include m {
      display: none;
    }
  }

  &__box {
    max-width: 730px;
    display: flex;
    margin: 20px auto;
    border: 1px solid $neutrals6;
    text-align: center;
    justify-content: center;
    align-items: center;

    @include dark {
      border: 1px solid $neutrals3;
    }

    @include m {
      width: 100%;
    }

    &__col {
      height: 60px;
      padding: 7px;
      flex: 0 0 16.666%;
      min-width: 16.66%;

      @include m {
        flex: 0 0 33.333%;
        min-width: 33.333%;
        padding: 7px;
      }

      &:nth-child(5) {
        border-right: 1px solid $neutrals6;
        border-left: 1px solid $neutrals6;

        @include dark {
          border-right: 1px solid $neutrals3;
          border-left: 1px solid $neutrals3;
        }
      }

      &:nth-child(4) {
        border-right: 1px solid $neutrals6;
        border-left: 1px solid $neutrals6;

        @include dark {
          border-right: 1px solid $neutrals3;
          border-left: 1px solid $neutrals3;
        }
      }

      &:nth-child(3) {
        border-right: 1px solid $neutrals6;
        border-left: 1px solid $neutrals6;

        @include dark {
          border-right: 1px solid $neutrals3;
          border-left: 1px solid $neutrals3;
        }

        @include m {
          border: 0px !important;
          border-right: 0px solid $neutrals6;
          border-left: 0px solid $neutrals6;
        }
      }

      &:nth-child(2) {
        border-right: 1px solid $neutrals6;
        border-left: 1px solid $neutrals6;

        @include dark {
          border-right: 1px solid $neutrals3;
          border-left: 1px solid $neutrals3;
        }
      }

      &__title {
        color: $neutrals4;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
      }

      &__value {
        color: $neutrals1;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;

        @include dark {
          color: $neutrals8;
        }
      }
    }
  }

  &__membership {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 6px;
    max-width: 190px;
    margin: 0 auto;
    border-radius: 40px;
    padding: 4px 12px;
    cursor: pointer;

    &.active {
      border: 1px solid $neutrals6;

      @include dark {
        border: 1px solid $neutrals3;
      }
    }

    @include t {
      white-space: nowrap;
      flex: 0 0 65%;
    }
  }

  &__membership_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    gap: 24px;
    max-width: 755px;
    height: 48px;
    border-radius: 40px;
    margin: 0 auto;
    background: $primary2;

    @media only screen and (max-width: 1050px) {
      overflow-x: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    @include dark {
      background: $primary1;
    }
  }

  &__membership_note {
    text-align: center;
    margin: 20px 0 24px 0;

    .note1 {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $neutrals2;

      @include dark {
        color: $neutrals7;
      }
    }

    .note2 {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: $neutrals3;

      @include dark {
        color: $neutrals6;
      }
    }
  }

  &__action_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    gap: 12px;

    .button {
      width: 150px;
      font-weight: 400;

      img {
        margin-right: 12px;
        @include filter-primary2;
      }

      &.chat {
        background-color: $primary3;
      }
    }
  }

  &__status_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 24px 0px;
    gap: 24px;
    overflow-x: auto;

    @include m {
      justify-content: flex-start;
      overflow-x: auto;
      width: 100%;
      -ms-overflow-style: none;
      scrollbar-width: none;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        display: none;
      }

      &:before,
      &:after {
        content: "";
        flex-shrink: 0;
        width: 32px;
        height: 1px;
      }
    }
    /* width */
    &::-webkit-scrollbar {
      height: 7px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  &__status {
    display: flex;
    gap: 24px;
    color: $neutrals5;

    &.active {
      color: $primary1;

      @include dark {
        color: $primary2;
      }
    }

    &_badge {
      .chevron {
        transform: rotate(90deg);

        &.ch-last {
          opacity: 0;
          visibility: hidden;
        }
      }

      img {
        filter: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(7465%) hue-rotate(264deg) brightness(105%)
          contrast(107%);

        @include dark {
          filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(55deg) brightness(105%)
            contrast(101%);
        }
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;

    @include m {
      display: block;
      margin: 0;
    }

    .no-items {
      margin: 100px auto;
    }
  }

  .card {
    flex: 0 0 calc(33.333% - 32px);
    width: calc(33.333% - 32px);
    margin: 32px 16px 0;

    @include x {
      flex: 0 0 calc(33.333% - 20px);
      width: calc(33.333% - 20px);
      margin: 32px 10px 0;
    }

    @include d {
      flex: 0 0 calc(50% - 32px);
      width: calc(50% - 32px);
      margin: 32px 16px 0;
    }

    @include m {
      width: 100%;
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 32px;
      }
    }
  }

  .loader {
    margin: 32px auto 10px;
  }

  .followers {
    padding-top: 16px;

    @include m {
      padding-top: 0;
    }
  }

  .end {
    text-align: end;

    @include m {
      text-align: center;
      justify-content: center;
    }
  }
}

/*test style form progress bar*/

#file {
  background-color: $primary3;
  border-radius: 7px;
  width: 25%;
  height: 20px;
}

.prpgress-wraper {
  margin-top: 40px;
  margin-left: 20px;
  position: relative;

  & progress {
    color: $primary2;

    & img {
      z-index: 3;
      position: absolute;
      width: 200px;
      height: 159px;
    }
  }

  & progress::after {
    content: "&#x2605";
    color: $primary2;
    font-size: 20px;
    position: absolute;
  }

  & label {
    display: block;
    font-size: 12px;
    color: $neutrals5;
  }

  & progress[value]::-webkit-progress-bar {
    background-color: #ecd9c6;
    border-radius: 7px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
  }

  progress[value]::-webkit-progress-value {
    background-color: $primary3;
    border-radius: 7px;
  }

  progress[value]::-webkit-progress-value::after {
    content: "&#x2605;";
    width: 6px;
    height: 6px;
    position: absolute;
    border-radius: 100%;
    right: 7px;
    top: 7px;
    background-color: $primary2;
    z-index: 3;
  }

  .progress-level {
    position: relative;
  }

  .progress-title {
    position: absolute;
    color: $primary2;
    font-size: 49px;
  }
}

.collections {
  .profile2__tab_title {
    margin-top: 27px;
  }
}

.empty {
  background-image: url("../../../assets/images/seed-back-fram.png");
}

.none {
  @media screen and (min-width: 1024px) {
    display: none;
  }
}

.progress-person {
  background-color: #baa97a;
  margin-top: -10px;
  border-radius: 10px;
  height: 10px;
  z-index: 3;
}

.progresss-person-all {
  background-color: #efece6;
  border-radius: 10px;
  height: 10px;
}

.loader-spinner {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid $primary1;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.referral_profile {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
  cursor: pointer;

  @include t {
    justify-content: flex-start;
    margin: 30px 0px;
  }
}

.referral-text {
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
  @include poppins;
  margin-left: 20px;
}

.referral-img {
  position: relative;
  border-radius: 50px;
  -webkit-backdrop-filter: blur(80px);
  backdrop-filter: blur(80px);
  display: flex;
  align-items: center;
  max-width: 90%;
  height: 35px;
  z-index: 0;
  transition: all 0.2s;
  @include poppins;
}

.referral-avator {
  position: relative;
  flex-shrink: 0;
  width: 35px;
  height: 35px;
  border: 2px solid $primary2;
  margin-right: 5px;
  border-radius: 50px;

  @include dark {
    border: 2px solid $black;
  }

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50px;
  }

  &:not(:first-child) {
    margin-left: -14px;
  }
}
