.switch {
    display: inline-block;
    position: relative;
    user-select: none;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-size: 0;

    &__input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;

        &:checked + .switch__inner .switch__box {
            background: $black;

            @include dark {
                background: $white;
            }

            &:before {
                transform: translate(24px,-50%);
                background: $white;

                @include dark {
                    background: $black;
                }
            }
        }
    }

    &__inner {
        position: relative;
        display: inline-block;
        transition: all .2s;
    }

    &__box {
        position: relative;
        display: block;
        width: 48px;
        height: 24px;
        background: $neutrals6;
        border-radius: 12px;
        transition: all .2s;

        @include dark {
            background: $neutrals3;
        }

        &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 4px;
            transform: translateY(-50%);
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: $black;
            transition: all .2s;

            @include dark {
                background: $white;
            }
        }
    }
}
