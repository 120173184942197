.discover {
  &__title {
    margin-bottom: 64px;

    @include m {
      margin-bottom: 32px;
    }
  }

  &__top {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;

    @include m {
      display: block;
    }

    .select {
      width: 180px;

      @include t {
        width: 260px;
      }

      @include m {
        width: 100%;
        margin-bottom: 12px;
      }
    }
  }

  &__top2 {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    overflow-x: auto;

    @include m {
      display: block;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    cursor: e-resize;

    .select {
      width: 180px;

      @include t {
        width: 260px;
      }

      @include m {
        width: 100%;
        margin-bottom: 12px;
      }
    }
  }

  &__nav {
    position: absolute;
    top: 10px;
    left: 50%;
    display: flex;
    justify-content: flex-start;
    transform: translateX(-50%);
    z-index: 1;
    width: 100%;

    @include t {
      display: none;
    }
  }

  &__link {
    margin: 0 6px;
    padding: 6px 25px;
    border-radius: 4px;
    background: none;
    font-weight: 500;
    font-size: 18px;
    line-height: 16px;
    color: $neutrals4;
    transition: all 0.2s;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 48px;

    &:hover {
      color: $neutrals3;

      @include dark {
        color: $neutrals6;
      }
    }

    &.active {
      background: $primary1;
      color: $primary2 !important;

      @include dark {
        background: $primary2;
        color: $primary1 !important;
      }
    }

    false {
      @include d {
        display: none;
      }
    }
  }

  &__nav1 {
    /*position: absolute;*/
    top: 10px;
    left: 50%;
    display: flex;
    justify-content: flex-start;
    /*transform: translateX(-50%);*/
    z-index: 1;
    width: 100%;

    @include t {
      display: none;
    }
  }

  &__container2 {
    position: relative;
    width: 100%;
    overflow: auto;
    scrollbar-width: none;
    .scroll-right-btn {
      position: absolute;
      right: 0;
      top: 36%;
      &::after {
        background: linear-gradient(to right, rgb(255 255 255 / 0%) 16%, $primary2 100%);
        content: "";
        pointer-events: none;
        position: absolute;
        right: 0;
        top: -25px;
        width: 64px;
        height: 57px;

        @include dark {
          background: linear-gradient(to right, rgb(255 255 255 / 0%) 56%, $neutrals2 100%);
        }
      }
    }
    .scroll-right-icon {
      position: absolute;
      right: 0;
      z-index: 2;
      font-size: 20px;
      bottom: -19px;
      @include dark {
        color: $primary2;
      }
    }
    .scroll-left-btn {
      position: absolute;
      left: 30px;
      top: 36%;
      &::after {
        background: linear-gradient(to right, $primary2 46%, rgba(255, 255, 255, 0%) 100%);
        height: 100%;
        width: 50px;
        content: "";
        pointer-events: none;
        position: absolute;
        right: 0;
        top: -25px;
        width: 64px;
        height: 57px;

        @include dark {
          background: linear-gradient(to right, $neutrals2 46%, rgba(255, 255, 255, 0%) 100%);
        }
      }
      .scroll-left-icon {
        position: absolute;
        left: -23px;
        z-index: 2;
        font-size: 20px;
        bottom: -19px;
        @include dark {
          color: $primary2;
        }
      }
    }
  }

  &__shadowLeft {
    width: 15px;
    height: 46px;
    position: absolute;
    top: 273px;
    left: 209px;
    box-shadow:
      -25px 0 32px -4px rgba(0, 0, 0, 0.5),
      -10px 0 15px -10px rgba(0, 0, 0, 0.5);
    background-color: transparent;

    @media only screen and (max-width: "1366px") {
      left: 125px;
      top: 273px;
    }

    @media only screen and (max-width: "1180px") {
      left: 80px;
      top: 257px;
    }

    @include t {
      top: 225px;
      left: 40px;
    }

    @include m {
      left: 24px;
      top: 192px;
    }

    @include dark {
      box-shadow:
        -25px 0 32px -4px rgba(255, 255, 255, 0.5),
        -10px 0 15px -10px rgba(255, 255, 255, 0.5);
    }
  }
  &__shadowRight {
    width: 15px;
    height: 46px;
    position: absolute;
    top: 273px;
    right: 209px;
    box-shadow:
      -10px 0 15px -10px rgba(0, 0, 0, 0.5),
      25px 0 32px -4px rgba(0, 0, 0, 0.5);
    background-color: transparent;

    @media only screen and (max-width: "1366px") {
      right: 115px;
      top: 273px;
    }

    @media only screen and (max-width: "1180px") {
      right: 80px;
      top: 257px;
    }

    @include t {
      top: 225px;
      right: 40px;
    }

    @include m {
      right: 24px;
      top: 192px;
    }

    @include dark {
      box-shadow:
        -10px 0 15px -10px rgba(255, 255, 255, 0.5),
        21px 0 38px -3px rgba(255, 255, 255, 0.5);
    }
  }

  &__nav2 {
    /*position: absolute;*/
    padding: 5px 0px;
    top: 10px;
    left: 50%;
    display: flex;
    justify-content: flex-start;
    /*transform: translateX(-50%);*/
    z-index: 1;
    width: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    cursor: e-resize;
    overflow: auto;
    //scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: none;
    }
    /*@include t {
            display: none;
        }*/
  }

  &__link1 {
    margin: 0 6px;
    padding: 6px 25px;
    border-radius: 4px;
    background: none;
    font-weight: 500;
    font-size: 18px;
    line-height: 16px;
    color: $neutrals4 !important;
    transition: all 0.2s;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 48px;
    border: 1px solid $neutrals6;

    @include dark {
      border: 1px solid $neutrals2;
    }

    &:hover {
      color: $primary1 !important;
      //border-color: $primary3;

      @include dark {
        color: $primary2 !important;
        // border-color: $primary3;
      }
    }

    &.active {
      background: $primary1;
      color: $primary2 !important;

      @include dark {
        background: $primary2;
        color: $primary1 !important;
      }
    }

    false {
      @include d {
        display: none;
      }
    }
  }

  &__nav_top {
    /*position: absolute;*/
    /*top: 10px;
        left: 50%;*/
    display: flex;
    justify-content: center;
    /*transform: translateX(-50%);*/
    border: 1px solid $neutrals6;
    z-index: 1;
    /*width: 80%;*/
    padding: 6px;
    border-radius: 5px;
    margin: 40px 30px 50px 30px;

    @include dark {
      border: 1px solid $neutrals3;
    }

    @include t {
      display: none;
    }
  }

  &__link_top {
    /*margin: 0 6px;*/
    padding: 12px 12px;
    border-radius: 5px;
    background: none;
    font-size: 15px;
    line-height: 1;
    // font-weight: 600;
    color: $neutrals4 !important;
    transition: all 0.2s;
    cursor: pointer;
    flex: 0 0 25%;
    text-align: center;

    &:hover {
      color: $neutrals3;

      @include dark {
        color: $neutrals6;
      }
    }

    @include dark {
      color: $neutrals5;
    }

    &.active {
      background: #000000;
      color: $neutrals8 !important;

      @include dark {
        background: $white;
        color: $black !important;
      }
    }

    false {
      @include d {
        display: none;
      }
    }
  }

  .tablet-show {
    margin-bottom: 20px;
  }

  &__filter {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0 0 24px;
    flex-shrink: 0;
    border-radius: 0px;
    background: $primary3;
    @include main-font;
    font-size: 16px;
    line-height: 48px;
    color: $neutrals8;
    transition:
      background 0.2s,
      box-shadow 0.2s;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    @include m {
      width: 100%;
    }

    &.active {
      .icon {
        &-filter {
          display: none;
        }

        &-close {
          display: inline-block;
        }
      }
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 52px;
    padding-right: 10px;
    background: none;
    font-size: 0;

    .icon {
      fill: $neutrals8;
      transition: transform 0.2s;

      &-filter {
        width: 18px;
        height: 18px;
      }

      &-close {
        display: none;
        width: 10px;
        height: 10px;
      }
    }
  }

  &__filters {
    display: none;
    padding: 32px 0;
    border-top: 1px solid $neutrals6;

    @include dark {
      border-color: $neutrals3;
    }
  }

  &__sorting {
    display: flex;
    flex-wrap: wrap;
    margin: -32px -16px 0;

    @include d {
      margin: -24px -8px 0;
    }

    @include m {
      display: block;
      margin: 0;
    }
  }

  &__cell {
    flex: 0 0 calc(25% - 32px);
    max-width: calc(25% - 32px);
    margin: 32px 16px 0;

    @include d {
      flex: 0 0 calc(25% - 16px);
      max-width: calc(25% - 16px);
      margin: 24px 8px 0;
    }

    @include t {
      flex: 0 0 calc(50% - 32px);
      max-width: calc(50% - 32px);
      margin: 32px 16px 0;
    }

    @include m {
      max-width: 100%;
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  &__list {
    @include m {
      margin: 0 -8px;
    }
  }

  &__slider {
    display: flex;
    flex-wrap: wrap;
    margin: -2px -16px 0;
    border-top: 1px solid $neutrals6;

    @include dark {
      border-top: 1px solid $neutrals3;
    }

    @include m {
      display: block;
      margin: 0;
    }
  }

  .card {
    /*flex: 0 0 calc(25% - 32px);
        max-width: calc(25% - 32px);*/
    flex: 0 0 calc(33.333% - 32px);
    max-width: calc(33.333% - 32px);
    margin: 32px 16px 0;

    @include d {
      flex: 0 0 calc(33.333% - 32px);
      max-width: calc(33.333% - 32px);
    }

    @include t {
      flex: 0 0 calc(50% - 32px);
      max-width: calc(50% - 32px);
    }

    @include m {
      max-width: 100%;
      margin: 0;
    }
  }

  &__btns {
    margin-top: 32px;
    text-align: center;

    @include m {
      display: none;
    }
  }

  .slick-list {
    overflow: hidden;
    padding-bottom: 72px;
  }

  .slick-slide {
    padding: 0 8px;
  }

  .slick-arrow {
    bottom: 0;
  }

  .slick-prev {
    left: calc(50% - 44px);
  }

  .slick-next {
    right: calc(50% - 44px);
  }

  &__note {
    line-height: 25px;
    color: $neutrals2;
    background-color: $primary2;
    border: 1px solid $neutrals6;
    padding: 0px 50px 0px 100px;
    margin: 47px 0px;

    @include dark {
      color: $neutrals8;
      background-color: $primary1;
    }
    @include m {
      color: $neutrals8;
      padding: 12px;
      margin: 17px 0px;
    }

    span {
      color: $primary3;
      font-weight: 700;
      font-size: 20px;
      line-height: 48px;

      @include m {
        line-height: 25px;
      }
    }
  }
}
