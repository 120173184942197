﻿.hero {
  // padding: 120px 0;

  &__center {
    display: flex;

    @include u {
      display: block;
    }
  }

  @include m {
    //  padding: 48px 0;
  }

  &__row {
    display: flex;
    flex-grow: 1;
    /*margin-right: 32px;*/
    //padding-right: 32px;
    /*border-right: 1px solid $neutrals6;*/

    @include x {
      margin-right: 0;
      padding: 0;
      border: none;
    }

    @include t {
      display: block;
    }

    @include dark {
      border-color: $neutrals3;
    }
  }

  &__col {
    &:first-child {
      flex-grow: 1;
      /*padding-right: 32px;*/
      padding: 20px 32px 20px 0;

      @include t {
        padding: 0;
      }
    }

    &:nth-child(2) {
      flex: 0 0 55%;

      @include d {
        flex: 0 0 50%;
      }

      @include t {
        margin-top: 30px;
        flex: 0 0 100%;
      }
    }
  }

  &__site_logo {
    img {
      width: 75%;
      margin-bottom: 2rem;

      @include t {
        display: none;

        @include dark {
          display: none;
        }
      }
    }
  }

  &__title {
    margin-bottom: 24px;
    margin-top: 80px;

    @include outfit;

    @include t {
      font-size: 40px;
      text-align: center;
      margin-top: 0;
    }
  }

  &__stage {
    /*text-transform: uppercase;*/
    /*margin-bottom: 50px;*/
    margin-top: 27px;
    /*@include hairline-2;*/
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: $neutrals4;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    img {
      margin-left: 12px;
      /*margin-bottom: 5px;*/
    }

    span {
      margin-right: 8px;
    }

    .bold {
      color: $primary1;

      @include dark {
        color: $primary2;
      }
    }

    &_count {
      margin-left: 20px;
      color: $primary3;
    }

    &_chain {
      display: flex;
      align-items: center;
      /*gap: 8px;*/

      img {
        @include filter-primary1;

        @include dark {
          @include filter-primary2;
        }
      }
    }

    @include t {
      text-align: center;
      justify-content: center;
      line-height: 28px;
    }
  }

  &__navigation {
    margin-bottom: 74px;
    margin-top: 50px;

    @include t {
      margin-bottom: 50px;
      text-align: center;
    }

    .button {
      width: 180px;
      height: 58px;
      font-weight: 500;
      font-size: 16px;

      &:nth-child(1) {
        margin-right: 20px;
      }

      @include m {
        width: 45%;

        &:nth-child(1) {
          margin-right: 20px;
        }
      }
    }
  }
}
