.options {
  &__list {
    display: flex;
    padding: 8px;
    border-radius: 50px;
    background: $neutrals8;
    align-items: center;
    justify-content: center;
    position: relative;

    @include dark {
      background: $neutrals2;
    }

    @include m {
      border-radius: 0;
      justify-content: center;
    }
  }

  &__button {
    .icon {
      width: 24px;
      height: 24px;
    }

    &:hover {
      img {
        @include filter-primary3;
      }
    }

    &_favorite {
      .icon {
        fill: $neutrals4;
        transition: fill 0.2s;
        transition: opacity 0.2s;
      }

      &:before {
        content: "";
        position: absolute;
        top: 51%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 22px;
        height: 22px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='18' fill='none' viewBox='0 0 22 18'%3E%3Cpath d='M11 1.81A6.48 6.48 0 0 0 6.5 0 6.5 6.5 0 0 0 0 6.5c0 6.368 6.97 9.885 9.814 11.055.766.315 1.607.315 2.372 0C15.03 16.385 22 12.868 22 6.5A6.5 6.5 0 0 0 15.5 0 6.48 6.48 0 0 0 11 1.81z' fill='%23ef466f'/%3E%3C/svg%3E")
          no-repeat 50% 50% / 100% auto;
        opacity: 0;
        transition: opacity 0.2s;
        filter: brightness(0) saturate(100%) invert(50%) sepia(11%)
          saturate(1721%) hue-rotate(7deg) brightness(92%) contrast(88%);
      }

      &:hover {
        .icon {
          fill: $neutrals2;
        }
      }

      &.active {
        .icon {
          opacity: 0;
        }

        &:before {
          opacity: 1;
        }
      }

      &:hover {
        .icon {
          fill: $neutrals8;
        }
      }
    }

    &_share {
      &.active {
        background: $primary3;
        box-shadow: inset 0 0 0 2px $primary3;

        @include dark {
          background: $primary3;
          box-shadow: inset 0 0 0 2px $primary3;
        }

        .icon {
          fill: $neutrals8;
        }
      }
    }

    &:not(:last-child) {
      margin: 0 24px 0 0;
    }
  }

  &__box {
    position: absolute;
    left: 50%;
    bottom: calc(94% + 8px);
    z-index: 2;
    width: 220px;
    transform: translateX(-50%);
    padding: 32px 16px;
    background: $neutrals8;
    border: 1px solid $neutrals6;
    box-shadow: 0px 32px 32px -8px rgba(31, 47, 70, 0.12);
    border-radius: 16px;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s;

    @include dark {
      background: $neutrals2;
      border-color: $neutrals3;
    }

    &.active {
      visibility: visible;
      opacity: 1;
    }
  }

  &__stage {
    margin-bottom: 24px;
    font-weight: 500;
  }

  &__share {
    display: flex;
    justify-content: center;
  }

  &__direction {
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: $neutrals6;
      cursor: pointer;
      transition: all 0.2s;

      .icon {
        width: 20px;
        height: 20px;
        transition: fill 0.2s;
      }

      &:hover {
        background: $primary3;

        .icon {
          fill: $neutrals8;
        }
      }

      @include dark {
        background: $neutrals3;

        .icon {
          fill: $neutrals8;
        }

        &:hover {
          background: $primary3;

          .icon {
            fill: $neutrals8;
          }
        }
      }
    }

    &:not(:last-child) {
      margin-right: 24px;
    }
  }

  &__direction2 {
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 64px;
      height: 40px;
      background: $primary1;
      cursor: pointer;
      transition: all 0.2s;

      .icon {
        width: 20px;
        height: 20px;
        transition: fill 0.2s;
        fill: $primary2;
      }

      &:hover {
        background: $primary3;

        .icon {
          fill: $neutrals8;
        }
      }

      @include dark {
        background: $primary2;
        fill: $primary1;

        .icon {
          fill: $neutrals8;
        }

        &:hover {
          background: $primary3;

          .icon {
            fill: $neutrals8;
          }
        }
      }
    }

    &:not(:last-child) {
      margin-right: 24px;
    }
  }
}

.landscape {
  .options2__list {
    justify-content: flex-start;

    @include t {
      justify-content: center;
    }
  }
}

.portrait {
  .actions__body {
    &::before {
      left: 45%;
    }
  }
}

.options2 {
  margin-top: 30px;
  margin-bottom: 30px;
  position: relative;

  @include t {
    margin-top: 0;
  }

  &__list {
    display: flex;
    padding: 8px;
    border-radius: 50px;

    @include t {
      border-radius: 0;
      justify-content: center;
    }

    .actions__body {
      position: absolute;
      width: 230px;
      padding: 16px 16px 20px;
      background: #fcfcfd;
      box-shadow: 0 16px 64px -16px rgba(31, 47, 70, 0.3);
      visibility: hidden;
      opacity: 0;
      transform: translateY(3px);
      transition: all 0.2s;
      z-index: 10;
      margin-top: 15px;
      margin-left: -35%;

      @include d {
        margin-left: -45%;
      }

      @include t {
        margin-left: -19%;
      }

      @include m {
        margin-left: -37%;
      }
    }

    .actions {
      @include m {
        button {
          margin: 0 !important;
        }
      }
    }
  }

  &__button {
    &.like {
      display: flex;
      gap: 8px;
      position: relative;
      align-items: center;
      justify-content: flex-start;
      padding-left: 12px;

      .like_count {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $neutrals2;

        @include dark {
          color: $neutrals7;
        }
      }

      &:hover {
        .like_count {
          color: $primary3;

          @include dark {
            color: $primary3;
          }
        }
      }
    }

    .icon {
      width: 24px;
      height: 24px;
    }

    &_favorite {
      .icon {
        fill: $neutrals4;
        transition: fill 0.2s;
        transition: opacity 0.2s;
      }

      &:before {
        content: "";
        position: absolute;
        width: 22px;
        height: 22px;
        left: 12px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='18' fill='none' viewBox='0 0 22 18'%3E%3Cpath d='M11 1.81A6.48 6.48 0 0 0 6.5 0 6.5 6.5 0 0 0 0 6.5c0 6.368 6.97 9.885 9.814 11.055.766.315 1.607.315 2.372 0C15.03 16.385 22 12.868 22 6.5A6.5 6.5 0 0 0 15.5 0 6.48 6.48 0 0 0 11 1.81z' fill='%23ef466f'/%3E%3C/svg%3E")
          no-repeat 50% 50% / 100% auto;
        opacity: 0;
        transition: opacity 0.2s;
        filter: brightness(0) saturate(100%) invert(50%) sepia(11%)
          saturate(1721%) hue-rotate(7deg) brightness(92%) contrast(88%);
      }

      &:hover {
        .icon {
          fill: $neutrals2;
        }
      }

      &.active {
        .icon {
          opacity: 0;
        }

        &:before {
          opacity: 1;
        }
      }

      &:hover {
        .icon {
          fill: $neutrals8;
        }
      }
    }

    &_share {
      &.active {
        background: $primary3;
        box-shadow: inset 0 0 0 2px $primary3;

        @include dark {
          background: $primary3;
          box-shadow: inset 0 0 0 2px $primary3;
        }

        .icon {
          fill: $neutrals8;
        }
      }

      .current-color {
        color: $neutrals4;
      }
    }

    &:not(:last-child) {
      margin: 0 20px 0 0 !important;
    }
  }

  &__box {
    position: absolute;
    bottom: calc(90% + 8px);
    right: calc(20% + 8px);
    z-index: 33;
    width: 220px;
    padding: 32px 16px;
    background: #fcfcfd;
    border: 1px solid #e6e8ec;
    box-shadow: 0 32px 32px -8pxrgba (31, 47, 70, 0.12);
    border-radius: 16px;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s;

    @include m {
      bottom: calc(82% + 8px);
    }

    @include dark {
      background: $neutrals2;
      border-color: $neutrals3;
    }

    &.active {
      visibility: visible;
      opacity: 1;
    }
  }

  &__stage {
    margin-bottom: 24px;
    font-weight: 500;
  }

  &__share {
    display: flex;
    justify-content: center;
  }

  &__direction {
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: $neutrals6;
      cursor: pointer;
      transition: all 0.2s;

      .icon {
        width: 20px;
        height: 20px;
        transition: fill 0.2s;
      }

      &:hover {
        background: $primary3;

        .icon {
          fill: $neutrals8;
        }
      }

      @include dark {
        background: $neutrals3;

        .icon {
          fill: $neutrals8;
        }

        &:hover {
          background: $primary3;

          .icon {
            fill: $neutrals8;
          }
        }
      }
    }

    &:not(:last-child) {
      margin-right: 24px;
    }
  }

  &__direction2 {
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 64px;
      height: 40px;
      background: $primary1;
      cursor: pointer;
      transition: all 0.2s;
      border-radius: 4px;

      .icon {
        width: 20px;
        height: 20px;
        transition: fill 0.2s;
        fill: $primary2;
      }

      &:hover {
        background: $primary3;

        .icon {
          fill: $neutrals8;
        }
      }

      @include dark {
        background: $primary2;

        .icon {
          fill: $primary1;
        }

        &:hover {
          background: $primary3;

          .icon {
            fill: $neutrals8;
          }
        }
      }
    }

    &:not(:last-child) {
      margin-right: 24px;
    }
  }
}
