﻿/*.loading {
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0px;
    background-color: rgba(0, 0, 0, 0.6);
    text-align: center;
    display: none;
    z-index: 10000;

    &.hide{
        display: none;
    }

    &.show{
        display: block !important;
    }

    .lds-ripple {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
        top: 50%;
    }

    .lds-ripple div {
        position: absolute;
        border: 4px solid #fff;
        opacity: 1;
        border-radius: 50%;
        animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }

    .lds-ripple div:nth-child(2) {
        animation-delay: -0.5s;
    }

    @keyframes lds-ripple {
        0% {
            top: 36px;
            left: 36px;
            width: 0;
            height: 0;
            opacity: 0;
        }

        4.9% {
            top: 36px;
            left: 36px;
            width: 0;
            height: 0;
            opacity: 0;
        }

        5% {
            top: 36px;
            left: 36px;
            width: 0;
            height: 0;
            opacity: 1;
        }

        100% {
            top: 0px;
            left: 0px;
            width: 72px;
            height: 72px;
            opacity: 0;
        }
    }
}
*/

// @mixin position-center($text-align: center, $top) {
//   position: absolute;
//   top: $top;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   margin: auto;
//   text-align: $text-align;
// }

#anime-loading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;

  rect {
    fill: transparent !important;
  }
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000ef;
  z-index: 9998;

  &.hide {
    display: none;
  }

  &.show {
    display: block !important;
  }

  &__loader {
    position: absolute;
    right: -14px;
    top: -16px;

    .lds-spinner {
      display: none;

      &.active {
        display: block;
      }

      div:after {
        background: $primary1;

        @include dark {
          background: $primary2;
        }
      }
    }
  }

  &__field {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
    padding: 12px 16px 12px 16px;
    margin-bottom: 40px;
    border-radius: 0px;
    border: 2px solid $neutrals6;
    background: none;
    @include button-2;
    color: $neutrals2;

    @include dark {
      border-color: $neutrals3;
      color: $neutrals8;
    }
  }

  &__Load-more {
    display: flex;
    justify-content: center;
    margin: 100px 0px;
  }
}

.loading-text {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  width: 100%;
  height: 100px;
  line-height: 100px;

  span {
    display: inline-block;
    margin: 0 5px;
    color: #fff;
    /*font-family: 'Quattrocento Sans', sans-serif;*/

    @for $i from 0 through 6 {
      &:nth-child(#{$i + 1}) {
        filter: blur(0px);
        animation: blur-text 1.5s (#{$i/5}) + s infinite linear alternate;
      }
    }
  }
}

@keyframes blur-text {
  0% {
    filter: blur(0px);
  }

  100% {
    filter: blur(4px);
  }
}

//spinner

$offset: 187;
$duration: 1.4s;

.spinner {
  animation: rotator $duration linear infinite;
  position: absolute;
  top: -150px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(270deg);
  }
}

.path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation:
    dash $duration ease-in-out infinite,
    colors ($duration * 4) ease-in-out infinite;
}

@keyframes colors {
  0% {
    stroke: #4285f4;
  }

  25% {
    stroke: #de3e35;
  }

  50% {
    stroke: #f7c223;
  }

  75% {
    stroke: #1b9a59;
  }

  100% {
    stroke: #4285f4;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: $offset;
  }

  50% {
    stroke-dashoffset: $offset/4;
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
}

/* --------- lds-ellipsis --------- */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: $primary1;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);

  @include dark {
    background: $primary2;
  }
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

/* --------- loading Image --------- */
.blacks {
  animation: blacks 1s linear;
}

@keyframes blacks {
  from {
    filter: blur(100px);
  }
}
.unblur {
  animation: unblur 1s linear;
}

@keyframes unblur {
  from {
    filter: blur(20px);
  }
  to {
    filter: blur(0);
  }
}
