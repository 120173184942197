.range {
  &__label {
    margin-bottom: 20px;
    @include hairline-2;
    color: $neutrals5;
  }

  &__indicators {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
  }

  &__text {
    font-weight: 500;
  }
}

.noUi {
  &-horizontal {
    height: 8px;
  }

  &-target {
    border: none;
    background: $neutrals6;
    box-shadow: none;

    @include dark {
      background: $neutrals3;
    }
  }

  &-connect {
    background: $primary3;
  }

  &-handle {
    border: none;
    box-shadow: none;
    background: $primary3;
    border-radius: 50%;
    cursor: pointer;

    &:before,
    &:after {
      display: none;
    }
  }

  &-horizontal &-handle {
    right: -12px;
    top: -8px;
    width: 24px;
    height: 24px;
    border: 4px solid $neutrals8;
  }

  &-tooltip {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 40px;
    padding: 4px 8px;
    background: $neutrals1;
    border-radius: 8px;
    border: none;
    font-size: 14px;
    font-weight: 600;
    color: $neutrals8;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s;

    &:before {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      @include arr(8, 4, $neutrals1, b);
    }

    @include dark {
      background: $neutrals3;

      &:before {
        @include arr(8, 4, $neutrals3, b);
      }
    }
  }

  &-handle:hover &-tooltip {
    visibility: visible;
    opacity: 1;
  }

  &-horizontal &-tooltip {
    bottom: 24px;
  }
}

html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
  right: -12px;
}

//new range slider
// doesnt work funnly on firefox or edge, need to fix

.range-slider {
  width: 100%;
  /*text-align: center;*/
  position: relative;
  /*margin-top: 50px;*/

  .field__label {
    margin-bottom: 18px;
  }

  .rangeValues {
    display: flex;
    margin-top: 10px;

    .min {
      margin-right: auto;
      margin-top: 20px;
      @include caption-bold-1;
    }

    .max {
      margin-top: 20px;
      @include caption-bold-1;
    }
  }
}

input[type="range"] {
  appearance: none;
  -webkit-appearance: none;
  /*border: 1px solid white;*/
  width: 100%;
  position: absolute;
  left: 0;
  border-radius: 10px;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  background: $neutrals7;
  border: none;
  border-radius: 3px;

  @include dark {
    background: $neutrals2;
  }
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: $primary3;
  margin-top: -10px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  border: 4px solid $primary1;

  @include dark {
    border: 4px solid $neutrals2;
  }
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #ccc;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 4px;
  background: $neutrals7;
  border: none;
  border-radius: 3px;

  @include dark {
    background: $neutrals2;
  }
}

input[type="range"]::-moz-range-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: $primary3;
}

/*hide the outline behind the border*/

input[type="range"]:-moz-focusring {
  outline: 1px solid white;
  outline-offset: -1px;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 4px;
  /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
  background: transparent;
  /*leave room for the larger thumb to overflow with a transparent border */
  border-color: transparent;
  border-width: 6px 0;
  /*remove default tick marks*/
  color: transparent;
  /*z-index: -4;*/
}

input[type="range"]::-ms-fill-lower {
  background: #777;
  border-radius: 10px;
}

input[type="range"]::-ms-fill-upper {
  background: #ddd;
  border-radius: 10px;
}

input[type="range"]::-ms-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: $primary3;
}

input[type="range"]:focus::-ms-fill-lower {
  background: #888;
}

input[type="range"]:focus::-ms-fill-upper {
  background: #ccc;
}
