.certificate {
  width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 10px;

  @include m {
    width: 100%;
  }

  &__image {
    width: 100%;
  }

  &__name {
    margin-right: auto;
  }

  &__head {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
  }

  .h1 {
    font-size: 32px;
  }
}

.certificate_holder {
  display: flex;
  align-items: center;
  gap: 20px;

  .certificate_content {
    display: flex;
    flex-direction: column;
    gap: 14px;

    .certificate_text {
      @include dm-sans;
      font-size: 12px;
      line-height: 16px;
    }

    .certificate_btn {
      padding: 10px;
      width: 100%;
      @include dm-sans;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      gap: 10px;

      img {
        @include dark {
          @include filter-primary2;
        }
      }
    }
  }
}
