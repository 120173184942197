﻿.news {
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    height: 70px;
    width: 100%;
    background: $primary1;
    z-index: 9;

    @include dark {
        background: $primary2;
    }

    &__content {
        display: flex;
        gap: 15px;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
        color: $primary2;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;

        @include m {
            font-size: 12px;
        }

        @include dark {
            color: $primary1;
        }

        img {
            width: 35px;
            filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(55deg) brightness(105%) contrast(101%);

            @include dark {
                filter: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(7465%) hue-rotate(264deg) brightness(105%) contrast(107%);
            }

            margin-left: 20px;
        }

        a {
            color: $primary3;
        }
    }

    &__close {
        margin: 0 40px;
        cursor: pointer;

        @include m {
            margin: 0 20px;
        }

        img {
            @include dark {
                filter: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(7465%) hue-rotate(264deg) brightness(105%) contrast(107%);
            }
        }
    }
}
