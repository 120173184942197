.user {
  padding: 32px 28px;
  border-radius: 16px;
  box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
  background: $neutrals8;
  border: 1px solid $neutrals6;
  text-align: center;

  @include dark {
    background: $neutrals2;
    border-color: $neutrals3;
  }

  &__avatar {
    width: 160px;
    height: 160px;

    @include m {
      margin: 0 auto 24px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  &__avatar2 {
    width: 118px;
    height: 118px;
    border: 10px solid $neutrals8;
    border-radius: 50%;

    @include dark {
      border: 10px solid $neutrals1;
    }

    @include m {
      margin: 0 auto 24px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  &__avatar3 {
    position: relative;
    width: 118px;
    border-radius: 50%;
    transition: width 0.2s;

    &:before {
      content: "";
      display: block;
      padding-bottom: 100%;
    }

    &__preview {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      overflow: hidden;
      border: 10px solid $neutrals8;

      @include dark {
        border: 10px solid $neutrals1;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1.05);
      }
    }

    &__reward {
      position: absolute;
      right: -2px;
      bottom: 16px;
    }
  }

  &__info_box {
    padding: 40px 30px 30px;
    width: 50%;

    @include m {
      margin-bottom: 20px;
      font-size: 12px;
      line-height: 1.6666666667;
      color: #777e90;
      text-align: center;
      width: 100%;
    }
  }

  &__follows_btn {
    display: flex;

    @include m {
      justify-content: center;
    }
  }

  &__follows_btn2 {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    gap: 10px;

    @include t {
      justify-content: flex-start;
      margin-top: 10px;
      gap: 30px;
    }
  }

  &__name {
    margin-bottom: 4px;
    @include body-bold-1;
  }

  &__code {
    display: inline-flex;
    align-items: center;
    // margin-bottom: 20px;
  }

  &__number {
    @include button-2;
    color: $neutrals2;
    font-weight: 400 !important;

    @include dark {
      color: $neutrals8;
    }
  }

  &_icon-copy {
    width: 24px !important;
    height: 24px !important;
    @include dark {
      @include filter-primary2;
    }
  }

  &_private-text {
    flex: 90%;
  }

  &_private-null {
    min-height: 42px !important;
    background-color: #dcdcdcfa !important;
    box-shadow: 2px 4px 33px rgba(0, 0, 0, 0.15);

    @include dark {
      background-color: #14151875 !important;
      box-shadow: -13px 0px 41px rgba(0, 0, 0, 0.4);
    }
  }

  &__private {
    max-width: 415px;
    min-height: 61px;
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
    background-color: $neutrals7;
    // box-shadow: inset 0 0 0 2px $primary3;
    // margin-top: 20px;

    @include dark {
      background-color: $neutrals2;
    }

    .private_code {
      overflow-wrap: anywhere;
      line-height: 22px;
    }
  }

  &_clipboard {
    cursor: pointer;
    display: flex;
    justify-content: center;
    // gap: 20px;

    button {
      margin-right: 20px;
      margin-left: 0px !important;
    }
  }

  &_clipboard-text {
    @include poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: $neutrals1 !important;

    @include dark {
      color: $neutrals8 !important;
    }
  }

  &__copy2 {
    margin-left: 18px !important;
  }

  &__copy {
    margin-left: 8px;

    .icon {
      width: 16px;
      height: 16px;
      fill: $black;
      transition: fill 0.2s;

      @include dark {
        fill: $white;
      }
    }

    &:hover {
      .icon {
        fill: darken($primary3, 10);
      }
    }
  }

  &__info {
    margin-bottom: 20px;
    @include caption-2;
    color: $neutrals4;
  }

  &__site {
    display: inline-flex;
    align-items: center;
    margin-bottom: 48px;
    @include button-2;
    color: $neutrals2;

    @include dark {
      color: $neutrals8;
    }

    .icon {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      fill: $neutrals4;
    }
  }

  &__control {
    display: inline-block;
    position: relative;
    margin-bottom: 48px;
  }

  &__box {
    position: absolute;
    left: 50%;
    bottom: calc(100% + 8px);
    z-index: 2;
    width: 220px;
    transform: translateX(-50%);
    padding: 32px 16px;
    background: $neutrals8;
    border: 1px solid $neutrals6;
    box-shadow: 0px 32px 32px -8px rgba(31, 47, 70, 0.12);
    border-radius: 16px;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s;

    @include dark {
      background: $neutrals2;
      border-color: $neutrals3;
    }

    &.active {
      visibility: visible;
      opacity: 1;
    }
  }

  &__stage {
    margin-bottom: 24px;
    font-weight: 500;
  }

  &__share {
    display: flex;
    justify-content: center;
  }

  &__direction {
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: $neutrals6;
      cursor: pointer;
      transition: all 0.2s;

      .icon {
        width: 20px;
        height: 20px;
        transition: fill 0.2s;
      }

      &:hover {
        background: $blue;

        .icon {
          fill: $neutrals8;
        }
      }

      @include dark {
        background: $neutrals3;

        .icon {
          fill: $neutrals8;
        }

        &:hover {
          background: $blue;

          .icon {
            fill: $neutrals8;
          }
        }
      }
    }

    &:not(:last-child) {
      margin-right: 24px;
    }
  }

  &__btns {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__button {
    .icon {
      width: 20px;
      height: 20px;
    }

    span {
      &:nth-child(2) {
        display: none;
      }
    }

    &.active {
      background: $blue;
      box-shadow: inset 0 0 0 2px $blue;
      color: $neutrals8;

      @include dark {
        background: $blue;
        box-shadow: inset 0 0 0 2px $blue;
      }

      .icon {
        fill: $neutrals8;
      }

      span {
        &:first-child {
          display: none;
        }

        &:nth-child(2) {
          display: inline;
        }
      }
    }

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  &__socials {
    display: flex;
    justify-content: left;
    padding: 15px;

    @include m {
      justify-content: center;
    }
  }

  &__socials2 {
    display: flex;
    justify-content: flex-end;
    /*padding: 15px;*/
    padding: 15px 0 15px 15px;

    @include t {
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 0 2px $neutrals6 inset;
      height: 40px;
      padding: 0 24px;
    }

    @include m {
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 0 2px $neutrals6 inset;
      height: 48px;
      padding: 0 15px;
    }
  }

  &__social {
    .icon {
      width: 20px;
      height: 20px;
      fill: $neutrals4;
      transition: fill 0.2s;

      @include t {
        width: 26px;
        height: 26px;
      }
    }

    i {
      font-size: 20px;
      color: $neutrals4;
      transition: fill 0.2s;
    }

    &:hover {
      .icon {
        fill: $primary3;
      }

      i {
        color: $primary3;
      }
    }

    &:not(:last-child) {
      margin-right: 24px;
    }
  }

  &__note {
    @include caption-1;
    color: $neutrals4;
    font-weight: 400;

    @include dark {
      border-color: $neutrals3;
    }
  }

  &__note_m {
    margin-top: 48px;
    padding-top: 48px;
    text-align: center;
    border-top: 1px solid $neutrals6;
    color: $neutrals4;

    @media (min-width: "678px") {
      display: none;
    }

    @include dark {
      border-color: $neutrals3;
    }
  }

  &__referral {
    &_note {
      @include main-font;
      font-style: normal;
      font-size: 14px;
      line-height: 24px;
      align-items: center;
      color: $neutrals4;
      margin-top: 32px;
    }

    &_Acknowledged {
      color: $primary3 !important;
      cursor: pointer;

      @include m {
        text-align: left;
      }
    }

    &_link {
      display: flex;
      gap: 10px;
      margin-top: 25px;
      overflow-wrap: anywhere;

      @include m {
        display: grid;
      }
    }

    &_address {
      color: $neutrals4;
    }
  }
}
