.h1,
.h2,
.h3,
.h4 {
  @include main-font;
  font-weight: 700;
}

/*.hero {
    font-size: 96px;
    line-height: 1;
    letter-spacing: -.02em;
}*/

.h1 {
  font-size: 64px;
  line-height: 1.5;
  letter-spacing: -0.02em;
}

.h2 {
  font-size: 48px;
  line-height: (56/48);
  letter-spacing: -0.02em;
}

.h3 {
  font-size: 24px;
  line-height: (48/40);
  letter-spacing: -0.01em;
}

.h4 {
  font-size: 32px;
  line-height: (40/32);
  letter-spacing: -0.01em;
}

.h5 {
  font-size: 24px;
  line-height: (40/24);
  letter-spacing: -0.01em;
}

.brown-title {
  color: $primary3;
}

.heading3 {
  @include dm-sans;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px; /* 120% */
  letter-spacing: -0.4px;

  @include m {
    font-size: 28px;
    margin-top: 30px;
  }
}
