.preview {
  &__inner {
    padding: 48px;
    background: $neutrals8;
    /*box-shadow: 0px 64px 64px rgba(31, 47, 70, 0.12);*/
    box-shadow: 0px 12px 60px rgba(0, 0, 0, 0.1);
    border-radius: 0px;

    @include d {
      padding: 24px;
    }

    @include t {
      position: relative;
      width: 352px;
      margin: auto;
    }

    @include dark {
      background: $neutrals1;
      box-shadow: inset 0 0 0 1px $neutrals3;
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    border-radius: 0;
    background-color: transparent;
    border: 0;
    position: relative;
  }

  &.landscape {
    transition: all 0.2s;

    .preview__inner {
      padding: 30px;
    }

    .card__preview_upload {
      height: 260px;
    }
  }

  &__category {
    position: absolute;
    top: 20px;
    left: 20px;

    &_name {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }

    .select2-selection__rendered {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }

    li {
      background: $primary3;
      color: $primary2;
      border-radius: 5px;
      padding: 0 5px;

      span {
        display: none;
      }
    }
  }

  &__name {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: start;
    padding: 15px 10px;
    padding-top: 10px;
    overflow-wrap: anywhere;
  }

  &__row {
    display: flex;
  }

  &__ratio {
    display: flex;
    /*gap: 8px;*/

    &__item {
      display: flex;
      /*gap: 8px;*/

      input {
        opacity: 0;
        visibility: hidden;

        &:checked + .ratio_img {
          background: $primary1;

          img {
            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7471%) hue-rotate(121deg)
              brightness(108%) contrast(108%);
          }

          @include dark {
            background: $primary2;

            img {
              filter: brightness(0) saturate(100%) invert(0%) sepia(78%) saturate(7428%) hue-rotate(201deg)
                brightness(90%) contrast(89%);
            }
          }
        }
      }

      .ratio_img {
        width: 40px;
        height: 40px;
        border: 2px solid $neutrals6;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include dark {
          border: 2px solid $neutrals3;
        }
      }
    }
  }

  &__close {
    display: none;
    position: absolute;
    top: 20px;
    right: 24px;
    width: 40px;
    height: 40px;
    background: $neutrals8;
    border: 2px solid $neutrals6;
    border-radius: 50%;
    font-size: 0;

    @include t {
      display: inline-block;
    }

    .icon {
      width: 14px;
      height: 14px;
      fill: $neutrals2;
      transition: transform 0.2s;
    }

    @include dark {
      border-color: $neutrals3;
      background: $neutrals1;

      .icon {
        fill: $neutrals8;
      }
    }
  }

  &__info {
    margin-bottom: 24px;
    @include body-bold-1;
    margin-right: auto;
    margin-left: 10px;
  }

  &__clear {
    gap: 10px;
    padding: 8px, 8px, 6px, 8px;
    min-height: 45px;
    min-width: 189px;
    margin: 20px 0px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    color: $neutrals4;
    transition: color 0.2s;

    span {
      display: flex;
      align-items: center;
      font-weight: 700;
      @include poppins;
      margin-right: 5px;
      font-size: 14px;
      line-height: 12px;
      text-align: left;
    }

    .icon {
      fill: $neutrals4;
      transition: fill 0.2s;

      @include dark {
        filter: brightness(0) saturate(100%) invert(0%) sepia(81%) saturate(7436%) hue-rotate(200deg) brightness(114%)
          contrast(100%);
      }
    }

    &:hover {
      color: $primary3;

      .icon {
        fill: $primary3;
      }
    }
  }
}
