@import "_variables.scss";
.text {
  &-right {
    text-align: right;
  }

  &-center {
    text-align: center;
  }

  &-uppercase {
    text-transform: uppercase;
  }
}

.m-auto {
  margin: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.mb-5 {
  margin-bottom: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-10 {
  margin-top: 10px;
}

.pt-15 {
  padding-top: 15px !important;
}

.align {
  &-baseline {
    align-items: baseline;
  }
}

.desktop {
  &-hide {
    @include d {
      display: none !important;
    }
  }

  &-hide-min {
    @include rmin(1023) {
      display: none !important;
    }
  }

  &-show {
    display: none !important;

    @include d {
      display: block !important;
    }
  }

  &-text-right {
    @include d {
      text-align: right !important;
    }
  }
}

.tablet {
  &-hide {
    @include t {
      display: none !important;
    }
  }

  &-show {
    display: none !important;

    @include t {
      display: block !important;
    }
  }

  &-text-right {
    @include t {
      text-align: right !important;
    }
  }
}

.mobile {
  &-hide {
    @include m {
      display: none !important;
    }
  }

  &-show {
    display: none !important;

    @include m {
      display: block !important;
    }
  }

  &-text-right {
    @include m {
      text-align: right !important;
    }
  }
}

.hide {
  display: none !important;
}

.link {
  color: $neutrals2;

  @include dark {
    color: $neutrals8;
  }
}

.underline {
  text-decoration: underline;
}

.text-center {
  text-align: center !important;
  margin: 0 auto !important;
}

.center-item {
  text-align: center;
}

.error {
  padding: 10px;
  border-radius: 5px;
  background-color: $pink;
  color: #fff;
  margin-bottom: 30px;
}
