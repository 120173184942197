﻿@import "../_variables.scss";

input[type="checkbox"],
input[type="radio"] {
  &.toggle {
    display: inline-block;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 48px;
    height: 24px;
    background-color: $neutrals6;
    position: relative;
    border-radius: 30px;
    /*border: 1px solid map-get($lightColor, primary);*/
    border: 1px solid $neutrals6;
    box-shadow: none;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    @include dark {
      background-color: $neutrals3;
      border: 1px solid $neutrals3;
    }

    &:checked:before {
      background-color: $primary1;

      @include dark {
        background-color: $primary1;
      }
    }

    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 16px;
      transform: translateY(-50%);
      height: 16px;
      background-color: $primary1;
      top: 50%;
      left: 4px;
      border-radius: 50%;
      transition: all 0.2s ease-in-out;

      @include dark {
        background-color: $primary1;
      }
    }
  }

  &:checked.toggle {
    background-color: $primary1;

    &:before {
      transform: translate(24px, -50%);
      background-color: $neutrals8;
    }

    @include dark {
      background-color: $primary2;
    }
  }
}

input[type="checkbox"],
input[type="radio"] {
  &.toggle2 {
    display: inline-block;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 50px;
    height: 30px;
    background-color: $neutrals6;
    position: relative;
    border-radius: 30px;
    border: 1px solid $neutrals6;
    box-shadow: none;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    @include dark {
      background-color: $neutrals3;
      border: 1px solid $neutrals3;
    }

    &:hover:before {
      background-color: $primary1;

      @include dark {
        background-color: $primary1;
      }
    }

    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      transform: translateY(-50%);
      width: 27px;
      height: 27px;
      background-color: $primary1;
      top: 50%;
      left: 1px;
      border-radius: 50%;
      transition: all 0.2s ease-in-out;

      @include dark {
        background-color: $primary1;
      }
    }
  }

  &:checked.toggle2 {
    background-color: $primary1;

    &:before {
      transform: translate(19px, -50%);
      background-color: $neutrals8;
      @include dark {
        background-color: $primary1;
      }
    }

    @include dark {
      background-color: $primary2;
    }
  }
}

input[type="checkbox"],
input[type="radio"] {
  &.toggle3 {
    display: inline-block;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 48px;
    height: 24px;
    background-color: $neutrals6;
    position: relative;
    border-radius: 30px;
    border: 1px solid $neutrals6;
    box-shadow: none;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    @include dark {
      background-color: $neutrals3;
      border: 1px solid $neutrals3;
    }

    &:hover:before {
      background-color: $primary1;

      @include dark {
        background-color: $primary1;
      }
    }

    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      transform: translateY(-50%);
      width: 18px;
      height: 18px;
      background-color: $primary1;
      top: 50%;
      left: 6px;
      border-radius: 50%;
      transition: all 0.2s ease-in-out;

      @include dark {
        background-color: $primary1;
      }
    }
  }

  &:checked.toggle3 {
    background-color: $primary3;

    &:before {
      transform: translate(19px, -50%);
      background-color: $neutrals8;
      @include dark {
        background-color: $primary1;
      }
    }

    @include dark {
      background-color: $primary3;
    }
  }
}
