.topphotographersSwipe {
  .swiper-button-prev::before,
  .swiper-button-next::before {
    font-size: 50px !important;
  }
  .swiper-button-prev {
    //left: -10px;
    color: $primary1;
    border-radius: 50%;
    width: 64px;
    height: 64px;
    background-color: $primary2;
    font-size: small;

    // transform: rotate(270deg);
    border: 2px solid $neutrals6;
    border-radius: 100%;
    ::after {
      font-size: small;
    }
    &:hover {
      border: 2px solid $primary3;
      color: $primary3;

      i {
        color: $primary3;
      }
    }
    @include t {
      position: relative;
      left: 28%;
      top: -43px;
    }

    @include dark {
      background-color: $neutrals2;
      border: 2px solid $neutrals3;
      color: $primary2;

      &:hover {
        border: 2px solid $primary3;
        color: $primary3;
      }
    }
  }
  .swiper-button-next {
    //left: -10px;
    color: $primary1;
    border-radius: 50%;
    width: 64px;
    height: 64px;
    background-color: $primary2;
    font-size: small;

    // transform: rotate(270deg);
    border: 2px solid $neutrals6;
    border-radius: 100%;
    ::after {
      font-size: small;
    }
    &:hover {
      border: 2px solid $primary3;
      color: $primary3;
    }

    @include dark {
      background-color: $neutrals2;
      border: 2px solid $neutrals3;
      color: $primary2;
      i {
        color: $primary2;
      }

      &:hover {
        border: 2px solid $primary3;
        color: $primary3;
        i {
          color: $primary3;
        }
      }
    }
    @include t {
      position: relative;
      top: -86px;
      left: 50%;
    }
  }
}
