// media queries
@mixin u {
  @media only screen and (max-width: "10000px") {
    @content;
  }
}

@mixin w {
  @media only screen and (max-width: "1419px") {
    @content;
  }
}

@mixin x {
  @media only screen and (max-width: "1339px") {
    @content;
  }
}

@mixin d {
  @media only screen and (max-width: "1179px") {
    @content;
  }
}

@mixin t {
  @media only screen and (max-width: "1023px") {
    @content;
  }
}

@mixin m {
  @media only screen and (max-width: "767px") {
    @content;
  }
}

@mixin a {
  @media only screen and (max-width: "639px") {
    @content;
  }
}

@mixin s {
  @media only screen and (max-width: "474px") {
    @content;
  }
}

@mixin f {
  @media only screen and (max-width: "280px") {
    @content;
  }
}
// fonts
@mixin dm-sans {
  font-family: var(--dm_sans), sans-serif;
}

@mixin poppins {
  font-family: var(--poppins), "sans-serif";
}

@mixin outfit {
  font-family: var(--outfit), sans-serif;
}

@mixin main-font {
  //   font-family: "Poppins", sans-serif;
  font-family: var(--poppins), "sans-serif";
}
// colors
$white: #ffffff;
$primary2: #ffffff;
$blue: #3772ff;
$blue-light: #4bc9f0;
$purple: #9757d7;
$purple-light: #cdb4db;
$pink: #ef466f;
$asphalt: #e4d7cf;
$yellow: #ffd166;
$black: #000000;
$primary1: #000000;
$primary3: #8e793e;
$primary4: #87733b;
$links: #8e793e;
$success: #5abf52;

$bnb: #f3ba2f;
$eth: #393939;
$matic: #8247e5;

$neutrals0: #0d0d0d;
$neutrals1: #141416;
$neutrals2: #23262f;
$neutrals3: #353945;
$neutrals4: #777e90;
$neutrals5: #b1b5c3;
$neutrals6: #e6e8ec;
$neutrals7: #f4f5f6;
$neutrals8: #fcfcfd;

$coconut: #d0d4dc;
$coconut2: #f6f4f2;
$primary5: #0f0f0f;
$primary6: #181818;
$navy1: #1e2227;

@mixin filter-primary1 {
  filter: brightness(0) saturate(100%) invert(0%) sepia(66%) saturate(7479%)
    hue-rotate(293deg) brightness(78%) contrast(101%);
}

@mixin filter-primary2 {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%)
    hue-rotate(100deg) brightness(102%) contrast(100%);
}

@mixin filter-primary3 {
  filter: brightness(0) saturate(100%) invert(48%) sepia(9%) saturate(2413%)
    hue-rotate(6deg) brightness(95%) contrast(80%);
}

@mixin filter-neutrals1 {
  filter: brightness(0) saturate(100%) invert(3%) sepia(2%) saturate(3967%)
    hue-rotate(201deg) brightness(96%) contrast(89%);
}

@mixin filter-neutrals4 {
  filter: brightness(0) saturate(100%) invert(49%) sepia(23%) saturate(258%)
    hue-rotate(186deg) brightness(95%) contrast(87%);
}

@mixin filter-neutrals3 {
  filter: brightness(0) saturate(100%) invert(19%) sepia(10%) saturate(1069%)
    hue-rotate(187deg) brightness(91%) contrast(86%);
}

@mixin filter-neutrals6 {
  filter: brightness(0) saturate(100%) invert(99%) sepia(10%) saturate(729%)
    hue-rotate(177deg) brightness(96%) contrast(93%);
}

// typography
@mixin body-1 {
  font-size: 24px;
  line-height: (32/24);
  letter-spacing: -0.01em;
}

@mixin body-bold-1 {
  font-size: 24px;
  line-height: (32/24);
  font-weight: 600;
  letter-spacing: -0.01em;
}

@mixin body-bold-1-1 {
  font-size: 24px;
  line-height: (32/16);
  font-weight: 600;
  letter-spacing: -0.01em;
}

@mixin body-2 {
  font-size: 16px;
  line-height: (24/16);
}

@mixin body-bold-2 {
  font-size: 16px;
  line-height: (24/16);
  font-weight: 500;
}

@mixin caption-1 {
  font-size: 14px;
  line-height: (24/14);
}

@mixin caption-bold-1 {
  font-size: 14px;
  line-height: (24/14);
  font-weight: 500;
}

@mixin caption-2 {
  font-size: 12px;
  line-height: (20/12);
}

@mixin caption-bold-2 {
  font-size: 12px;
  line-height: (20/12);
  font-weight: 600;
}

@mixin hairline-1 {
  font-size: 16px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
}

@mixin hairline-2 {
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
  /*text-transform: uppercase;*/
}

@mixin hairline-3 {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  /*text-transform: uppercase;*/
}

@mixin hairline-4 {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  /*text-transform: uppercase;*/
}

@mixin headline($fSize, $fWeight, $fHeight) {
  font-weight: $fWeight;
  font-size: $fSize;
  line-height: $fHeight;
}

@mixin button-1 {
  @include main-font;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
}

@mixin button-2 {
  @include main-font;
  font-size: 14px;
  line-height: (16/14);
  font-weight: 700;
}

@mixin button-3 {
  @include main-font;
  font-size: 16px;
  line-height: (16/14);
  font-weight: 400;
}

@mixin wrap-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  /*text-align: start;*/
}

@mixin wrap-text1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
}

@mixin wrap-text2($num) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $num;
  line-clamp: $num;
  -webkit-box-orient: vertical;
}

@mixin control-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
