//Data Table

// .kVrXuC,
// .dwPlXY,
// .gKbhqU,
// .iSAVrt,
.rdt_Table,
.rdt_TableHeadRow,
.rdt_TableRow,
.rdt_TableBody,
.rdt_Pagination,
.rdt_ExpanderRow {
  background-color: transparent !important;
  color: $black !important;

  @include dark {
    color: $white !important;
  }
}

.ChildTable .rdt_Table,
.ChildTable .rdt_TableHeadRow,
.ChildTable .rdt_TableRow,
.ChildTable .rdt_TableBody,
.ChildTable .rdt_Pagination {
  background-color: transparent !important;
  color: $black !important;

  @include dark {
    color: $white !important;
  }
}

.rdt_ExpanderRow {
  // box-shadow: 0px 0px 7px -1px ;
  border-radius: 5px;
  margin: 20px 12px;
  max-width: 95%;

  .rdt_TableCell i {
    font-size: 20px;
  }
}

.rdt_Table {
  &.eSTlnH {
    background-color: $neutrals7 !important;
    color: $neutrals0;

    @include dark {
      background-color: $neutrals0 !important;
      color: $neutrals7;
    }
  }

  .gZWIKX,
  .jVeafb {
    background-color: $neutrals7 !important;
    color: $neutrals0;

    @include dark {
      background-color: $neutrals0 !important;
      color: $neutrals7;
    }
  }

  .jVeafb:not(:last-of-type),
  .gZWIKX {
    border-bottom-color: $neutrals4;

    @include dark {
      border-bottom-color: $neutrals5;
    }
  }
}

.rdt_Pagination {
  &.jxflYm {
    background-color: $neutrals7 !important;
    color: $neutrals0;
    border-bottom-color: $neutrals4;

    @include dark {
      background-color: $neutrals0 !important;
      color: $neutrals7;
      border-bottom-color: $neutrals5;
    }
  }

  .bvxQGL {
    fill: $neutrals0;

    @include dark {
      fill: $neutrals8;
    }
  }

  .bvxQGL:disabled {
    fill: $neutrals0;

    @include dark {
      fill: $neutrals8;
    }
  }
}

.custome-grid-1 {
  border-radius: 8px;
  // box-shadow: 0 1px 9px -2px rgba(#878787, 0.2);
  min-height: 400px !important;
}

.custome-grid-mobile-1 {
  background: $neutrals6;
  border-radius: 10px;
  padding: 20px;
  margin: 0px 20px;
  margin-bottom: 10px;
  border: 1px solid $neutrals4;

  @include dark {
    background: $neutrals0;
    border: 1px solid $neutrals2;
  }
}

.rdt_TableCol:last-child,
.sc-fqkwJk:last-child,
.sc-dcJtft:last-child,
.sc-dAlxHm:last-child,
.fPLNFH:last-child,
.dBiUCQ:last-child,
.buBlD:last-child {
  background: #ffffff !important;
  position: sticky !important;
  right: -5px !important;

  @include dark {
    background: #000000 !important;
  }
}

.dOTSzF:disabled {
  fill: rgb(172 172 172 / 65%) !important;
  @include dark {
    fill: rgb(255 255 255 / 39%) !important;
  }
}

.dOTSzF {
  @include dark {
    fill: rgb(255, 255, 255) !important;
  }
}

.bOmZm {
  background-color: transparent !important;
  @include dark {
    background-color: black !important;
  }
}

.bOmZm option {
  color: black !important;
  @include dark {
    color: #ffffff !important;
  }
}

.iTCtdz {
  color: black !important;
  fill: black !important;

  @include dark {
    color: rgb(255, 255, 255) !important;
    fill: rgb(255, 255, 255) !important;
  }
}

.iTCtdz:disabled {
  color: rgb(172 172 172 / 65%) !important;
  fill: rgb(172 172 172 / 65%) !important;
  @include dark {
    fill: rgb(255 255 255 / 39%) !important;
    color: rgb(172 172 172 / 65%) !important;
  }
}

.rdt_TableRow {
  svg {
    // fill: $table-color;
  }
}

.table-state {
  display: inline-flex;
  padding: 2px 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.195px;

  &_waiting {
    background: rgba(1, 127, 237, 0.15);
    color: #0187ed;
  }

  &_active {
    background: rgba(5, 237, 1, 0.15);
    color: #4fd601;
  }

  &_pending {
    background: rgba(237, 162, 1, 0.15);
    color: #ed8f01;
  }

  &_reject {
    background: rgba(237, 1, 12, 0.15);
    color: #ed010c;
  }

  &_Enable {
    background: rgba(1, 237, 221, 0.15);
    color: rgb(1, 207, 214);
  }

  &_Disable {
    background: rgba(255, 193, 7, 0.15);
    color: #ffc107;
  }

  &_visible {
    background: rgba(40, 167, 70, 0.15);
    color: #28a745;
  }

  &_red {
    background: rgba(220, 53, 70, 0.15);
    color: #dc3545;
  }

  &_gray {
    background: rgba(219, 219, 219, 0.15);
    color: #565656;
  }

  &_Disable-button {
    background: rgba(137, 137, 137, 0.15);
    color: #ffc107;
    opacity: 0.4;
  }
}

.table-status {
  padding: 10px;
  border-radius: 12px;
}

.table-status-endDate {
  color: #ed010c;

  @include dark {
    color: #ff3232;
  }
}

.fix-column {
  position: fixed;
  right: 50px;
  padding: 5px 40px 5px 10px;
}

#cell-end-undefined {
  background: $neutrals6 !important;
  position: sticky !important;
  right: -5px !important;

  @include dark {
    background: $neutrals0 !important;
  }
}
.jSsogc:last-child {
  background: $neutrals6 !important;
  position: sticky !important;
  right: -5px !important;

  @include dark {
    background: $neutrals0 !important;
  }
}

.last-column-header {
  background: $neutrals6 !important;
  position: sticky !important;
  right: -5px !important;

  @include dark {
    background: $neutrals0 !important;
  }
}

.rdt_TableRow {
  &:hover {
    background: rgb(172 147 76 / 38%) !important;
    @include dark {
      background: #1d1600 !important;
    }
  }
}

.rdt_TableCol:last-child,
.sc-fqkwJk:last-child,
.sc-dcJtft:last-child,
.sc-dAlxHm:last-child,
.fPLNFH:last-child,
.dBiUCQ:last-child,
.buBlD:last-child {
  background: $neutrals6 !important;
  position: sticky !important;
  right: -5px !important;

  @include dark {
    background: $neutrals0 !important;
  }
}

.DfDUN:disabled {
  fill: rgb(172 172 172 / 65%) !important;
  @include dark {
    fill: rgb(255 255 255 / 39%) !important;
  }
}

.DfDUN {
  @include dark {
    fill: rgb(255, 255, 255) !important;
  }
}

.bOmZm {
  background-color: transparent !important;
  @include dark {
    background-color: black !important;
  }
}

.bOmZm option {
  color: black !important;
  @include dark {
    color: #ffffff !important;
  }
}

.btJbqV {
  color: black !important;
  fill: black !important;

  @include dark {
    color: rgb(255, 255, 255) !important;
    fill: rgb(255, 255, 255) !important;
  }
}

.btJbqV:disabled {
  color: rgb(172 172 172 / 65%) !important;
  fill: rgb(172 172 172 / 65%) !important;
  @include dark {
    fill: rgb(255 255 255 / 39%) !important;
    color: rgb(172 172 172 / 65%) !important;
  }
}

.customTask-table {
  display: none;
  @include m {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  &_flex-pc {
    display: flex;
    align-items: center;
    @include m {
      display: none;
    }
  }

  &_padding {
    margin-right: 10px;
  }
}

.table-filter {
  border: 1px solid gray;
  padding: 10px 30px;
  border-radius: 10px;

  &_title {
    font-size: 20px;
    @include poppins;

    @include m {
      font-size: 18px;
    }
  }

  &_text {
    font-size: 18px;
    @include poppins;

    @include m {
      font-size: 16px;
    }
  }

  &_button {
    padding: 10px;
    border-radius: 10px;
    margin: 5px 0px;
  }

  &_button2 {
    cursor: pointer;
  }

  &_position {
    display: flex;
    align-items: center;
    position: relative;
    margin-left: 0 !important;

    svg {
      width: 40px;
      height: 40px;
      margin-left: 0 !important;
    }
  }

  &_margin {
    margin: 10px 0px;
  }

  &_flex {
    margin: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &_flex2 {
    display: flex;
    justify-content: end;
    align-items: center;
  }

  &_flex-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &_block {
    margin: 30px;
    display: block;
  }
}

.table-filter-button {
  display: flex;
  justify-content: end;
  border-radius: 10px;

  &_padding {
    padding: 0 5px;
  }
}

//-------------- list grid table --------------

.list_table {
  &__grid {
    padding: 10px;
    overflow-x: auto;

    &__grid2 {
      padding: 10px 0px !important;
    }

    &::-webkit-scrollbar {
      height: 5px;
      width: 5px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #8888884d;
      border-radius: 10px;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    @include t {
      width: 100%;
    }
  }

  &__user {
    &_image {
      width: 42px;
      height: 42px;
      object-fit: cover;
      border-radius: 50px;
    }

    &_info {
      .info_name {
        @include body-bold-2;
        color: $primary1;

        @include dark {
          color: $primary2;
        }
      }

      .info_address {
        @include caption-2;
        color: $neutrals4;
      }
    }
  }

  &__username {
    display: flex;
    gap: 14px;
  }

  &__table {
    display: table;
    width: 100%;
    background: $primary2;
    border: 1px solid $neutrals6;

    @include dark {
      background: $primary1;
      border: 1px solid $neutrals3;
    }

    &_heading {
      display: table-header-group;
    }

    &_body {
      display: table-row-group;
    }

    &_row {
      display: table-row;
    }

    &_head {
      @include caption-bold-1;
    }
    /*&_cell {
        }*/

    &_head,
    &_cell {
      padding: 12px 16px;
      display: table-cell;
      min-width: 200px;
      vertical-align: middle;
      border-bottom: 1px solid $neutrals6;

      @include dark {
        border-bottom: 1px solid $neutrals3;
      }
    }

    &_message {
      min-height: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
      position: sticky;
      left: 0;
      background: $primary2;

      @include dark {
        background: $primary1;
      }

      .message_text {
        font-size: 30px;
        font-weight: 600;
        text-transform: capitalize;
        text-align: center;

        @include m {
          font-size: 25px;
        }
      }

      .message_text2 {
        @include poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        color: $neutrals4;
        max-width: 596px;

        @include dark {
          color: $neutrals5;
        }
      }
    }
  }

  &__status {
    border-radius: 4px;
    height: 40px;
    padding: 8px 12px;
    display: inline-flex;
    align-items: center;

    &.one {
      background: #e4ffef;

      @include dark {
        background: #00333e;
      }
    }

    &.two {
      background: #e4ffef;

      @include dark {
        background: #1c241f;
      }
    }

    &.three {
      background: #e7dcff;

      @include dark {
        background: #150a2d;
      }
    }

    &.four {
      background: #daf7ff;

      @include dark {
        background: #00333e;
      }
    }

    &.five {
      background: #ffe0da;

      @include dark {
        background: #3e0b00;
      }
    }

    &.six {
      background: #ffe1da00;

      @include dark {
        background: #150a2d00;
      }
    }

    .count {
      min-width: 20px;
      height: 20px;
      padding: 0 3px;
      border-radius: 50px;
      border: 2px solid $primary3;
      color: $primary3;
      font-size: 10px;
      margin-left: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__socials {
    display: flex;
    align-items: center;
  }

  &__social {
    display: flex;
    align-items: center;

    .icon {
      width: 20px;
      height: 20px;
      fill: $neutrals4;
      transition: fill 0.2s;

      @include t {
        width: 26px;
        height: 26px;
      }
    }

    i {
      font-size: 20px;
      color: $neutrals4;
      transition: fill 0.2s;

      @include t {
        font-size: 26px;
      }
    }

    &:hover {
      .icon {
        fill: $primary3;
      }

      i {
        color: $primary3;
      }
    }

    &:not(:last-child) {
      margin-right: 24px;
    }
  }
}
