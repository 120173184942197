.collections {
  &__wrapper {
    position: relative;
  }

  &__title {
    margin-bottom: 80px;

    @include d {
      margin-bottom: 64px;
    }

    @include m {
      margin-bottom: 65px;
      text-align: center;
    }
  }

  &__inner {
    margin: 0 -16px;
  }

  &__gallery {
    display: flex;
    flex-wrap: wrap;
    margin: -8px -4px 16px;
  }

  &__preview {
    flex: 0 0 calc(33.333% - 8px);
    width: calc(33.333% - 8px);
    margin: 8px 4px 0;

    &:first-child {
      flex: 0 0 calc(100% - 8px);
      width: calc(100% - 8px);

      img,
      video {
        height: calc(330px + 8px);
        object-fit: cover;
      }
    }

    img,
    video {
      width: 100%;
      border-radius: 0px;
      height: 70px;
      object-fit: cover;
    }
  }

  &__preview_2 {
    flex: 0 0 calc(100% - 8px);
    width: calc(100% - 8px);
    margin: 8px 4px 0;

    &:first-child {
      flex: 0 0 calc(100% - 8px);
      width: calc(100% - 8px);

      img,
      video {
        height: calc(330px / 2);
        object-fit: cover;
      }
    }

    img,
    video {
      width: 100%;
      border-radius: 0px;
      height: calc(330px / 2);
      object-fit: cover;
    }
  }

  &__preview_3 {
    flex: 0 0 calc(50% - 8px);
    width: calc(50% - 8px);
    margin: 8px 4px 0;

    &:first-child {
      flex: 0 0 calc(100% - 8px);
      width: calc(100% - 8px);

      img,
      video {
        height: 250px;
        object-fit: cover;
      }
    }

    img,
    video {
      width: 100%;
      border-radius: 0px;
      height: 80px;
      object-fit: cover;
    }
  }

  &__preview_4 {
    flex: 0 0 calc(33.333% - 8px);
    width: calc(33.333% - 8px);
    margin: 8px 4px 0;

    &:first-child {
      flex: 0 0 calc(100% - 8px);
      width: calc(100% - 8px);

      img,
      video {
        height: 250px;
        object-fit: cover;
      }
    }

    img,
    video {
      width: 100%;
      border-radius: 0px;
      height: 80px;
      object-fit: cover;
    }
  }

  &__subtitle {
    margin-bottom: 8px;
    @include body-bold-1;
    color: $neutrals2;
    transition: color 0.2s;

    @include dark {
      color: $neutrals8;
    }
  }

  &__item:hover &__subtitle {
    color: $primary3;
  }

  &__line {
    display: flex;
    align-items: center;
  }

  &__user {
    display: flex;
    align-items: center;
    margin-right: auto;
    color: $neutrals3;

    @include dark {
      color: $neutrals6;
    }

    span {
      font-weight: 500;
    }
  }

  &__avatar {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-right: 12px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  &__counter {
    flex-shrink: 0;
  }

  &__slider {
    position: static;
    visibility: hidden;

    @include m {
      padding-bottom: 72px;
    }

    &.slick-initialized {
      visibility: visible;
    }
  }

  .slick-list {
    overflow: hidden;
  }

  .slick-slide {
    padding: 0 16px;
    opacity: 0;
    transition: opacity 0.4s;

    &.slick-active {
      opacity: 1;
    }
  }

  .slick-arrow {
    top: 4px;

    @include m {
      top: auto;
      bottom: 0;
    }
  }

  .slick-prev {
    right: 48px;

    @include m {
      right: auto;
      left: calc(50% - 44px);
    }
  }

  .slick-next {
    right: 0;

    @include m {
      right: calc(50% - 44px);
    }
  }

  .fix-image-1-row {
    height: 300px;
    object-fit: cover;
  }

  .fix-image-2-row {
    height: 150px;
    object-fit: cover;
  }

  .card {
    background-color: transparent !important;

    &__title {
      color: $neutrals2;

      @include dark {
        color: $neutrals8;
      }
    }
  }
}

/*collection2*/
.collections2 {
  padding-bottom: 140px;

  &__wrapper {
    position: relative;
  }

  &__row {
    display: flex;
  }

  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $neutrals2;
    margin-right: auto;
    margin-bottom: 35px;
    @include wrap-text;

    @include dark {
      color: $neutrals8;
    }
  }

  &__video {
    cursor: pointer;
  }

  &__inner {
    margin: 0 -16px;
  }

  &__control {
    position: absolute;
    /* top: 14px; */
    left: 0;
    width: 100%;
    height: 260px;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s;

    &_share {
      position: absolute;
      right: 0;
      bottom: 0;
      padding: 10px;
      background: rgba($primary2, 0.3);
      border-top-left-radius: 25px;
      transition: all 0.3s;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        background: $neutrals8;
        // padding: 5px 8px;
        border-radius: 50%;

        &:hover {
          color: $primary3;
        }

        @include dark {
          background: $neutrals2;

          .current-color {
            color: $neutrals4;

            &:hover {
              color: $primary3;
            }
          }
        }

        img {
          width: 20px;
          height: 20px;
          @include filter-neutrals4;
        }
      }
    }

    &_likeShareControl {
      position: absolute;
      right: 0;
      bottom: 0;
      padding: 10px;
      background: rgba($primary2, 0.3);
      border-top-left-radius: 25px;
      transition: all 0.3s;
      display: flex;
      gap: 8px;

      .card2__favorite2 {
        padding: 0px 10px;
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        background: $neutrals8;
        // padding: 5px 8px;
        border-radius: 50%;

        &:hover {
          color: $primary3;
        }

        @include dark {
          background: $neutrals2;

          .current-color {
            color: $neutrals4;

            &:hover {
              color: $primary3;
            }
          }
        }

        img {
          width: 20px;
          height: 20px;
          @include filter-neutrals4;
        }
      }
    }
  }

  &__gallery1 {
    display: grid;
    margin: 0 0 16px 0;
    gap: 8px;
    grid-template-columns: repeat(1, 1fr);
    position: relative;
  }

  &__gallery2 {
    display: grid;
    margin: 0 0 16px 0;
    gap: 8px;
    grid-template-columns: repeat(2, 1fr);
    position: relative;
  }

  &__gallery3 {
    display: grid;
    margin: 0 0 16px 0;
    gap: 8px;
    grid-template-columns: repeat(3, 1fr);
    position: relative;
  }

  &__preview {
    grid-column: 1/3;
    grid-row: 1/3;

    img,
    video {
      width: 100%;
      border-radius: 0px;
      height: 260px;
      object-fit: cover;
    }
  }

  &__preview_2 {
    &:first-child {
      grid-column: 1/2;
      grid-row: 1/2;
    }

    &:nth-child(2) {
      grid-column: 2/2;
      grid-row: 1/2;
    }

    img,
    video {
      width: 100%;
      border-radius: 0px;
      height: 260px;
      object-fit: cover;
    }
  }

  &__preview_3 {
    &:first-child {
      grid-column: 1 / 3;
      grid-row: 1 / 3;
      height: 260px;

      img,
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &:nth-child(2) {
      grid-column: 3;
      grid-row: 1;
      height: 125px;

      img,
      video {
        object-fit: cover;
      }
    }

    &:nth-child(3) {
      grid-column: 3;
      grid-row: 2;
      height: 125px;

      img,
      video {
        object-fit: cover;
      }
    }

    img,
    video {
      width: 100%;
      border-radius: 0px;
      object-fit: cover;
      height: 100%;
    }
  }

  &__preview_4 {
    flex: 0 0 calc(33.333% - 8px);
    width: calc(33.333% - 8px);
    margin: 8px 4px 0;

    &:first-child {
      flex: 0 0 calc(100% - 8px);
      width: calc(100% - 8px);

      img,
      video {
        height: 250px;
        object-fit: cover;
      }
    }

    img,
    video {
      width: 100%;
      border-radius: 0px;
      height: 80px;
      object-fit: cover;
    }
  }

  &__view {
    font-size: 14px;
    height: 40px;
  }

  &__card {
    border: 1px solid $neutrals6;
    padding: 14px;
    transition: box-shadow 0.2s ease;

    &:hover {
      .collections2__control {
        opacity: 1;
        visibility: visible;
      }
    }

    @include dark {
      border: 1px solid $neutrals3;
      background-color: $neutrals1;
    }
  }

  &__subtitle {
    margin-bottom: 8px;
    @include body-bold-1;
    color: $neutrals2;
    transition: color 0.2s;

    @include dark {
      color: $neutrals8;
    }
  }

  &__item:hover &__subtitle {
    color: $primary3;
  }

  &__line {
    display: flex;
    align-items: center;
  }

  &__user {
    display: flex;
    align-items: center;
    margin-right: auto;
    color: $neutrals4;

    @include dark {
      color: $neutrals4;
    }

    span {
      font-weight: 500;
      @include wrap-text1;
    }

    .button-stroke {
      max-height: 35px;
      max-width: 140px;
      padding: 9px 15px;
      font-size: 11px;
    }
  }

  &__avatar {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  &__author {
    @include wrap-text1;
    color: $neutrals2;
    @include dark {
      color: $neutrals4;
    }
  }

  &__counter {
    flex-shrink: 0;
    font-weight: 400;
    font-size: 13px;
    color: $neutrals4;
    margin-bottom: 4px;
  }

  &__slider {
    position: static;
    visibility: hidden;

    @include m {
      padding-bottom: 72px;
    }

    &.slick-initialized {
      visibility: visible;
    }
  }

  .slick-list {
    overflow: hidden;
  }

  .slick-slide {
    padding: 0 16px;
    opacity: 0;
    transition: opacity 0.4s;

    &.slick-active {
      opacity: 1;
      /*min-width: 470px;*/
    }
  }

  .slick-arrow {
    top: 4px;

    @include m {
      top: auto;
      bottom: 0;
    }

    &:hover {
      box-shadow: inset 0 0 0 2px $primary3;

      path {
        fill: $primary3;
      }
    }

    @include dark {
      box-shadow: inset 0 0 0 2px $neutrals3;

      &:hover {
        box-shadow: inset 0 0 0 2px $primary3;

        path {
          fill: $primary3;
        }
      }
    }
  }

  .slick-prev {
    right: 48px;

    @include m {
      right: auto;
      left: calc(50% - 44px);
    }
  }

  .slick-next {
    right: 0;

    @include m {
      right: calc(50% - 44px);
    }
  }

  .fix-image-1-row {
    height: 300px;
    object-fit: cover;
  }

  .fix-image-2-row {
    height: 150px;
    object-fit: cover;
  }

  .card {
    background-color: transparent !important;

    &__title {
      color: $neutrals2;

      @include dark {
        color: $neutrals8;
      }
    }
  }

  &__create {
    &_container {
      text-align: center;
    }

    &_logo {
      display: block;
      margin: auto;
    }

    &_title {
      font-weight: 500;
      font-size: 40px;
      color: $primary3;
      margin-bottom: 16px;

      @include m {
        font-size: 30px;
      }
    }

    &_stage {
      margin-bottom: 16px;
    }
  }
}

.collection__select {
  display: flex;
  overflow-x: scroll;
  align-items: flex-end;
  min-width: 0;

  .choose__collection {
    margin: 5px;

    input {
      opacity: 0;
      visibility: hidden;

      &:checked + .card__collection {
        border: 2px solid $primary3;
      }
    }

    .card__collection {
      background-color: $primary2;
      border: 1px solid $neutrals6;
      height: 190px;
      width: 240px;

      @include dark {
        background-color: $neutrals1;
      }

      &.create_collection {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      &:hover {
        border-color: $primary3;
        cursor: pointer;
      }

      &_image {
        flex: 1 0 32.333%;
        width: 32.333%;
        height: 100px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &_images {
        display: flex;
        gap: 2px;
      }

      &_info {
        padding: 20px 8px;
        text-align: center;
      }

      &_count {
        color: $neutrals4;

        .num {
          color: $primary3;
          font-weight: 600;
        }
      }

      &__name {
        margin-top: 8px;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: $neutrals2;

        @include dark {
          color: white;
        }
      }
    }
  }
}

/* ------------------------- User Collection ------------------------- */

.user_collection {
  padding-top: 80px;

  &__hero {
    display: flex;

    @include m {
      display: block;
    }
  }

  &__details {
    margin-right: auto;
  }

  &__title {
    @include hairline-3;
    margin: 37px 0;
  }

  &__description {
    @include caption-1;
    width: 530px;
    @include wrap-text2(4);

    @include t {
      width: 350px;
    }

    @include m {
      width: 100%;
    }
  }

  &__first {
    .the_first {
      width: 340px;
      background: $primary2;
      border: 0.56253px solid #e6e8ec;
      padding: 9px;

      @include t {
        width: 100%;
        margin-left: 30px;
      }

      @include m {
        margin: 20px;
      }

      @include dark {
        background: $primary1;
      }

      &__preview {
        height: 185px;

        img,
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__name {
        margin-top: 14px;
        font-weight: 500;
        font-size: 10px;
        line-height: 14px;
        color: $primary1;

        @include dark {
          color: $primary2;
        }
      }
    }
  }

  .divider {
    margin: 56px 0;
    border: 1px solid $neutrals6;

    @include dark {
      border: 1px solid $neutrals3;
    }
  }
}

.add-collections {
  margin: 22px 0 40px !important;
  padding: 0px 15px 0px 0px !important;
  width: "" !important;

  &:not([class^="button-circle"]) {
    .icon {
      &:first-child {
        margin-right: 0px !important;
      }

      &:last-child {
        margin-left: 15px;
      }
    }
  }
}
