@import "../_variables.scss";

.testimonials {
  &__wrapper {
    position: relative;
  }

  &__tw {
    &_text {
      @include m {
        font-size: 12px !important;
        padding: 0px !important;
      }
    }

    &_vector {
      @include m {
        width: 43.81px !important;
        height: 35.85px !important;
      }
    }

    &_title {
      @include m {
        top: -21px !important;
      }
    }

    &_padding {
      @include m {
        padding: 50px 0px !important;
        min-height: 317px;
      }
    }
  }

  .testimonial {
    padding: 120px 0;

    &__swiper {
      .swiper-button-prev {
        @include t {
          right: 51% !important;
          bottom: 10px;
          top: unset !important;
        }
        @include m {
          right: 51% !important;
          bottom: -30px;
          top: unset !important;
        }

        @include r(560) {
          right: 53% !important;
          bottom: -30px;
          top: unset !important;
        }
        @include r(460) {
          right: 55% !important;
          bottom: -30px;
          top: unset !important;
        }
        @include r(320) {
          right: 54% !important;
          bottom: -30px;
          top: unset !important;
        }
      }

      .swiper-button-next {
        @include t {
          right: 41% !important;
          bottom: 10px;
          top: unset !important;
        }
        @include m {
          right: 40% !important;
          bottom: -30px;
          top: unset !important;
        }

        @include r(560) {
          right: 38% !important;
          bottom: -30px;
          top: unset !important;
        }
        @include r(460) {
          right: 36% !important;
          bottom: -30px;
          top: unset !important;
        }
        @include r(320) {
          right: 31% !important;
          bottom: -30px;
          top: unset !important;
        }
      }
    }

    &__card {
      border-radius: 0px 0px 16px 16px;

      @include dark {
        // background-color: $neutrals1;
      }
    }

    &__title {
      position: absolute;
      top: -63px;
      left: 10px;
      margin-left: 37px;
      margin-bottom: -45px;
      z-index: 2;

      @include s {
        margin-bottom: 30px;
      }
    }

    &__body {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 48px 32px 24px;
      border-radius: 0 0 16px 16px;
      gap: 16px;
      z-index: 1;

      @include dark {
        background-color: $neutrals1;
      }

      .text_container {
        @include main-font;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 40px;
        padding: 48px 0 24px 0;
        border-bottom: 1px solid $neutrals6;
      }
    }

    &__detail {
      display: flex;
      margin-left: auto;
      padding-bottom: 15px;

      @include s {
        margin: 130px auto 0 auto;
        width: 243px;
      }

      .sold {
        display: block;
        padding-right: 10px;
        margin-right: auto;

        &__header {
          @include main-font;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $neutrals4 !important;
        }

        &__body {
          display: flex;

          .item_sold {
            margin-left: 5px;
            @include main-font;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }

      .artworks {
        border-left: 1px solid $neutrals6;
        padding-left: 10px;
        text-align: end;

        &__header {
          @include main-font;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $neutrals4 !important;
        }

        &__body {
          .item_artwork {
            @include main-font;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }

    &__collector {
      position: absolute;
      bottom: -25px;
      left: 32px;
      padding: 20px;
      box-shadow: 0px 12px 60px 0px #0000001a;
      width: 187px;
      height: 96px;
      z-index: 2;
      border-radius: 8px;

      @include dark {
        background-color: $neutrals2;
      }

      @include m {
        width: 215px !important;
      }
      @include s {
        margin: -60px auto 0 auto;
        position: absolute;
        width: 243px;
        bottom: 112px;
        left: calc((100% / 2) - 123px);
      }

      .main__item {
        display: flex !important;
        width: 100% !important;
        margin: 4px 0 !important;
      }
    }
  }

  &__bioWrap {
    @include wrap-text2(6);
    -webkit-box-orient: vertical;
  }

  &__slider {
    position: static;
    visibility: hidden;

    &.slick-initialized {
      visibility: visible;
    }
  }

  .slick-list {
    overflow: hidden;

    @include s {
      /*margin: 0 -215px 0 0;*/
    }
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    display: flex;
    height: auto;
    margin: 0 16px;
    /*opacity: 0;
        transition: opacity .4s;*/

    @include m {
      margin: 0 8px;
    }

    &.slick-active {
      opacity: 1;
    }
  }

  .slick-arrow {
    /*top: 4px;*/
    top: -40px;

    &:hover {
      box-shadow: inset 0 0 0 2px $primary3;

      path {
        fill: $primary3;
      }
    }

    @include m {
      top: -45px;
    }
  }

  .slick-prev {
    right: 48px;

    &:hover {
      box-shadow: inset 0 0 0 2px $primary3;
    }
  }

  .slick-next {
    right: 0;

    &:hover {
      box-shadow: inset 0 0 0 2px $primary3;
    }
  }
}

#testimoniallogo-light {
  margin: 0 5px;

  @include dark {
    margin: 0 5px;
    display: none;
  }
}

#testimoniallogo-dark {
  margin: 0 5px;
  display: none;

  @include dark {
    margin: 0 5px;
    display: flex;
  }
}

#testimonial__titlelogo {
  transform: scale(1.5);
}
