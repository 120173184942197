.selection {
  &__center {
    display: flex;

    @include u {
      display: block;
    }
  }

  &__row {
    display: flex;
    flex-grow: 1;
    /*margin-right: 32px;*/
    padding-right: 32px;
    /*border-right: 1px solid $neutrals6;*/

    @include x {
      margin-right: 0;
      padding: 0;
      border: none;
    }

    @include dark {
      border-color: $neutrals3;
    }
  }

  &__sidebar {
    flex-shrink: 0;
    width: 224px;

    @include u {
      width: 100%;
      /*margin-top: 64px;*/
      margin-top: 75px;
    }

    @include m {
      margin-top: 40px;
      padding-top: 40px;
      border-top: 1px solid $neutrals6;

      @include dark {
        border-color: $neutrals3;
      }
    }

    .selection__button {
      margin-top: 8px;

      @include x {
        display: none;
      }

      .icon {
        width: 10px;
        height: 10px;
      }
    }
  }

  &__col {
    &:first-child {
      flex-grow: 1;
      padding-right: 32px;

      @include t {
        padding: 0;
      }
    }

    &:nth-child(2) {
      flex: 0 0 38%;

      @include d {
        flex: 0 0 43.5%;
      }

      @include t {
        display: none;
      }
    }
  }

  &__preview {
    position: relative;
    border-radius: 0px;
    overflow: hidden;

    img {
      width: 100%;
      transition: transform 1s;
    }

    video {
      width: 100%;
      transition: transform 1s;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background: rgba($neutrals1, 0.3)
        url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none' xmlns:v='https://vecta.io/nano'%3E%3Cpath d='M0 24C0 10.745 10.745 0 24 0s24 10.745 24 24-10.745 24-24 24S0 37.255 0 24z' fill='%23fcfcfd'/%3E%3Cpath fill-rule='evenodd' d='M25.394 20.843c.271-.25.692-.233.942.038l2.154 2.333c.236.255.236.649 0 .904l-2.154 2.333c-.25.271-.672.287-.942.038s-.287-.671-.038-.942l1.121-1.215H20c-.368 0-.667-.298-.667-.667S19.632 23 20 23h6.477l-1.121-1.214c-.25-.271-.233-.692.038-.942z' fill='%23777e91'/%3E%3C/svg%3E")
        no-repeat 50% 50% / 48px 48px;
      opacity: 0;
      visibility: hidden;
      transition: all 0.2s;
    }
  }

  &__card,
  &__item {
    color: $neutrals2;

    @include dark {
      color: $neutrals8;
    }

    &:hover {
      .selection__preview {
        img {
          transform: scale(1.1);
        }

        &:before {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  &__card {
    .selection__preview {
      /*margin-bottom: 24px;*/

      img {
        height: 400px !important;
        object-fit: cover;
      }

      video {
        height: 400px !important;
        object-fit: cover;
        background: $white;
        background-size: 100%;
        background-position: center;

        &.lazy {
          background: $white;
        }
      }
    }

    .selection__avatar {
      width: 48px;
      height: 48px;
      margin-right: 16px;
    }

    .selection__title {
      @include body-bold-1-1;

      @include m {
        font-size: 16px;
      }
    }

    .selection__content {
      margin-bottom: 2px;
    }
  }

  &__line,
  &__line__s2,
  &__head {
    display: flex;
  }

  &__line__s2 {
    align-items: center;

    .selection__avatar {
      /*width: 100% !important;*/
      height: 24px;
      margin-right: 12px;
      display: flex;

      img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 5px;
      }
    }
  }

  &__box {
    margin-left: auto;
    padding-left: 20px;
    text-align: right;
  }

  &__avatar {
    flex-shrink: 0;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__description {
    flex-grow: 1;
  }

  &__counter {
    font-weight: 500;
    color: $neutrals3;

    @include dark {
      color: $neutrals6;
    }
  }

  &__content {
    @include caption-2;
    color: $neutrals4;
  }

  &__price {
    display: inline-block;
    padding: 7px;
    box-shadow: inset 0 0 0 2px $primary3;
    border-radius: 0px;
    @include hairline-2;
    color: $primary3;
  }

  &__item {
    display: flex;
    align-items: center;

    .selection__preview {
      flex-shrink: 0;
      width: 160px;
      margin-right: 24px;

      img {
        height: 150px !important;
        object-fit: cover;
      }
    }

    .selection__avatar {
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }

    .selection__title {
      margin-bottom: 8px;
      @include body-bold-2;
    }

    .selection__line {
      margin-bottom: 16px;
    }

    .selection__price {
      margin-right: 12px;
    }

    .selection__button {
      padding: 0 12px;
      height: 32px;
    }

    &:not(:last-child) {
      margin-bottom: 53px;
    }
  }

  &__info {
    @include caption-bold-2;
    color: $neutrals4;
    margin-bottom: 25px;

    @include x {
      margin-bottom: 24px;
    }

    @include t {
      margin-bottom: 20px;
    }
  }

  &__list {
    @include u {
      display: flex;
      margin: 0 -12px;
    }

    @include t {
      margin: 0 -40px;
      padding-top: 4px;
      overflow: auto;
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        display: none;
      }

      &:before,
      &:after {
        content: "";
        flex-shrink: 0;
        width: 40px;
        height: 1px;
      }
    }
  }

  &__user {
    display: flex;
    align-items: center;
    padding: 24px 0;

    @include u {
      flex: 0 0 calc(25% - 24px);
      width: calc(25% - 24px);
      margin: 0 12px;
      padding: 0;
    }

    @include t {
      flex: 0 0 224px;
      width: 224px;
      margin: 0;
      padding-right: 16px;
    }

    .selection__avatar {
      position: relative;
      width: 56px;
      height: 56px;
      margin-right: 16px;
    }

    &:not(:last-child) {
      /*border-bottom: 1px solid $neutrals6;*/
      border-bottom: 0px solid $neutrals6;

      @include x {
        border: none;
      }

      @include dark {
        border-color: $neutrals3;
      }
    }
  }

  &__number {
    position: absolute;
    top: -4px;
    left: -4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid $neutrals8;
    background: $neutrals2;
    @include caption-bold-2;
    color: $neutrals8;

    @include dark {
      border-color: $neutrals1;
      background: $neutrals8;
      color: $neutrals2;
    }
  }

  &__name {
    margin-bottom: 2px;
    font-weight: 500;
  }

  &__money {
    @include caption-2;
    color: $neutrals4;

    span {
      font-weight: 600;
      color: $neutrals2;

      @include dark {
        color: $neutrals6;
      }
    }
  }
}
