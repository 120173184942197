.breadcrumbs {
  display: flex;

  @include m {
    display: none;
  }

  &__item {
    @include button-2;

    &:not(:last-child) {
      margin-right: 33px;

      &:after {
        content: "";
        display: inline-block;
        width: 6px;
        height: 9px;
        margin-left: 33px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='10' fill='none' viewBox='0 0 6 10'%3E%3Cpath fill-rule='evenodd' d='M.793.793a1 1 0 0 0 0 1.414L3.586 5 .793 7.793a1 1 0 0 0 1.414 1.414l3.5-3.5a1 1 0 0 0 0-1.414l-3.5-3.5a1 1 0 0 0-1.414 0z' fill='%23777e91'/%3E%3C/svg%3E")
          no-repeat 50% 50% / 100% auto;
      }
    }
  }

  &__link {
    color: $neutrals4;
    transition: color 0.2s;

    &:hover {
      color: $primary3;
    }
  }
}
