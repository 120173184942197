.footer {
  border-top: 1px solid $neutrals6;

  @include dark {
    border-color: $neutrals3;
  }

  &__row {
    display: flex;
    padding: 80px 0 48px;
    border-bottom: 1px solid $neutrals6;

    @include t {
      display: block;
    }

    @include m {
      padding: 64px 0 48px;
    }

    @include dark {
      border-color: $neutrals3;
    }
  }

  &__col {
    &:first-child {
      flex-grow: 1;
      padding-right: 32px;

      @include t {
        position: relative;
        margin-bottom: 32px;
        padding: 0;
      }
    }

    &:nth-child(2) {
      display: flex;
      flex-shrink: 0;
      width: 352px;

      @include d {
        width: 276px;
      }

      @include t {
        width: auto;
        margin-bottom: 32px;
      }

      @include m {
        display: block;
        border-width: 1px 0;
        border-style: solid;
        border-color: $neutrals6;

        @include dark {
          border-color: $neutrals3;
        }
      }
    }

    &:nth-child(3) {
      flex-shrink: 0;
      width: 384px;
      padding-left: 32px;

      @include d {
        width: 304px;
      }

      @include t {
        width: 100%;
        padding: 0;
      }
    }
  }

  &__logo {
    display: inline-block;
    margin-bottom: 32px;

    img {
      width: 128px;
    }
  }

  &__info {
    max-width: 256px;
    @include body-1;

    @include d {
      font-size: 16px;
    }
  }

  &__theme {
    display: flex;
    align-items: center;
    margin-top: 24px;

    @include t {
      position: absolute;
      top: 5px;
      right: 0;
      margin: 0;
    }
  }

  &__details {
    margin-right: 16px;
    @include caption-2;

    @include m {
      margin-right: 10px;
    }
  }

  &__group {
    flex: 0 0 50%;

    &:first-child {
      padding-right: 16px;

      @include m {
        padding-right: 0;
      }
    }

    &:nth-child(2) {
      padding-left: 16px;

      @include m {
        padding-left: 0;
      }
    }

    &:not(:last-child) {
      @include m {
        border-bottom: 1px solid $neutrals6;

        @include dark {
          border-color: $neutrals3;
        }
      }
    }
  }

  &__head {
    margin-bottom: 40px;
    @include body-bold-2;

    @include t {
      margin-bottom: 32px;
    }

    @include m {
      position: relative;
      margin-bottom: 0;
      padding: 32px 32px 32px 0;
      @include hairline-2;
    }

    .icon {
      display: none;

      @include m {
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 10px;
        width: 10px;
        height: 10px;
        transform: translateY(-50%);
        fill: $neutrals4;
        transition: transform 0.2s;
      }
    }

    &.active {
      .icon {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  &__body {
    @include rmin(768) {
      display: flex !important;
    }

    @include m {
      display: none;
      padding-bottom: 32px;
    }
  }

  &__menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__link {
    @include button-2;
    color: $neutrals4;
    transition: color 0.2s;

    &:hover {
      color: $primary3;
    }

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &__category {
    margin-bottom: 40px;
    @include body-bold-2;
  }

  &__text {
    margin-bottom: 24px;
  }

  &__foot {
    display: flex;
    justify-content: space-between;
    padding: 32px 0;
    @include caption-2;

    @include m {
      display: block;
      padding: 24px 0 32px;
    }
  }

  &__copyright {
    color: $neutrals4;

    @include m {
      text-align: center;
    }
  }

  &__note {
    @include m {
      display: none;
    }

    a {
      margin-left: 16px;
      font-weight: 600;
      color: $primary3;
      transition: color 0.2s;

      &:hover {
        color: darken($primary3, 10);
      }
    }
  }
}

.footernew {
  background: $primary1;
  color: $neutrals8;
  padding-bottom: 80px;
  padding-top: 90px;
  z-index: 0;
  /*margin-top: 80px;*/

  @include t {
    padding-top: 20px;
    padding-left: 0;
  }

  .with-back {
    width: 25px;
    height: 25px;
    border-radius: 50px;
    background-color: $neutrals4;
    object-fit: none;
    &:hover {
      background-color: $primary3;
    }
  }

  &__row {
    display: flex;
    padding: 80px 0 48px;
    border-bottom: 1px solid;
    border-image-source: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 10%, #ffffff2e 50%, rgba(0, 0, 0, 0) 100%);
    border-image-slice: 1;
    /*@include t {
            // display: block;
        }*/

    @include t {
      display: block;
      padding-top: 10px;
    }
  }

  &__col {
    &:first-child {
      padding: 0;
      flex: 0 0 20%;
      max-width: 20%;
      border-left: 1px solid;
      border-image-source: linear-gradient(180deg, $neutrals3, rgba(252, 252, 253, 0));
      border-image-slice: 1;

      @include t {
        max-width: 100%;
        width: 100%;
        border-left: none;
      }
    }

    &:nth-child(2) {
      display: flex;
      flex-shrink: 0;
      flex: 0 0 58%;
      max-width: 58%;

      @include d {
        width: 276px;
      }
      /*@include t {
                width: auto;
                margin-bottom: 32px;
            }*/

      @include t {
        max-width: 100%;
        width: 100%;
        display: block;
        margin-left: 10px;
      }
    }

    &:nth-child(3) {
      flex-shrink: 0;
      flex: 0 0 22%;
      max-width: 22%;
      /*width: 48%;
            padding-left: 215px;*/
      border-left: 1px solid;
      border-image-source: linear-gradient(180deg, $neutrals3, rgba(252, 252, 253, 0));
      border-image-slice: 1;

      @include d {
        // width: 304px;
      }

      @include t {
        //width: 100%;
        padding-left: 190px;
      }

      @include t {
        width: 100%;
        max-width: 100%;
        padding-left: 0;
        margin-left: 10px;
        border-left: none;
      }
    }
  }

  &__logo {
    padding-left: 10px;
    display: inline-block;

    @include t {
      padding-left: 15px;
      margin-bottom: 5px;
      border-left: 2px solid $primary3;
    }

    img {
      width: 128px;
    }
  }

  &__info {
    padding-left: 12px;
    margin-top: 32px;
    max-width: 147px;
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    letter-spacing: 0.3px;
    // @include body-1;

    @include d {
      font-size: 16px;
    }

    @include t {
      max-width: none;
    }
  }

  &__theme {
    display: flex;
    align-items: center;
    margin-top: 24px;

    @include t {
      position: absolute;
      top: 5px;
      right: 0;
      margin: 0;
    }
  }

  &__details {
    margin-right: 16px;
    @include caption-2;

    @include t {
      margin-right: 10px;
    }
  }

  &__group {
    /*flex: 0 0 50%;*/
    flex: 0 0 33.333%;
    border-left: 1px solid;
    border-image-source: linear-gradient(180deg, $neutrals3, rgba(252, 252, 253, 0));
    border-image-slice: 1;

    @include t {
      border-left: none;
      margin: 30px 0;
    }

    &:first-child {
      // padding-right: 16px;

      @include m {
        //padding-right: 0;
      }
    }

    &:nth-child(2) {
      // padding-left: 16px;

      @include m {
        // padding-left: 0;
      }
    }

    &:not(:last-child) {
      @include m {
        // border-bottom: 1px solid $neutrals6;
      }
    }
  }

  &__head {
    padding-left: 10px;
    border-left: 2px solid #8e793e;
    margin-left: -1px;
    font-size: 20px !important;
    @include body-bold-2;
    font-weight: 700;
    /*@include t {
            // margin-bottom: 32px;
        }*/

    @include t {
      position: relative;
      margin-bottom: 0;
      padding: 12px 32px 12px 0;
      font-weight: 500;
      font-size: 25px;
      line-height: 24px;
      // @include hairline-2;
      padding-left: 15px;
    }

    .iconnew {
      display: none;

      @include t {
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 10px;
        width: 10px;
        height: 10px;
        transform: translateY(-50%);
        fill: $neutrals4;
        transition: transform 0.2s;
      }
    }

    &.active {
      .icon {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  &__body {
    padding-left: 10px;

    @include rmin(1023) {
      display: flex !important;
    }

    @include t {
      display: none;
      padding-bottom: 32px;
    }
  }

  &__menu {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include t {
      padding-top: 20px;
      padding-left: 15px;
    }
  }

  &__link {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: $neutrals6;
    transition: color 0.2s;

    @include t {
      color: $neutrals6;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
    }

    &:hover {
      color: $primary3;
    }

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &__category {
    margin-bottom: 40px;
    @include body-bold-2;

    @include t {
      margin-bottom: 20px;
      font-weight: 400;
      font-size: 24px;
      line-height: 24px;
      //padding-left: 15px;
      padding: 12px 15px 15px 15px;
      border-left: 2px solid #8e793e;
    }
  }

  &__text {
    margin-bottom: 24px;
  }

  &__foot {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    color: $neutrals8;
    @include caption-2;

    @include t {
      display: block;
      justify-content: center;
      text-align: center;
      padding: 24px 0 32px;
      margin: auto;
    }
  }

  &__copyright {
    color: $neutrals8;
    padding-top: 6px;

    @include t {
      text-align: center;
      margin-bottom: 10px;
    }
  }

  &__note {
    display: flex;
    gap: 15px;
    align-items: center;

    a > img {
      &:not(.with-back) {
        &:hover {
          @include filter-primary3;
        }
      }
    }

    @include t {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 30px;
    }

    img {
      cursor: pointer;
      margin: 0 7px;
    }
  }

  &__download_button {
    margin-top: 20px;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 11px 12px;
    width: fit-content;
    gap: 9px;
    height: 34px;
    border: 1px solid $neutrals4;
    border-radius: 4px;
    color: $neutrals8;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;

    @include t {
      margin-left: 0;
      height: 50px;
      font-size: 22px;
    }

    @include m {
      margin-left: 0;
      width: 100%;
    }

    &:hover {
      background-color: $primary3;
      color: $primary2;
    }
  }

  &__twitterIcon {
    @include filter-neutrals4;
  }

  &_button-submit {
    position: absolute;
    top: 13%;
    right: 0px;
    bottom: 0;
    margin-left: 32px;
    width: 42px;
    margin: 8px;

    .icon {
      font-size: 18px !important;
      cursor: pointer;
      padding: 15px;
    }
  }
}
