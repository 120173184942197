.actions {
  &__button {
    .icon {
      width: 24px;
      height: 24px;

      @include dark {
        fill: $neutrals4;
      }
    }
  }

  &__body {
    position: absolute;
    width: 208px;
    padding: 16px 16px 20px;
    background: $neutrals8;
    box-shadow: 0px 16px 64px -16px rgba(31, 47, 70, 0.3);

    transform: translateY(3px);
    transition: all 0.2s;

    @include dark {
      background: $neutrals2;
      box-shadow: inset 0 0 0 2px $neutrals2, 0 4px 24px rgba($neutrals1, 0.5);
    }

    &:before {
      content: "";
      position: absolute;
      left: 87%;
      bottom: 100%;
      width: 32px;
      height: 13px;
      transform: translateX(-50%);
      background: url("../../images/Vector 1.svg") no-repeat 100% 50% / 100%
        auto;

      @include dark {
        filter: brightness(0) saturate(100%) invert(12%) sepia(10%)
          saturate(1090%) hue-rotate(187deg) brightness(96%) contrast(92%);
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 16px 0 12px;
    @include button-2;
    @include dm-sans;
    cursor: pointer;
    color: $neutrals4;
    transition: color 0.2s;

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      fill: $neutrals4;
      transition: fill 0.2s;
    }

    img {
      margin-right: 8px;
      transition: fill 0.2s;
    }

    &:hover {
      color: $primary3;

      .icon {
        fill: $primary3;
      }

      img {
        /*primary3*/
        filter: brightness(0) saturate(100%) invert(49%) sepia(12%)
          saturate(1597%) hue-rotate(7deg) brightness(95%) contrast(89%);
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid $neutrals6;

      @include dark {
        border-color: $neutrals3;
      }
    }

    &.no-border {
      border-bottom: 0px;
    }
  }

  &__item-disable {
    opacity: 0.5;
    cursor: default;
  }

  &__chain {
    display: flex;
    align-items: center;
    padding: 16px 0 12px;
    @include button-2;
    @include dm-sans;
    cursor: pointer;
    color: $neutrals4;
    transition: color 0.2s;

    img {
      margin-right: 12px;
      width: 20px;
      height: 20px;
    }

    &:hover {
      color: $primary3;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $neutrals6;

      @include dark {
        border-color: $neutrals3;
      }
    }
  }

  &.active {
    .actions__button {
      /*background: $neutrals2;*/
      box-shadow: inset 0 0 0 2px $neutrals2;

      @include dark {
        /*background: $neutrals1;*/
        box-shadow: inset 0 0 0 2px $neutrals1;
      }
    }

    .actions__body {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }
}
