[class^="button"] {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 0 24px;
  background: $primary1;
  border-radius: 0;
  @include button-1;
  @include dm-sans;
  text-align: center;
  color: $primary2;
  transition: all 0.2s;
  cursor: pointer;
  //sssss
  @include dark {
    background: $primary2;
    color: $primary1;
  }

  @include m {
    margin: 5px 0;
  }

  &:hover {
    background: $primary3;
    box-shadow: 0 0 0 2px $primary3 inset;
    color: $primary2;

    @include dark {
      background: $primary3;
      box-shadow: 0 0 0 2px $primary3 inset;
      color: $primary2;
    }
  }

  &.disabled {
    /*opacity: .5;*/
    background: $neutrals6;
    pointer-events: none;

    @include dark {
      background: $neutrals2;
      color: $neutrals4;
    }
  }

  &.spinner-btn {
    background: $primary1;
    cursor: default;
    border: 0;

    @include dark {
      background: $primary3;
    }
  }

  &.done {
    background: $neutrals5;
  }

  &.loading {
    background: darken($primary3, 15);
    font-size: 0;
  }

  &.error {
    box-shadow: 0 0 0 2px $neutrals6 inset;
    background: none;
    color: $pink;

    @include dark {
      box-shadow: 0 0 0 2px $neutrals4 inset;
    }
  }
  /*shervin*/
  &.add-input {
    width: 100%;

    span {
      width: 90%;
      text-align: start;
    }

    .icon {
      border: 2px solid;
      border-radius: 50px;
      width: 25px !important;
      height: 25px !important;
    }
  }

  &.with-icon {
    .icon {
      width: 25px !important;
      height: 25px !important;
      @include dark {
        fill: $primary1;
      }
    }

    &:hover {
      .icon {
        fill: $primary2;
      }
    }
  }

  &:focus {
    outline: 0;
  }

  &.active {
    background: $primary1;
    color: $primary2;
    box-shadow: none;

    @include m {
      margin: 7px 0;
    }

    @include dark {
      background: $primary2;
      color: $primary1;
      box-shadow: none;

      @include m {
        margin: 7px 0;
      }
    }

    .icon {
      fill: $primary2;

      @include dark {
        fill: $primary1;
      }
    }
  }

  .icon {
    fill: $neutrals8;
  }

  &:not([class^="button-circle"]) {
    .icon {
      &:first-child {
        margin-right: 15px;
      }

      &:last-child {
        margin-left: 15px;
      }
    }
  }

  &.color_text {
    color: $primary2 !important;

    @include dark {
      color: $primary1 !important;
    }

    &:hover {
      color: $primary2 !important;

      @include dark {
        color: $primary2 !important;
      }
    }
  }
}

.button-stroke {
  background: none;
  box-shadow: 0 0 0 2px $neutrals6 inset;
  color: $neutrals2;
  cursor: pointer;

  .icon {
    fill: $neutrals2;
    transition: fill 0.2s;
  }

  @include dark {
    box-shadow: 0 0 0 2px $neutrals3 inset;
    color: $primary2;
    background: none;

    .icon {
      fill: $neutrals8;
    }
  }

  &.follow-btn {
    height: 30px !important;
  }

  &:hover {
    /*        background: $primary3;
        box-shadow: 0 0 0 2px $primary3 inset;
        color: $primary2;*/
    background: none;
    box-shadow: 0 0 0 2px $primary3 inset;
    color: $neutrals2;

    @include dark {
      background: none;
      box-shadow: 0 0 0 2px $primary3 inset;
      color: $neutrals7;
    }

    .icon {
      fill: $neutrals8;
    }
  }

  i {
    margin-left: 8px;
    font-weight: 600;
    transform: rotate(90deg);
  }
}

.button-pink {
  background: $pink;

  &:hover {
    background: darken($pink, 12);
  }
}

.button-brown {
  background: $primary3;

  &:hover {
    background: darken($primary3, 12);
    color: $primary2;
  }

  @include dark {
    background: $primary3;

    &:hover {
      background: darken($primary3, 12);
      color: $primary2;
    }
  }
}

.button-small {
  height: 40px;
  border-radius: 0px;
  padding: 0 16px;
  font-size: 14px;
  /*&:hover {
        color: $black;

        @include dark {
            color: $primary2;
        }
    }*/
}

.button-circle-stroke {
  flex: 0 0 48px;
  width: 48px;
  height: 48px;
  padding: 0;
  border-radius: 50px;
  box-shadow: 0 0 0 2px $neutrals6 inset;
  background: transparent;
  transition: all 0.2s;

  .icon {
    fill: $neutrals4;
    transition: fill 0.2s;
  }
  /*&:hover {
        background: $black;
        box-shadow: 0 0 0 2px $black inset;

        .icon {
            fill: $neutrals8;
        }
    }*/

  &:hover {
    /*background: $primary2 !important;*/
    box-shadow: 0 0 0 2px $primary3 inset !important;
    background: $primary2 !important;

    .icon {
      fill: $primary3 !important;
    }

    @include dark {
      background: $primary1 !important;
      // box-shadow: 0 0 0 2px $primary1 inset;
    }
  }

  &.active {
    background: transparent;
    color: $primary1;

    @include dark {
      color: $primary2;
    }

    .icon {
      fill: $primary1 !important;
    }
  }

  @include dark {
    box-shadow: 0 0 0 2px $neutrals3 inset;

    &:hover {
      background: $neutrals3;
    }
  }
}

.button-square-stroke {
  flex: 0 0 48px;
  width: 48px;
  height: 48px;
  padding: 0;
  border-radius: 0px;
  box-shadow: 0 0 0 2px $neutrals6 inset !important;
  background: transparent;
  transition: all 0.2s;

  .icon {
    fill: $neutrals4;
    transition: fill 0.2s;
    margin: 0 !important;
  }

  &:hover {
    background: $primary2 !important;
    box-shadow: 0 0 0 2px $primary3 inset !important;

    .icon {
      fill: $primary3 !important;
    }

    .current-color {
      color: $primary3;
    }
  }

  &.active {
    background: transparent;
    color: $primary1;

    @include dark {
      color: $primary2;
    }

    .icon {
      fill: $primary1 !important;
    }
  }

  @include dark {
    box-shadow: 0 0 0 2px $neutrals3 inset !important;
    background: transparent;

    &:hover {
      background: transparent !important;
      box-shadow: 0 0 0 2px $primary3 inset !important;
    }

    &.active {
      .icon {
        fill: $primary2;
      }

      background: transparent !important;
    }
  }
}

.button-rec-stroke {
  flex: 0 0 78px;
  width: 78px;
  height: 48px;
  padding: 0;
  border-radius: 0px;
  box-shadow: 0 0 0 2px $neutrals6 inset;
  background: transparent;
  transition: all 0.2s;

  .icon {
    fill: $neutrals4;
    transition: fill 0.2s;
    margin: 0 !important;
  }

  &.like {
    &:hover {
      .like_count {
        color: $primary3;
      }
    }
  }

  &:hover {
    /*background: $primary2 !important;*/
    box-shadow: 0 0 0 2px $primary3 inset;
    color: $primary3;
    background: transparent;

    .like_count {
      color: $primary3;
    }

    .icon {
      fill: $primary3 !important;
    }
  }

  &.active {
    box-shadow: 0 0 0 2px $neutrals6 inset;
    background: transparent;
    color: $primary1;

    @include dark {
      box-shadow: 0 0 0 2px $neutrals3 inset;
      color: $primary2;
    }

    &:hover {
      box-shadow: 0 0 0 2px $primary3 inset;
      background: transparent;
    }

    .icon {
      fill: $primary1 !important;
    }
  }

  @include dark {
    box-shadow: 0 0 0 2px $neutrals3 inset;
    background: transparent;

    &:hover {
      box-shadow: 0 0 0 2px $primary3 inset;
      background: transparent;

      .icon {
        fill: $primary3 !important;
      }
    }

    &.active {
      .icon {
        fill: $primary2;
      }

      background: transparent !important;
    }
  }
}

.button-circle-stroke.button-small {
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
}

.add-button {
  background-color: $primary1;
  color: $primary2 !important;
  padding: 7px;
  cursor: pointer;

  @include dark {
    background-color: $primary2;
    color: $primary1 !important;
    border: 2px solid $primary1;
  }

  .icon {
    fill: $primary2 !important;

    @include dark {
      fill: $primary1 !important;
    }
  }
}

.button-cancle {
  background-color: $neutrals8;
  color: $primary1;
  font-weight: 400;

  &:hover {
    color: $primary2 !important;
  }

  @include dark {
    background-color: $neutrals1;
    color: $primary2;
  }
}

button:focus {
  outline: none;
}

.remove-upload-btn {
  @include dark {
    filter: brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(2%)
      hue-rotate(195deg) brightness(107%) contrast(100%);
  }
}

.delete-btn {
  left: 0;
  z-index: 11;
  display: none;
  text-align: center;
  cursor: pointer;
  transition: 0.2s ease;
  font-weight: 700;
  margin-left: 20px;
  color: $pink;

  @include dark {
    color: $pink;
  }
  @include t {
    left: 77px;
  }

  // &:hover {
  //   color: $primary3 !important;
  // }
}

.button-view {
  cursor: pointer;
  color: white;
  background: #8e793ebd;

  &:hover {
    background: #8e793e;
    color: white;
  }
}

.asset {
  &__btn {
    .icon {
      width: 24px !important;
      height: 24px !important;
      fill: $neutrals4;
    }

    &.not_allow {
      color: $neutrals6;
      cursor: default;

      @include dark {
        color: $neutrals3;
      }

      img {
        @include filter-neutrals6;

        @include dark {
          @include filter-neutrals3;
        }
      }

      &:hover {
        &.button-stroke {
          box-shadow: 0 0 0 2px $neutrals6 inset;

          @include dark {
            box-shadow: 0 0 0 2px $neutrals3 inset;
          }
        }

        img {
          @include filter-neutrals6;

          @include dark {
            @include filter-neutrals3;
          }
        }
      }
    }

    img {
      width: 24px;
      height: 24px;
      @include filter-neutrals4;
      margin-right: 15px;
    }

    &:hover {
      .icon {
        @include dark {
          fill: $primary3;
        }

        fill: $primary3;
      }

      img {
        @include filter-primary3;
      }
    }

    &:last-child {
      @include t {
        margin-left: 0;
      }
    }

    color: $neutrals4;
    width: 100%;
  }
}
