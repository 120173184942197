[class^="section"] {
  padding: 120px 0;

  @include x {
    padding: 120px 0;
  }

  @include m {
    padding: 48px 0;
  }
}

[class^="section1"] {
  padding: 20px 0;

  @include x {
    padding: 20px 0;
  }

  @include m {
    padding: 20px 0;
  }
}

.section1 {
  background: $primary2;
  @include dark {
    background: $neutrals0;
  }
}

.section-bg {
  background: $primary2;

  @include dark {
    background: $primary1;
  }
}

.section-p0 {
  padding: 0;
  background: $primary2;

  @include dark {
    background: $primary1;
  }
}

.section {
  background: $neutrals8;

  @include dark {
    background: $neutrals1;
  }
}

.section-rv {
  background: $primary2;

  @include dark {
    background: $neutrals1;
  }
}

.section-rv2 {
  background: $neutrals8;

  @include dark {
    background: $primary1;
  }
}

.section-pb {
  padding-top: 0;

  @include d {
    padding-top: 0;
  }

  @include m {
    padding-top: 0;
  }
}

.section-pt80 {
  padding-top: 80px;

  @include d {
    padding-top: 64px;
  }

  @include m {
    padding-top: 32px;
  }
}

.section-row {
  display: flex;

  @include m {
    display: block;
    margin: 0 auto;
  }
}

.socials-section {
  display: none;
  border: 2px solid #e6e8ec;
  padding: 30px;
}

$base-color: #ddd;
$shine-color: #e8e8e8;
$animation-duration: 1.6s;
$avatar-offset: 52 + 16;

.section-skeleton {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 80vh;
  margin: 20px auto;
  /*width: 80%;
    height: 500px;*/
  transition: all 200ms ease-in-out;
  position: relative;
  /*background-color: rgba( 255, 255, 255, 0.4 );*/
  background-color: rgba(0, 0, 0, 0.4);

  @include dark {
    background-color: rgba(0, 0, 0, 0.4);
  }
  /*box-shadow: 0 8px 32px 0 rgba( 255, 255, 255, 0.16 );*/
  box-shadow: 0 8px 32px 0 rgba(57, 53, 53, 0.16);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  overflow: hidden;
  background-size: 600px;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: load 1s infinite;
  }

  img {
    width: 80px;
    /*-webkit-animation: ping 1.2s ease-in-out infinite both;*/
    /*animation: ping 1.2s ease-in-out infinite both;*/
  }
}

@keyframes load {
  100% {
    transform: translateX(100%);
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .section-skeleton::after {
    animation: none;
  }
}

.ping {
  -webkit-animation: ping 1.4s ease-in-out infinite both;
  animation: ping 1.4s ease-in-out infinite both;
}

/**
 * ----------------------------------------
 * animation ping
 * ----------------------------------------
 */
@-webkit-keyframes ping {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0.8;
  }

  80% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(2.2);
    transform: scale(2.2);
    opacity: 0;
  }
}

@keyframes ping {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0.8;
  }

  80% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(2.2);
    transform: scale(2.2);
    opacity: 0;
  }
}
