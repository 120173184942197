.notif {
  padding: 5px;
  font-weight: 500;
  text-align: start;
  border-radius: 5px;
  &__danger {
    background: rgba($pink, 0.09);
    color: $pink;
  }

  &__warning {
    background: rgba($yellow, 0.08);
    color: $yellow;

    a {
      color: $primary2;
    }
  }

  &__success {
    background: rgba($success, 0.08);
    color: $success;
  }
}
