.activity {
  &__top {
    position: relative;
    z-index: 3;
    display: flex;
    align-items: center;

    /*        width: calc(100% - 352px);
*/
    padding-right: 112px;
    padding-left: 112px;
    justify-content: space-between;

    @include x {
      padding-right: 64px;
    }

    @include d {
      /*            width: calc(100% - 304px);
*/
      padding-left: 100px;
      padding-right: 40px;
    }

    @include m {
      display: grid;
      width: 100%;
      padding: 40px;
    }
  }

  &__row > &__button {
    @include m {
      width: 100%;
      margin-top: 16px;
    }
  }

  &__toggle {
    position: relative;
    margin-left: 24px;

    .icon {
      &-filter {
        width: 24px;
        height: 24px;
      }

      &-close {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 14px;
        height: 14px;
        transform: translate(-50%, -50%);
        opacity: 0;
      }
    }

    &.active {
      background: $primary3;
      box-shadow: inset 0 0 0 2px $primary3;

      @include dark {
        background: $primary3;
      }

      .icon {
        fill: $neutrals8;

        &-filter {
          opacity: 0;
        }

        &-close {
          opacity: 1;
        }
      }
    }
  }

  &__row {
    display: flex;
    align-items: flex-start;
    margin-top: -56px;

    @include t {
      flex-direction: column-reverse;
      margin-top: 0;
    }
  }

  &__wrapper {
    flex-grow: 1;
    padding-right: 128px;
    padding-top: 120px;

    @include x {
      padding-right: 64px;
    }

    @include d {
      padding-right: 32px;
    }

    @include t {
      width: 100%;
      padding: 30px;
    }
    @include m {
      width: 100%;
      padding: 20px;
    }
  }

  &__filters {
    flex-shrink: 0;
    width: 352px;
    padding: 48px;
    border-radius: 24px;
    box-shadow:
      inset 0 0 0 1px $neutrals6,
      0px 40px 32px -24px rgba(15, 15, 15, 0.12);

    @include d {
      width: 304px;
      padding: 32px;
    }

    @include t {
      display: none;
      width: 100%;
    }

    @include dark {
      box-shadow:
        inset 0 0 0 1px $neutrals3,
        0px 40px 32px -24px rgba(15, 15, 15, 0.12);
      background: $neutrals2;
    }

    &.visible {
      @include t {
        display: block;
        margin-top: 32px;
      }

      @include m {
        margin-top: 16px;
      }
    }

    .active {
      background: brown;
    }
  }

  &__nav {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -6px 32px;

    @include t {
      margin-top: 40px;
    }

    @include m {
      margin-top: 32px;
    }
  }

  &__link {
    margin: 0 6px;
    padding: 6px 12px;
    border-radius: 14px;
    background: none;
    @include button-2;
    color: $neutrals4;
    transition: all 0.2s;

    &:hover {
      color: $neutrals3;

      @include dark {
        color: $neutrals6;
      }
    }

    &.active {
      background: $neutrals3;
      color: $neutrals8;
    }
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 16px 64px 16px 16px;
    border-radius: 20px;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition: background 0.2s;

    @include m {
      padding-right: 32px;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 24px;
      width: 12px;
      height: 12px;
      transform: translateY(-50%);
      transition: all 0.2s;

      @include m {
        right: 12px;
      }
    }

    &:before {
      border-radius: 50%;
      background: $primary3;
    }

    &:after {
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='10' fill='none' viewBox='0 0 6 10'%3E%3Cpath fill-rule='evenodd' d='M.793.793a1 1 0 0 0 0 1.414L3.586 5 .793 7.793a1 1 0 0 0 1.414 1.414l3.5-3.5a1 1 0 0 0 0-1.414l-3.5-3.5a1 1 0 0 0-1.414 0z' fill='%23777e91'/%3E%3C/svg%3E")
        no-repeat 50% 50% / 6px auto;
      opacity: 0;
    }

    &:hover {
      background: $neutrals7;

      @include dark {
        background: $neutrals2;
      }

      &:before {
        transform: translateY(-50%) scale(0);
      }

      &:after {
        opacity: 1;
      }
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__item2 {
    position: relative;
    display: flex;
    align-items: center;
    padding: 16px 64px 16px 16px;
    border-radius: 20px;
    // cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition: background 0.2s;

    @include m {
      padding-right: 32px;
    }

    &:before,
    &:after {
      position: absolute;
      top: 50%;
      right: 24px;
      width: 12px;
      height: 12px;
      transform: translateY(-50%);
      transition: all 0.2s;

      @include m {
        right: 12px;
      }
    }

    &:before {
      border-radius: 50%;
      background: $primary3;
    }

    &:after {
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='10' fill='none' viewBox='0 0 6 10'%3E%3Cpath fill-rule='evenodd' d='M.793.793a1 1 0 0 0 0 1.414L3.586 5 .793 7.793a1 1 0 0 0 1.414 1.414l3.5-3.5a1 1 0 0 0 0-1.414l-3.5-3.5a1 1 0 0 0-1.414 0z' fill='%23777e91'/%3E%3C/svg%3E")
        no-repeat 50% 50% / 6px auto;
      opacity: 0;
    }

    &:hover {
      background: $neutrals7;

      @include dark {
        background: $neutrals2;
      }

      &:before {
        transform: translateY(-50%) scale(0);
      }

      &:after {
        opacity: 1;
      }
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__item_Collection {
    position: relative;
    display: flex;
    align-items: center;
    padding: 16px 64px 16px 16px;
    border-radius: 20px;
    /*cursor: pointer;*/
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition: background 0.2s;

    @include m {
      padding-right: 32px;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 24px;
      width: 12px;
      height: 12px;
      transform: translateY(-50%);
      transition: all 0.2s;

      @include m {
        right: 12px;
      }
    }

    &:before {
      border-radius: 50%;
      background: $primary3;
    }

    &:hover {
      background: $neutrals7;

      @include dark {
        background: $neutrals2;
      }

      &:before {
        transform: translateY(-50%) scale(0);
      }

      &:after {
        opacity: 1;
      }
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    .view {
      &:hover {
        color: $primary3;
      }
    }

    .delete {
      &:hover {
        color: $pink;
      }
    }
  }

  &__preview {
    position: relative;
    flex-shrink: 0;
    width: 96px;
    height: 96px;
    margin-right: 24px;

    @include m {
      width: 64px;
      height: 64px;
      margin-right: 16px;
    }

    & > img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      object-fit: cover;
    }

    & > video {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      object-fit: cover;
    }
  }

  &__icon {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;

    @include m {
      width: 24px;
      height: 24px;
    }

    @include dark {
      border: 1px solid $neutrals3;
    }

    img {
      max-width: 16px;

      @include m {
        max-width: 12px;
      }
    }
  }

  &__details {
    flex-grow: 1;
  }

  &__subtitle {
    @include body-bold-1;
    color: $neutrals2;

    @include m {
      margin-bottom: 3px;
      font-size: 16px;
    }

    @include dark {
      color: $neutrals8;
    }
  }

  &__description {
    @include body-2;
    color: $neutrals3;

    @include m {
      font-size: 12px;
    }

    @include dark {
      color: $neutrals6;
    }
  }

  &__date {
    @include caption-bold-2;
    color: $neutrals4;
  }

  .loader {
    margin: 32px auto 0;
  }

  &__text {
    padding: 10px;
    color: $neutrals4;
    @include caption-1;
    border: 1px solid $neutrals6;
    border-radius: 4px;
    margin-bottom: 16px;
    width: 180%;
    /*        margin-left: 90px;
*/
    @include dark {
      border: 1px solid $neutrals3;
    }
  }

  &__line {
    display: flex;
    gap: 14px;

    &_box {
      display: block;
      width: 25%;

      @include m {
        display: none;
      }
    }

    &_top {
      display: flex;
      height: 100%;
      margin-top: -27px;
    }

    &_left {
      width: 50%;
      margin-top: -14px;
    }

    &_right {
      width: 50%;
      border: solid 1px $neutrals6;
      height: 76%;
      border-right: unset;
      border-top: unset;
      border-bottom-left-radius: 25px;

      @include dark {
        border: 1px solid $neutrals3;
        border-right: unset;
        border-top: unset;
        border-bottom-left-radius: 25px;
      }
    }
  }

  &__title-notification {
    @include m {
      font-size: 30px !important;
    }
  }

  &__header-notification {
    gap: 10px;
    display: grid;
  }

  &__header {
    a {
      color: #3396ff;
      &:hover {
        color: $primary3;
      }
    }
    &_note {
      @include caption-2;
      font-weight: 700;
      color: $primary1;
      margin-top: 24px;

      @include dark {
        color: $primary2;
      }
    }

    span {
      color: $primary3;
    }
  }

  &__sort {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 20%;

    @include m {
      padding-top: 20px;
      flex-direction: row;
      width: 100%;
      align-items: center;
    }
  }

  &__info {
    @include caption-2;
    font-weight: 700;
    color: $neutrals5;
  }

  &__group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include m {
      width: 100%;
    }

    li {
      @include caption-bold-1;
      color: $neutrals2;

      @include dark {
        color: $neutrals7;
      }
    }
  }

  .checkbox {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &__btns {
    display: flex;
    margin: 0 -6px;

    .activity__button {
      width: calc(50% - 12px);
      flex: 0 0 calc(50% - 12px);
      height: 32px;
      margin: 0 6px;
      padding: 0 8px;

      @include dark {
        box-shadow: inset 0 0 0 2px $neutrals4;

        &:hover {
          box-shadow: inset 0 0 0 2px $primary3;
        }
      }
    }
  }
}

.Visited {
  &.activity__item {
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 24px;
      width: 12px;
      height: 12px;
      transform: translateY(-50%);
      transition: all 0.2s;

      @include m {
        right: 12px;
      }
    }

    &:before {
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='10' fill='none' viewBox='0 0 6 10'%3E%3Cpath fill-rule='evenodd' d='M.793.793a1 1 0 0 0 0 1.414L3.586 5 .793 7.793a1 1 0 0 0 1.414 1.414l3.5-3.5a1 1 0 0 0 0-1.414l-3.5-3.5a1 1 0 0 0-1.414 0z' fill='%23777e91'/%3E%3C/svg%3E")
        no-repeat 50% 50% / 6px auto;
    }

    &:after {
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='10' fill='none' viewBox='0 0 6 10'%3E%3Cpath fill-rule='evenodd' d='M.793.793a1 1 0 0 0 0 1.414L3.586 5 .793 7.793a1 1 0 0 0 1.414 1.414l3.5-3.5a1 1 0 0 0 0-1.414l-3.5-3.5a1 1 0 0 0-1.414 0z' fill='%23777e91'/%3E%3C/svg%3E")
        no-repeat 50% 50% / 6px auto;
    }
  }

  &.activity__item2 {
    &:before,
    &:after {
      // content: "";
      position: absolute;
      top: 50%;
      right: 24px;
      width: 12px;
      height: 12px;
      transform: translateY(-50%);
      transition: all 0.2s;

      @include m {
        right: 12px;
      }
    }

    &:before {
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='10' fill='none' viewBox='0 0 6 10'%3E%3Cpath fill-rule='evenodd' d='M.793.793a1 1 0 0 0 0 1.414L3.586 5 .793 7.793a1 1 0 0 0 1.414 1.414l3.5-3.5a1 1 0 0 0 0-1.414l-3.5-3.5a1 1 0 0 0-1.414 0z' fill='%23777e91'/%3E%3C/svg%3E")
        no-repeat 50% 50% / 6px auto;
    }

    &:after {
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='10' fill='none' viewBox='0 0 6 10'%3E%3Cpath fill-rule='evenodd' d='M.793.793a1 1 0 0 0 0 1.414L3.586 5 .793 7.793a1 1 0 0 0 1.414 1.414l3.5-3.5a1 1 0 0 0 0-1.414l-3.5-3.5a1 1 0 0 0-1.414 0z' fill='%23777e91'/%3E%3C/svg%3E")
        no-repeat 50% 50% / 6px auto;
    }
  }
}
