.player {
  position: relative;

  &__preview {
    & > img {
      width: 100%;
      height: 100%;
      transition: transform 1s;
      object-fit: cover;
    }

    & > video {
      width: 100%;
      height: 100%;
      transition: transform 1s;
      object-fit: cover;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background: rgba($neutrals1, 0.3)
        url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none' xmlns:v='https://vecta.io/nano'%3E%3Cpath d='M0 24C0 10.745 10.745 0 24 0s24 10.745 24 24-10.745 24-24 24S0 37.255 0 24z' fill='%23fcfcfd'/%3E%3Cpath fill-rule='evenodd' d='M25.394 20.843c.271-.25.692-.233.942.038l2.154 2.333c.236.255.236.649 0 .904l-2.154 2.333c-.25.271-.672.287-.942.038s-.287-.671-.038-.942l1.121-1.215H20c-.368 0-.667-.298-.667-.667S19.632 23 20 23h6.477l-1.121-1.214c-.25-.271-.233-.692.038-.942z' fill='%23777e91'/%3E%3C/svg%3E")
        no-repeat 50% 50% / 48px 48px;
      opacity: 0;
      visibility: hidden;
      transition: all 1s;
    }
  }

  img {
    width: 100%;
    border-radius: 0px;
  }

  video {
    width: 100%;
    border-radius: 0px;
  }

  &__control {
    position: absolute;
    left: 8px;
    right: 8px;
    bottom: 8px;
    z-index: 3;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 20px;
    border-radius: 0px;
    background: $neutrals8;

    @include dark {
      background: $neutrals1;
    }
  }

  &__button {
    .icon {
      width: 24px;
      height: 24px;
      fill: $neutrals4;
      transition: fill 0.2s;
    }

    &:hover {
      .icon {
        fill: $neutrals2;

        @include dark {
          fill: $neutrals8;
        }
      }
    }

    &:not(:last-child) {
      margin-right: 24px;

      @include m {
        margin-right: 16px;
      }
    }
  }

  &__line {
    position: relative;
    flex-grow: 1;
    height: 8px;
    margin-right: 24px;
    background: $neutrals6;
    border-radius: 0px;

    @include m {
      margin-right: 16px;
    }

    @include dark {
      background: $neutrals3;
    }
  }

  &__progress {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border-radius: 0px;
    background: $primary3;
  }

  &__time {
    margin-right: 24px;
    @include body-bold-2;

    @include m {
      margin-right: 16px;
    }
  }
}
