.card {
  display: flex;
  flex-direction: column;
  border-radius: 0px;
  background-color: transparent;
  border: none;

  &:hover {
    .card__preview > img {
      transform: scale(1.1);

      &:before {
        opacity: 1;
        visibility: visible;
      }
    }

    .card__preview_profile > img {
      transform: scale(1.1);

      &:before {
        opacity: 1;
        visibility: visible;
      }
    }

    .card__preview_upload > img {
      transform: scale(1.1);
    }

    .card__control {
      visibility: visible;
      opacity: 1;
    }
  }

  &__preview {
    position: relative;
    border-radius: 0px;
    overflow: hidden;
    height: 400px;
    margin: 10px;

    &.skeleton {
      transition: all 200ms ease-in-out;
      position: relative;
      background-color: #f1f2f345;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0,
          rgba(255, 255, 255, 0.2) 20%,
          rgba(255, 255, 255, 0.5) 60%,
          rgba(255, 255, 255, 0)
        );
        animation: load 1s infinite;
      }
    }

    @keyframes load {
      100% {
        transform: translateX(100%);
      }
    }

    @media screen and (prefers-reduced-motion: reduce) {
      .skeleton::after {
        animation: none;
      }
    }

    & > img {
      width: 100%;
      height: 100%;
      transition: transform 1s;
      object-fit: cover;
      animation: load_img 0.5s;
    }

    @keyframes load_img {
      0% {
        -webkit-filter: blur(0px);
      }

      50% {
        -webkit-filter: blur(5px);
      }

      100% {
        -webkit-filter: blur(0px);
      }
    }

    & > video {
      width: 100%;
      height: 100%;
      transition: transform 1s;
      object-fit: cover;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background: rgba($neutrals1, 0.3)
        url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none' xmlns:v='https://vecta.io/nano'%3E%3Cpath d='M0 24C0 10.745 10.745 0 24 0s24 10.745 24 24-10.745 24-24 24S0 37.255 0 24z' fill='%23fcfcfd'/%3E%3Cpath fill-rule='evenodd' d='M25.394 20.843c.271-.25.692-.233.942.038l2.154 2.333c.236.255.236.649 0 .904l-2.154 2.333c-.25.271-.672.287-.942.038s-.287-.671-.038-.942l1.121-1.215H20c-.368 0-.667-.298-.667-.667S19.632 23 20 23h6.477l-1.121-1.214c-.25-.271-.233-.692.038-.942z' fill='%23777e91'/%3E%3C/svg%3E")
        no-repeat 50% 50% / 48px 48px;
      opacity: 0;
      visibility: hidden;
      transition: all 0.2s;
    }
  }

  &__preview_profile {
    position: relative;
    border-radius: 0px;
    overflow: hidden;
    height: 400px;
    margin: 10px;

    & > img {
      width: 100%;
      height: 100%;
      transition: transform 1s;
      object-fit: cover;
    }
  }

  &__preview_upload {
    position: relative;
    border-radius: 0px;
    overflow: hidden;
    height: 400px;
    margin: 10px;
    text-align: center;

    & > img,
    video {
      max-width: 100%;
      height: 100%;
      transition: transform 1s;
      object-fit: cover;
    }
  }

  &__preview-catalog {
    position: relative;
    border-radius: 0px;
    overflow: hidden;
    height: 300px;
    margin: 10px;

    & > img {
      width: 100%;
      height: 100%;
      transition: transform 1s;
      object-fit: cover;
    }
  }

  &__control {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($neutrals2, 0.2);
    visibility: hidden;
    border-radius: 0px;
    opacity: 0;
    transition: all 0.3s;
  }

  &__category {
    position: absolute;
    top: 11px;
    left: 8px;
  }

  &__favorite {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 32px;
    height: 32px;
    background: $neutrals8;
    box-shadow: 0px 8px 16px rgba(15, 15, 15, 0.2);
    border-radius: 50%;

    .icon {
      width: 20px;
      height: 20px;
      fill: $neutrals4;
      transition: all 0.2s;
    }

    &:before {
      content: "";
      position: absolute;
      top: 51%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 18px;
      height: 18px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='18' fill='none' viewBox='0 0 22 18'%3E%3Cpath d='M11 1.81A6.48 6.48 0 0 0 6.5 0 6.5 6.5 0 0 0 0 6.5c0 6.368 6.97 9.885 9.814 11.055.766.315 1.607.315 2.372 0C15.03 16.385 22 12.868 22 6.5A6.5 6.5 0 0 0 15.5 0 6.48 6.48 0 0 0 11 1.81z' fill='%23ef466f'/%3E%3C/svg%3E")
        no-repeat 50% 50% / 100% auto;
      opacity: 0;
      transition: opacity 0.2s;
    }

    &:hover {
      .icon {
        fill: $neutrals2;
      }
    }

    &.active {
      .icon {
        opacity: 0;
      }

      &:before {
        opacity: 1;
      }
    }

    @include dark {
      background: $neutrals2;

      &:hover {
        .icon {
          fill: $neutrals8;
        }
      }
    }
  }

  &__button {
    position: absolute;
    left: 50%;
    bottom: 16px;
    min-width: 140px;
    transform: translateX(-50%);

    .icon {
      width: 16px;
      height: 16px;
    }
  }

  &__link {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    /*padding: 20px 0;*/
    color: $neutrals2;

    @include dark {
      color: $neutrals8;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 5px 10px;
  }

  &__line {
    display: flex;
    align-items: flex-start;

    &:first-child {
      margin-bottom: 12px;
      border-bottom: 1px solid $neutrals6;
      padding-bottom: 15px;

      @include dark {
        border-bottom: 1px solid $neutrals3;
      }
    }

    &:nth-child(2) {
      margin-bottom: 12px;
      border-bottom: 1px solid $neutrals6;
      padding-bottom: 15px;

      @include dark {
        border-bottom: 1px solid $neutrals3;
      }
    }

    &:nth-child(3) {
      margin-top: auto;
    }
  }
  /*&__title {
        margin-right: auto;
        padding-top: 1px;
        @include body-bold-2;
    }*/

  &__title {
    margin-right: auto;
    padding-top: 1px;
    font-size: 18px;
    line-height: 1.5;
    font-weight: 600;
  }

  &__details {
    margin-right: auto;
    padding-top: 1px;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 500;
    color: $neutrals4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      color: $primary1;
      font-weight: 600;

      @include dark {
        color: $primary2;
      }
    }
  }

  &__price {
    flex-shrink: 0;
    margin-left: 8px;
    padding: 0 8px;
    border-radius: 0px;
    box-shadow: inset 0 0 0 2px $primary3;
    font-size: 12px;
    line-height: 26px;
    font-weight: 700;
    text-transform: uppercase;
    color: $primary3;
  }

  &__target {
    flex-shrink: 0;
    margin-left: 8px;
    padding: 0 8px;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 500;
    color: $neutrals4;

    b {
      color: $primary1;
      font-weight: 600;

      @include dark {
        color: $primary1;
      }
    }
  }

  &__auction_time {
    flex-shrink: 0;
    margin-left: 8px;
    padding: 0 8px;
    font-size: 14px;
    line-height: 2;
    font-weight: 500;
    color: $neutrals4;

    span {
      color: $primary3;
      font-weight: 600;

      @include dark {
        color: $primary3;
      }
    }

    b {
      color: $black;
      font-weight: 600;

      @include dark {
        color: $white;
      }
    }
  }

  &__users {
    display: flex;
    margin-right: auto;
  }

  &__author {
    align-self: center;
    margin-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__avatar {
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid $neutrals8;

    @include dark {
      border-color: $neutrals2;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }

    &:not(:first-child) {
      margin-left: -8px;
    }
  }

  &__counter {
    @include dark {
      color: $neutrals6;
    }
  }

  &__foot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid $neutrals6;
    @include caption-2;
    color: $neutrals4;

    @include dark {
      border-color: $neutrals3;
    }
  }

  &__status {
    .icon {
      width: 20px;
      height: 20px;
      margin: -2px 4px 0 0;
      fill: $neutrals4;
    }

    span {
      font-weight: 600;
      color: $neutrals3;

      @include dark {
        color: $neutrals8;
      }
    }
  }
}

.seed_list .card2__box {
  padding: 16px;
}

.card2__preview {
  height: 120px;
}

.seed__slide {
  .card2 {
    &.portrait {
      width: 350px;
      margin: 0 10px 20px;

      @include m {
        width: 300px;
      }

      @include f {
        width: 250px;
      }
    }

    &.landscape {
      width: 500px;
      margin: 0 10px 20px;

      @include m {
        width: 350px;
      }

      @include f {
        width: 250px;
      }
    }
  }
}

.seed_list {
  margin-top: 50px;

  &.inspire {
    margin-top: 0;
  }

  .card2 {
    &.raise_scale {
      -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }

    &.raise_scale-left {
      -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }

    &.raise_scale-right {
      -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }

    border: none;
    // padding: 10px;

    @include dark {
      border: none;
    }

    &.portrait {
      width: 100%;

      @include m {
        margin-bottom: 25px;
      }
    }

    &.landscape {
      width: 100%;
      @include m {
        margin-bottom: 25px;
      }

      .card2__preview {
        height: 250px;
      }
    }

    &__inspire {
      padding: 0 !important;
      margin: 0 !important;

      @include u {
        width: calc(100% / 8);
        margin: 0 0 20px 0;
        border: none;
        padding: 10px;

        .card2__preview {
          height: calc((480px * 4) / 2);
        }

        &.landscape {
          .card2__preview {
            height: calc((250px * 4) / 2);
          }
        }
      }

      @include r(4700) {
        width: calc(100% / 7);

        .card2__preview {
          height: calc((480px * 3) / 2);
        }

        &.landscape {
          .card2__preview {
            height: calc((250px * 3) / 2);
          }
        }
      }

      @include r(3700) {
        width: calc(100% / 6);

        .card2__preview {
          height: calc((480px * 2) / 2);
        }

        &.landscape {
          .card2__preview {
            height: calc((250px * 2) / 2);
          }
        }
      }

      @include r(2300) {
        width: calc(100% / 5);

        .card2__preview {
          height: calc((480px * 2) / 2);
        }

        &.landscape {
          .card2__preview {
            height: calc((250px * 2) / 2);
          }
        }
      }

      @include w {
        width: 25%;
        margin: 0 0 20px 0;
        border: none;
        padding: 10px;

        .card2__preview {
          height: calc((480px * 2) / 2);
        }

        &.landscape {
          .card2__preview {
            height: calc((250px * 2) / 2);
          }
        }
      }

      .card2__box {
        padding: 0;
      }
    }

    &__box {
      background: $primary2;
      border: 1px solid $neutrals6;
      padding: 4px;

      @include dark {
        background: $primary1;
        border: 1px solid $neutrals3;
      }
    }

    &__preview {
      height: 460px;
    }

    &.hide {
      opacity: 0;
      visibility: hidden;
    }
  }
}

/**
 * ----------------------------------------
 * animation
 * ----------------------------------------
 */

@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: translateX(-10px) scale(0);
    transform: translateX(-10px) scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-10px) scale(1);
    transform: translateX(-10px) scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: translateX(-10px) scale(0);
    transform: translateX(-10px) scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-10px) scale(1);
    transform: translateX(-10px) scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
    opacity: 1;
  }
}

.list-1c {
  .card2 {
    flex: none;
    width: 100%;
    max-width: 100%;
    margin: 0;

    @include m {
      width: 100%;
      margin-bottom: 25px;
    }

    &.portrait {
      flex: none;
      width: 100%;
      max-width: 100%;
      margin: 0;

      @include m {
        width: 100%;
        max-width: 100%;
        margin-bottom: 25px;
      }
    }

    &.landscape {
      flex: none;
      width: 100%;
      max-width: 100%;
      margin: 0;

      @include m {
        width: 100%;
        max-width: 100%;
        margin-bottom: 25px;
      }
    }

    &__preview {
      height: 100%;
    }
  }
}

/*card redesign*/
.card2 {
  display: flex;
  flex-direction: column;
  border-radius: 0px;
  background-color: transparent;
  border: 1px solid $neutrals6;
  padding: 10px;
  width: 350px;
  direction: ltr;
  /*&__box{

    }*/

  @include m {
    width: 100%;
  }

  @include f {
    width: 100%;
  }

  @include dark {
    border: 1px solid $neutrals3;
    background-color: $neutrals1;
  }

  &.instagram {
    .card2__preview {
      height: 370px;
    }
  }

  &.portrait {
    flex: 0 0 37%;
    max-width: 350px;
    margin: 0 10px 20px;

    &.card2__inspire {
      flex: unset;
      max-width: unset;
    }

    @include m {
      flex: 0 0 100%;
      width: 100%;
    }

    @include f {
      flex: 0 0 100%;
      width: 100%;
      max-width: 320px;
    }
  }

  &.landscape {
    flex: 0 0 55%;
    max-width: 500px;
    margin: 0 10px 20px;
    &.card2__inspire {
      flex: unset;
      max-width: unset;
    }

    @include m {
      flex: 0 0 100%;
      width: 100%;
      max-width: 320px;
    }

    @include f {
      flex: 0 0 100%;
      width: 100%;
    }
  }

  &.event {
    padding: 26px;
    width: 560px;
    border-radius: 4px;

    @include m {
      width: 100%;
      margin: 0 20px;
    }

    .card2__preview {
      height: 270px;
    }

    iframe,
    img {
      width: 100%;
      height: 270px;
    }

    .card2__title {
      @include wrap-text;
      margin: 20px 0;
    }

    .event__row {
      display: flex;
      align-items: center;
      font-size: 16.42px;
      font-weight: 400;
      line-height: 28px;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }

      .row_texts {
        display: flex;
        align-items: center;
      }

      .row_title {
        color: $neutrals4;
        margin-right: 5px;
      }

      .row_value {
        @include wrap-text;
      }

      &.more_link {
        color: $primary3;
        font-size: 16.42px;
        font-weight: 400;
        line-height: 28px;

        img {
          @include dark {
            @include filter-primary2;
          }
        }
      }
    }
  }

  &:hover {
    .card2__preview > img {
      transform: scale(1.1);

      &:before {
        opacity: 1;
        visibility: visible;
      }
    }

    .card2__preview_profile > img {
      transform: scale(1.1);

      &:before {
        opacity: 1;
        visibility: visible;
      }
    }

    .card2__preview_upload > img {
      transform: scale(1.1);
    }

    .card2__control {
      visibility: visible;
      opacity: 1;
    }
    .card2__creator {
      width: auto;
      // padding: 4px;

      .creator__name {
        display: -webkit-box;
      }

      &.multi_owner {
        display: none;
      }

      &_list {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &__preview {
    position: relative;
    border-radius: 0px;
    overflow: hidden;
    height: 325px;
    /*margin: 10px;*/

    &.skeleton {
      transition: all 200ms ease-in-out;
      position: relative;
      background-color: #f1f2f345;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0,
          rgba(255, 255, 255, 0.2) 20%,
          rgba(255, 255, 255, 0.5) 60%,
          rgba(255, 255, 255, 0)
        );
        animation: load 1s infinite;
      }
    }

    @keyframes load {
      100% {
        transform: translateX(100%);
      }
    }

    @media screen and (prefers-reduced-motion: reduce) {
      .skeleton::after {
        animation: none;
      }
    }

    & > img {
      width: 100%;
      height: 100%;
      transition: transform 1s;
      object-fit: cover;
      animation: load_img 0.5s;
    }

    @keyframes load_img {
      0% {
        -webkit-filter: blur(0px);
      }

      50% {
        -webkit-filter: blur(5px);
      }

      100% {
        -webkit-filter: blur(0px);
      }
    }

    & > video {
      width: 100%;
      height: 100%;
      transition: transform 1s;
      object-fit: cover;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background: rgba($neutrals1, 0.3)
        url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none' xmlns:v='https://vecta.io/nano'%3E%3Cpath d='M0 24C0 10.745 10.745 0 24 0s24 10.745 24 24-10.745 24-24 24S0 37.255 0 24z' fill='%23fcfcfd'/%3E%3Cpath fill-rule='evenodd' d='M25.394 20.843c.271-.25.692-.233.942.038l2.154 2.333c.236.255.236.649 0 .904l-2.154 2.333c-.25.271-.672.287-.942.038s-.287-.671-.038-.942l1.121-1.215H20c-.368 0-.667-.298-.667-.667S19.632 23 20 23h6.477l-1.121-1.214c-.25-.271-.233-.692.038-.942z' fill='%23777e91'/%3E%3C/svg%3E")
        no-repeat 50% 50% / 48px 48px;
      opacity: 0;
      visibility: hidden;
      transition: all 0.2s;
    }
  }

  &__creator {
    position: absolute;
    top: 10px;
    left: 8px;
    // padding-right: 10px;
    border-radius: 50px;
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(80px);
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    height: 40px;
    min-width: 40px;
    z-index: 1;
    transition: all 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
    // padding-right: 10px;
    // padding: 0 5px;
    gap: 5px;
    @include main-font;
    box-shadow: 0px 0px 12px 0px #00000040;

    &:hover {
      width: auto;
      // margin-right: 5px;
      transform: scale(1.05);
      background: rgba(142, 121, 62, 0.1);
      backdrop-filter: blur(80px);
      border-radius: 72px;
      -webkit-backdrop-filter: blur(80px);
      // padding: 0 4px;

      .creator__name {
        display: flex;
        padding: 1px;
        transition: all 0.2s;
      }
    }

    .creator__name {
      color: $primary2;
      overflow: hidden;
      text-overflow: ellipsis;
      display: none;
      -webkit-line-clamp: 1; /* number of lines to show */
      line-clamp: 1;
      -webkit-box-orient: vertical;
      text-align: start;
      // transition: all 0.2s;
      margin-right: 8px;
    }

    &_list {
      visibility: hidden;
      opacity: 0;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      position: absolute;
      top: 10px;
      left: 8px;
      transition: all 0.2s ease;

      .card2__creator {
        position: unset;
      }
    }
  }

  &__timer {
    border-radius: 5px;
    background-color: $neutrals2;
    padding: 2px 10px;
    gap: 4px;

    &.time-limited {
      background-color: #d0342c;
      color: $primary2;
    }

    @include dark {
      background-color: $neutrals7;
    }
  }

  &__avatars {
    position: relative;
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    border: 2px solid $primary2;
    // margin-right: 5px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3px;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50px;
      vertical-align: unset;
    }

    &:not(:first-child) {
      margin-left: -14px;
    }
  }

  &__preview_profile {
    position: relative;
    border-radius: 0px;
    overflow: hidden;
    height: 400px;
    margin: 10px;

    & > img {
      width: 100%;
      height: 100%;
      transition: transform 1s;
      object-fit: cover;
    }
  }

  &__preview_upload {
    position: relative;
    border-radius: 0px;
    overflow: hidden;
    height: 400px;
    margin: 10px;

    & > img {
      width: 100%;
      height: 100%;
      transition: transform 1s;
      object-fit: cover;
    }
  }

  &__preview-catalog {
    position: relative;
    border-radius: 0px;
    overflow: hidden;
    height: 300px;
    margin: 10px;

    & > img {
      width: 100%;
      height: 100%;
      transition: transform 1s;
      object-fit: cover;
    }
  }

  &__control {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($neutrals2, 0.2);
    visibility: hidden;
    border-radius: 0px;
    opacity: 0;
    transition: all 0.3s;
  }

  &__category {
    position: absolute;
    top: 11px;
    left: 8px;
  }

  &__favorite {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 32px;
    height: 32px;
    background: $neutrals8;
    box-shadow: 0px 8px 16px rgba(15, 15, 15, 0.2);
    border-radius: 50%;

    .icon {
      width: 20px;
      height: 20px;
      fill: $neutrals4;
      transition: all 0.2s;
    }

    &:before {
      content: "";
      position: absolute;
      top: 51%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 18px;
      height: 18px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='18' fill='none' viewBox='0 0 22 18'%3E%3Cpath d='M11 1.81A6.48 6.48 0 0 0 6.5 0 6.5 6.5 0 0 0 0 6.5c0 6.368 6.97 9.885 9.814 11.055.766.315 1.607.315 2.372 0C15.03 16.385 22 12.868 22 6.5A6.5 6.5 0 0 0 15.5 0 6.48 6.48 0 0 0 11 1.81z' fill='%23ef466f'/%3E%3C/svg%3E")
        no-repeat 50% 50% / 100% auto;
      opacity: 0;
      transition: opacity 0.2s;
    }

    &:hover {
      .icon {
        fill: $neutrals2;
      }
    }

    &.active {
      .icon {
        opacity: 0;
      }

      &:before {
        opacity: 1;
      }
    }

    @include dark {
      background: $neutrals2;

      &:hover {
        .icon {
          fill: $neutrals8;
        }
      }
    }
  }

  &__like_container {
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba($primary2, 0.3);
    border-radius: 0px 24px 0px 0px;
    padding: 8px 12px 8px 8px;
    display: flex;
    gap: 8px;
  }

  &__share {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background: $neutrals8;
    // padding: 5px 8px;
    border-radius: 50%;

    img {
      width: 20px;
      height: 20px;
      @include filter-neutrals4;

      &:hover {
        @include filter-primary3;
      }
    }

    @include dark {
      background: $neutrals2;

      .current-color {
        color: $neutrals4;
      }
      &.collections {
        background: $neutrals1;
      }
    }
  }

  &__favorite2,
  &__chain {
    display: flex;
    align-items: center;
    gap: 8px;
    background: $neutrals8;
    box-shadow: 0px 8px 16px rgba(15, 15, 15, 0.2);
    border-radius: 50px;
    padding: 8px 10px;
    position: relative;

    img {
      width: 20px;
      height: 20px;
    }

    .icon {
      width: 20px;
      height: 20px;
      fill: $neutrals4;
      transition: all 0.2s;
    }

    .btn__favorite {
      display: flex;
      gap: 8px;

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 68%;
        transform: translate(-50%, -50%);
        width: 18px;
        height: 18px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='18' fill='none' viewBox='0 0 22 18'%3E%3Cpath d='M11 1.81A6.48 6.48 0 0 0 6.5 0 6.5 6.5 0 0 0 0 6.5c0 6.368 6.97 9.885 9.814 11.055.766.315 1.607.315 2.372 0C15.03 16.385 22 12.868 22 6.5A6.5 6.5 0 0 0 15.5 0 6.48 6.48 0 0 0 11 1.81z' fill='%23ef466f'/%3E%3C/svg%3E")
          no-repeat 50% 50% / 100% auto;
        opacity: 0;
        transition: opacity 0.2s;

        @include filter-primary3;
      }

      &:hover {
        .icon {
          fill: $primary3;
        }
      }

      &.active {
        .icon {
          opacity: 0;
        }

        &:before {
          opacity: 1;
        }
      }
    }

    @include dark {
      background: $neutrals2;

      &:hover {
        .icon {
          fill: $primary3;
        }
      }
    }

    .like_count,
    .chain_name {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $neutrals2;

      @include dark {
        color: $neutrals7;
      }
    }
  }

  &__button {
    position: absolute;
    left: 50%;
    bottom: 16px;
    min-width: 140px;
    transform: translateX(-50%);

    .icon {
      width: 16px;
      height: 16px;
    }
  }

  &__link {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    /*padding: 20px 0;*/
    color: $neutrals2;

    @include dark {
      color: $neutrals8;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 5px 10px;
  }

  &__line {
    display: flex;
    align-items: center;

    &:first-child {
      margin-bottom: 12px;
      border-bottom: 1px solid $neutrals6;
      padding-bottom: 15px;

      @include dark {
        border-bottom: 1px solid $neutrals3;
      }
    }
  }

  &__title {
    margin-right: auto;
    padding-top: 1px;
    font-size: 18px;
    line-height: 1.5;
    font-weight: 600;
  }

  &__details {
    margin-right: auto;
    padding-top: 1px;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 500;
    color: $neutrals4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      color: $primary1;
      font-weight: 600;

      @include dark {
        color: $primary2;
      }
    }

    &.card2__details_Highest {
      overflow: unset;
    }
  }

  &__details2 {
    margin-right: auto;
    padding-top: 1px;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 500;
    color: $neutrals4;

    span {
      color: $primary1;
      font-weight: 600;

      @include dark {
        color: $primary2;
      }
    }
  }

  &__price {
    flex-shrink: 0;
    margin-left: 8px;
    padding: 0 8px;
    border-radius: 0px;
    font-size: 12px;
    line-height: 26px;
    font-weight: 700;
    text-transform: uppercase;

    &.bnb {
      box-shadow: inset 0 0 0 2px $primary3;
      color: $primary3;
    }

    &.matic {
      box-shadow: inset 0 0 0 2px $primary3;
      color: $primary3;
    }

    &.eth {
      box-shadow: inset 0 0 0 2px $primary3;
      color: $primary3;
    }
  }

  &__target {
    flex-shrink: 0;
    margin-left: 8px;
    padding: 0 8px;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 500;
    color: $neutrals4;
    text-align: end;

    b {
      color: $primary1;
      font-weight: 600;

      @include dark {
        color: $primary1;
      }
    }
  }

  .bnb__price {
    display: flex;
    align-items: center;
    color: $neutrals3;

    @include dark {
      color: $white;
    }

    img {
      margin-right: 4px;
      width: 16px;
      height: 16px;
    }
  }

  &__owner {
    @include main-font;
    font-size: 14px;
    line-height: 20px;
    color: $neutrals4;

    &_user {
      display: flex;
      align-items: flex-start;
      align-self: center;
      gap: 10px;
      @include main-font;
      font-size: 16px;
      line-height: 24px;
      color: $neutrals3;

      @include dark {
        color: $neutrals6;
      }
    }
  }

  &__auction_time {
    flex-shrink: 0;
    margin-left: 8px;
    padding: 0 8px;
    font-size: 14px;
    line-height: 2;
    font-weight: 500;
    color: $neutrals4;

    span {
      color: $primary3;
      font-weight: 600;

      @include dark {
        color: $primary3;
      }
    }

    b {
      color: $black;
      font-weight: 600;

      @include dark {
        color: $primary2;
      }
    }
  }

  &__users {
    display: flex;
    margin-right: auto;
  }

  &__author {
    align-self: center;
    margin-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__avatar {
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid $neutrals8;

    @include dark {
      border-color: $neutrals2;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }

    &:not(:first-child) {
      margin-left: -8px;
    }
  }

  &__counter {
    @include dark {
      color: $neutrals6;
    }
  }

  &__foot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid $neutrals6;
    @include caption-2;
    color: $neutrals4;

    @include dark {
      border-color: $neutrals3;
    }
  }

  &__status {
    .icon {
      width: 20px;
      height: 20px;
      margin: -2px 4px 0 0;
      fill: $neutrals4;
    }

    span {
      font-weight: 600;
      color: $neutrals3;

      @include dark {
        color: $neutrals8;
      }
    }
  }
}

// .museum {
//   .card2 {
//     &.portrait {
//       @include m {
//         max-width: 96px;
//         height: 120px;
//       }
//     }

//     &.landscape {
//       @include m {
//         max-width: 210px;
//         height: 120px;
//       }
//     }
//   }
// }

.museum {
  &__button {
    width: 95%;
    background: $primary1;
    display: block;
    margin: 36px auto 16px;

    @include dark {
      background: $primary2;
    }

    &.lock {
      background: $neutrals4;
    }

    &[data-tooltip]:before {
      width: 100% !important;
      left: 25% !important;

      @include t {
        left: 26% !important;
      }

      @include m {
        left: 30.5% !important;
      }
    }

    &_btn {
      display: flex;
      gap: 12px;
      align-items: center;
      justify-content: center;
      padding: 20px 0;

      @include dark {
        img {
          @include filter-primary1;
        }
      }
    }

    &_details {
      color: $primary2;
      @include hairline-1;

      @include dark {
        color: $primary1;
      }
    }
  }
}

.museum_slider {
  .card2 {
    padding: 0px;
  }
}

.museum__calendar_nfts {
  .card2 {
    width: 298px;
    padding: 0px;

    @include t {
      max-width: 50%;

      &:first-child,
      &:nth-child(2) {
        flex: 1 0 50%;
      }

      &:not(:first-child, :nth-child(2)) {
        display: none;
      }
    }

    @include m {
      max-width: 100%;

      &:first-child {
        flex: 1 0 100%;
      }

      &:not(:first-child) {
        display: none;
      }
    }
  }

  .card2__preview {
    height: 298px;

    @include m {
      height: 300px;
    }
  }
}

.museum__UpComingReleased_card {
  &.card2 {
    @include dark {
      background: transparent;
    }

    &.portrait {
      margin: 0;

      @include t {
        flex: 0 0 calc(50% - 12px);
        max-width: calc(50% - 12px);
      }

      @include m {
        width: 100%;
        margin-bottom: 25px;
        max-width: 100%;
      }
    }

    &.landscape {
      margin: 0;

      @include t {
        flex: 0 0 calc(50% - 12px);
        max-width: calc(50% - 12px);
      }

      @include m {
        width: 100%;
        margin-bottom: 25px;
        max-width: 100%;
      }

      .card2__preview {
        height: 240px;
      }
    }
  }

  .card2__line:first-child {
    display: flex;
    flex-direction: column;
  }
}

.museum__index_card {
  &.card2 {
    @include m {
      width: 100%;
      max-width: 320px;
    }
  }
}

.card_auction {
  &:hover {
    .card__preview > img {
      transform: scale(1.1);

      &:before {
        opacity: 1;
        visibility: visible;
      }
    }

    .card__preview {
      &:before {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__change_price {
    text-align: right;
    font-size: 14px;
    color: $neutrals4;
  }
}

.card-new {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  width: 100%;

  @include dark {
    background-color: $neutrals2;
  }

  &:hover {
    .card__preview > img {
      transform: scale(1.1);
    }

    .card__control {
      visibility: visible;
      opacity: 1;
    }
  }

  &__preview {
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    height: 250px;

    & > img {
      width: 100%;
      height: 100%;
      transition: transform 1s;
      object-fit: cover;
    }
  }

  &__control {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($neutrals2, 0.2);
    visibility: hidden;
    border-radius: 16px;
    opacity: 0;
    transition: all 0.3s;
  }

  &__category {
    position: absolute;
    top: 11px;
    left: 8px;
  }

  &__favorite {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 32px;
    height: 32px;
    background: $neutrals8;
    box-shadow: 0px 8px 16px rgba(15, 15, 15, 0.2);
    border-radius: 50%;

    .icon {
      width: 20px;
      height: 20px;
      fill: $neutrals4;
      transition: all 0.2s;
    }

    &:before {
      content: "";
      position: absolute;
      top: 51%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 18px;
      height: 18px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='18' fill='none' viewBox='0 0 22 18'%3E%3Cpath d='M11 1.81A6.48 6.48 0 0 0 6.5 0 6.5 6.5 0 0 0 0 6.5c0 6.368 6.97 9.885 9.814 11.055.766.315 1.607.315 2.372 0C15.03 16.385 22 12.868 22 6.5A6.5 6.5 0 0 0 15.5 0 6.48 6.48 0 0 0 11 1.81z' fill='%23ef466f'/%3E%3C/svg%3E")
        no-repeat 50% 50% / 100% auto;
      opacity: 0;
      transition: opacity 0.2s;
    }

    &:hover {
      .icon {
        fill: $neutrals2;
      }
    }

    &.active {
      .icon {
        opacity: 0;
      }

      &:before {
        opacity: 1;
      }
    }

    @include dark {
      background: $neutrals2;

      &:hover {
        .icon {
          fill: $neutrals8;
        }
      }
    }
  }

  &__button {
    position: absolute;
    left: 50%;
    bottom: 16px;
    min-width: 140px;
    transform: translateX(-50%);

    .icon {
      width: 16px;
      height: 16px;
    }
  }

  &__link {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 20px 0;
    color: $neutrals2;

    @include dark {
      color: $neutrals8;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 5px 15px;
  }

  &__line {
    display: flex;
    align-items: flex-start;

    &:first-child {
      margin-bottom: 12px;
    }

    &:nth-child(2) {
      margin-top: auto;
    }
  }

  &__title {
    margin-right: auto;
    padding-top: 1px;
    @include body-bold-2;
  }

  &__price {
    flex-shrink: 0;
    margin-left: 8px;
    padding: 0 8px;
    border-radius: 4px;
    box-shadow: inset 0 0 0 2px $primary3;
    font-size: 12px;
    line-height: 26px;
    font-weight: 700;
    text-transform: uppercase;
    color: $primary3;
  }

  &__users {
    display: flex;
    margin-right: auto;
  }

  &__avatar {
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid $neutrals8;

    @include dark {
      border-color: $neutrals2;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }

    &:not(:first-child) {
      margin-left: -8px;
    }
  }

  &__avatar_new_nft {
    flex-shrink: 0;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 2px solid $neutrals8;
    margin: -60px auto 0;
    z-index: 1;

    @include dark {
      border-color: $neutrals2;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }

    &:not(:first-child) {
      margin-left: -8px;
    }
  }

  &__counter {
    @include dark {
      color: $neutrals6;
    }
  }

  &__foot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid $neutrals6;
    @include caption-2;
    color: $neutrals4;

    @include dark {
      border-color: $neutrals3;
    }
  }

  &__status {
    .icon {
      width: 20px;
      height: 20px;
      margin: -2px 4px 0 0;
      fill: $neutrals4;
    }

    span {
      font-weight: 600;
      color: $neutrals3;

      @include dark {
        color: $neutrals8;
      }
    }
  }
}

//Masonry Component
.masonry-Layout {
  position: relative;
}

.masonry-item {
  break-inside: avoid;
  box-sizing: border-box;
  &.item-animation {
    transition:
      max-width 0.6s ease,
      top 0.6s ease,
      left 0.6s ease,
      transform 0.6s ease;
  }
}
