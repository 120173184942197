.invoice {
  &__card {
    position: relative;
    max-width: 448px;
    margin: 15px auto;
    padding: 40px 20px;
    background: $neutrals8;
    border-radius: 10px;

    @include dark {
      background: $neutrals1;
      box-shadow: inset 0 0 0 1px $neutrals2;
    }
  }

  &__logo {
    margin: 0 auto;
    width: 170px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__chain {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    .chain_icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $neutrals6;
      border-radius: 100px;
      padding: 5px;
      gap: 5px;

      @include dark {
        border: 1px solid $neutrals3;
      }
    }
  }

  &__origin {
    font-size: 0.875rem;
    line-height: 140%;
    font-style: normal;
    font-weight: normal;
    text-align: center;
    margin-top: 20px;
  }

  &__web2_action {
    margin: 20px 0;
    padding: 5px;
    border: 1px solid $neutrals6;
    border-radius: 4px;
    text-align: center;

    @include dark {
      border: 1px solid $neutrals3;
    }

    .dest_address {
      color: $primary3;
    }
  }

  &__amount {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    font-weight: 600;
  }

  &__message_title {
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    text-align: center;
    max-width: 85%;
    margin: 0 auto;
  }

  &__message_text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    text-align: center;
    max-width: 95%;
    margin: 10px auto;
  }

  &__table {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }

  &__row {
    display: flex;

    .row_title {
      margin-right: auto;
    }
  }

  &__actions {
    display: flex;
    flex-flow: row;
    justify-content: center;
    flex: 0 0 auto;
    gap: 15px;
  }

  &__action {
    font-size: 0.95rem;
    font-weight: 600;
    padding: 0.75rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    margin-top: 60px;
    cursor: pointer;

    &.rounded {
      border-radius: 100px;
      will-change: box-shadow;
      transition: box-shadow cubic-bezier(0.6, -0.28, 0.735, 0.045) 200ms;
    }

    &.primary {
      background-color: $primary1;
      color: $primary2;

      @include dark {
        background-color: $primary2;
        color: $primary1;
      }

      &:hover {
        background-color: $primary3;
        color: $primary2;
      }
    }

    &.secondary {
      background-color: transparent;
      border: 1px solid $primary1;
      color: $primary1;

      @include dark {
        background-color: transparent;
        border: 1px solid $primary2;
        color: $primary2;
      }

      &:hover {
        background-color: $primary3;
        color: $primary2;
      }
    }

    &:disabled {
      background: $neutrals6;
      pointer-events: none;

      @include dark {
        background: $neutrals2;
        color: $neutrals4;
      }
    }
  }
}

.seed-invoice {
  z-index: 9999 !important;
}
