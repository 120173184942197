.variants {
    text-align: center;

    &__center {
        max-width: 896px;
    }

    &__top {
        max-width: 736px;
        margin: 0 auto 80px;

        @include d {
            margin-bottom: 64px;
        }

        @include m {
            margin-bottom: 32px;
        }
    }

    &__title {
        margin-bottom: 16px;
    }

    &__info {
        color: $neutrals4;

        span {
            color: $neutrals2;

            @include dark {
                color: $neutrals8;
            }
        }
    }

    &__list {
        display: flex;
        margin: 0 -16px 32px;

        @include m {
            display: block;
            margin: 0 0 32px;
        }
    }

    &__item {
        flex: 0 0 calc(50% - 32px);
        width: calc(50% - 32px);
        margin: 0 16px;
        padding: 16px 16px 24px;
        border-radius: 16px;
        border: 1px solid $neutrals6;
        transition: box-shadow .2s;

        @include m {
            width: 100%;
            margin: 0;
        }

        @include dark {
            border-color: $neutrals3;
        }

        &:not(:last-child) {
            @include m {
                margin-bottom: 24px;
            }
        }

        &:hover {
            box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
        }
    }

    &__preview {
        margin-bottom: 24px;

        img {
            width: 100%;
            border-radius: 8px;
        }
    }

    &__note {
        @include caption-2;
        color: $neutrals4;
    }
}
