.control {
  padding: 24px 0;
  border-bottom: 1px solid $neutrals6;

  @include d {
    padding: 16px 0;
  }

  @include m {
    padding: 24px 0;
  }

  @include dark {
    border-color: $neutrals3;
  }

  &__center {
    display: flex;
    align-items: center;
  }

  &__button {
    margin-right: auto;

    .icon {
      width: 10px;
      height: 10px;
    }
  }
}

/////
.control2 {
  .control2Btn {
    @include t {
      display: none;
    }
  }

  .control2Section {
    @include t {
      display: none !important;
    }
  }

  &__top {
    @include t {
      display: flex;
      justify-content: space-between;
      margin: auto;
      justify-content: flex-start;
    }
  }
  /*    &__Auction {
        @include m {
            display: none;
        }
    }
*/
  /*    .item__sections {
        &.flex {
            @include m {
                display: none;
            }
        }
    }*/

  &__flex {
    @include m {
      display: flex;
      align-items: center;

      .control2Btn:not(:first-child) {
        border-left: 1px solid $neutrals5;
      }
    }
  }

  &__Owner {
    @include t {
      margin: auto;
    }
  }

  &__tablet {
    &_top {
      @include t {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }

    &_child {
      display: flex;

      &:not(:first-child) {
        border-left: 1px solid $neutrals5;
      }

      @include t {
        flex: 0 0 33.33%;

        &:nth-child(1) {
          justify-content: flex-start;
          flex: 1 0 33.33%;
        }

        &:nth-child(2) {
          justify-content: center;
          flex-direction: column;

          .item__currency_price {
            margin-right: unset;
          }
        }

        &:nth-child(3) {
          justify-content: flex-end !important;
        }
      }

      @include m {
        flex: 0 0 50%;
        flex-direction: column;
        justify-content: center;

        .item__user {
          flex-direction: column;
        }

        .item__description {
          text-align: center;
        }

        .item__chain {
          margin-right: unset;
          width: 48px;
          height: 48px;
        }

        .item__value,
        .item__position,
        .item__avatar {
          margin-right: unset;
          text-align: center;
        }

        &:nth-child(1) {
        }

        &:nth-child(3) {
        }
      }
    }
  }

  &__video {
    justify-content: left;
  }
  /*    .item__sections {
        @include m {
            display: flex;
        }
    }*/

  .is-musuem {
    @include t {
      display: none !important;
    }
  }

  &.active {
    .control2Btn {
      -webkit-animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
      animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;

      @include t {
        display: unset;
        flex: 1 0 49%;
      }

      &.mobile {
        @include m {
          display: unset !important;
        }
      }

      &.flex-cell {
        @include m {
          flex: 0 0 41%;
          padding-left: 0;

          .item__control2__title,
          .item__price {
            text-align: start;
            margin: 0;
          }

          &.has-divider {
            border-right: 2px solid $neutrals5 !important;
          }
        }
      }
    }

    .flex {
      @include t {
        display: flex;
      }
    }

    .control2Section {
      -webkit-animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
      animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;

      @include t {
        display: flex !important;
        flex: 1 0 45%;
      }
    }

    .icon-chevron_up {
      transform: rotate(180deg);
    }

    .is-musuem {
      @include t {
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        width: 100%;
      }
    }
    /*        .control2__Auction {
            @include m {
                display: block;
                width: 100%;
            }
        }
*/
    .control2__Owner {
      @include t {
        margin: unset;
      }
    }

    .control2__top {
      @include t {
        width: 100%;
        margin: unset;
        justify-content: flex-start;
      }
    }

    .control2__tablet_top {
      width: 100%;
    }
  }

  &__mobile {
    &_btn {
      display: none;

      @include t {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $neutrals6;
        clip-path: polygon(10% 0%, 90% 0%, 100% 100%, 0% 100%);
        width: 50px;
        height: 20px;
        position: absolute;
        top: -16px;
        right: 24px;

        @include dark {
          background-color: $primary1;
        }
      }

      i {
        color: $primary1;

        @include dark {
          color: $primary2;
        }
      }
    }

    &_btns {
      justify-content: center;
    }
  }
}

/**
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}
@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation swing-out-top-bck
 * ----------------------------------------
 */
@-webkit-keyframes swing-out-top-bck {
  0% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
}
@keyframes swing-out-top-bck {
  0% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
}
