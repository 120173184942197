﻿.featured {
  &__center {
    display: flex;
    // padding-top: 70px;
    // padding-bottom: 70px;

    @include u {
      display: block;
    }
  }

  &__land {
    height: 550px;
    margin-bottom: 30px;

    .featured__card {
      flex: 0 0 100%;
      max-width: 100%;
      flex-grow: 1;
    }

    .featured__preview {
      height: 550px;
    }

    @include t {
      height: auto;

      .featured__preview {
        height: auto;
      }
    }
  }

  &__portrait {
    display: flex;
    height: 700px;
    gap: 30px;

    .featured__card {
      flex: 0 0 48%;
      /*max-width: 50%;*/
      flex-grow: 1;

      @include t {
        flex: 0 0 100%;
      }
    }

    .featured__preview {
      height: 700px;
    }

    @include t {
      /*display: block;*/
      flex-wrap: wrap;
      /*.featured__card {
                &:nth-child(2) {
                    margin-top: 30px;
                }
            }*/
    }

    @include t {
      height: auto;

      .featured__preview {
        height: auto;
      }
    }
  }

  &__preview {
    position: relative;
    border-radius: 0px;
    overflow: hidden;
    transition: transform 1s;

    & > img {
      width: 100%;
      height: 100%;
      transition: transform 1s;
      object-fit: cover;
    }

    &:hover {
      .featured__control {
        visibility: visible;
        opacity: 1;
      }

      .card2__creator {
        /*padding-right: 10px;*/
        width: auto;

        .creator__name {
          display: -webkit-box;
        }

        &.multi_owner {
          display: none;
        }

        &_list {
          visibility: visible;
          opacity: 1;
        }
      }

      & > img {
        transform: scale(1.1);
      }
    }
  }

  &__control {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($neutrals2, 0.2);
    visibility: hidden;
    border-radius: 0px;
    opacity: 0;
    transition: all 0.3s;
    padding: 25px;
    display: flex;
    align-items: flex-end;

    .card2__creator {
      top: 35px;
      left: 25px;

      &_list {
        visibility: hidden;
        opacity: 0;
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        position: absolute;
        top: 35px;
        left: 25px;
        transition: all 0.2s ease;

        .card2__creator {
          position: unset;
        }
      }
    }
  }

  &__like {
    display: flex;
    gap: 8px;
    align-items: center;
    background: $primary2;
    border-radius: 50px;
    padding-right: 7px;

    @include dark {
      background: $neutrals2;
    }

    .like_count {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $neutrals2;

      @include dark {
        color: $neutrals7;
      }
    }
  }

  &__btns {
    position: absolute;
    top: 35px;
    right: 25px;
    display: flex;
    gap: 8px;

    .btn__favorite {
      width: 32px;
      height: 32px;
      background: $neutrals8;
      /*box-shadow: 0px 8px 16px rgba(15, 15, 15, 0.2);*/
      border-radius: 50%;
      /*----*/
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .icon {
        width: 20px;
        height: 20px;
        fill: $neutrals4;
        transition: all 0.2s;
      }

      &:before {
        content: "";
        /*content: "";
                position: absolute;
                top: 27%;
                left: 10%;*/
        /* transform: translate(-50%,-50%); */
        width: 18px;
        height: 18px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='18' fill='none' viewBox='0 0 22 18'%3E%3Cpath d='M11 1.81A6.48 6.48 0 0 0 6.5 0 6.5 6.5 0 0 0 0 6.5c0 6.368 6.97 9.885 9.814 11.055.766.315 1.607.315 2.372 0C15.03 16.385 22 12.868 22 6.5A6.5 6.5 0 0 0 15.5 0 6.48 6.48 0 0 0 11 1.81z' fill='%23ef466f'/%3E%3C/svg%3E")
          no-repeat 50% 50% / 100% auto;
        opacity: 0;
        transition: opacity 0.2s;
        display: none;
        filter: brightness(0) saturate(100%) invert(50%) sepia(11%)
          saturate(1721%) hue-rotate(7deg) brightness(92%) contrast(88%);
      }

      &:hover {
        .icon {
          fill: $primary3;
        }
      }

      &.active {
        .icon {
          opacity: 0;
          display: none;
        }

        &:before {
          opacity: 1;
          display: block;
        }
      }

      @include dark {
        background: $neutrals2;

        &:hover {
          .icon {
            fill: $primary3;
          }
        }
      }
    }

    .btn__share {
      width: 32px;
      height: 32px;
      background: $neutrals8;
      box-shadow: 0px 8px 16px rgba(15, 15, 15, 0.2);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        .current-color {
          color: $primary3;
        }
      }

      .current-color {
        color: $neutrals4;
      }

      &:hover {
        .current-color {
          fill: $neutrals2;
        }
      }

      @include dark {
        background: $neutrals2;
        img {
          @include filter-neutrals4;
        }
        &:hover {
          .current-color {
            fill: $neutrals8;
          }
        }
      }
    }
  }

  &__creator {
    position: absolute;
    top: 35px;
    left: 25px;
    padding: 4px 10px 4px 4px;
    border-radius: 50px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(80px);
    display: flex;
    align-items: center;
    max-width: 90%;
    height: 40px;

    .creator__name {
      color: #fff;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1; /* number of lines to show */
      line-clamp: 1;
      -webkit-box-orient: vertical;
      margin-right: 25px;
    }
  }

  &__users {
    position: relative;
    flex-shrink: 0;
    width: 35px;
    height: 35px;
    border: 2px solid #fff;
    margin-right: 5px;
    border-radius: 50px;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50px;
    }

    &:not(:first-child) {
      margin-left: -14px;
    }
  }

  &__info {
    /*position: absolute;
        bottom: 20px;
        left: 26px;*/
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(80px);
    border-radius: 4px;
    padding: 16px;
    transition: transform 0.3s ease-in;

    &:hover {
      transform: scale(1.03);
      background: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(40px);
    }

    .verified__badge {
      text-align: center;
      margin-top: -30px;
    }

    .item__name {
      color: $neutrals8;
      font-weight: 500;
      font-family: 16px;
    }

    .card__info {
      display: flex;
      margin-top: 10px;

      .item__list {
        flex: 0 0 50%;
        color: $neutrals7;
        font-size: 14px;
        text-align: start;
      }

      .item__price {
        flex: 0 0 50%;
        color: $neutrals8;
        font-size: 14px;
        text-align: end;
      }
    }
  }

  &__stroke {
    box-shadow: inset 0 0 0 2px $neutrals8;
    color: $neutrals8;
    padding: 4px 8px;
    border-radius: 4px;
  }
}
