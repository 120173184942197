.details {
  // &__center {
  //   // max-width: 896px;
  // }

  &__text-verification {
    padding: 0.5rem 1.4rem !important;
  }

  &__toplightimage {
    @include dark {
      display: none;
    }
  }

  &__topdarkimage {
    display: none;

    @include dark {
      display: block;
    }
  }

  &__top {
    margin-bottom: 32px;

    .play-tutorial {
      padding-top: 0px;
      margin: 0;
      object-fit: contain;
    }

    img {
      padding-top: 37px;
      margin: 0 0 32px 0;
    }

    @include m {
      margin-bottom: 32px;
      padding-bottom: 16px;
      border-bottom: 1px solid $neutrals6;

      @include dark {
        border-color: $neutrals3;
      }
    }
  }

  .reject_description {
    margin: 10px 0 20px 0;

    .reject_detail {
      margin-left: 10px;
    }
  }

  &__register {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__topinfo {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 32px;
  }

  &__topDescription {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  &__suggestions {
    display: flex;
    flex-wrap: wrap;

    @include m {
      flex-wrap: nowrap;
      overflow: auto;
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        display: none;
      }

      &:before,
      &:after {
        content: "";
        flex-shrink: 0;
        width: 32px;
        height: 1px;
      }
    }
    /* width */
    &::-webkit-scrollbar {
      height: 7px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  &__suggestion {
    display: inline-block;
    padding: 6px;
    background: #8e793e;
    border: 1px solid #8e793e;
    margin: 5px;
    border-radius: 52px;
    cursor: pointer;
    font-size: 13px;
    color: #fff;
  }

  .doso {
    display: none;
  }

  .active {
    display: block;
  }

  &__card {
    border: 1px solid $neutrals6;
    border-radius: 4px;
    padding: 24px;
    height: 165px;
    background: $primary2;

    @include m {
      height: auto;
    }

    img {
      margin-bottom: 10px;
    }

    &:hover {
      box-shadow: 0px 8px 16px -8px rgba(15, 15, 15, 0.2);
      cursor: pointer;
    }

    @include dark {
      background: $primary1;
      border: 1px solid $neutrals3;
    }
  }

  &__usercard {
    margin-bottom: 32px;
    padding: 32px 32px 40px 32px;
    box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    background: $white;

    @include dark {
      background: $black;
    }
  }

  &__checkbox {
    display: flex;
    margin: 25px 0;

    input {
      width: 24px;
      height: 24px;
    }

    .ml-1 {
      // margin: auto 0;
      font-weight: bold;
    }

    a {
      // margin: auto 0 auto 5px;
      color: $primary3 !important;
    }
  }

  &__submit {
    padding: 24px 0 10px 0;

    a {
      margin-left: 20px;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      color: $neutrals4;
    }
  }

  &__fileuplod {
    margin-bottom: 20px;

    .upload__note {
      min-height: 40px;
    }
  }

  .excellent_title {
    margin-bottom: 5px;
    font-size: 40px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
  }

  &__title {
    margin-bottom: 16px;

    &.brown-title {
      color: $primary3;
    }
  }

  &__info {
    color: $neutrals4;

    strong {
      font-weight: 500;
      color: $neutrals2;

      @include dark {
        color: $neutrals8;
      }
    }
  }

  &__row {
    display: flex;
    margin: 0 -16px;

    @include d {
      display: block;
      margin: 0;
    }
  }

  &__col {
    flex: 0 0 calc(50% - 32px);
    width: calc(50% - 32px);
    margin: 0 16px;

    @include d {
      width: 100%;
      margin: 0;
    }

    &:not(:last-child) {
      @include d {
        margin-bottom: 64px;
      }

      @include m {
        margin-bottom: 32px;
        padding-bottom: 32px;
        border-bottom: 1px solid $neutrals6;

        @include dark {
          border-color: $neutrals3;
        }
      }
    }
  }

  &__user {
    display: flex;

    @include d {
      max-width: 416px;
    }
  }

  &__avatar {
    flex-shrink: 0;
    width: 150px;
    height: 150px;
    margin-right: 32px;

    @include m {
      width: 73px;
      height: 73px;
      margin-right: 16px;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;

      @include m {
        width: 73px !important;
        height: 73px !important;
      }
    }
  }

  &__wrap {
    flex-grow: 1;
    margin: auto 0;
  }

  &__stage {
    margin-bottom: 8px;
    @include body-bold-2;
  }

  &__text {
    margin-bottom: 16px;
    @include caption-2;
    color: $neutrals4;
  }

  &__verify-text {
    margin-bottom: 16px;
    color: $neutrals4;
  }

  &__file {
    cursor: pointer;
    display: flex;
    position: relative;
    overflow: hidden;
    transition: 0.2s;
    width: 130px;

    &:hover {
      cursor: pointer;

      .button-stroke {
        box-shadow: 0 0 0 2px $primary3 inset;
      }
    }

    .upload-profile {
      width: 150px;
      height: 150px;
      border-radius: 200px;
      color: #fff;
      font-weight: 600;
      background-color: #00000060;
      cursor: pointer;
    }
  }

  &__input {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 140px;
    opacity: 0;
    width: auto;
    height: 42px;
  }

  &__feature-list {
    list-style-image: url("../../images/camera-icon.svg");
    list-style-position: inside;
    margin-bottom: 20px;

    li {
      color: $neutrals4;
    }
  }

  &__list {
    margin-bottom: 40px;

    @include d {
      display: flex;
      margin: 0 -20px 64px;
    }

    @include m {
      display: block;
      margin: 0 0 32px;
    }
  }

  &__item {
    @include d {
      flex: 0 0 calc(50% - 40px);
      width: calc(50% - 40px);
      margin: 0 20px;
    }

    @include m {
      width: 100%;
      margin: 0;
    }

    & > .details__button {
      margin-top: 32px;

      .icon {
        width: 16px;
        height: 16px;
      }
    }

    &:not(:last-child) {
      margin-bottom: 40px;

      @include d {
        margin-bottom: 0;
      }

      @include m {
        margin-bottom: 32px;
      }
    }
  }

  &__category {
    margin-bottom: 32px;
    @include body-bold-2;
  }

  &__fieldset {
    & > .field {
      &:not(:last-child) {
        margin-bottom: 32px;
      }
    }
  }

  &__box {
    position: relative;

    .details__button {
      position: absolute;
      right: 12px;
      bottom: 8px;
      height: 32px;
      padding: 0 10px;
    }
  }

  &__note {
    color: $neutrals4;
  }

  &__verify {
    color: $primary3;
  }

  &__rejected {
    filter: brightness(0) saturate(100%) invert(37%) sepia(34%) saturate(5503%)
      hue-rotate(326deg) brightness(108%) contrast(87%);
  }

  &__pending {
    filter: brightness(0) saturate(100%) invert(51%) sepia(13%) saturate(463%)
      hue-rotate(185deg) brightness(93%) contrast(86%);
  }

  &__btns {
    display: flex;
    margin-top: 40px;
    padding-top: 40px;
    border-top: 1px solid $neutrals6;

    @include m {
      display: block;
      text-align: center;
    }

    @include dark {
      border-color: $neutrals3;
    }

    .details__button {
      @include m {
        width: 100%;
      }

      &:not(:last-child) {
        margin-right: 32px;

        @include m {
          margin: 0 0 32px;
        }
      }
    }

    .end {
      text-align: end;
    }
  }

  &__clear {
    display: inline-flex;
    align-items: center;
    @include button-1;
    color: $neutrals4;
    transition: color 0.2s;

    .icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      fill: $neutrals4;
      transition: fill 0.2s;
    }

    &:hover {
      color: $primary3;

      .icon {
        fill: $primary3;
      }
    }
  }

  &__box {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    padding: 1rem !important;
    @include t {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @include m {
      position: relative;
      width: 100%;
      flex: unset;
      max-width: unset;
      padding: unset;
    }

    &_uploadFiled {
      flex: 0 0 50%;
      max-width: 50%;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      @include m {
        flex: unset;
        max-width: unset;
      }
    }

    &_note {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      @include m {
        flex: unset;
        max-width: unset;
      }
    }
  }
}

.details2 {
  &__popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    background: rgba($primary2, 0.5);
    backdrop-filter: blur(8px);
    z-index: 20;
    gap: 17px;
    top: 0;
    width: 100%;
    left: 0;
    height: 100vh;
    overflow: hidden;

    @include dark {
      background: rgba($primary1, 0.5);
    }

    &_row {
      width: 33.33%;

      &:not(:first-child) {
        border-left: 1px solid $neutrals5;
        padding-left: 16px;
      }
    }

    &_popup {
      position: relative;
      max-width: 70px;
      max-height: 85%;
      margin: 15px auto;
      padding: 32px;
      background: $neutrals8;
      border-radius: 0px;
      overflow-y: auto;
      overflow-x: clip;

      &.landscape {
        max-width: 1120px;

        @include m {
          max-width: 100%;
        }
      }

      &.portrait {
        max-width: 880px;

        @include m {
          max-width: 100%;
        }
      }
      /* width */
      &::-webkit-scrollbar {
        height: 4px;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #2121219b;
        border-radius: 10px;
      }
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      @include dark {
        background: $neutrals1;
        box-shadow: inset 0 0 0 1px $neutrals2;
      }

      @include t {
        max-width: 77%;
        max-height: 50%;
      }

      @include m {
        max-width: 100%;
        max-height: 50%;
      }
    }

    &_section {
      width: 100%;
      height: 100%;
      //display: flex;
      justify-content: center;
      align-items: center;
    }

    &_title {
      @include dm-sans;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      color: $neutrals2;
      margin-bottom: 24px;

      @include dark {
        color: $neutrals7;
      }
    }

    &_body {
      display: inline-flex;
      padding: 0px;

      @include m {
        display: block;
      }
    }

    &_left {
      &.landscape {
        flex: 1 0 50%;
        padding-right: 30px;
        height: 100%;
      }

      &.portrait {
        //flex: 1 0 50%;
        padding-right: 30px;
        height: 406px;
        object-fit: cover;
      }

      @include m {
        padding-right: 0px;
        padding-bottom: 16px;
      }
    }

    &_photo {
      width: 100%;
      height: 100%;
    }

    &_name {
      @include main-font;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: $neutrals2;

      @include dark {
        color: $neutrals7;
      }
    }

    &_box {
      box-sizing: border-box;
      /* Auto layout */

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px;
      gap: 16px;
      border: 1px solid $neutrals6;
    }

    &_table {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 16px;
      width: 448px;
      height: 44px;
      width: 100%;
    }

    &_right {
      flex: 0 0 50%;
      display: flex;
      flex-direction: column;
      padding: 0px;
      gap: 24px;
    }

    &_ckeckBox {
      display: flex;
      @include main-font;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $neutrals1;
      gap: 12px;

      @include dark {
        color: $neutrals8;
      }
    }

    &_titleInfo {
      @include main-font;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: $neutrals4;

      @include dark {
        color: $neutrals5;
      }
    }

    &_info {
      @include main-font;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $neutrals2;

      @include dark {
        color: $neutrals7;
      }
    }

    &_hr {
      width: 100%;
      border: 1px dashed $neutrals6;

      @include dark {
        color: $neutrals3;
      }
    }

    &_detail {
      @include main-font;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: $neutrals4;

      @include dark {
        color: $neutrals5;
      }
    }

    &_closeBox {
      position: absolute;
      top: 32px;
      right: 32px;
    }

    &_close {
      width: 40px;
      height: 40px;
      fill: $neutrals6;
      cursor: pointer;
      border: 2px solid $neutrals6;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: border-color 0.2s;

      @include dark {
        border: 2px solid $neutrals3;

        img {
          @include filter-neutrals6;
        }
      }

      &:hover {
        border-color: $primary1;

        @include dark {
          border-color: $primary2;
        }
      }
    }
  }
}

.orderconfirmation {
  .landscape__main_wrap {
    padding: unset;
    border: unset;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include dark {
      border: unset;
    }
  }

  .landscape__main_right {
    padding: 0 16px 0px 16px;
    border: 1px solid $neutrals6;

    @include dark {
      border: 1px solid $neutrals3;
    }
  }

  &__name {
    @include hairline-1;
    color: $neutrals2;
    text-transform: unset;

    @include dark {
      color: $neutrals7;
    }
  }

  .item__bg {
    margin-right: unset;
  }

  .landscape__main_details {
    margin: -100px auto 0px;
    padding: 30px;
    width: 90%;

    @include t {
      margin: unset;
      padding: unset;
    }

    @include m {
      width: 100%;
    }
  }

  .main__subtitle {
    @include hairline-3;
    font-size: 40px !important;
    color: $neutrals2;
    text-align: start;

    @include dark {
      color: $neutrals7;
    }

    @include m {
      font-size: 21px !important;
      line-height: unset;
    }
  }

  .main__auction {
    width: 100%;
  }

  &__purchasenumber {
    display: flex;
    justify-content: center;
    margin-top: 8px;
    @include body-bold-1;
    color: $neutrals1;

    @include dark {
      color: $neutrals8;
    }
  }

  .item__user {
    padding-bottom: unset;
  }
}

.landscape__main_wrap-mobile {
  @include m {
    border: 0px solid $neutrals6 !important;
    margin-bottom: 0px !important;
    background: none !important;
  }
}

.auth-twitter {
  position: absolute;
  top: -15%;
  right: -8px;
  bottom: 0;
  margin-left: 32px;
  padding: 24px !important;
  font-size: 16px !important;
  margin: 8px;
}

.nftFlip {
  &_cardContainer {
    perspective: 1000px;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
  }

  &_card {
    width: 100%;
    position: relative;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  &_cardAbsolute {
    position: absolute !important;
  }

  &_front,
  &_back {
    width: 100%;
    padding: 20px;
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }

  &_back {
    transform: rotateY(180deg);
  }

  &_back_landscape {
    transform: rotateX(180deg);
  }

  &_flip {
    transform: rotateY(180deg);
  }

  &_flip_landscape {
    transform: rotateX(180deg);
  }

  &_Landscape {
    margin: 22px 0px;
    display: flex;
    background: $neutrals7;
    padding: 35px;
    align-items: start;
    gap: 30px;
    min-height: 736px;

    @include dark {
      background: #171717;
    }

    @include m {
      display: grid;
      padding: 15px;
      min-height: 590px;
    }

    &_box {
      width: 100%;
      flex: 0 48%;
      display: grid;
      gap: 30px;

      @include m {
        gap: 10px;
      }
    }

    &_title {
      @include dm-sans;
      font-size: 22px;
      font-weight: 700;
      line-height: 28.64px;
      letter-spacing: -0.02em;
      text-align: left;
      color: $primary1;

      @include dark {
        color: $primary2;
      }

      @include m {
        font-size: 20px;
        line-height: 26.04px;
      }
    }

    &_hr {
      width: 100%;
      border: 0px;
      height: 1px;
      background: #777e90;
    }

    &_flex {
      display: flex;
      justify-content: space-between;
      align-items: start;
      gap: 5px;
    }

    &_flex2 {
      display: flex;
      justify-content: start;
      align-items: start;
      gap: 10px;

      img {
        @include filter-neutrals4;
      }
    }

    &_text {
      @include poppins;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      text-align: left;
      color: $neutrals2;
      margin-bottom: 10px;

      @include dark {
        color: $coconut;
      }
    }

    &_details {
      @include poppins;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      color: #777e90;
    }
  }

  &_portrait {
    margin: 22px 0px;
    background: $neutrals7;
    min-height: 770px;
    display: flex;
    background: #f4f5f6;
    padding: 35px;
    grid-gap: 10px;
    gap: 40px;
    flex-direction: column;
    align-items: start;
    justify-content: start;

    @include dark {
      background: #171717;
    }

    @include m {
      padding: 15px;
      min-height: 590px;
    }

    &_box {
      width: 100%;
      display: grid;
      gap: 30px;

      @include m {
        gap: 10px;
      }
    }
  }

  &_button {
    position: absolute;
    right: 11px;
    bottom: 20px;
  }
}
