﻿.tooltip1 {
  position: relative;
  // display: inline-block;
  cursor: pointer;

  &.disabled {
    cursor: default;
  }
}

.tooltipText1 {
  visibility: hidden;
  //width: 250px;
  max-width: 250px;
  min-width: 170px;
  @include main-font;
  font-size: 12px;
  text-align: center;
  background-color: $neutrals1;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 2;
  bottom: 125%;
  left: -76px;
  opacity: 0;
  transition: opacity 0.3s;
  //padding-right: 100%;
  color: $neutrals8;
  transform: translateX(-50%);
  left: 50%;

  @include dark {
    color: $neutrals1;
    background-color: $neutrals8;
  }

  &:before {
    content: "";
    position: absolute;
    left: calc(47%);
    background: url("../../images/icons/icon-tooltip-before.svg") no-repeat 100%
      50%/100% auto;
    width: 13px;
    height: 13px;
    // margin-left: -5px;
    bottom: -9px;

    @include dark {
      @include filter-primary2;
    }
  }

  &.right {
    &:before {
      left: calc(70%);
    }
    // left: 75%;
    left: -50px;
  }

  &.left {
    &:before {
      left: calc(17%);
    }
    // left: 75%;
    left: 95px;
  }

  &.bottom {
    &:before {
      bottom: 90%;
      transform: rotate(180deg);
    }

    bottom: -53px;
  }
}

.tooltip1:hover .tooltipText1 {
  visibility: visible;
  opacity: 1;
}
