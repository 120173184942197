.countdown-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.circle {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: $primary3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.circle-fill {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.inside-circle {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    z-index: 1;

    @include dark {
        background-color: $black;
    }
}
