﻿@mixin size($width, $height) {
  width: $width;
  height: $height;
}

@mixin clear-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

@mixin center {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  align-content: center;

  @include m {
    flex-wrap: wrap;
    margin: 0 3px;
  }
}
.pagination {
  /*@include size(400px, 60px);*/
  border-radius: 0px;
  overflow: hidden;

  &,
  &__list {
    @include center;
  }

  &__list {
    @include clear-list;
    /*@include size(350, 50px);*/
    border-radius: 0px;
    margin: 20px 9px;
    overflow: hidden;

    @include m {
      //   overflow-x: scroll;
      overflow: auto;
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__item {
    @include size(50px, 50px);
    @include center;
    font-size: 1.1rem;
  }

  &__item > button,
  &__button {
    border: none;
    outline: none;
    stroke: none;
    box-shadow: none;
    cursor: pointer;
    border-radius: 9px;
    background: $neutrals6;

    @include dark {
      background: $neutrals3;
    }

    &:hover {
      background: color(secondary);
      color: color(primary);
    }
  }

  &__button2 {
    width: 48px;
    height: 48px;
    min-width: 30px;
    min-height: 30px;
    padding: 0;
    border-radius: 0px;
    box-shadow: 0 0 0 2px $neutrals6 inset !important;
    background: transparent;
    transition: all 0.2s;
    margin: 4px;

    .icon {
      fill: $neutrals4;
      transition: fill 0.2s;
      margin: 0 !important;
    }

    &:hover {
      //   background: $primary2 !important;
      box-shadow: 0 0 0 2px $primary3 inset !important;
      color: $primary3;

      .icon {
        fill: $primary3 !important;
      }
    }

    &.active {
      background: $primary3;
      color: $primary2;

      @include dark {
        background: $primary3;
        color: $primary2;
      }

      .icon {
        fill: $primary1 !important;
      }
    }

    @include dark {
      box-shadow: 0 0 0 2px $neutrals3 inset !important;
      background: transparent;
      color: $primary2;

      &:hover {
        background: transparent !important;
        box-shadow: 0 0 0 2px $primary3 inset !important;
        color: $primary3;
      }

      &.active {
        .icon {
          fill: $primary2;
        }

        // background: transparent !important;
      }
    }
  }

  &__prev {
    i {
      font-weight: 600;
    }

    transform: rotate(270deg);
  }

  &__next {
    i {
      font-weight: 600;
    }

    transform: rotate(90deg);
  }

  &__item > button {
    @include size(45px, 45px);

    &[data-level="target"] {
      background: $primary3;
      color: $primary2;
    }
  }

  &--move-prev {
    animation: pagination-move-prev 0.5s ease both;
  }

  &--move-next {
    animation: pagination-move-next 0.5s ease both;
  }

  &--move-top {
    animation: pagination-move-top 0.5s ease both;
  }

  &__button {
    @include size(35px, 35px);
  }
}

@keyframes pagination-move-prev {
  from,
  0% {
    transform: translateX(25px);
  }

  50% {
    transform: translateX(-5px);
  }

  to,
  100% {
    transform: translateX(0px);
  }
}

@keyframes pagination-move-next {
  from,
  0% {
    transform: translateX(-25px);
  }

  50% {
    transform: translateX(5px);
  }

  to,
  100% {
    transform: translateX(0px);
  }
}

@keyframes pagination-move-top {
  from,
  0% {
    transform: translateY(-25px);
  }

  50% {
    transform: translateY(10px);
  }

  to,
  100% {
    transform: translateY(0px);
  }
}

.pagination-dashboard {
  &__padding {
    padding: 30px 0px;

    @include m {
      padding: 10px 0pc;
    }
  }
  position: relative;
  display: flex;
  justify-content: center;
  gap: 20px;

  &__prev {
    padding: 8px;
    cursor: pointer;
    i {
      color: $neutrals5;
      @include poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
    }
    color: $neutrals5;
    @include poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    transform: rotate(270deg);
    color: $neutrals5;
  }

  &__next {
    padding: 8px;
    cursor: pointer;
    i {
      color: $neutrals5;
      @include poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
    }

    transform: rotate(90deg);
    color: $neutrals5;
    @include poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }

  &__number {
    padding: 8px 16px;
    color: $neutrals5;
    @include poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;

    @include m {
      padding: 8px 4px;
    }

    &__active {
      border-radius: 4px;
      background: $neutrals1;
      color: $white;

      @include dark {
        background: $white;
        color: $black;
      }

      @include m {
        padding: 8px;
      }
    }
  }
}
