.checkbox {
  display: inline-block;
  position: relative;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;

    &:checked + .checkbox__inner .checkbox__tick {
      background: $primary1;
      border-color: $primary1;

      @include dark {
        color: white;
        background-color: $primary2;
        border: 1px solid $primary2;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
      }

      &:before {
        opacity: 1;
      }
    }

    &:checked + .checkbox__inner .checkbox__tick2 {
      background: $black;
      border-color: $black;

      @include dark {
        background: $white;
        border-color: $white;
      }

      &:before {
        opacity: 1;
      }
    }
  }

  &__inner {
    display: flex;

    &:hover {
      .checkbox__tick,
      .checkbox__tick2 {
        border-color: $black;

        @include dark {
          border-color: $white;
        }
      }
    }
  }

  &__tick {
    position: relative;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-right: 12px;
    border-radius: 4px;
    border: 2px solid $neutrals6;
    transition: all 0.2s;

    &:checked {
      color: white;
      background-color: $primary2;
      border: 1px solid $primary2;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-border-radius: 4px;
    }

    @include dark {
      background: $neutrals2;
      border-color: $neutrals3;
    }

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 14px;
      height: 10px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='10' fill='none' viewBox='0 0 14 10'%3E%3Cpath fill-rule='evenodd' d='M13.707.293a1 1 0 0 1 0 1.414l-8 8a1 1 0 0 1-1.414 0l-4-4a1 1 0 1 1 1.414-1.414L5 7.586 12.293.293a1 1 0 0 1 1.414 0z' fill='%23fcfcfd'/%3E%3C/svg%3E")
        no-repeat 50% 50% / 100% auto;
      opacity: 0;
      transition: opacity 0.2s;

      @include dark {
        filter: brightness(0) saturate(100%) invert(0%) sepia(100%) saturate(0%)
          hue-rotate(48deg) brightness(109%) contrast(104%);
      }
    }
  }

  &__tick2 {
    position: relative;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-right: 12px;
    border-radius: 50px;
    border: 2px solid $neutrals6;
    transition: all 0.2s;

    @include dark {
      background: $neutrals2;
      border-color: $neutrals3;
    }

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 14px;
      height: 10px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='10' fill='none' viewBox='0 0 14 10'%3E%3Cpath fill-rule='evenodd' d='M13.707.293a1 1 0 0 1 0 1.414l-8 8a1 1 0 0 1-1.414 0l-4-4a1 1 0 1 1 1.414-1.414L5 7.586 12.293.293a1 1 0 0 1 1.414 0z' fill='%23fcfcfd'/%3E%3C/svg%3E")
        no-repeat 50% 50% / 100% auto;
      opacity: 0;
      transition: opacity 0.2s;

      @include dark {
        filter: brightness(0) saturate(100%) invert(0%) sepia(100%) saturate(0%)
          hue-rotate(48deg) brightness(109%) contrast(104%);
      }
    }
  }

  &__text {
    line-height: (24/14);
    font-weight: 500;
    color: $neutrals2;

    @include dark {
      color: $neutrals8;
    }
  }
}

input[type="checkbox" i] {
  background-color: black;
  cursor: default;
  appearance: auto;
  box-sizing: border-box;
  margin: 3px 3px 3px 4px;
  padding: initial;
  border: initial;

  &:checked {
    background-color: black;
  }
}
