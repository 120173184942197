//new style m.akbari
.subscription {
  position: relative;
  margin-top: 42px;

  @include m {
    padding-left: 20px;
  }

  &__input {
    width: 100%;
    height: 48px;
    padding: 0 48px 0 14px;
    border-radius: 0px;
    background: none;
    border: 2px solid $neutrals6;
    @include main-font;
    @include caption-1;
    color: $neutrals4;
    transition: border-color 0.2s;

    &:focus {
      border-color: $neutrals4;
    }

    @include placeholder {
      color: $neutrals4;
    }
  }

  &__btn {
    position: absolute;
    top: 8px;
    right: 8px;
    bottom: 8px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: $black;
    transition: background 0.2s;

    .icon {
      width: 14px;
      height: 14px;
      fill: $neutrals8;
      /* @include dark {
                fill: $black;
            }*/
    }
  }

  &__button {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    gap: 12px;
    width: 73px;
    height: 32px;
    background: #8e793e;
    flex: none;
    order: 1;
    flex-grow: 0;
    color: #fcfcfd;
  }
}
