.hot {
  @include m {
    padding-bottom: 120px;
  }
  &__center {
    // padding-top: 70px;
    // padding-bottom: 70px;
    position: relative;
  }

  &__wrapper {
    position: relative;
  }

  &__Partnership {
    margin-right: 20px;

    @include r(400) {
      margin-right: 10px;
    }
  }

  &__title {
    display: flex !important;
    // justify-content: center;
    margin-bottom: 5px;
    font-size: 40px;
    align-items: center;

    @include m {
      //  margin-bottom: 48px;
      font-size: 24px;
    }
  }

  &__inner {
    margin: 0 -16px;

    @include m {
      margin: 0 -8px;
    }
  }

  .card {
    width: 100%;
    height: 100%;
  }

  &__slider {
    position: static;
    /*visibility: hidden;*/
    /*&.slick-initialized {
            visibility: visible;
        }*/
  }

  .slick-list {
    overflow: hidden;
    max-width: 100%;

    @include s {
      /*margin: 0 -215px 0 0;*/
    }
  }

  .slick-track {
    display: flex;
    /*        &:hover {
            transition: none !important;
        }

        &:blur {
            transition: transform 10000ms linear 0s;
        }*/
  }
  /*.listing{
        transition: 1000s;
        .slick-track{
            transform: translateX(-50000px);
        }
    }*/

  .slick-slide {
    display: flex;
    height: auto;
    margin: 0 16px;
    /*opacity: 0;
        transition: opacity .4s;*/

    @include m {
      margin: 0 8px;
    }

    &.slick-active {
      opacity: 1;
    }
  }

  .slick-arrow {
    top: 4px;

    &:hover {
      box-shadow: inset 0 0 0 2px $primary3;

      path {
        fill: $primary3;
      }
    }

    @include dark {
      box-shadow: inset 0 0 0 2px $neutrals3;

      &:hover {
        box-shadow: inset 0 0 0 2px $primary3;

        path {
          fill: $primary3;
        }
      }
    }
  }

  .slick-prev {
    right: 48px;
  }

  .slick-next {
    right: 0;
  }
}

.listings {
  &:hover {
    .hot__slider .slick-track {
      transition: none !important;
    }
  }

  &:blur {
    .hot__slider .slick-track {
      transition: transform 20000ms linear 0s;
    }
  }
}

.recently {
  position: relative;

  // &__center {
  //   // margin-bottom: 70px;
  //   // margin-top: 70px;
  // }

  &__wrapper {
    position: relative;
  }

  &__title {
    margin-bottom: 64px;
    font-size: 40px;

    @include m {
      margin-bottom: 48px;
      font-size: 24px;
    }
  }

  &__inner {
    margin: 0 -16px;

    @include m {
      margin: 0 -8px;
    }
  }

  .card {
    width: 100%;
    height: 100%;
  }

  &__slider {
    position: static;
    visibility: hidden;

    &.slick-initialized {
      visibility: visible;
    }
  }

  .slick-list {
    overflow: hidden;

    @include s {
      /*margin: 0 -215px 0 0;*/
    }
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    display: flex;
    height: auto;
    margin: 0 16px;
    /*opacity: 0;
        transition: opacity .4s;*/

    @include m {
      margin: 0 8px;
    }

    &.slick-active {
      opacity: 1;
    }
  }

  .slick-arrow {
    top: 4px;
    box-shadow: inset 0 0 0 2px $neutrals6;

    &:hover {
      box-shadow: inset 0 0 0 2px $primary3;

      path {
        fill: $primary3;
      }
    }

    @include dark {
      box-shadow: inset 0 0 0 2px $neutrals3;

      &:hover {
        box-shadow: inset 0 0 0 2px $primary3;

        path {
          fill: $primary3;
        }
      }
    }

    @include m {
      top: -6px;
    }
  }

  .slick-prev {
    right: 18.5%;

    @include w {
      right: 12.5%;
    }
    /*@include r(1370) {
            right: 166px;
        }*/

    @include t {
      right: 86px;
    }

    @include m {
      right: 73px;
    }
  }

  .slick-next {
    right: 16%;

    @include w {
      right: 9%;
    }
    /*@include r(1370) {
            right: 120px;
        }*/

    @include t {
      right: 40px;
    }

    @include m {
      right: 23px;
    }
  }
}
/*.new {
    &__wrapper {
        position: relative;
    }
}
*/
