.tw_farm {
    &__championsLeague {
        .swiper {
            position: unset;
            .swiper-pagination-bullets {
                width: unset;
                height: 30px;
                top: -53px;
                display: flex;
                left: 195%;

                @include r(1601) {
                    left: 160%;
                }

                @include x {
                    left: 155%;
                }

                @include r(1235) {
                    left: 143%;
                }

                @include d {
                    left: 137%;
                }

                @include r(1145) {
                    left: 130%;
                }

                @include r(1090) {
                    left: 115%;
                }

                @include t {
                    left: 100%;
                    top: -51px;
                }

                @include r(790) {
                    left: 90%;
                    top: -20px;
                }

                @include r(630) {
                    left: 80%;
                    top: -20px;
                }

                @include r(490) {
                    left: 70%;
                    top: -20px;
                }
                .swiper-pagination-bullet {
                    width: 13px;
                    height: 13px;
                    background-color: $neutrals3;

                    @include dark {
                        background-color: $neutrals6;
                    }
                }
                .swiper-pagination-bullet-active {
                    background: $primary1 !important;

                    @include dark {
                        background: $primary2 !important;
                    }
                }
            }
        }
    }
}

.communities {
    .swiper {
        padding: 40px 0 60px 0;
    }
}

.swiper-pagination-bullet-active {
    opacity: var(--swiper-pagination-bullet-opacity, 1);
    background: black !important;

    @include dark {
        background: white !important;
    }
}

.swiper-pagination-bullet {
    @include dark {
        background: var(--swiper-pagination-bullet-inactive-color, #cecece);
    }
}

//
.section3-video {
    border-radius: 6px;
    width: 100%;
    height: 60vh;

    @include w {
        height: 30vh;
    }

    @include m {
        width: 100%;
        height: 200px;
    }
}

.SearchMobileContainer {
    height: auto;
}

.TutorialHelp {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    margin-left: unset;
    justify-content: flex-end;

    @include t {
        display: flex;
        margin: 0 auto;
    }
}

.Tutorialsplay4-img {
    position: relative;
    width: 100%;

    @include r(1150) {
        height: 345px;
    }
    @include m {
        height: auto;
    }
}

.Tutorialsplay4 {
    padding-left: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 40px;
    height: 40px;
    left: calc(50% - 40px / 2 - 1.38px);
    top: calc(50% - 40px / 2 - 0.38px);
    background: linear-gradient(124.64deg, #8f7816 29.19%, #ffd630 118.18%);

    backdrop-filter: blur(4px);
    border-radius: 40px;

    img {
        @include filter-primary2;

        width: 15px !important;
        height: 15px !important;

        @include dark {
            @include filter-neutrals1;
        }
    }
}

.CustonTaskTitle {
    @include poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: $neutrals3;

    @include dark {
        color: $neutrals6;
    }

    @include m {
        font-size: 16px;
    }
}

.CustonTaskText {
    @include dm-sans;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: 0;
    text-align: left;
    margin: 0px 20px;

    @include t {
        font-size: 20px;
        line-height: 30px;
        padding-left: 10px;
    }
}

.TwFarmTitle {
    @include poppins;
    color: $neutrals2;
    font-size: 40px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: -0.01em;
    text-align: left;
    display: flex;
    align-items: center;
    margin-right: auto;

    @include dark {
        color: $coconut;
    }

    @include m {
        justify-content: center;
        text-align: center;
        margin-bottom: 30px;
        font-size: 26px;
        letter-spacing: -1%;
        margin-right: 0px;
    }
}

.profileMuseum {
    .profile2__tab {
        flex-direction: column;
    }
}

.MarketPlaceSlide {
    padding-bottom: 140px;
}
