.modal-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  // z-index: 19;
  z-index: 35;
  width: 100%;
  height: 100%;
  background-color: #20222aad;
  animation: fadeIn 0.3s ease;
  overflow: hidden auto;
  backdrop-filter: blur(9px);
}

.modal-container {
  text-align: center;
  position: absolute;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  left: 0;
  top: 0;
  box-sizing: border-box;
  padding: 20px;

  &::before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}

.modal-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: start;
  width: 100%;
}

.modal {
  background-color: white;
  padding: 40px;
  border: 2px solid rgba(59, 65, 81, 0.771);
  position: relative;
  width: 35%;
  animation: bounceIn 0.5s ease;
  @include dark {
    background: black;
  }

  &__row_tab {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;

    @include m {
      gap: 10px;
    }

    .popup__button {
      width: 50%;
    }
  }

  &__simple_row {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.modalmeta {
  background-color: white;
  padding: 40px;
  border: 2px solid rgba(59, 65, 81, 0.771);
  position: relative;
  width: 35%;
  @include dark {
    background: black;
  }
}

.close {
  margin: 20px;
  border: 2px solid rgba(59, 65, 81, 0.771);
  padding: 2px 10px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 35px;
  cursor: pointer;

  &:hover {
    border: 2px solid rgb(0, 0, 0);
    // transform: rotate(30deg);
    // -webkit-transform: rotate(30deg);
    // -moz-transform: rotate(30deg);
    // -ms-transform: rotate(30deg);
    // -o-transform: rotate(30deg);
    @include dark {
      border: 2px solid rgb(255, 255, 255);
    }
  }

  @include dark {
    color: rgb(255, 255, 255);
  }
}

.hide-modal {
  display: none;
}

.show-modal {
  display: block;
}

@keyframes bounceIn {
  from {
    transform: scale(0.4);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.text {
  @include dark {
    color: white;
  }

  &__meta {
    margin: 20px 0px 10px 0px;
  }

  &__meta_span {
    font-size: 15px;
  }
}

.buttonMetaMask {
  margin-top: 30px;
  :hover {
    border: 1px solid rgba(59, 65, 81, 0.771);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
}

.ovaerlay_body {
  overflow: hidden;
  // overflow: hidden auto;
}

.rate-button {
  display: flex;
  align-items: center;
  justify-content: center;
  filter: grayscale(1);

  @include poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: $primary1;

  margin: 16px 10px;
  // opacity: 0;
  cursor: pointer;

  @include dark {
    color: $primary2;
  }

  @include m {
    min-width: 50px;
    margin: 16px 0px;
  }

  &.active {
    filter: grayscale(0);
    margin: 0px 10px;
    cursor: pointer;

    border-radius: 8px;
    border: 1px 0px 0px 0px;
    min-width: 240px;
    height: 68px;
    padding: 16px 20px;
    border: 1px solid $neutrals6;

    @include dark {
      border: 1px solid $neutrals3;

      @include m {
        border: 0px;
      }
    }

    @include m {
      min-width: 50px;
      border: 0px;
      padding: 0px;
      margin: 0px;
    }
  }
}

.text-rate {
  @include poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: black;

  @include dark {
    color: white;
  }

  &__field {
    max-width: 522px;
  }

  &__field-textbox {
    justify-content: center;
    display: flex;
    margin-top: 5px;
  }
}

.box-sticker-rate {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  @include m {
    gap: 10px;
  }
}

.rate-submit {
  display: flex;
  justify-content: center;
  margin: 20px 0px;
}

.title-rate {
  @include poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #353945;
  margin-bottom: 20px;

  @include dark {
    color: #c4c7d2;
  }
}

.modal-rate {
  height: 600px;
  width: 700px;
}

.title-modal-rate {
  @include dm-sans;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.01em;
  text-align: center;
  margin: 20px 0px;
}

.rate-mobile {
  display: none;
  @include poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: black;
  margin-bottom: 20px;

  border-radius: 8px;
  border: 1px 0px 0px 0px;
  padding: 16px 20px 16px 20px;
  border: 1px solid $neutrals6;

  @include dark {
    color: white;
  }

  @include m {
    display: block;
  }
}

.rate-desktop {
  display: block;
  margin-left: 15px;

  @include m {
    display: none;
  }
}
