.catalog {
  &__top {
    display: flex;
    align-items: center;
    margin-bottom: 48px;
    padding-bottom: 32px;
    /*border-bottom: 1px solid $neutrals6;*/

    @include t {
      display: block;
      margin-bottom: 32px;
      padding-bottom: 0;
      border: none;
    }

    @include m {
      margin-bottom: 24px;
    }

    @include dark {
      border-color: $neutrals3;
    }
  }

  &__title {
    margin-right: auto;
    @include body-1;

    @include t {
      display: none;
    }
  }

  &__search {
    position: relative;
    flex-shrink: 0;
    width: 320px;
    margin-left: 30px;

    @include t {
      width: 100%;
      margin: 0;
    }
  }

  &__input {
    width: 100%;
    height: 48px;
    padding: 0 48px 0 14px;
    border-radius: 12px;
    background: none;
    border: 2px solid $neutrals6;
    @include main-font;
    @include caption-1;
    color: $neutrals2;
    transition: border-color 0.2s;

    @include dark {
      border-color: $neutrals3;
      color: $neutrals8;
    }

    @include placeholder {
      color: $neutrals4;
    }

    &:focus {
      border-color: $neutrals4;

      @include dark {
        border-color: $neutrals4;
      }
    }
  }

  &__item {
    display: none;
    min-height: 300px;
  }

  &__result {
    position: absolute;
    top: 8px;
    right: 8px;
    bottom: 8px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: $primary3;
    transition: background 0.2s;

    .icon {
      width: 16px;
      height: 16px;
      fill: $neutrals8;
    }

    &:hover {
      background: darken($primary3, 10);
    }
  }

  &__sorting {
    display: flex;
    align-items: center;
    margin-bottom: 48px;

    @include t {
      display: block;
    }

    @include m {
      margin-bottom: 32px;
    }

    .select {
      width: 256px;
      margin-right: auto;

      @include t {
        width: 100%;
        margin: 0 0 32px;
      }
    }
  }

  &__nav {
    display: flex;
    width: 100%;

    @include m {
      /*margin: 0 -32px;*/
      overflow: auto;
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        display: none;
      }
      /*&:before,
            &:after {
                content: "";
                flex-shrink: 0;
                width: 32px;
                height: 1px;
            }*/
    }
  }

  &__link,
  &__link_login {
    flex-shrink: 0;
    margin: 0 6px;
    padding: 0px 16px 4px 0px;
    /*padding: 6px 12px;*/
    /*border-radius: 14px;*/
    background: none;
    font-weight: 500;
    font-size: 18px;
    line-height: 16px;
    color: $neutrals4;
    transition: all 0.2s;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-bottom: 1px solid $neutrals6;

    @include dark {
      border-bottom: 1px solid $neutrals3;
    }

    @include m {
      margin: 0;
    }

    &:hover {
      color: $neutrals3;

      @include dark {
        color: $neutrals6;
      }
    }

    &.active {
      /*background: $neutrals3;
            color: $neutrals8;*/
      border-bottom: 1px solid $neutrals1;

      @include dark {
        border-bottom: 1px solid $neutrals8;
        /*background: $neutrals8;
                color: $neutrals2;*/
      }
    }

    &:not(:last-child) {
      @include m {
        margin-right: 12px;
      }
    }
  }

  &__link2 {
    flex-shrink: 0;
    margin: 0 6px;
    padding: 0px 16px 4px 0px;
    /*padding: 6px 12px;*/
    /*border-radius: 14px;*/
    background: none;
    font-weight: 500;
    font-size: 18px;
    line-height: 16px;
    color: $neutrals4;
    transition: all 0.2s;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-bottom: 1px solid $neutrals6;

    @include dark {
      border-bottom: 1px solid $neutrals3;
    }

    @include m {
      margin: 0;
    }

    &:hover {
      color: $neutrals3;

      @include dark {
        color: $neutrals6;
      }
    }

    &.active {
      /*background: $neutrals3;
            color: $neutrals8;*/
      border-bottom: 1px solid $neutrals1;

      @include dark {
        border-bottom: 1px solid $neutrals8;
        /*background: $neutrals8;
                color: $neutrals2;*/
      }
    }

    &:not(:last-child) {
      @include m {
        margin-right: 12px;
      }
    }
  }

  &__filter {
    display: flex;
    padding: 40px 0px;
    justify-content: center;
    /*border-bottom: 1px dashed $neutrals5;*/

    @include m {
      padding: 10px 0px;
    }

    &__col {
      background: $neutrals8;
      padding: 0 10px;

      @include dark {
        background: $neutrals1;
      }
      /*flex: 0 0 40%;

            &:last-child {
                flex: 0 0 20%;
            }*/
    }

    &__hr {
      border-top: 1px dashed $neutrals5;
      margin-top: -66px;

      @include m {
        display: none;
      }
    }

    @include m {
      display: block;
    }
    &_Count {
      background: $primary3;
      color: $primary2;
      border-radius: 50%;
      width: 21px;
      height: 21px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .btn_more_filter {
    cursor: pointer;
    display: flex;
    padding: 16px 30px;
    align-items: center;
    gap: 10px;
    /*border: 1px solid $neutrals6;*/
    border-radius: 4px;
    margin-right: 16px;
    font-weight: 500;
    line-height: 25px;
    font-size: 18px;
    color: $neutrals4;

    @include dark {
      color: $neutrals4;
      /*border: 1px solid $neutrals2;*/
    }

    @include t {
      flex-grow: 1;
      border: 2px solid $neutrals6;
      @include dark {
        border: 2px solid $neutrals3;
      }
    }

    i {
      transition: transform 0.2s;
      @include t {
        margin-left: auto;
      }
    }

    &.active {
      i {
        transform: rotate(180deg);
        /*transition: transform .2s;*/
      }
    }
  }

  .filter-status {
    width: 10px;
    height: 10px;
    border-radius: 25px;
    background: $primary3;
    display: none;
  }

  .filter_clear {
    display: none;
  }

  .filter__more {
    display: flex;
    margin-top: 40px;
    gap: 32px;
    align-items: flex-end;
    margin-top: 40px;

    &.active {
      @include m {
        display: block !important;
      }
      @include r(1230) {
        display: block !important;
      }
    }

    @include m {
      display: block;
    }
    &__col {
      /*flex: 0 0 31.333%;*/
      min-width: 20%;

      @include m {
        margin-top: 24px;
      }
    }

    @include m {
      display: block;
    }
  }

  .ratio-filter {
    display: flex;
    align-items: center;
    width: 362px;
    border: 1px solid $neutrals6;
    border-radius: 4px;
    color: $neutrals4;

    @include m {
      width: 100%;
    }

    @include r(380) {
      flex-wrap: wrap;
    }

    @include dark {
      border: 1px solid $neutrals2;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px;
      height: 48px;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      flex: 0 0 35%;
      gap: 20px;
      cursor: pointer;

      @include m {
        gap: 12px;
      }

      &:first-child {
        flex: 0 0 25%;
        border-radius: 4px 0 0 4px;
      }

      &:not(:last-child):not(.active) {
        border-right: 1px solid $neutrals6;

        &:hover {
          // background-color: $primary3;
          color: $neutrals1;

          img {
            @include filter-neutrals1;
          }
        }

        @include dark {
          border-right: 1px solid $neutrals2;

          &:hover {
            // background-color: $primary3;
            color: $neutrals8;

            img {
              @include filter-neutrals6;
            }
          }
        }
      }

      &:last-child:not(.active) {
        flex: 0 0 40%;
        flex-grow: 1;
        border-radius: 0 4px 4px 0;

        @include r(380) {
          flex: 0 0 50%;
        }

        &:hover {
          //background-color: $primary3;
          color: $neutrals1;

          img {
            @include filter-neutrals1;
          }
        }

        @include dark {
          border-right: 1px solid $neutrals2;

          &:hover {
            // background-color: $primary3;
            color: $neutrals8;

            img {
              @include filter-neutrals6;
            }
          }
        }
      }

      img {
        width: 20px;
        height: 20px;
        object-fit: contain;
        /*gray*/
        filter: brightness(0) saturate(100%) invert(49%) sepia(17%) saturate(335%) hue-rotate(185deg) brightness(98%)
          contrast(93%);
      }

      &.active {
        color: $primary2;
        transition: all 0.2s;
        background: $primary1;

        img {
          /*white*/
          filter: brightness(0) saturate(100%) invert(100%) sepia(12%) saturate(0%) hue-rotate(357deg) brightness(106%)
            contrast(101%);
        }

        @include dark {
          color: $primary1;
          transition: all 0.2s;
          background: $primary2;

          img {
            /*black*/
            filter: brightness(0) saturate(100%) invert(0%) sepia(100%) saturate(0%) hue-rotate(345deg) brightness(98%)
              contrast(102%);
          }
        }
      }
    }
  }

  .grid_show {
    display: flex;
    gap: 8px;
    justify-content: flex-end;

    &_link {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px;
      height: 48px;
      width: 48px;
      border-radius: 4px;
      border: 1px solid $neutrals6;
      cursor: pointer;

      @include dark {
        border: 1px solid $neutrals2;
      }

      img {
        width: 20px;
        height: 20px;
        object-fit: contain;
        /*black*/
        filter: brightness(0) saturate(100%) invert(0%) sepia(81%) saturate(7436%) hue-rotate(200deg) brightness(114%)
          contrast(100%);

        @include dark {
          /*white*/
          filter: brightness(0) saturate(100%) invert(100%) sepia(12%) saturate(0%) hue-rotate(357deg) brightness(106%)
            contrast(101%);
        }
      }

      &.active {
        color: $primary2;
        transition: all 0.2s;
        background: $primary1;

        img {
          /*white*/
          filter: brightness(0) saturate(100%) invert(100%) sepia(12%) saturate(0%) hue-rotate(357deg) brightness(106%)
            contrast(101%);
        }

        @include dark {
          color: $primary1;
          transition: all 0.2s;
          background: $primary2;

          img {
            /*black*/
            filter: brightness(0) saturate(100%) invert(0%) sepia(100%) saturate(0%) hue-rotate(345deg) brightness(98%)
              contrast(102%);
          }
        }
      }
    }

    @include m {
      display: none;
    }
  }

  &__row {
    display: flex;

    @include t {
      // display: block;
      max-width: 100%;
    }
  }

  &__wrapper {
    flex-grow: 1;
    // padding-left: 32px;

    @include t {
      padding-left: 0;
    }
  }

  &__wrapper2 {
    flex-grow: 1;
    /*padding-left: 32px;*/

    @include t {
      padding-left: 0;
    }
  }

  &__filters {
    flex-shrink: 0;
    width: 256px;

    @include t {
      width: 100%;
      margin-bottom: 32px;
    }
  }

  .range {
    margin-bottom: 24px;
  }

  &__group {
    margin-bottom: 24px;
    padding: 24px 0;
    border-width: 1px 0;
    border-style: solid;
    border-color: $neutrals6;

    @include dark {
      border-color: $neutrals3;
    }
  }

  .field {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &__reset {
    display: inline-flex;
    align-items: center;
    @include button-2;
    color: $neutrals2;
    cursor: pointer;
    transition: color 0.2s;

    @include dark {
      color: $neutrals8;
    }

    .icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      fill: $neutrals2;
      transition: fill 0.2s;

      @include dark {
        fill: $neutrals8;
      }
    }

    &:hover {
      color: $primary3;

      .icon {
        fill: $primary3;
      }

      @include dark {
        color: $primary3;

        .icon {
          fill: $primary3;
        }
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: -32px -16px 0;

    @include m {
      display: block;
      margin: 0;
    }
  }

  &__list2 {
    display: flex;
    flex-wrap: wrap;
    /*margin: -32px -16px 0;*/
    gap: 32px;
    margin-top: 56px;

    @include m {
      display: block;
      /*margin: 0;*/
    }
  }

  &__list3 {
    display: flex;
    flex-wrap: wrap;
    margin-top: 56px;

    @include m {
      display: block;
      /*margin: 0;*/
    }
  }

  .card {
    flex: 0 0 calc(33.333% - 32px);
    max-width: calc(33.333% - 32px);
    margin: 32px 16px 0;
    padding: 12px;
    /*box-shadow: 0px 32px 32px rgba(31, 47, 70, 0.12);
        border-radius: 20px;*/

    @include r(1279) {
      flex: 0 0 calc(50% - 32px);
      max-width: calc(50% - 32px);
    }

    @include m {
      max-width: 100%;
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 32px;
      }
    }

    @include dark {
      /*background: $neutrals2;*/
      background-color: transparent;
    }
  }

  &__btns {
    margin-top: 32px;
    text-align: center;
  }

  .exclusive {
    flex: 0 0 33.333%;
    max-width: 33.333%;
    /*margin: 32px 16px 0;*/
    padding: 12px;
    /*box-shadow: 0px 32px 32px rgba(31, 47, 70, 0.12);
        border-radius: 20px;*/

    @include r(1279) {
      flex: 0 0 calc(50% - 8px);
      max-width: calc(50% - 8px);
    }

    @include m {
      max-width: 100%;
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 32px;
      }
    }

    @include dark {
      /*background: $neutrals2;*/
      background-color: transparent;
    }
  }
  /*.tutorial {
        flex: 0 0 25%;
        max-width: 25%;*/
  /*margin: 32px 16px 0;*/
  /*padding: 12px;*/
  /*box-shadow: 0px 32px 32px rgba(31, 47, 70, 0.12);
        border-radius: 20px;*/
  /*@include r(1279) {
            flex: 0 0 calc(50% - 8px);
            max-width: calc(50% - 8px);
        }

        @include m {
            max-width: 100%;
            margin: 0;

            &:not(:last-child) {
                margin-bottom: 32px;
            }
        }

        @include dark {*/
  /*background: $neutrals2;*/
  /*background-color: transparent;
        }
    }*/

  .tops {
    flex: 0 0 20%;
    max-width: 20%;
    /*margin: 32px 16px 0;*/
    padding: 12px;
    /*box-shadow: 0px 32px 32px rgba(31, 47, 70, 0.12);
        border-radius: 20px;*/

    @include r(1279) {
      flex: 0 0 calc(50% - 32px);
      max-width: calc(50% - 32px);
    }

    @include m {
      max-width: 100%;
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 32px;
      }
    }

    @include dark {
      /*background: $neutrals2;*/
      background-color: transparent;
    }
  }

  &.tops__center {
    padding: 100px 80px;

    @include m {
      padding: 50px 30px;
    }
  }

  .spacer {
    height: 70px;
  }
}
