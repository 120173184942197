.field {
  &_label {
    font-size: 16px;
    text-align: start;
    @include poppins;
    margin-bottom: 5px;
  }

  &__number-filter {
    top: -4px;
    right: -12px;
    position: absolute;
    color: white;
    background: rgb(180, 0, 0);
    border-radius: 50%;
    padding: 0px 8px;
  }

  &__label {
    margin-bottom: 12px;
    margin-top: 18px;
    @include hairline-2;
    color: black;

    @include dark {
      color: white;
    }

    &_counter {
      display: block;
      text-align: end;
    }
  }

  &__wrap {
    position: relative;
    display: block;
    align-items: center;
    .lods {
      position: absolute;
      right: 0px;
      display: flex;
    }

    .search-input {
      position: absolute;
      top: 10px;
      left: 10px;
      font-size: 16px;
      font-weight: 400;
      color: $neutrals4;
      background: $primary2;
      z-index: 999;
      @include dark {
        background: $primary1;
      }
    }
  }

  &__input,
  &__textarea {
    width: 100%;
    border-radius: 0px;
    border: 2px solid $neutrals6;
    background: none;
    @include main-font;
    @include caption-bold-1;
    color: $neutrals2;
    transition: border-color 0.2s;

    @include dark {
      border-color: $neutrals3;
      color: $neutrals8;
    }

    @include placeholder {
      color: $neutrals4;
    }

    &:focus {
      border-color: $neutrals4;

      @include dark {
        border-color: $neutrals4;
      }
    }
  }

  &__textarea2 {
    @include m {
      min-height: 160px;
    }
  }

  &__input {
    height: 48px;
    padding: 0 14px;
  }

  &__textarea {
    height: 100px;
    padding: 14px;
    resize: none;
  }

  &__currency {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    pointer-events: none;
  }

  &_textarea {
    font-size: 0;
  }

  .end {
    text-align: end;
  }

  &__userNametick {
    color: green;
    display: flex;
    font-size: 25px;
    position: absolute;
    right: 12px;
    top: 12px;
  }
}

.required__field:after {
  content: "*";
  color: #f00;
  font-size: 14px;
  margin-left: 3px;
}

.row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  margin-right: -15px;
  margin-left: -15px;
}

.colmd6 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  @media (min-width: 768px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
