.landing {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;

  &.popup {
    padding: 26px;
    @include m {
      margin: 10px;
    }
  }

  hr {
    width: 100%;
  }

  .profile2__box {
    width: 100%;

    .profile2__box__col {
      flex: 0 0 33.333%;
      min-width: 33.333%;

      .icon {
        width: 20px;
        height: 20px;
        fill: $primary1;

        @include dark {
          fill: $primary2;
        }
      }

      .profile2__box__col__value a {
        font-size: 14px;
        margin-left: 4px;
        color: $primary1;

        @include dark {
          color: $primary2;
        }
      }
    }
  }

  .notif {
    width: 100%;
    padding: 12px;
  }

  .warn_list {
    list-style-type: disclosure-closed;
    margin-left: 22px;
    margin-top: 10px;
  }
}

.kyc {
  &.gi-box {
    background-color: $primary2 !important;
  }

  .title {
    color: $primary1;
    margin-bottom: 20px;
  }
}

.iconArtverse {
  display: flex;
  align-items: center;
}
