.success {
    padding-top: 40px;

    &__title {
        margin-bottom: 32px;
        text-align: center;
    }

    &__info {
        max-width: 240px;
        margin: 0 auto 32px;
        text-align: center;
        @include body-bold-2;

        span {
            text-decoration: underline;
        }
    }

    &__table {
        margin-bottom: 32px;
        padding: 24px;
        border-radius: 12px;
        border: 1px solid $neutrals6;

        @include dark {
            border-color: $neutrals3;
        }
    }

    &__row {
        display: flex;

        &:first-child {
            color: $neutrals4;
        }

        &:nth-child(2) {
            font-weight: 500;

            .success__col {
                &:first-child {
                    color: $purple;
                }
            }
        }

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    &__col {
        &:first-child {
            margin-right: auto;
            padding-right: 16px;
        }

        &:nth-child(2) {
            flex-shrink: 0;
            width: 152px;
        }
    }

    &__stage {
        margin-bottom: 16px;
        text-align: center;
        @include body-bold-2;
    }

    &__socials {
        display: flex;
        justify-content: center;
    }

    &__social {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 48px;
        height: 48px;
        border: 2px solid $neutrals6;
        border-radius: 50%;
        transition: border-color .2s;

        @include dark {
            border-color: $neutrals3;
        }

        .icon {
            width: 24px;
            height: 24px;
            fill: $neutrals4;
            transition: fill .2s;
        }

        &:hover {
            border-color: $blue;

            @include dark {
                border-color: $blue;
            }

            .icon {
                fill: $blue;
            }
        }

        &:not(:last-child) {
            margin-right: 16px;
        }
    }
}
