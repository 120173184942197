.theme {
  display: inline-block;
  position: relative;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 0;

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;

    &:checked + .theme__inner .theme__box {
      background: $primary1;

      @include dark {
        background: $primary2;
      }

      &:before {
        transform: translate(20px, -50%);
        background: $primary2;

        @include dark {
          background: $primary1;
        }
      }
    }
  }

  &__inner {
    position: relative;
    display: inline-block;
    transition: all 0.2s;
  }

  &__box {
    position: relative;
    display: block;
    width: 40px;
    height: 20px;
    background: $neutrals6;
    border-radius: 10px;
    transition: all 0.2s;

    @include dark {
      background: $neutrals3;
    }

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 4px;
      transform: translateY(-50%);
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: $primary1;
      transition: all 0.2s;
    }
  }

  &_big &__box {
    width: 48px;
    height: 24px;
    border-radius: 12px;

    &:before {
      left: 4px;
      width: 16px;
      height: 16px;
    }
  }

  &_big &__input {
    &:checked + .theme__inner .theme__box {
      &:before {
        transform: translate(24px, -50%);
      }
    }
  }
}

.theme2 {
  position: relative;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 0;
  width: 56px;
  height: 56px;
  border: 1px solid $neutrals6;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin-left: 32px;

  @include w {
    margin-left: 10px;
  }

  @include m {
    /*display: none;*/
    margin-left: 0;
  }

  @include dark {
    border: 1px solid $neutrals2;
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;

    &:checked + .theme__inner .theme__box {
      background: $black;

      &:before {
        transform: translate(20px, -50%);
        background: $white;
      }
    }
  }

  &__inner {
    position: relative;
    display: inline-block;
    transition: all 0.2s;
  }

  &__box {
    position: relative;
    display: block;
    width: 40px;
    height: 20px;
    background: $neutrals6;
    border-radius: 10px;
    transition: all 0.2s;

    @include dark {
      background: $neutrals3;
    }

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 4px;
      transform: translateY(-50%);
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: $black;
      transition: all 0.2s;
    }
  }

  &_big &__box {
    width: 48px;
    height: 24px;
    border-radius: 12px;

    &:before {
      left: 4px;
      width: 16px;
      height: 16px;
    }
  }

  &_big &__input {
    &:checked + .theme__inner .theme__box {
      &:before {
        transform: translate(24px, -50%);
      }
    }
  }
}
