.followers {
    &__item {
        display: flex;
        padding-bottom: 32px;
        border-bottom: 1px solid $neutrals6;

        @include m {
            position: relative;
            display: block;
            padding: 0 100px 0 0;
            border: none;
        }

        @include dark {
            border-color: $neutrals3;
        }

        &:not(:last-child) {
            margin-bottom: 48px;

            @include m {
                margin-bottom: 32px;
            }
        }
    }

    &__user {
        display: flex;
        align-items: center;
        width: 326px;
        flex: 0 0 326px;
        padding-right: 24px;

        @include d {
            flex: 0 0 244px;
            width: 244px;
        }

        @include m {
            width: 100%;
            padding-right: 0;
        }
    }

    &__wrap {
        width: calc(100% - 326px);
        flex: 0 0 calc(100% - 326px);

        @include d {
            width: calc(100% - 244px);
            flex: 0 0 calc(100% - 244px);
        }

        @include m {
            display: none;
        }
    }

    &__avatar {
        flex-shrink: 0;
        width: 88px;
        height: 88px;
        margin-right: 20px;

        @include m {
            width: 72px;
            height: 72px;
            margin-right: 16px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
        }
    }

    &__title {
        @include body-bold-2;
    }

    &__counter {
        @include caption-2;
        color: $neutrals4;
    }

    &__button {
        height: 32px;
        margin-top: 12px;
        padding: 0 12px;

        @include m {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            margin-top: 0;
        }
    }

    &__gallery {
        display: flex;
        margin: 0 -4px;
        overflow-x: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__preview {
        flex-shrink: 0;
        width: 112px;
        margin: 0 4px;

        img {
            width: 100%;
            border-radius: 12px;
        }
    }

    .loader {
        margin: 48px auto 10px;
    }
}

// style for new desine

.followers2 {
    &__item {
        display: flex;
        padding-bottom: 35px;

        @include m {
            position: relative;
            // padding: 0 100px 35px 0;
        }
    }

    &__user {
        display: flex;
        align-items: center;
        width: 326px;
        margin: auto;
    }

    &__details {
        margin-right: auto;
    }

    &__wrap {
        width: calc(100% - 326px);
        flex: 0 0 calc(100% - 326px);

        @include d {
            width: calc(100% - 244px);
            flex: 0 0 calc(100% - 244px);
        }

        @include m {
            display: none;
        }
    }

    &__avatar {
        flex-shrink: 0;
        width: 70px;
        height: 70px;
        margin-right: 20px;

        @include m {
            width: 48px;
            height: 48px;
            margin-right: 16px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
        }
    }

    &__title {
        @include body-bold-2;
    }

    &__counter {
        @include caption-2;
        color: $neutrals4;
    }

    &__button {
        height: 32px;
        padding: 0 12px;
        background: $neutrals6;

        @include dark {
            background: $neutrals3;
        }

        @include m {
            position: absolute;
            right: 5%;
            transform: translateY(-50%);
            margin-top: 0;
        }

        &:hover {
            background: $primary1;
            color: $primary2;

            @include dark {
                background: $primary2;
                color: $primary1;
            }
        }
    }

    &__preview {
        flex-shrink: 0;
        width: 112px;
        margin: 0 4px;

        img {
            width: 100%;
            border-radius: 12px;
        }
    }

    .loader {
        margin: 48px auto 10px;
    }
}

.popup__follow {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #777e91;
    height: 73px;
    margin-bottom: 40px;
    justify-content: center;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    margin-top: 30px;

    &_tab {
        flex: 0 0 50%;
        max-width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            cursor: pointer;
            color: $primary1 !important;
            border-bottom: 1px solid $primary1;

            @include dark {
                color: $primary2 !important;
                border-bottom: 1px solid $primary2;
            }
        }

        &.active {
            color: $primary1 !important;
            border-bottom: 1px solid $primary1;

            @include dark {
                color: $primary2 !important;
                border-bottom: 1px solid $primary2;
            }
        }
    }
    /*&__followers {
        margin: 0 auto;
        padding: 20px;
        color: #777E91 !important;

        &:hover {
            cursor: pointer;
            color: #000000 !important;
            border-bottom: 1px solid #000000;

            @include dark {
                color: #FCFCFD !important;
                border-bottom: 1px solid #FCFCFD;
            }
        }

        &.active {
            color: #000000 !important;
            border-bottom: 1px solid #000000;

            @include dark {
                color: #FCFCFD !important;
                border-bottom: 1px solid #FCFCFD;
            }
        }
    }

    &__following {
        margin: 0 auto;
        padding: 20px;
        color: #777E91 !important;

        &:hover {
            cursor: pointer;
            color: #000000 !important;
            border-bottom: 1px solid #000000;

            @include dark {
                color: #FCFCFD !important;
                border-bottom: 1px solid #FCFCFD;
            }
        }

        &.active {
            color: #000000 !important;
            border-bottom: 1px solid #000000;

            @include dark {
                color: #FCFCFD !important;
                border-bottom: 1px solid #FCFCFD;
            }
        }
    }*/
}
