.skeletonCurator {
  width: 120px;

  @media (max-width: 1021px) {
    width: 400px;
  }
}

.skeleton {
  &__text {
    width: 60px;
    height: 20px;
    background-color: rgba($neutrals4, 0.3);
    border-radius: 4px;
  }

  &__button {
    height: 48px;
    width: 200px;
    background-color: rgba($neutrals4, 0.3);
  }

  &__portrait {
    &_image {
      width: 800px;
      height: 900px;
      background-color: rgba($neutrals4, 0.3);
      @include m {
        width: 100%;
        height: 500px;
      }
    }

    &_title {
      width: 200px;
      height: 28px;
      margin-bottom: 28px;
      background-color: rgba($neutrals4, 0.3);
      border-radius: 4px;
    }

    &_chain {
      width: 30px;
      height: 30px;
      border-radius: 50px;
      background-color: rgba($neutrals4, 0.3);
    }

    &_cost-item {
      width: 75px;
      height: 30px;
      border-radius: 4px;
      background-color: rgba($neutrals4, 0.3);
      margin-right: 8px;
    }
  }

  &__landscape {
    &_image {
      width: 100%;
      height: 750px;
      background-color: rgba($neutrals4, 0.3);
      @include m {
        width: 100%;
        height: 500px;
      }
    }
  }

  &__option {
    &_button {
      margin-right: 20px;
      width: 48px;
      height: 48px;
      background-color: rgba($neutrals4, 0.3);
    }
  }

  &__user {
    &_avatar {
      width: 48px;
      height: 48px;
      background-color: rgba($neutrals4, 0.3);
      border-radius: 50%;
    }

    &_text {
      width: 100px;
      height: 20px;
      background-color: rgba($neutrals4, 0.3);
      margin-bottom: 6px;
      border-radius: 6px;
    }
  }

  &__tab {
    &_detail {
      width: 290px;
      height: 20px;
      background-color: rgba($neutrals4, 0.3);
      margin-bottom: 6px;
      margin-right: 5px;
      border-radius: 10px;
    }
  }

  &__card {
    &_preview {
      height: 327px;
      position: relative;
      width: 100%;
    }
    &_img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      background-color: rgba($neutrals4, 0.3);
      margin-right: 8px;

      //add motions
      transition: all 200ms ease-in-out;
      position: relative;
      background-color: rgba(119, 126, 144, 0.3);

      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0,
          rgba(255, 255, 255, 0.2) 20%,
          rgba(255, 255, 255, 0.5) 60%,
          rgba(255, 255, 255, 0)
        );
        animation: load 1s infinite;
      }

      @keyframes load {
        100% {
          transform: translateX(100%);
        }
      }
    }
    &_avatars {
      position: relative;
      flex-shrink: 0;
      width: 35px;
      height: 35px;
      border: 2px solid #fff;
      margin-right: 5px;
      border-radius: 50px;
    }
    &_creator {
      position: absolute;
      top: 10px;
      left: 8px;
      border-radius: 50px;
      display: flex;
      align-items: center;
      max-width: 90%;
      height: 35px;
      width: 35px;
      z-index: 1;
      padding-right: 10px;
    }
    &_link {
      display: flex;
      flex-direction: column;
      height: 69px;
      width: 100%;
    }

    &_line {
      &:first-child {
        margin-bottom: 4px;
        border-bottom: 1px solid $neutrals6;
        padding-bottom: 5px;

        @include dark {
          border-bottom: 1px solid $neutrals3;
        }
      }
      &:nth-child(2) {
        @include dark {
          border-bottom: 1px solid $neutrals3;
        }
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    &_name {
      width: 100px;
      height: 20px;
      background-color: rgba($neutrals4, 0.3);
      margin-bottom: 3px;
      margin-top: 10px;
      border-radius: 6px;
    }
    &_auctions {
      width: 100px;
      height: 20px;
      background-color: rgba($neutrals4, 0.3);
      border-radius: 6px;
    }
    &_price {
      display: flex;
      gap: 5px;
      align-items: center;
    }
  }

  &__profile {
    &_avatar {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: scale(1.05);
      background-color: rgba(119, 126, 144, 0.3);
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      z-index: 1;
    }
    &_user-Name {
      margin: 14px auto;
      width: 112px;
      height: 30px;
      border-radius: 4px;
      background-color: rgba(119, 126, 144, 0.3);
      @include m {
        margin: 10px 0;
      }
    }
    &_referral {
      width: 200px;
      height: 40px;
      margin-bottom: 28px;
      background-color: rgba(119, 126, 144, 0.3);
      border-radius: 4px;
      @include m {
        width: 100%;
      }
    }
    &_button {
      &_bio {
        margin: 0 6px;
        padding: 12px 12px;
        border-radius: 14px;
        background-color: rgba(119, 126, 144, 0.3);
        width: 95px;
      }
      &_option {
        margin-right: 20px;
        width: 40px;
        height: 40px;
        background-color: rgba(119, 126, 144, 0.3);
      }
      &_socialIcon {
        width: 37px;
        height: 25px;
        background-color: rgba(119, 126, 144, 0.3);
        border-radius: 4px;
        margin-right: 24px;
      }
    }
    &_categories {
      display: none;
      @include m {
        display: flex;
      }
    }
  }

  &__dashboard {
    &_flex {
      justify-content: space-around;
      display: flex;
    }
    &_chart {
      width: 100px;
      height: 100px;
      margin-left: 50px;
      background-color: rgba(119, 126, 144, 0.3);
      border-radius: 50%;
    }

    &_chart2 {
      width: 200px;
      height: 100px;
      margin-left: 50px;
      border-radius: 5px;
      background-color: rgba(119, 126, 144, 0.3);

      @include m {
        margin-left: 20px;
        width: 100px;
      }
    }

    &_button {
      width: 150px;
      height: 30px;
      margin-right: 20px;
      border-radius: 5px;
      background-color: rgba(119, 126, 144, 0.3);
    }

    &_like {
      width: 20px;
      height: 20px;
      margin-right: 5px;
      border-radius: 5px;
      background-color: rgba(119, 126, 144, 0.3);
    }

    &_rank2 {
      width: 50px;
      height: 20px;
      margin-right: 5px;
      border-radius: 5px;
      background-color: rgba(119, 126, 144, 0.3);
    }

    &_rank {
      width: 20px;
      height: 20px;
      margin: 0px 5px;
      border-radius: 5px;
      background-color: rgba(119, 126, 144, 0.3);
    }

    &_flex2 {
      display: flex;
    }
  }
}

.skeleton {
  border-radius: 6px;
  width: 10px;
  height: 10px;
  background: linear-gradient(90deg, #e7e7e7 25%, #f4f4f4 60%, #e7e7e7 65%);
  background-size: 200% 100%;
  -webkit-animation: skeleton-loading 3s infinite linear;
  animation: skeleton-loading 3s infinite linear;

  @include dark {
    -webkit-animation: skeleton-loading 3s infinite linear;
    animation: skeleton-loading 3s infinite linear;
    background: linear-gradient(90deg, #2f2f2fb3 25%, #2d2d2dbe 60%, #333333b3 65%);
    background-size: 200% 100%;
  }

  &__60-60 {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }

  &__50-50 {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  &__60-60 {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  &__100-20 {
    width: 100px;
    height: 20px;
  }

  &__70-20 {
    width: 70px;
    height: 20px;
  }

  &__25-25 {
    width: 25px;
    height: 25px;
  }

  &__30-15 {
    width: 30px;
    height: 15px;
  }

  &__50-15 {
    width: 50px;
    height: 15px;
  }

  &__30-10 {
    margin-top: 5px;
    width: 25px;
    height: 10px;
  }

  &__30-30 {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  &__30-30-2 {
    width: 30px;
    height: 30px;
  }

  &__40-20 {
    width: 40px;
    height: 20px;
  }

  &__80-20 {
    width: 80px;
    height: 20px;
  }

  &__80-15 {
    width: 80px;
    height: 15px;
  }

  &__100-40 {
    width: 130px;
    height: 40px;
  }

  &__80-30 {
    width: 80px;
    height: 30px;
  }

  &__100-30 {
    width: 100%;
    height: 40px;
  }

  &__100-50-2 {
    width: 100%;
    height: 50px;
  }

  &__100precent-20 {
    width: 100%;
    height: 20px;
  }

  &__150-150 {
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }

  &__100-100 {
    width: 100%;
    height: 100%;
  }
}
