// common styles
:root {
  --scroll-thumb: #000;
}

.dark {
  --scroll-thumb: #fff;
}

html {
  scroll-behavior: smooth;
}

.w3m-overlay {
  z-index: 10000 !important;
}

body {
  /*position: relative;*/
  /*min-width: 375px;*/
  background: $neutrals8;
  @include main-font;
  font-size: 14px;
  line-height: (24/14);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $neutrals2;
  overflow-x: hidden;
  // max-width: 100%;

  @include dark {
    --scroll-thumb: #fff;
    background: $neutrals1;
    /*background: $black;*/
    color: $neutrals8;
  }
  // &.dark {
  //     background: $neutrals1;
  //     /*background: $black;*/
  //     color: $neutrals8;
  // }
}

.seed__content {
  position: relative;
  max-width: 100%;
  overflow-x: clip;
}

// scroll bar style ----- Start
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: var(--scroll-thumb);
  border-radius: 10px;
}
// scroll bar style ----- End

.hr {
  border-top: 1px solid $neutrals6;

  @include dark {
    border: 1px solid $neutrals3;
  }
}

.toast {
  border: 2px solid $primary3;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  background: rgba($primary2, 0.26) !important;
  backdrop-filter: blur(20px);
  color: $neutrals1;
  z-index: 10001 !important;
  height: 80px !important;

  @include rmin(760) {
    width: 400px;
  }

  @include dark {
    background: rgba($primary1, 0.26) !important;
    color: $primary2;
  }
}

.toast-notify {
  // border: 2px solid $primary3;
  padding: 10px;
  margin: 10px;
  // border-radius: 5px;
  background: $primary2;
  // backdrop-filter: blur(60px);
  color: $neutrals3;
  z-index: 10001 !important;
  min-height: 80px;
  display: flex;
  align-items: center;
  position: fixed;
  top: 96px;
  right: 35px;
  font-weight: 500;
  font-size: 16px;
  box-shadow: 0 0 2px 0 #0000001a;

  @include dark {
    background: $neutrals0;
    color: $primary2;
    box-shadow: 0 0 2px 0 #1e2227;
  }

  &.success-notif {
    border-left: 4px solid #4bb543;
    .toast-icon {
      background: #4bb543;
    }

    @include dark {
      border-left: 4px solid #5abf52;
      .toast-icon {
        background: #5abf52;
      }
    }
  }

  &.error-notif {
    border-left: 4px solid #d0342c;
    .toast-icon {
      background: #d0342c;
    }

    @include dark {
      border-left: 4px solid #d6473f;
      .toast-icon {
        background: #d6473f;
      }
    }
  }

  &.warning-notif {
    border-left: 4px solid #ebc008;
    .toast-icon {
      background: #ebc008;
    }

    @include dark {
      border-left: 4px solid #f7ce16;
      .toast-icon {
        background: #f7ce16;
      }
    }
  }

  &.info-notif {
    border-left: 4px solid #2955bf;
    .toast-icon {
      background: #2955bf;
    }

    @include dark {
      border-left: 4px solid #7ca4ff;
      .toast-icon {
        background: #7ca4ff;
      }
    }
  }

  @include rmin(760) {
    min-width: 400px;
    max-width: 600px;
  }

  @include m {
    width: 96%;
    top: 90px;
    left: 0;
    min-height: 65px;
    border-radius: 5px;
    margin: calc(100% - 98%);
  }

  .message-text {
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .message-close {
    width: 21px;
    height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    color: #b1b5c3;
    border: 2px solid #b1b5c3;
    cursor: pointer;
    margin-left: 5px;
  }

  .toast-icon {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    margin-right: 10px;
    color: $primary2;

    @include dark {
      color: $neutrals0;
    }

    .icon {
      font-weight: 800;
    }
  }
}

.copy {
  border-left: 1px solid $neutrals6;
  padding-left: 10px;

  &:hover {
    img {
      @include filter-primary3;
      cursor: pointer;
    }
  }

  @include dark {
    border-left: 1px solid $neutrals3;
  }

  @include m {
    display: none;
  }
}

.referral__share {
  margin-left: 10px;

  @include t {
    margin-left: 0px;
  }

  button {
    @include m {
      width: 100%;
      margin: 10px 0;
    }
  }
}

.asset {
  &__list {
    display: flex;
    flex-wrap: wrap;

    @include t {
      display: block;
    }

    &.list-1c {
      .asset__item {
        flex: 0 0 100%;
        padding: 0 0 48px 0;
      }
    }
  }

  &__item {
    flex: 0 0 50%;
    padding-bottom: 48px;
    transition: 0.2s ease;
    padding-top: 20px;

    @include rmin(1024) {
      &:nth-child(even) {
        padding-left: 20px;
      }

      &:nth-child(odd) {
        padding-right: 20px;
      }
    }
  }

  &__action {
    /*flex: 0 0 33.333%;*/
    justify-content: center;
    display: flex;
    align-items: center;
    gap: 10px;

    @include rmin(1024) {
      width: 33.333%;
    }
  }
}

.pagination-scroll-infinite {
  position: fixed;
  position: fixed;
  right: 15px;
  bottom: 15px;
}

.scroll-up {
  z-index: 200;
  right: 0px;
  bottom: 0px;
  position: absolute;
  background-color: $primary2;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 12px 60px rgba(0, 0, 0, 0.7);
  cursor: pointer;

  &:hover {
    .scroll-up__pagination {
      transition: width 0.2s linear;
      width: 94%;
      right: 15px;
      bottom: 15px;
      position: fixed;
      background-color: #ffffffab;
      backdrop-filter: blur(41px);
      height: 50px;
      border-radius: 100px;
      line-height: normal;
      display: flex;

      justify-content: center;
      align-items: center;
      z-index: 100;
      box-shadow: 0px 12px 60px rgba(0, 0, 0, 0.7);
      /* padding-left: 0px; */
    }

    .scroll-up__active {
      transition: width 0.2s linear;
      visibility: visible;
      opacity: 1;
    }
  }

  &__pagination {
    transition: width 0.2s linear;
    right: 0px;
    bottom: 0px;
    position: absolute;
    background-color: $primary2;
    height: 50px;
    width: 40px;
    border-radius: 20px;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    box-shadow: 0px 12px 60px rgba(0, 0, 0, 0.7);
  }

  &__active {
    visibility: hidden;
    opacity: 0;
  }

  i {
    font-size: 20px;
    font-weight: bold;
    color: $primary1;
    display: flex;
    justify-content: center;
    line-height: normal;
    /*        @include dark {
            color: black;
        }*/
  }

  /*@include dark {
        background-color: white;
    }*/
}

.lunch-text {
  position: absolute;
  bottom: 20%;
  left: 33%;
  color: $primary2;
}

.lunch-text-p {
  position: absolute;
  bottom: 20%;
  left: 28%;
  color: $primary2;
}

.pagination-scroll-infinite2 {
  position: fixed;
  position: fixed;
  right: 15px;
  bottom: 15px;

  .scroll-up2 {
    z-index: 200;
    right: 0px;
    bottom: 0px;
    position: absolute;
    background-color: $primary2;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 12px 60px rgba(0, 0, 0, 0.7);
    cursor: pointer;

    i {
      font-size: 20px;
      font-weight: bold;
      color: $primary1;
      display: flex;
      justify-content: center;
      line-height: normal;
      /*        @include dark {
              color: black;
          }*/
    }

    /*@include dark {
          background-color: white;
      }*/
  }

  .pagination-holder {
    transition: height 0.2s linear;
    right: 0px;
    bottom: 0px;
    position: absolute;
    background-color: $primary2;
    height: 0px;
    width: 100%;
    border-radius: 20px;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;
    box-shadow: 0px 12px 60px rgba(0, 0, 0, 0.7);
  }

  .active2 {
    visibility: hidden;
    opacity: 0;
  }

  &.active {
    .scroll-up2 {
      display: none;

      i {
        display: none;
      }
    }
    .pagination-holder {
      transition: height 0.2s linear;
      width: 100%;
      right: 0px;
      bottom: 0px;
      position: fixed;
      background-color: #ffffffab;
      backdrop-filter: blur(41px);
      height: 50px;
      border-radius: 30px 30px 0px 0px;
      line-height: normal;
      display: flex;

      justify-content: center;
      align-items: center;
      z-index: 300;
      box-shadow: 0px 12px 60px rgba(0, 0, 0, 0.7);
      /* padding-left: 0px; */
    }

    .active2 {
      visibility: visible;
      opacity: 1;
    }
  }
}

.pagination-control2 {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-clip-path: polygon(10% 0%, 90% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(10% 0%, 90% 0%, 100% 100%, 0% 100%);
  width: 50px;
  height: 20px;
  position: absolute;
  top: -20px;
  right: 28px;
  background-color: #ffffffab;
  backdrop-filter: blur(41px);

  @include m {
    top: -19.5px;
  }
}

.pagination-infinite-scroll {
  color: black;
  padding: 0px 10px;
  font-size: 18px !important;

  @include m {
    padding: 0px 9px;
    font-size: 16px !important;
  }
}

.pagination-scroll-active {
  color: #8e793e !important;
  font-weight: bold;
}

.spacer {
  height: 30px;
}

.burst {
  display: flex;

  img {
    // width: 30px;
    width: auto;
    height: auto;
    margin-right: 20px;

    @include r(400) {
      margin-right: 10px;
    }

    @include dark {
      filter: brightness(0) saturate(100%) invert(100%) sepia(84%) saturate(1%)
        hue-rotate(167deg) brightness(104%) contrast(101%);
    }
  }
}

.text-danger {
  color: #dc3545;
}

.img-filter {
  @include filter-primary1;

  @include dark {
    @include filter-primary2;
  }
}

.sidebar__close {
  position: absolute;
  right: 15px;
  top: 30px;
  /*display: none;*/
  z-index: 5;
  cursor: pointer;

  &__icon {
    flex-shrink: 0;
    width: 30px;
    margin-right: 8px;

    .icon {
      width: 30px;
      height: 30px;
      fill: $neutrals4;
      transition: fill 0.2s;
    }
  }

  @include t {
    display: block;
  }
}

.ul-list {
  li::before {
    content: "\2022";
    color: #8e793e;
    font-weight: 900;
    vertical-align: middle;
    font-size: 25px;
    display: inline-block;
    width: 1em;
    margin-left: 1em;
  }
}

.opacity {
  opacity: 1 !important;
}

// .hero-seed {
//   width: 75%;
//   height: 100%;
//   margin: 0 auto;

//   @include m {
//     width: 100%;
//     height: 100%;
//   }

//   rect {
//     fill: transparent !important;
//   }
// }

.hero-container {
  background-color: #fcfcfd;

  @include dark {
    background-color: #131314;
  }
}

a {
  text-decoration: none;
}

svg,
img {
  vertical-align: middle;
}

.outer {
  overflow: hidden;
}

.center {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 80px;

  @include rmin(1600) {
    max-width: 1480px;
  }

  @include t {
    padding: 0 40px;
  }

  @include m {
    padding: 0 24px;
  }
}

// .center-profile-tab {
//   width: 100%;
//   max-width: 1035px;
//   margin: 0 auto;
//   padding: 0 80px;

//   @include t {
//     padding: 0 40px;
//   }

//   @include m {
//     padding: 0 32px;
//   }
// }

.some-icon {
  @include dark {
    display: none;
  }
}

.some-icon-dark {
  display: none;

  @include dark {
    display: inline-block;
  }
}

// .slick-arrow {
//   position: absolute;
//   z-index: 2;
//   width: 40px;
//   height: 40px;
//   border-radius: 50%;
//   font-size: 0;
//   transition: all 0.2s;

//   path {
//     position: relative;
//     z-index: 2;
//     fill: $neutrals4;
//     transition: fill 0.2s;
//   }

//   &:hover {
//     box-shadow: inset 0 0 0 2px $neutrals6;
//   }

//   @include dark {
//     &:hover {
//       box-shadow: inset 0 0 0 2px $neutrals3;

//       path {
//         fill: $neutrals6;
//       }
//     }
//   }
// }

// .select {
//   float: none;
//   width: 100%;
//   height: 48px;
//   padding: 0 48px 0 16px;
//   /*box-shadow: inset 0 0 0 2px $neutrals6;*/
//   background: $neutrals8;
//   border-radius: 0px;
//   border: none;
//   opacity: 1;
//   font-size: 14px;
//   font-weight: 500;
//   line-height: 48px;

//   &.more-discover {
//     /*height: 30px !important;*/
//     /*line-height: 27px;*/
//     width: auto !important;
//     font-weight: 600 !important;
//     font-size: 15px !important;
//     display: flex;
//     align-items: center;
//     /*&:before{
//             left: 56px !important;
//         }*/

//     .list {
//       min-width: 180px;
//     }
//   }

//   &.select-discover {
//     box-shadow: inset 0 0 0 2px $neutrals6;
//     color: $primary1;

//     @include dark {
//       color: $primary2;
//     }

//     @include dark {
//       box-shadow: inset 0 0 0 2px $neutrals2;
//     }
//   }

//   &:after {
//     display: none;
//   }

//   &:before {
//     content: "";
//     position: absolute;
//     top: 50%;
//     right: 8px;
//     width: 32px;
//     height: 32px;
//     transform: translateY(-50%);
//     border-radius: 50%;
//     /*box-shadow: inset 0 0 0 2px $neutrals6;*/
//     background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' fill='none' viewBox='0 0 10 6'%3E%3Cpath fill-rule='evenodd' d='M9.207.793a1 1 0 0 0-1.414 0L5 3.586 2.207.793A1 1 0 1 0 .793 2.207l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z' fill='%23777e91'/%3E%3C/svg%3E")
//       no-repeat 50% 50% / 10px auto;
//     transition: transform 0.2s;
//   }

//   @include dark {
//     background: $neutrals1;
//     /*box-shadow: inset 0 0 0 2px $neutrals3;*/
//     -webkit-appearance: none;

//     &:before {
//       /*box-shadow: inset 0 0 0 2px $neutrals3;*/
//       background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' fill='none' viewBox='0 0 10 6'%3E%3Cpath fill-rule='evenodd' d='M9.207.793a1 1 0 0 0-1.414 0L5 3.586 2.207.793A1 1 0 1 0 .793 2.207l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z' fill='%23FCFCFD'/%3E%3C/svg%3E");
//     }
//   }

//   &.open {
//     /*box-shadow: inset 0 0 0 2px $neutrals4;*/

//     @include dark {
//       /*box-shadow: inset 0 0 0 2px $neutrals4;*/
//     }

//     &:before {
//       transform: translateY(-50%) rotate(180deg);
//     }
//   }

//   .current {
//     display: block;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
//   }

//   .list {
//     right: 0;
//     margin-top: 2px;
//     border-radius: 0px;
//     background: $neutrals8;
//     border: 2px solid $neutrals6;
//     box-shadow: 0 4px 12px rgba($neutrals2, 0.1);
//     max-height: 400px;
//     overflow-y: auto;

//     @include dark {
//       background: $neutrals1;
//       border-color: $neutrals3;
//       box-shadow: 0 4px 12px rgba($neutrals1, 0.1);
//     }
//   }

//   .option {
//     min-height: auto;
//     padding: 10px 14px;
//     font-weight: 500;
//     line-height: 1.4;

//     &:hover,
//     &.focus,
//     &.selected.focus {
//       background: $neutrals7;

//       @include dark {
//         background: $neutrals2;
//       }
//     }

//     &.selected {
//       font-weight: 500;
//       color: $primary3;
//     }
//   }
// }

// .select-empty {
//   float: none;
//   width: 100%;
//   height: 64px;
//   padding: 0 64px 0 24px;
//   box-shadow: none;
//   background: transparent;
//   border-radius: 32px;
//   border: none;
//   opacity: 1;
//   @include main-font;
//   font-size: 40px;
//   font-weight: 700;
//   line-height: 64px;

//   &:after {
//     display: none;
//   }

//   &:before {
//     content: "";
//     position: absolute;
//     top: 50%;
//     right: 27px;
//     width: 15px;
//     height: 15px;
//     transform: translateY(-50%);
//     background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' fill='none' viewBox='0 0 10 6'%3E%3Cpath fill-rule='evenodd' d='M9.207.793a1 1 0 0 0-1.414 0L5 3.586 2.207.793A1 1 0 1 0 .793 2.207l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z' fill='%2323262F'/%3E%3C/svg%3E")
//       no-repeat 50% 50% / 100% auto;
//     transition: transform 0.2s;
//   }

//   @include dark {
//     &:before {
//       background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' fill='none' viewBox='0 0 10 6'%3E%3Cpath fill-rule='evenodd' d='M9.207.793a1 1 0 0 0-1.414 0L5 3.586 2.207.793A1 1 0 1 0 .793 2.207l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z' fill='%23FCFCFD'/%3E%3C/svg%3E");
//     }
//   }

//   &.open {
//     background: $neutrals8;

//     @include dark {
//       background: $neutrals1;
//     }

//     &:before {
//       transform: translateY(-50%) rotate(180deg);
//     }
//   }

//   .current {
//     display: block;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
//   }

//   .list {
//     right: 0;
//     margin-top: 4px;
//     border: none;
//     border-radius: 24px;
//     background: $neutrals8;
//     box-shadow: 0px 24px 32px 0 rgba(15, 15, 15, 0.15);

//     @include dark {
//       border: 2px solid $neutrals1;
//       background: $neutrals1;
//       box-shadow: 0 4px 12px rgba($neutrals1, 0.1);
//     }
//   }

//   .option {
//     min-height: auto;
//     padding: 10px 24px;
//     border: none;
//     @include main-font;
//     font-size: 40px;
//     font-weight: 700;
//     line-height: 1.2;
//     color: $neutrals4;

//     @include dark {
//       padding: 10px 22px;
//       color: $neutrals8;
//     }

//     &:hover,
//     &.focus,
//     &.selected.focus {
//       background: $neutrals7;

//       @include dark {
//         background: lighten($neutrals1, 2);
//       }
//     }

//     &.selected {
//       color: $primary3;

//       @include dark {
//         color: $primary3;
//       }
//     }
//   }
// }

// .select2-container {
//   vertical-align: unset !important;

//   .select2-search--inline {
//     position: absolute !important;
//     top: 8px !important;
//     left: 8px !important;

//     input {
//       color: $primary1;

//       @include dark {
//         color: $primary2;
//       }
//     }
//   }
// }

// .select2-container--default
//   .select2-selection--multiple
//   .select2-selection__rendered {
//   margin: 60px 0 0 -20px !important;
// }

// .select2-container--default .select2-selection--multiple {
//   width: 100%;
//   height: 48px;
//   padding: 0 48px 0 16px;
//   box-shadow: inset 0 0 0 2px $neutrals6;
//   background-color: $neutrals8;
//   border-radius: 0px !important;
//   border: none !important;
//   opacity: 1;
//   font-size: 14px;
//   font-weight: 500;
//   /*line-height: 48px;*/

//   @include dark {
//     background: $neutrals1;
//     box-shadow: inset 0 0 0 2px $neutrals3;
//     -webkit-appearance: none;
//   }
// }

// .select2-dropdown {
//   background-color: $neutrals8;

//   @include dark {
//     background: $neutrals1;
//     box-shadow: inset 0 0 0 2px $neutrals3;
//     -webkit-appearance: none;
//   }
// }

// .select2-container--default
//   .select2-selection--multiple
//   .select2-selection__choice {
//   background-color: $black !important;
//   color: $primary2 !important;
//   border: none !important;
//   border-radius: 0 !important;
//   padding: 2px 7px !important;

//   @include dark {
//     background-color: $primary2 !important;
//     color: $black !important;
//   }
// }

// .select2-container--default
//   .select2-selection--multiple
//   .select2-selection__choice__remove {
//   color: $primary2 !important;

//   @include dark {
//     color: $black !important;
//   }
// }

// .select2-container--default
//   .select2-results__option--highlighted[aria-selected] {
//   background-color: $primary3 !important;
// }

// .select2-container--default .select2-results__option[aria-selected="true"] {
//   background-color: $primary1;
//   color: $primary2;

//   @include dark {
//     background-color: $primary2;
//     color: $primary1;
//   }
// }

.not-allowed-cursor {
  cursor: not-allowed !important;
}

.pointer-cursor {
  cursor: pointer !important;
}

.no-border {
  border: none !important;
}

.link {
  color: $links !important;
  text-decoration: none;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none !important;
  }
}

.filter__row {
  display: flex;

  .ratio-filter {
    margin-right: auto;
  }
}

.ratio-filter {
  display: flex;
  align-items: center;
  width: 362px;
  border: 1px solid $neutrals6;
  border-radius: 4px;
  color: $neutrals4;

  @include dark {
    border: 1px solid $neutrals2;
  }

  @include m {
    width: 100%;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    height: 48px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    flex: 0 0 35%;
    gap: 20px;
    cursor: pointer;

    &:first-child {
      flex: 0 0 25%;
      border-radius: 4px 0 0 4px;
    }

    &:not(:last-child) {
      border-right: 1px solid $neutrals6;

      @include dark {
        border-right: 1px solid $neutrals2;
      }
    }

    &:last-child {
      flex: 0 0 40%;
      flex-grow: 1;
      border-radius: 0 4px 4px 0;
    }

    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
      /*gray*/
      filter: brightness(0) saturate(100%) invert(49%) sepia(17%) saturate(335%)
        hue-rotate(185deg) brightness(98%) contrast(93%);
    }

    &.active {
      color: $primary2;
      transition: all 0.2s;
      background: $primary1;

      img {
        /*white*/
        filter: brightness(0) saturate(100%) invert(100%) sepia(12%)
          saturate(0%) hue-rotate(357deg) brightness(106%) contrast(101%);
      }

      @include dark {
        color: $primary1;
        transition: all 0.2s;
        background: $primary2;

        img {
          /*black*/
          filter: brightness(0) saturate(100%) invert(0%) sepia(100%)
            saturate(0%) hue-rotate(345deg) brightness(98%) contrast(102%);
        }
      }
    }
  }
  button {
    color: $neutrals4;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    height: 48px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    flex: 0 0 35%;
    gap: 20px;
    cursor: pointer;
    @include main-font;

    &:first-child {
      flex: 0 0 25%;
      border-radius: 4px 0 0 4px;

      @include r(380) {
        flex: 0 0 50%;
      }
    }

    &:nth-child(2) {
      @include r(380) {
        flex: 0 0 50%;
      }
    }

    &:not(:last-child) {
      border-right: 1px solid $neutrals6;

      @include dark {
        border-right: 1px solid $neutrals2;
      }
    }

    &:last-child {
      flex: 0 0 40%;
      flex-grow: 1;
      border-radius: 0 4px 4px 0;
    }

    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
      /*gray*/
      filter: brightness(0) saturate(100%) invert(49%) sepia(17%) saturate(335%)
        hue-rotate(185deg) brightness(98%) contrast(93%);
    }

    &.active {
      color: $primary2;
      transition: all 0.2s;
      background: $primary1;

      img {
        /*white*/
        filter: brightness(0) saturate(100%) invert(100%) sepia(12%)
          saturate(0%) hue-rotate(357deg) brightness(106%) contrast(101%);
      }

      @include dark {
        color: $primary1;
        transition: all 0.2s;
        background: $primary2;

        img {
          /*black*/
          filter: brightness(0) saturate(100%) invert(0%) sepia(100%)
            saturate(0%) hue-rotate(345deg) brightness(98%) contrast(102%);
        }
      }
    }
  }
}

.grid_show {
  display: flex;
  gap: 8px;
  justify-content: flex-end;

  &_link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    height: 48px;
    width: 48px;
    border-radius: 4px;
    border: 1px solid $neutrals6;
    cursor: pointer;

    @include dark {
      border: 1px solid $neutrals2;
    }

    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
      /*black*/
      filter: brightness(0) saturate(100%) invert(0%) sepia(81%) saturate(7436%)
        hue-rotate(200deg) brightness(114%) contrast(100%);

      @include dark {
        /*white*/
        filter: brightness(0) saturate(100%) invert(100%) sepia(12%)
          saturate(0%) hue-rotate(357deg) brightness(106%) contrast(101%);
      }
    }

    &.active {
      color: $primary2;
      transition: all 0.2s;
      background: $primary1;

      img {
        /*white*/
        filter: brightness(0) saturate(100%) invert(100%) sepia(12%)
          saturate(0%) hue-rotate(357deg) brightness(106%) contrast(101%);
      }

      @include dark {
        color: $primary1;
        transition: all 0.2s;
        background: $primary2;

        img {
          /*black*/
          filter: brightness(0) saturate(100%) invert(0%) sepia(100%)
            saturate(0%) hue-rotate(345deg) brightness(98%) contrast(102%);
        }
      }
    }
  }

  @include m {
    display: none;
  }
}

.headerprogress {
  &__area {
    position: sticky;
    top: 97px;
    z-index: 2;

    @include t {
      top: 77px;
    }

    @include m {
      top: 97px;
    }
  }

  &__bar {
    height: 4px;
    width: 100%;
    border-radius: 10px;
    // transition: 0.4s ease;
    position: relative;
  }

  &__progress {
    height: 100%;

    background: $primary3;
    border-radius: inherit;
    animation: progress-animat 3s ease;
  }

  @keyframes progress-animat {
    0% {
      width: 0%;
    }

    100% {
      width: 100%;
    }
  }
}

/*--------------------------------------------------------------------------------*/

@mixin white-gradient {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

$animationSpeed: 40s;

// Animation
@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 7));
  }
}

// Styling
.slider {
  background: white;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
  height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;

  &::before,
  &::after {
    @include white-gradient;
    content: "";
    height: 100px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }

  .slide-track {
    animation: scroll $animationSpeed linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slide {
    height: 100px;
    width: 250px;
  }
}

.seed_carousel {
  position: unset !important;
  &.is-middle {
    .swiper-button-prev {
      top: 60%;
      left: 60px;
      @include m {
        top: unset;
        bottom: 0px;
        left: 32%;
      }
    }

    .swiper-button-next {
      top: 60%;
      right: 60px;

      @include m {
        top: unset;
        bottom: 0px;
        right: 32%;
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      background: $neutrals7;
      @include dark {
        background: $neutrals2;
      }
    }
  }

  &.has-padding {
    .swiper-button-prev {
      @include m {
        bottom: -16px;
      }
    }

    .swiper-button-next {
      @include m {
        bottom: -16px;
      }
    }
  }

  &.is-top-right-scretch {
    .swiper-button-next {
      right: 15%;
      top: 18%;

      @include d {
        right: 10%;
      }

      @include t {
        right: 5%;
      }

      @include m {
        right: 3%;
        top: 10%;
      }
    }
    .swiper-button-prev {
      right: 18%;
      top: 18%;
      left: unset;

      @include r(1700) {
        right: 19%;
      }

      // @include x {
      //   right: 16%;
      // }

      @include t {
        right: 12%;
      }

      @include m {
        top: 10%;
        right: 18%;
      }
    }
  }
  &.is-buttom-center-scretch {
    .swiper-button-next {
      right: 47%;
      top: 100%;

      @include d {
        right: 45%;
      }

      @include t {
        right: 40%;
      }

      @include m {
        top: 100%;
        right: 37%;
      }
    }
    .swiper-button-prev {
      right: 51%;
      top: 100%;
      left: unset;

      @include d {
        right: 52%;
      }

      @include t {
        right: 50%;
      }

      @include m {
        top: 100%;
        right: 52%;
      }
    }
  }
  &.is-top-right {
    .swiper-button-next {
      right: 0;
      top: 15px;
    }
    .swiper-button-prev {
      right: 56px;
      top: 15px;
      left: unset;
    }
  }

  &.is-buttom-center-and-top-right {
    .swiper-button-next {
      right: 0;
      top: 15px;

      @include m {
        top: 110%;
        right: 36%;
      }
    }
    .swiper-button-prev {
      right: 56px;
      top: 15px;
      left: unset;

      @include m {
        top: 110%;
        right: 53%;
      }
    }
  }

  &.is-top-right-normal {
    .swiper-button-next {
      right: 0;
      top: 3%;

      // @include d {
      //   right: 45%;
      // }

      // @include t {
      //   right: 0%;
      // }

      // @include m {
      //   right: 0;
      // }
    }
    .swiper-button-prev {
      right: 56px;
      top: 3%;
      left: unset;

      @include d {
        right: 8%;
      }

      @include t {
        right: 9%;
      }

      @include m {
        right: 18%;
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 48px;
    height: 48px;
    box-shadow: inset 0 0 0 2px $neutrals6;
    border-radius: 50px;

    @include dark {
      box-shadow: inset 0 0 0 2px $neutrals3;
    }

    &::after {
      font-size: 12px;
      color: $primary1;
      font-weight: 600;

      @include dark {
        color: $primary2;
      }
    }

    &:hover {
      box-shadow: inset 0 0 0 2px $primary3;
      color: $primary3;
    }
  }
}

.hotCollections {
  .swiper {
    position: unset;
  }
}

//style-select

.css-1nmdiq5-menu {
  border: 1px solid #e6e8ec;
  padding: 10px;

  @include dark {
    background-color: black;
    border: 1px solid #353945;
  }
}

.css-1txcbfl-Control {
  border: 2px solid #cbcccf;

  @include dark {
    border: 2px solid #353945;
    background-color: black;
  }
  &:hover {
    border: 2px solid #777e90;
  }
}

.css-1mu8kqz-MultiValueGeneric {
  padding: 3px;
  color: white;
  background-color: black;

  @include dark {
    background-color: white;
    color: black;
  }
}

.css-1i3a68c-singleValue {
  color: $neutrals4 !important;

  @include dark {
    color: $neutrals5 !important;
  }
}

.icon-arrow-next {
  @include dark {
    fill: #000 !important;
  }

  &_disabled {
    @include dark {
      fill: #777e90 !important;
    }
  }
}
