﻿.curator {
  overflow: hidden;
  // padding-bottom: 120px;
  // padding-top: 120px;

  @include x {
    // padding-bottom: 112px;
  }

  @include m {
    //  padding-bottom: 64px;
  }

  .swiper {
    padding: 5px;
  }

  &__qtext {
    /*text-align: end;*/
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include t {
      margin-bottom: 20px;
    }
  }

  &__line {
    width: 318px;
    height: 1px;
    margin-right: 71px;
    margin-top: 2px;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(177, 181, 196, 1) 100%
    );

    @include x {
      margin-right: 160px;
    }

    @include d {
      margin-right: 165px;
    }

    @include dark {
      background: linear-gradient(90deg, #141416 0%, #b1b5c4 100%);
    }

    @include t {
      display: none;
    }
  }

  &__avatar {
    position: relative;
    width: 64px;
    margin: -35px auto 16px;
    transition: width 0.2s;

    &:before {
      content: "";
      display: block;
      padding-bottom: 100%;
    }

    &__preview {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      overflow: hidden;
      border: 4px solid #fff;

      @include dark {
        border: 4px solid $neutrals1;
      }

      img,
      &__skeletoncurator {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1.05);
        -webkit-transform: scale(1.05);
        -moz-transform: scale(1.05);
        -ms-transform: scale(1.05);
        -o-transform: scale(1.05);
      }
    }

    &__reward {
      position: absolute;
      right: -4px;
      bottom: -4px;
    }
  }

  &__card {
    margin-bottom: 80px;
    align-items: center;
    text-align: center;
    padding: 0;
    min-height: 225px;
    background: $primary2;
    border: 1px solid #e6e8ec;
    border-radius: 4px;
    width: 100%;

    @include m {
      max-width: 350px;
      /*margin-left: -10px;*/
    }

    @include r(375) {
      max-width: 325px;
    }

    @include dark {
      border: 1px solid #353945;
      box-shadow: 0px 16px 24px -16px rgba(15, 15, 15, 0.1);
      background: $primary1;
    }

    &:hover {
      box-shadow: 0px 12px 60px -12px #0000001a;
      border: hidden;

      @include dark {
        border: 1px solid #353945;
        background: $neutrals1;
      }
    }

    &__preview {
      border-radius: 4px 4px 0 0;
      margin: 0;
      /*height: 140px;*/

      .curator__collected {
        display: flex;
        height: 110px;
        gap: 4px;

        .skeleton_curator,
        img,
        video {
          flex: 0 0 23%;
          overflow: hidden;
          width: 100%;
          height: 100%;
          object-fit: cover;
          flex-grow: 1;
        }

        .banner {
          object-fit: inherit !important;
          width: 100%;
        }
      }
      /*& > img {
                width: 100%;
                height: 100%;
                transition: transform 1s;
                object-fit: cover;
                animation: load_img .5s;
            }*/
    }

    &__body {
      margin-bottom: 25px;

      h4 {
        margin-top: 7px;
        display: flex;
        justify-content: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #23262f;
        @include wrap-text1;

        @include dark {
          color: $primary2;
        }
      }
    }

    &__subtitle {
      /*display: flex;*/
      line-height: 24px;
      justify-content: center;
      margin-bottom: 12px;
    }
  }

  &__name {
    margin-top: 7px;
    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #23262f;
    /*@include wrap-text1;*/

    @include dark {
      color: $primary2;
    }
  }

  &__count {
    color: $primary3;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /*@include dark {
            color: #FFFFFF;
        }*/

    span {
      margin-left: 5px;
      color: #777e91;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__container {
    width: 70% !important;
    margin-bottom: 30px;
  }

  &__slider {
    visibility: hidden;
    /*@include t {
            padding-bottom: 80px;
        }

        @include m {
            padding-bottom: 140px;
        }*/

    &.slick-initialized {
      visibility: visible;
    }
  }

  .slick-list {
    overflow: visible;
    /*height: 231px;*/

    @include s {
      margin: 0 -136px 0 0;
    }
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    height: auto;
    padding: 0 8px;
    opacity: 0;
    transition: opacity 0.4s;

    @include m {
      padding: 0 4px;
    }

    &.slick-active {
      opacity: 1;
    }
  }

  .slick-arrow {
    top: 111px;

    @include t {
      top: auto;
      bottom: 0;
    }
  }

  .slick-prev {
    left: -60px;

    @include x {
      left: -40px;
    }

    @include t {
      left: calc(50% - 56px);
    }

    @include m {
      left: calc(50% - 44px);
    }
  }

  .slick-next {
    right: -60px;

    @include x {
      right: -40px;
    }

    @include t {
      right: calc(50% - 56px);
    }

    @include m {
      right: calc(50% - 44px);
    }
  }

  .slick-dots {
    display: flex;
    justify-content: center;
    /*margin-top: 20px;*/
    /*margin-right: 21px;*/
    /*padding: 1rem 0;*/
    list-style-type: none;
    /*top: -38px;
        right: 235px;*/
    top: -51px;
    right: 235px;
    position: absolute;

    @include t {
      display: flex;
      margin-right: 0;
      margin-bottom: 40px;
      /*height: 80px;*/
      top: -40px;
    }

    li {
      /*margin: -075rem 0.25rem;*/
      margin: 0rem 0.25rem;

      @include t {
        margin: 0 0.25rem;
      }
    }

    button {
      display: block;
      width: 12px;
      height: 12px;
      padding: 0;
      border: none;
      border-radius: 100%;
      background-color: $neutrals6;
      text-indent: -9999px;

      @include dark {
        background-color: $neutrals3;
      }
    }

    li.slick-active button {
      background-color: $black;

      @include dark {
        background-color: $neutrals7;
      }
    }
  }
}
