@import "../_variables.scss";

.accept {
  &__layout {
    padding: 7px;
    width: 100%;
    height: 100vh;
    position: fixed;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    top: 0;
    z-index: 10;
  }

  &__box {
    width: 375px;
    background: $primary2;
    border: 1px solid $neutrals6;
    padding: 37px;
    min-height: 400px;
    text-align: center;
    -webkit-animation: fade-in-fwd 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in-fwd 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    box-shadow: 0 0 34px -4px #0000002e;
    z-index: 10;

    @include dark {
      background: $neutrals1;
      border: 1px solid $neutrals3;
    }

    @include m {
      width: 100%;
      min-height: 500px;
    }
  }

  &__preview {
    text-align: center;
    width: 130px;
    margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    margin-top: 27px;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
  }

  &__commitment {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    margin: 16px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 11px;

      @include dark {
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%)
          hue-rotate(263deg) brightness(100%) contrast(103%);
      }
    }
  }

  &__notice {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 27px;
  }

  &__line {
    display: flex;
    align-items: center;
    margin-top: 72px;
    margin-bottom: 32px;
    @include body-2;

    strong {
      white-space: nowrap;
      font-weight: 500;
    }
  }

  &__icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    margin-right: 16px;
    background: $primary3;
    border: 2px solid $primary3;
    border-radius: 50%;
    transition: all 0.2s;

    .icon {
      width: 24px;
      height: 24px;
      fill: $neutrals8;
      transition: fill 0.2s;
    }

    .loader {
      position: absolute;
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
      background: $neutrals8;

      @include dark {
        background: $neutrals1;
      }
    }
  }

  &__subtitle {
    margin-bottom: 16px;
    @include body-bold-1;
  }

  &__row {
    display: flex;
    padding-top: 12px;

    &:first-child {
      border-top: 1px solid $neutrals6;

      @include dark {
        border-color: $neutrals3;
      }
    }
  }

  &__col {
    &:first-child {
      margin-right: auto;
      padding-right: 16px;
      color: $neutrals4;
    }

    &:nth-child(2) {
      font-weight: 500;
    }
  }
}

/**
 * ----------------------------------------
 * animation fade-in-fwd
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
