@import "../_variables.scss";
@import "../helpers/_mixins.scss";
@import "../helpers/_helpers.scss";

.upload {
  @include x {
    padding-top: 80px;
  }

  @include m {
    padding-top: 64px;
  }

  &__center {
    display: flex;
    align-items: flex-start;

    @include t {
      display: block;
    }
  }

  &__wrapper {
    flex: 0 0 calc(100% - 352px);
    width: calc(100% - 352px);
    padding-right: 128px;

    @include x {
      padding-right: 64px;
    }

    @include d {
      flex: 0 0 calc(100% - 304px);
      width: calc(100% - 304px);
      padding-right: 32px;
    }

    @include t {
      width: 100%;
      padding: 0;
    }
  }

  &__page_title {
    @include dm-sans;
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -0.02em;
  }

  .preview {
    flex-shrink: 0;
    width: 420px;
    position: sticky;
    top: 105px;

    @include w {
      top: 60px;
    }

    @include d {
      width: 304px;
      top: 70px;
    }

    @include t {
      display: flex;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 48px;
      background: rgba($neutrals1, 0.9);
      overflow: auto;
      z-index: 999;
      visibility: hidden;
      opacity: 0;
      transition: all 0.4s;

      @include dark {
        background: rgba($neutrals2, 0.9);
      }

      &.visible {
        visibility: visible;
        opacity: 1;
      }
    }

    @include m {
      padding: 32px 16px;
    }
  }

  &__Royalty {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__more {
    margin-top: 32px;
    display: flex;
    padding: 24px;
    flex-direction: column;
    gap: 24px;
    border: 1px solid $neutrals6;
    transition: all 0.3s ease;

    @include dark {
      border: 1px solid $neutrals3;
    }

    &_top {
      display: flex;
      align-items: center;
      width: 100%;
    }

    &_title {
      font-size: 16px;
      @include main-font;
      font-weight: 500;
      line-height: 24px;
      margin-right: auto;

      .optional {
        font-size: 14px;
        color: $neutrals4;
      }
    }

    &_fields {
      display: none;

      &.active {
        display: block;
      }
    }
  }

  &__sensitive {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: rgba($primary1, 0.5);
    backdrop-filter: blur(10.5px);
    top: 0;
    left: 0;
    right: 0;

    &_image {
      margin-bottom: 25px;
    }

    &_title {
      color: $primary2;
      @include button-2;
    }

    &_ditale {
      color: $primary2;
      @include dm-sans;
      font-weight: 400;
      font-size: 11px;
      line-height: 16px;
      width: 67%;
      text-align: center;
    }
  }

  &__box {
    background: #ffffff;
    box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    padding: 24px;

    @include dark {
      background: black;
    }

    &:not(:first-child) {
      margin-top: 30px;
    }

    &_head_collection {
      margin-bottom: 10px !important;
    }

    &__head {
      display: flex;
      align-items: baseline;
      margin-bottom: 30px;

      .upload__box__title {
        margin-right: auto;
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
      }

      .upload__boxes__title {
        margin-right: auto;
        font-weight: 500;
        font-size: 32px;
        line-height: 40px;
      }
    }
  }

  .promote__want {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .copyright__nav {
    display: flex;
    justify-content: center;
    border: 1px solid $neutrals6;
    background: $neutrals8;
    padding: 8px;
    color: $neutrals4;

    @include dark {
      border: 1px solid $neutrals3;
      background: $neutrals1;
    }

    @include m {
      flex-direction: column;
    }
    &__link {
      padding: 14px 16px;
      border-radius: 5px;
      background: none;
      font-size: 15px;
      line-height: 1;
      font-weight: 600;
      color: $neutrals4;
      transition: all 0.2s;
      cursor: pointer;
      flex: 0 0 50%;
      text-align: center;

      &:hover {
        color: $neutrals3;

        @include dark {
          color: $neutrals6;
        }
      }

      &.active {
        background: black;
        color: $neutrals8 !important;

        @include dark {
          background: white;
          color: $neutrals1 !important;
        }
      }

      false {
        @include d {
          display: none;
        }
      }
    }
  }

  &__head {
    display: flex;
    margin-bottom: 40px;

    @include d {
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    @include m {
      margin-bottom: 32px;
    }

    .upload__button {
      flex-shrink: 0;
      margin-left: 24px;

      @include d {
        margin: 0 0 16px;
      }
    }
  }

  &__title {
    margin-right: auto;
  }

  &__list {
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid $neutrals6;

    @include m {
      margin-bottom: 32px;
      padding-bottom: 32px;
    }

    @include dark {
      border-color: $neutrals3;
    }
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 40px;

      @include m {
        margin-bottom: 32px;
      }
    }
  }

  &__note {
    margin-top: 4px;
    @include caption-2;
    color: $neutrals4;

    &__recommended {
      // margin-top: 4px;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      @include poppins;
      color: $primary3;
      align-items: center;
      display: flex;
    }
  }

  &__file {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 182px;
    margin-top: 16px;
    border-radius: 0px;
    overflow: hidden;
    background: $neutrals7;

    @include dark {
      background: $neutrals2;
    }

    .file-control {
      position: absolute;
      top: 56%;
    }
  }

  &__file2 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 130px;
    margin-top: 16px;
    border-radius: 0px;
    overflow: hidden;
    background: $neutrals7;
    padding: 32px;

    @include m {
      padding: 22px;
      text-align: center;
    }

    @include dark {
      background: $neutrals2;
    }

    .file-control {
      display: flex;
      align-items: center;
      gap: 8px;
      flex-grow: 1;
      justify-content: center;

      @include t {
        top: 23%;
        left: 49px;

        .file_name-selected {
          @include wrap-text;
          max-width: 245px;
        }
      }
      @include m {
        position: unset;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: space-between;

        .file_name-selected {
          @include wrap-text;
          max-width: 245px;
          flex: 50% 0 0;
        }
        .delete-btn {
          flex: 50% 0 0;
          position: unset;
          margin-top: unset;
        }
      }
    }

    @include m {
      display: block;
    }
  }

  &_fieldMargin {
    margin-top: 16px;
  }

  &__textInfo {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-grow: 1;
    justify-content: center;
  }

  &__input2 {
    top: 0;
    left: 0;
    font-size: 400px;
    opacity: 0;
    cursor: pointer;
  }

  &__input {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 178px;
    opacity: 0;
    cursor: pointer;
    width: 127px;
    height: 50px;
  }

  &__icon {
    margin-bottom: 10px;
    position: absolute;
    top: 20%;

    .icon {
      width: 24px;
      height: 24px;
      fill: $neutrals4;
    }
  }

  &__icon2 {
    top: 40%;
    left: 10%;

    .icon {
      width: 24px;
      height: 24px;
      fill: $neutrals4;
    }
  }

  &__format {
    @include caption-2;
    color: $neutrals4;
    position: absolute;
    top: 40%;
  }

  &__format2 {
    @include caption-2;
    color: $neutrals4;
    top: 42%;
    left: 18%;
  }

  &__btn {
    color: $neutrals8;
    border-radius: 5px;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    @include dm-sans;
    position: relative;

    &:hover {
      background: $primary3;
      box-shadow: unset;
    }

    // &.gray {
    //   background: $neutrals4;
    // }

    @include m {
      margin-top: 20px;
    }

    @include dark {
      color: $neutrals1;

      // &.gray {
      //   background: $neutrals4;
      //   color: $primary2;
      // }
    }
  }

  &__category {
    @include body-bold-2;
    display: flex;
    gap: 7px;
  }

  &__fieldset {
    margin-top: 32px;

    .field {
      &:not(:last-child) {
        margin-bottom: 32px;

        @include m {
          margin-bottom: 20px;
        }
      }
    }
  }

  &__row {
    display: flex;
    margin: 0 -10px;

    @include m {
      display: block;
      margin: 0;
    }
  }

  &__col {
    flex: 0 0 calc(33.333% - 20px);
    width: calc(33.333% - 20px);
    margin: 0 10px;

    @include m {
      width: 100%;
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  &__col_50 {
    flex: 0 0 calc(50% - 20px);
    width: calc(33.333% - 20px);
    margin: 5px 10px;

    @include m {
      width: 100%;
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  &__col_100 {
    flex: 0 0 calc(100% - 20px);
    width: calc(100% - 20px);
    margin: 5px 10px;

    @include m {
      width: 100%;
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  &__options {
    margin-bottom: 40px;

    @include m {
      margin-bottom: 32px;
    }
  }

  &__option {
    display: flex;
    align-items: flex-start;

    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }
  /*&__box {
        flex-grow: 1;
    }*/

  .switch {
    flex-shrink: 0;
    margin-left: 24px;
  }

  &__text {
    margin-top: 4px;
    @include caption-2;
    color: $neutrals4;
  }

  &__cards {
    display: flex;
    margin: 24px -4px 0;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;

    @include m {
      margin: 24px -32px 0;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    @include m {
      &:before,
      &:after {
        content: "";
        flex-shrink: 0;
        width: 32px;
        height: 1px;
      }
    }
  }

  &__card {
    flex-shrink: 0;
    width: 160px;
    margin: 0 4px;
    padding: 24px;
    border-radius: 16px;
    background: $neutrals7;
    @include button-2;
    cursor: pointer;

    @include m {
      margin: 0;

      &:not(:last-child) {
        margin-right: 8px;
      }
    }

    @include dark {
      background: $neutrals2;
    }

    &:hover {
      .upload__plus {
        .icon {
          opacity: 1;
        }
      }
    }
  }

  &__plus {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-bottom: 12px;

    .icon {
      width: 24px;
      height: 24px;
      fill: $neutrals8;
      opacity: 0;
      transition: opacity 0.2s;
    }
  }

  &__foot {
    display: flex;
    align-items: center;

    @include m {
      display: block;
      text-align: center;
    }

    &_button-sticky-back {
      background-color: #fcfcfd !important;
      padding: 15px 0px;
      position: sticky;
      bottom: 0;

      @include dark {
        background-color: #141416 !important;
      }
    }

    .upload__button {
      .icon {
        width: 10px;
        height: 10px;
      }

      &:hover {
        svg {
          fill: #fff !important;
        }
      }

      @include m {
        width: 100%;
      }

      &:not(:last-child) {
        margin-right: 24px;

        @include m {
          margin: 0 0 12px;
        }
      }
    }
  }

  &__saving {
    display: inline-flex;
    align-items: center;
    margin-left: auto;
    @include button-1;
  }

  .loader {
    margin-left: 24px;
  }

  .remove-align {
    @include t {
      display: flex;
      align-items: center;
    }
  }

  #group {
    @include m {
      margin-top: 10px;
    }
  }

  &__promotion__notice {
    display: flex;
    gap: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    .symbol {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $primary3;
      width: 20px;
      height: 20px;
      border-radius: 25px;
      color: $primary2;
      font-size: 13px;
    }
  }

  .control-label {
    margin: 20px 0;
  }

  &__nsfw {
    display: flex;
    margin-top: 10px;
    flex-direction: column;
    gap: 24px;

    &_text {
      color: $neutrals1;
      @include hairline-2;
      display: flex;
      align-items: center;
      margin-top: 32px;

      @include dark {
        color: $neutrals8;
      }

      span {
        color: red;
        padding-right: 4px;
      }

      @include m {
        display: block;
        line-height: unset;
      }
    }

    &_btns {
      display: flex;
      gap: 10px;

      input,
      label {
        text-align: center;
        align-items: center;
        justify-content: center;
        display: flex;
        cursor: pointer;
        width: 110px;
        height: 44px;
        border-radius: 4px;
        border: 2px solid $neutrals6;
      }

      label {
        display: flex !important;
        gap: 15px;

        img {
          width: 14px;
          height: 14px;
          @include filter-neutrals4;
        }
      }

      input[type="radio"] {
        display: none;
      }

      input[type="radio"]:checked + label {
        background: $neutrals1;
        color: $neutrals8;
        border: 2px solid $neutrals1;

        @include dark {
          background: $neutrals8;
          color: $neutrals1;
          border: 2px solid $neutrals8;

          img {
            @include filter-primary1;
          }
        }
        img {
          @include filter-primary2;
        }
      }

      input[type="radio"]:hover + label {
        background: $neutrals1;
        color: $neutrals8;
        border: 2px solid $neutrals1;

        @include dark {
          background: $neutrals8;
          color: $neutrals1;
          border: 2px solid $neutrals8;

          img {
            @include filter-primary1;
          }
        }
        img {
          @include filter-primary2;
        }
      }
    }

    &_desNo {
      display: none;
      @include main-font;
      font-weight: 700;
      font-size: 12px;
      line-height: 24px;
      color: $primary1;

      span {
        color: $primary3;
      }

      @include dark {
        color: $primary2;
      }
    }

    &_desYes {
      display: none;
      @include main-font;
      font-weight: 700;
      font-size: 12px;
      line-height: 24px;
      color: $primary1;

      span {
        color: $primary3;
      }

      @include dark {
        color: $primary2;
      }
    }

    &_lable {
      display: flex;
      gap: 15px;

      img {
        width: 14px;
        height: 14px;
        @include filter-neutrals4;
      }
    }
  }

  &__ai {
    margin-top: 10px;

    &_loader {
      .lds-spinner {
        display: none;

        &.active {
          display: block;
        }

        div:after {
          background: $primary1;

          @include dark {
            background: $primary2;
          }
        }
      }
    }

    &_headsection {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &_header {
      @include hairline-2;
      color: $primary1;
      display: flex;
      align-items: center;
      gap: 12px;
      padding-bottom: 10px;

      @include dark {
        color: $primary2;

        img {
          @include filter-neutrals6;
        }
      }
      @include m {
        line-height: unset;
      }
    }

    &_story {
      @include caption-bold-1;
      color: $neutrals4;
      padding-top: 10px;
      width: 100%;
      background: $primary2;
      border: none;
      height: 133px;
      resize: none;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;

      &::placeholder {
        color: $neutrals4;
      }

      @include dark {
        background: $neutrals1;
      }
    }

    &_btns {
      display: flex;
      gap: 10px;
      align-items: center;
      padding-top: 10px;

      @include m {
        flex-direction: column;
      }
    }

    &_btn {
      display: flex;
      align-content: center;
      justify-content: center;
      padding: 10px;
      border: 2px solid $neutrals6;
      border-radius: 4px;
      gap: 15px;
      color: $neutrals4;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;

      img {
        @include filter-neutrals4;
      }

      &:active {
        background: $neutrals1;
        color: $neutrals8;

        img {
          @include filter-primary2;
        }
      }

      @include dark {
        border: 2px solid $neutrals3;

        &:active {
          background: $neutrals8;
          color: $neutrals1;

          img {
            @include filter-primary1;
          }
        }
      }

      @include m {
        width: 100%;
      }

      &.disabled {
        color: $neutrals6;
        border: 2px solid $neutrals7;

        @include dark {
          color: $neutrals3;
          border: 2px solid $neutrals2;
        }

        img {
          @include filter-neutrals6;

          @include dark {
            @include filter-neutrals3;
          }
        }
      }
    }

    .section {
      display: inline-table;

      @include m {
        display: inline-block;
        overflow: auto;
        height: auto;
      }
    }
  }

  &__ownercol {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    max-width: 94%;
    @include t {
      display: grid;
    }

    &_title {
      color: $neutrals5 !important;
      font-weight: 800 !important;
    }

    &_title2 {
      font-weight: 800 !important;
    }

    &_ownership-mobile-precent {
      padding: 0px;

      @include t {
        padding: 0px 15px;
      }
    }

    &_address {
      max-width: 67%;
      position: relative;
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      @include m {
        max-width: 100%;
      }
    }

    &_percentage {
      max-width: 32%;
      width: 100%;
    }

    &_percentage-ownership {
      max-width: 31%;
      width: 100%;
      @include t {
        max-width: 43%;
        padding-left: 15px;
        padding-right: 15px;
      }

      @include m {
        max-width: 100%;
        width: 240px;
      }
    }
  }
  &__condition {
    margin-top: 1.5rem;
  }
  &__firstowner {
    &_address {
      padding-left: 0;
      max-width: 67.5%;
      @include m {
        max-width: 99%;
        padding: 0;
      }
    }
    &_percentage {
      max-width: 32%;

      @include t {
        max-width: 32%;
      }
      @include m {
        width: 150px;
        max-width: 100%;
        padding: 0;
      }
    }
  }
  &__ownercol {
    max-width: 94%;
  }

  &__ownership-precent {
    color: $pink !important;
    margin-right: 0px;

    @include t {
      margin-right: 20px;
    }
  }

  // &__typing-effect {
  // }
  .cursor {
    width: 10px;
    background-color: transparent;
  }
  .cursor.show {
    background-color: black; /* رنگ خط تایپ */
    animation: blink 0.7s steps(1) infinite;
  }
  @keyframes blink {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
}

.promote {
  &__box {
    border: 1px solid $neutrals6;
    padding: 30px;

    @include dark {
      border: 1px solid $neutrals3;
    }

    @include m {
      padding: 20px;
    }

    &:nth-child(3) {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }

  &__package_list {
    margin-top: 16px;
  }

  &__description {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: $neutrals3;
    margin-bottom: 15px;
    display: flex;
    gap: 8px;

    @include dark {
      color: $neutrals6;
    }

    @include m {
      line-height: 28px;
    }
  }

  &__divider {
    border-top: 1px dashed $neutrals6;
    margin-top: 28px;
    margin-bottom: 50px;
    border-bottom: 0px;

    @include dark {
      border-top: 1px dashed $neutrals3;
    }
  }

  &__label {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;

    b {
      color: $primary3;
    }
  }

  &__note {
    display: flex;
    gap: 4px;
    margin-bottom: 30px;

    .note_word {
      color: $primary3;
      font-weight: 600;
    }
  }

  &__recommended {
    padding: 4px 16px;
    background: rgba(6, 92, 49, 0.06);
    border-radius: 40px;
    color: #065c31;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.slected-image {
  width: 80px;
  height: 60px;
  object-fit: cover;
  border-radius: 10px;
}

.no-border {
  border: 0;
}

.Typewriter {
  @include caption-bold-1;
  color: $neutrals4;
  width: 100%;
  background: $primary2;
  border: none;
  height: 133px;
  resize: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  max-height: 200px;
  overflow: auto;

  &::placeholder {
    color: $neutrals4;
  }

  @include dark {
    background: $neutrals1;
  }
}

#PhotographyTypesSearchId {
  margin-top: -3px !important;
}
