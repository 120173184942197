﻿.carousel {
  &__listings {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: 50px 0 0;
    /*&:hover {
            #infinite .highwaySlider .highwaySlider__lane .highwaySlider__car {
                animation-play-state: paused !important;
            }
        }*/

    &_inner {
      display: flex;
      justify-content: center;
      width: 100%;
      // height: 400px;
    }

    .highwaySlider {
      position: relative;
      width: 100%;
      overflow-x: hidden;
      /*:root {
                --QTY: 0px;
            }*/
    }
  }
}

/*@keyframes translateinfinite {

    100% {
        transform: translateX(var(--QTY));
    }
}*/

#infinite .highwaySlider {
  .highwaySlider__lane {
    /*width: (var(--QTY));*/
    margin-left: -50px;
    display: flex;
    // height: 100%;
    will-change: transform;
    /*transition: transform .2s linear;*/
    /*.highwaySlider__car {
            // margin: 0 10px;
            // width: 416px;*/
    /*animation: translateinfinite 100s linear infinite;*/
    /*&_revers {
                animation-direction: reverse !important;
            }
        }*/
  }
}
