@import "../variables";

.description {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 800px;
  padding: 80px 0;
  border-top: 1px solid $neutrals6;

  @include d {
    min-height: 576px;
  }

  @include m {
    display: block;
    min-height: auto;
    padding: 64px 0;
    text-align: center;
  }

  @include dark {
    border-color: $neutrals3;
  }

  &__wrap {
    position: relative;
    z-index: 3;
    max-width: 480px;
    margin-left: 70px;
    margin-top: 80px;

    @include d {
      max-width: 420px;
      margin-left: 45px;
      margin-top: 15px;
    }

    @include m {
      max-width: 100%;
      margin-bottom: 40px;
      margin-left: 0;
    }
  }

  &__stage {
    margin-bottom: 12px;
    @include hairline-1;
    color: $neutrals4;
  }

  &__title {
    margin-bottom: 20px;

    @include m {
      font-size: 48px;
    }
  }

  &__text {
    margin-bottom: 40px;
    @include body-2;
    color: $neutrals4;
  }

  &__btns {
    display: flex;

    @include m {
      display: block;
    }
  }

  &__button {
    @include m {
      width: 100%;
    }

    &:not(:last-child) {
      margin-right: 16px;

      @include m {
        margin: 0 0 16px;
      }
    }
  }

  &__gallery {
    position: relative;
    /* top: 50%;
        right: calc(50% - 560px);*/
    width: 500px;
    /*transform: translateY(-50%);*/
    pointer-events: none;
    /*transform: scaleX(-1);*/

    @include x {
      /*right: calc(50% - 520px);*/
      width: 550px;
    }

    @include d {
      /*right: calc(50% - 440px);*/
      width: 390px;
    }

    @include m {
      position: static;
      transform: translateY(0);
    }

    @include s {
      width: auto;
      margin: 0 0 0 -22px;
    }
  }

  &__preview {
    &:first-child {
      position: relative;
      z-index: 2;
    }

    &:nth-child(2) {
      right: -11%;
      bottom: -3.5%;
      z-index: 3;
      width: 47%;
    }

    &:not(:first-child) {
      position: absolute;
    }

    img {
      width: 100%;
    }
  }
  //download now
  &__download {
    display: flex;
    flex-direction: column;

    &_header {
      display: flex;
      flex-direction: row;
      @include hairline-3;
      color: $neutrals1;
      gap: 37px;
      font-size: 40px;

      @include dark {
        color: $neutrals8;
      }
    }

    &_title {
      @include body-1;
      color: $neutrals1;
      margin-bottom: 38px;

      @include dark {
        color: $neutrals8;
      }
    }

    &_section {
      // padding: 67px 159px 65px;
      position: relative;
      display: flex;

      @include t {
        flex-direction: column-reverse;
      }

      @include m {
        padding: 0px;
      }
    }

    &_box {
      background-color: $primary1;
      padding: 67px 0 67px 0;
    }

    &_details {
      display: flex;
      flex-direction: column;
      gap: 48px;
    }

    &_detail {
      display: flex;

      @include m {
        flex-direction: column;
        gap: 50px;
        // padding: 41px;
        width: 100%;
        padding: 24px 13px 0px;
      }
    }

    &_description {
      display: flex;
      flex-direction: column;
      gap: 20px;
      border-left: 1px solid rgba($neutrals8, 0.4);
      padding-right: 20px;

      .heading {
        font-weight: 700;
        font-size: 20px;
        line-height: 150%;
        color: $neutrals8;
        border-left: 2px solid $primary3;
        padding-left: 16px;
      }

      .Content {
        @include dm-sans;
        font-weight: 400;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: 0.3px;
        color: $neutrals8;
        padding-left: 16px;
        max-width: 340px;
      }
    }

    &_btns {
      display: flex;
      margin-top: 48px;
      align-items: center;
      gap: 10px;

      @include t {
        justify-content: center;
      }

      @include m {
        flex-direction: column;
        gap: 20px;
        padding-bottom: 20px;
      }
    }

    &_btn {
      // display: flex;
      @include poppins;
      gap: 9px;
      padding: 14px 20px;
      background-color: $primary2;
      color: $primary1;
      @include body-bold-2;
      display: flex;
      align-items: center;

      &:hover {
        color: $primary2;
        background-color: $primary3;

        img {
          &.dark-filter {
            @include filter-primary2;
          }
        }
      }

      img {
        width: 30px;
        &.dark-filter {
          @include filter-primary1;
        }
      }
    }

    &_apple {
      gap: 9px;
      padding: 9px 53px;
      background-color: $primary2;
      color: $neutrals1;
      @include body-bold-2;
      display: flex;
      align-items: center;
      cursor: pointer;

      @include dark {
        color: $neutrals1;
      }
    }

    &_photo {
      position: relative;
      margin-right: 292px;
      margin-left: auto;

      @include t {
        margin: 0;
        display: flex;
        justify-content: center;
      }

      img {
        position: absolute;
        top: -245px;
        width: 355px;
        height: 500px;
        object-fit: contain;

        @include t {
          position: unset;
        }
      }
    }
  }

  &__popup {
    max-width: 464px;
    height: 335px;
    background: $primary2;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 62px;
    position: relative;
    justify-content: center;

    @include dark {
      background: $primary1;
    }

    .mfp-close {
      width: 40px;
      height: 40px;
      fill: $neutrals6;
      cursor: pointer;
      border: 2px solid $neutrals6;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: border-color 0.2s;

      @include dark {
        border: 2px solid $neutrals5;
        color: $neutrals6;
      }
    }

    &_title {
      color: $neutrals1;
      @include body-bold-1;
      padding-left: 16px;

      @include dark {
        color: $neutrals8;
      }
    }

    &_guide {
      list-style-type: decimal;
      padding-left: 37px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin: 50px 0;

      li {
        @include body-2;
        color: $neutrals1;

        @include dark {
          color: $neutrals8;

          img {
            @include filter-neutrals6;
            width: 27px;
            height: 27px;
          }
        }
      }
    }
  }

  &__join {
    width: 713.01px;

    @include d {
      width: 400px;
    }

    @include t {
      width: 400px;
      margin: auto;
    }

    @include m {
      width: 308px;
      margin: auto;
    }

    &_box {
      border-top: 1px solid;
      border-bottom: 1px solid;
      border-image-source: linear-gradient(to left, #8e793e, #eacb76);
      border-image-slice: 1;
    }

    &_section {
      padding: 141px 80px;
      display: flex;
      justify-content: space-between;

      @include t {
        display: block;
        padding: 40px 30px;
        text-align: center;
      }

      @include m {
        display: block;
        padding: 40px 30px;
      }
    }

    &_marketplace {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      color: $neutrals4;
      text-transform: uppercase;

      img {
        width: 24px;
        margin-right: 8px;
        filter: brightness(0) saturate(100%) invert(58%) sepia(3%)
          saturate(1985%) hue-rotate(186deg) brightness(84%) contrast(89%);
      }

      @include t {
        justify-content: center;
      }
    }

    &_title {
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 40px;
      height: 48px;
      margin: 15px 0 20px;
      text-transform: capitalize;
      @include dm-sans;

      @include d {
        width: 400px;
        height: 80px;
        font-size: 40px;
      }

      @include t {
        width: 308px;
        height: 80px;
        font-size: 30px;
        margin: 15px auto;
      }

      @include s {
        width: 308px;
        height: 80px;
        font-size: 30px;
      }
    }

    &_content {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      width: 713.01px;

      @include d {
        width: 400px;
      }

      @include t {
        width: 400px;
        margin: auto;
      }

      @include m {
        width: 308px;
        margin: auto;
      }

      @include s {
        width: 308px;
      }
    }

    &_btns {
      display: flex;
      margin-top: 40px;
      @include poppins;

      @include t {
        margin: 40px 0;
        justify-content: center;
      }

      @include s {
        margin: 40px 0;
      }
    }

    &_btn {
      background: $primary3;
      height: 48px;
      display: flex;
      gap: 8px;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px 24px;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      color: #fcfcfd !important;
      @include poppins;
    }

    &_gallery {
      //  margin: auto 0 auto auto;
      width: 300px;

      @include t {
        margin: 0 auto;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

// .featurein {
//   margin: 60px auto;
//   padding: 0;

//   &__header {
//     justify-content: center;
//     text-align: center;
//     font-style: normal;
//     font-weight: 700;
//     font-size: 40px;
//     line-height: 48px;
//     color: #23262f;
//     margin-bottom: 80px;

//     @include dark {
//       color: #ffffff;
//     }

//     @include d {
//       margin-bottom: 40px;
//     }

//     img {
//       width: 30px;
//       margin-right: 20px;

//       &:last-child {
//         margin-left: 20px;
//       }

//       @include dark {
//         filter: brightness(0) saturate(100%) invert(100%) sepia(84%)
//           saturate(1%) hue-rotate(167deg) brightness(104%) contrast(101%);
//       }
//     }
//   }

//   &__container {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     /*gap: 20px;*/

//     @include d {
//       display: flex;
//       flex-wrap: wrap;
//       justify-content: center;
//       align-items: center;
//     }
//   }

//   &__item {
//     margin: auto;
//     flex: 0 0 20%;
//     max-width: 20%;
//     padding: 10px;

//     @include dark {
//       color: #777e90;
//     }

//     @include d {
//       margin-bottom: 20px;
//       justify-content: center;
//       text-align: center;
//     }

//     @include t {
//       flex: 0 0 65%;
//       max-width: 65%;

//       &:not(:nth-child(3)) {
//         flex: 0 0 50%;
//         max-width: 50%;
//       }
//     }

//     img {
//       /*filter: brightness(0) saturate(100%) invert(86%) sepia(19%) saturate(153%) hue-rotate(189deg) brightness(83%) contrast(87%);*/
//       width: 100%;
//       filter: grayscale(100%);
//       opacity: 0.6;
//       transition: opacity 0.3s;

//       &:hover {
//         filter: grayscale(0%);
//         opacity: 1;
//         /*filter: brightness(0) saturate(100%) invert(8%) sepia(6%) saturate(522%) hue-rotate(201deg) brightness(94%) contrast(98%);*/
//       }
//       /*@include dark {
//                     &:hover {
//                         filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(17deg) brightness(102%) contrast(104%);
//                     }
//                 }*/
//     }
//   }
// }
.featurein {
  max-width: 100%;
  margin: 60px auto;
  padding: 0;

  &_description {
    display: flex;
    flex-direction: column;
    gap: 20px;
    /*        border-left: 1px solid rgb( $neutrals5, 0.4);
*/
    border-left: 1px solid rgb($neutrals5, 0.4);
    padding-right: 20px;
    flex: 0 0 25%;
    max-width: 25%;
    margin-bottom: 68px;

    @include d {
      margin-bottom: 20px;
      justify-content: center;
      //  text-align: center;
    }

    @include t {
      flex: 0 0 50%;
      max-width: 50%;
    }

    @include m {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .heading {
      font-weight: 700;
      font-size: 20px;
      //   line-height: 150%;
      color: $neutrals8;
      border-left: 2px solid $primary3;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: start;

      img {
        // filter: grayscale(100%) brightness(50%) saturate(100%);
        @include filter-neutrals4;
        // width: 50%;
        height: 100%;
      }
    }

    .Body {
      @include dm-sans;
      font-weight: 400;
      //   font-size: 16px;
      // line-height: 160%;
      letter-spacing: 0.3px;
      color: $neutrals4;
      padding-left: 14px;
      max-width: 340px;
      line-height: 26px;
      text-align: left;
    }

    &:hover {
      img {
        filter: grayscale(100%) brightness(0%) saturate(100%);

        @include dark {
          filter: brightness(0) invert(1);
        }
      }

      .Body {
        color: $neutrals1;

        @include dark {
          color: $neutrals6;
        }
      }
    }
  }

  &__header {
    justify-content: center;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: #23262f;
    margin-bottom: 80px;

    .burst {
      display: block;
    }

    &.double_burst {
      display: flex;

      .burst img {
        margin: 0 15px;
      }

      .hot__title {
        justify-content: center;
        @include m {
          max-width: 80%;
        }
      }
    }

    @include dark {
      color: #ffffff;
    }

    @include d {
      margin-bottom: 40px;
    }
    /*        img {
          width: 30px;
          margin-right: 20px;

          &:last-child {
              margin-left: 20px;
          }

          @include dark {
              filter: brightness(0) saturate(100%) invert(100%) sepia(84%) saturate(1%) hue-rotate(167deg) brightness(104%) contrast(101%);
          }
      }*/
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    /*gap: 20px;*/
    flex-wrap: wrap;

    @include d {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }
  }

  &__item {
    // margin: auto;
    flex: 0 0 20%;
    max-width: 20%;
    padding: 10px;

    @include dark {
      color: #777e90;
    }

    @include d {
      margin-bottom: 20px;
      justify-content: center;
      text-align: center;
    }

    @include t {
      flex: 0 0 65%;
      max-width: 65%;

      &:not(:nth-child(3)) {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
    /*img {*/
    /*filter: brightness(0) saturate(100%) invert(86%) sepia(19%) saturate(153%) hue-rotate(189deg) brightness(83%) contrast(87%);*/
    /*width: 100%;
          filter: grayscale(100%);
          opacity: 0.6;
          transition: opacity .3s;

          &:hover {
              filter: grayscale(0%);
              opacity: 1;*/
    /*filter: brightness(0) saturate(100%) invert(8%) sepia(6%) saturate(522%) hue-rotate(201deg) brightness(94%) contrast(98%);*/
    /*}*/
    /*@include dark {
                  &:hover {
                      filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(17deg) brightness(102%) contrast(104%);
                  }
              }*/
    /*}*/
  }
}

/*#visibelwhite {

    @include dark {
        display: none;
    }
}

#visibeldark {
    display: none;

    @include dark {
        display: inline;
    }
}
*/

.marketplace {
  display: flex;
  align-items: center;
  min-height: 322px;
  padding: 0;
  margin-bottom: -90px;
  /*border: 3px solid;
    border-image-source: linear-gradient(to bottom, #8E793E, rgba(142, 121, 62, 0));
    border-image-slice: 1;
    margin-bottom: -50px;*/
  z-index: 2;

  @include t {
    margin-bottom: 0;
  }

  @include m {
    height: 684px;
    margin-bottom: 0;
  }

  @include s {
    height: 684px;
  }

  &__center {
    display: flex;
    padding: 40px 80px;
    width: 100%;
    border: 3px solid;
    border-image-source: linear-gradient(
      to bottom,
      #8e793e,
      rgba(142, 121, 62, 0)
    );
    border-image-slice: 1;
    align-items: center;
    background: $primary2;

    @include dark {
      background: $neutrals1;
    }

    @include t {
      display: block;
      padding: 40px 30px;
      text-align: center;
    }

    @include s {
      display: block;
      padding: 40px 30px;
    }
  }

  &__stage {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: $neutrals4;
    text-transform: uppercase;

    img {
      width: 24px;
      margin-right: 8px;
      filter: brightness(0) saturate(100%) invert(58%) sepia(3%) saturate(1985%)
        hue-rotate(186deg) brightness(84%) contrast(89%);
    }

    @include t {
      justify-content: center;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;
    /*width: 713.01px;*/
    margin: 10px 0 20px;
    text-transform: capitalize;

    @include d {
      width: 400px;
      height: 80px;
      font-size: 40px;
    }
    /*@include t {
            width: 400px;
            height: 80px;
            font-size: 30px;
            margin: 15px auto;
        }*/

    @include t {
      width: 308px;
      height: 80px;
      font-size: 30px;
      margin: 15px auto;
    }

    @include s {
      width: 308px;
      height: 80px;
      font-size: 30px;
    }
  }

  &__description {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    width: 713.01px;
    /*height: 56px;*/

    @include d {
      width: 400px;
      /*height: 80px;*/
    }

    @include t {
      width: 400px;
      /*height: 96px;*/
      margin: auto;
    }

    @include m {
      width: 308px;
      /*height: 96px;*/
      margin: auto;
    }

    @include s {
      width: 100%;
      /*height: 96px;*/
    }
  }

  &__btns {
    display: flex;
    flex-direction: row;
    /*padding-top: 16px;*/
    gap: 12px;
    margin-top: 40px;

    @include t {
      margin: 40px 0;
      justify-content: center;
    }

    @include s {
      margin: 40px 0;
    }
  }

  &__leftbtn {
    background: $primary1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    width: 103px;
    height: 48px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #fcfcfd !important;

    @include dark {
      background: $primary2 !important;
      color: $primary1 !important;
    }
  }

  &__rightbtn {
    background: $primary3;
    width: 107px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #fcfcfd !important;
  }

  &__gallery {
    margin: auto 0 auto auto;
    width: 300px;

    @include t {
      margin: 0 auto;
    }

    img {
      width: 100%;
      height: 100%;

      @include s {
        width: 80%;
        height: 80%;
      }
    }
    // width: 100%;
    /* @include d {
            margin: auto 70px;
        }

        @include m {
            margin-left: 40px;
        }

        @include s {
            margin-left: 40px;
        }*/
  }
}

.text-Certificate-nft {
  @include poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: right;
  color: #8e793e;
  padding-left: 13px;
  cursor: pointer;

  @include m {
    padding-left: 0px;
  }
}

.Certificate-title {
  display: flex;
  flex-direction: row;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  align-items: center;

  @include m {
    font-size: 30px;
  }
}
