.guideline {
  width: 100%;

  &__padding {
    padding: 0px 200px;
    @include t {
      padding: 0px 150px;
    }
    @include m {
      padding: 0px 30px;
    }
  }

  &__flex {
    display: flex;
    justify-content: center;
  }

  &__titlehead {
    margin-top: 80px;
    @include poppins;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    color: $neutrals4;
    text-align: center;
    margin-bottom: 8px;
  }

  &__title {
    @include dm-sans;
    font-size: 64px;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: -0.02em;
    text-align: center;
    margin-bottom: 50px;

    @include t {
      font-size: 40px;
    }

    @include m {
      font-size: 30px;
      line-height: 40px;
    }
  }

  &__text {
    @include poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: $neutrals4;
    max-width: 1148px;
    padding: 0px 15px;

    @include m {
      font-size: 14px;
    }
  }

  &__text2 {
    @include poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
    margin-bottom: 5px;
    margin-left: 5px;

    @include m {
      font-size: 18px;
    }
  }

  &__text-center {
    text-align: center;
  }

  &__text3 {
    max-width: 954px;
    @include poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: $neutrals4;
    margin-bottom: 50px;

    @include m {
      font-size: 14px;
    }
  }

  &__box {
    background: $primary2;
    padding: 50px;
    border-radius: 10px;
    margin-bottom: 100px;
    box-shadow: 0px 64px 64px -48px #1f2f461f;

    @include dark {
      background: $primary1;
      box-shadow: 0px 64px 64px -48px rgb(255 255 255 / 6%);
    }
  }

  &__text-box {
    display: flex;
    justify-content: center;
    @include poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    padding: 30px 0px;
    color: $neutrals3;
    @include dark {
      color: $neutrals5;
    }
  }

  &__image {
    margin-bottom: 40px;
  }
}
