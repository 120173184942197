.result {
  overflow: hidden;

  @include m {
    padding-top: 48px;
  }

  &__preview {
    margin-bottom: 96px;

    @include d {
      margin: 0 -64px 90px;
    }

    @include t {
      margin-bottom: 64px;
    }

    @include m {
      margin: 0 -160px 32px;
    }

    img {
      width: 100%;
    }
  }

  &__wrap {
    max-width: 736px;
    margin: 0 auto 96px;
    text-align: center;

    @include m {
      margin-bottom: 64px;
    }
  }

  &__title {
    margin-bottom: 8px;
  }

  &__info {
    margin-bottom: 24px;
    color: $neutrals4;
  }

  .subscription {
    max-width: 300px;
    margin: 0 auto;

    @include m {
      max-width: 100%;
    }
  }

  &__subtitle {
    margin-bottom: 48px;
    text-align: center;
    @include body-bold-1;
  }

  &__list {
    display: flex;
    margin: 0 -16px;

    @include d {
      margin: 0 -80px;
      overflow: auto;
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        display: none;
      }

      &:before,
      &:after {
        content: "";
        flex-shrink: 0;
        width: 80px;
        height: 1px;
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    flex: 0 0 calc(25% - 32px);
    width: calc(25% - 32px);
    margin: 0 16px;
    padding: 9px;
    border: 1px solid $neutrals6;
    border-radius: 32px;
    cursor: pointer;
    transition: all 0.2s;

    @include d {
      flex: 0 0 256px;
      width: 256px;
      margin: 0;
    }

    &:not(:last-child) {
      @include d {
        margin-right: 20px;
      }
    }

    &:hover {
      box-shadow: 0px 24px 24px -16px rgba(15, 15, 15, 0.2);
    }

    @include dark {
      border-color: $neutrals3;

      &:hover {
        border-color: $neutrals4;
      }
    }
  }

  &__picture {
    flex-shrink: 0;
    width: 44px;
    height: 44px;
    margin-right: 12px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  &__category {
    @include body-bold-2;
    color: $neutrals2;

    @include dark {
      color: $neutrals8;
    }
  }

  &__text {
    @include caption-2;
    color: $neutrals4;
  }
}

// new design style :
.resultnew {
  overflow: hidden;

  @include m {
    padding-top: 48px;
  }

  &__preview {
    margin-bottom: 96px;

    @include d {
      margin: 0 -64px 90px;
    }

    @include t {
      margin-bottom: 64px;
    }

    @include m {
      margin: 0 -160px 32px;
    }

    img {
      width: 50%;
      display: flex;
      justify-content: center;
      margin: auto;
    }
  }

  &__header {
    text-align: center;
    margin-bottom: -55px;

    h1 {
      font-weight: 700;
      font-size: 48px;
      line-height: 56px;
      color: #23262f;

      @include dark {
        color: #ffffff;
      }
    }

    h2 {
      margin-top: -12px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #777e91;
    }
  }

  &__wrap {
    max-width: 736px;
    margin: 0 auto 40px;
    text-align: center;

    h1 {
      font-weight: 700;
      font-size: 48px;
      line-height: 56px;
      color: #23262f;

      @include dark {
        color: #ffffff;
      }

      @include m {
        font-size: 42px;
      }

      @include a {
        font-size: 28px;
      }

      @include s {
        font-size: 24px;
      }
    }

    h2 {
      margin-top: 30px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #777e91;

      @include m {
        font-size: 12px;
      }

      @include a {
        font-size: 10px;
      }
    }

    a {
      margin-top: 30px;
      background: #000000;
      color: #fcfcfd;

      @include dark {
        background: #ffffff;
        color: #000000;
      }
    }

    @include m {
      margin-bottom: 64px;
    }
  }
}
