﻿.notification {
  &__tab_head {
    margin: 80px 0;

    .title {
      @include dm-sans;
      font-weight: 700;
      font-size: 48px;
      line-height: 56px;
      margin-bottom: 16px;
    }
  }

  &__head {
    @include dm-sans;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;

    @include m {
      font-size: 28px;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  &__text {
    flex-grow: 1;
  }

  &__note {
    margin-top: 16px;

    &_title {
      color: $primary3;
      font-weight: 600;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: $primary1;

    @include dark {
      color: $primary2;
    }
  }

  &__desc {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  &__sortTypes {
    width: 100%;
  }
}
