.errorpage {
  margin: auto;
  &__header {
    background: #fcfcfd;
    width: 100%;
    border-bottom: 1px solid #e6e8ec;
    display: flex;

    &_logo {
      padding: 24px 0;
      margin: auto;
    }
  }

  &__title {
    @include main-font;
    &:nth-child(1) {
      font-size: 56px;
      font-weight: 700;
      line-height: 48px;
      letter-spacing: -0.01em;
      text-align: center;
      margin-top: 88px;
    }
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    text-align: center;
  }
  &__icon {
    display: flex;
    justify-content: center;
    &_img {
      max-width: 100%;
    }
  }

  &__description {
    @include body-2;
    text-align: center;
    color: #777e90;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 24px;
    max-width: 100%;
  }
  &__button {
    @include button-2;
    text-align: center;
    background: #000;
    padding: 12px 16px;
    color: #fff;
    margin: 0 auto 59px;
    text-decoration: none;
    &:hover {
      background: #8e793e;
      color: #fff;
    }
  }
}
