.main {
    // padding-bottom: 150px;

    &__head {
        /*max-width: 600px;*/
        margin: 0 auto 50px;
        text-align: center;

        @include x {
            margin-bottom: 50px;
            margin-top: 20px;
        }

        @include m {
            margin-bottom: 64px;
        }
    }

    &__stage {
        /*text-transform: uppercase;*/
        margin-bottom: 30px;
        margin-top: 30px;
        /*@include hairline-2;*/
        font-size: 16px;
        line-height: 1;
        font-weight: 700;
        color: $neutrals4;
    }

    &__row__title {
        display: flex;
        /*align-items: flex-start;*/
        // align-items: center;
    }

    &__btnExploreforMobile {
        display: none;

        @include m {
            display: flex;
            justify-content: center;
            margin-top: 19px;
        }
    }

    &__btnExploreforPC {
        @include m {
            display: none;
        }
    }

    &__title {
        margin-bottom: 40px;
        margin-right: auto;
        font-size: 40px;

        @include m {
            font-size: 20px;
            margin-bottom: 40px;
        }

        .has_learning {
            margin-left: 20px;
            @include m {
                margin-left: 8px;
            }
        }
    }

    &__live {
        /*margin-bottom: 36px;*/
        display: flex;
        align-items: baseline;
        gap: 16px;
        color: #cd454d;

        @include m {
            display: none;
        }

        @include hairline-1;

        img {
            width: 40px;
            margin-right: 5px;

            @include t {
                width: 35px;
            }

            @include dark {
                filter: brightness(0) saturate(100%) invert(100%) sepia(84%) saturate(1%) hue-rotate(167deg)
                    brightness(104%) contrast(101%);
            }
        }
    }

    &__wrapper {
        position: relative;
        margin: 0 -12px;
    }

    &__row {
        display: flex;
        /*align-items: flex-start;*/
        align-items: center;
        position: relative;

        @include t {
            display: block;
        }
    }

    .player {
        flex-grow: 1;
        width: 620px;
        height: 850px;

        @include t {
            /*max-width: 768px;*/
            width: 100%;
            margin: 0 auto;
            max-height: 700px;
        }
    }

    &__details {
        flex-shrink: 0;
        width: 560px;
        margin-left: -55px;
        margin-bottom: 12px;
        z-index: 1;
        padding-bottom: 80px;
        background-color: $white;
        padding: 40px;
        box-shadow: 0px 12px 60px rgba(0, 0, 0, 10%);

        @include dark {
            background-color: $neutrals1;

            @include t {
                background-color: transparent;
            }
        }
        /*@include x {
            margin-left: 58px;
        }*/

        @include d {
            width: 470px;
            margin-left: -55px;
            padding-bottom: 64px;
        }

        @include t {
            width: 100%;
            margin: 48px 0 0;
            /*padding-bottom: 80px;*/
            padding: 0px;
            box-shadow: none;
        }

        @include m {
            margin-top: 32px;
        }
    }

    &__subtitle {
        margin-bottom: 20px;
        font-size: 32px !important;

        @include d {
            font-size: 32px;
        }
    }

    &__line {
        display: flex;
        margin: 0 -16px 40px;
        padding: 24px 0;
        background: $neutrals7;

        @include d {
            margin-bottom: 24px;
        }

        @include dark {
            background: $neutrals2;
        }
    }

    &__auction-mobile {
        display: flex;
        width: 100%;
        justify-content: space-around;
        padding: 8px 0px;
        align-items: center;
        border-radius: 5px;
    }

    &__auction {
        display: flex;
        /*margin: 0 -16px 40px;*/
        margin: 0 auto;

        @include d {
            display: block;
            margin-bottom: 24px;
        }

        @include t {
            display: flex;
            /*margin: 0 -16px 40px;*/
            margin: 0 auto;
        }

        @include m {
            display: block;
            margin-bottom: 24px;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        flex: 0 0 calc(50% - 32px);
        width: calc(50% - 32px);
        margin: 0 16px;

        @include m {
            display: flex;
            /*width: 100%;*/
        }
    }

    &__item2 {
        display: flex;
        align-items: center;
        flex: 0 0 calc(50% - 32px);
        width: calc(50% - 32px);
        margin: 0 16px;

        @include m {
            display: flex;
            width: 100%;
            flex: 0 0 100%;
        }
    }

    &__item-pc {
        @include m {
            display: none;
        }
    }
    // &__auction__info-mobile {
    // }

    &__auction__info {
        text-align: start;
        flex: 0 0 45%;
        margin-right: auto;

        @include d {
            text-align: center;
            flex: 0 0 100%;
        }

        @include t {
            text-align: start;
            flex: 0 0 45%;
        }

        @include m {
            text-align: center;
            flex: 0 0 100%;
            margin-bottom: 20px;
        }
    }

    // &__auction__time-mobile {
    // }

    &__auction__time {
        text-align: center;

        @include d {
            text-align: center;
            flex: 0 0 100%;
        }

        @include m {
            text-align: center;
            flex: 0 0 100%;
        }

        @include t {
            text-align: center;
            flex: 0 0 55%;
        }
    }

    &__pc {
        @include m {
            display: none !important;
        }
    }

    &__mobile {
        display: none !important;
        @include m {
            display: flex !important;
            margin-top: 20px;
        }
    }

    &__controls {
        /*display: flex;*/
        display: grid;
        align-self: flex-start;
        margin-top: 145px;
        margin-left: 20px;
        margin-bottom: 20px;
        z-index: 1;
        gap: 20px;

        @include t {
            margin-top: 20px;
            margin-left: 20px;
            margin-bottom: 20px;
            height: 80px;
            display: flex;
        }

        .options__button {
            background-color: $white;
            box-shadow: 0 0 0 2px $neutrals6 inset;
            margin: 0 !important;

            @include dark {
                background-color: $neutrals1;
                box-shadow: 0 0 0 2px $neutrals3 inset;
            }

            &:hover {
                background-color: $black;
                /*@include dark {
                    background-color: $white;
                }*/
            }

            .icon {
                position: absolute;
            }

            img {
                width: 23px;
                filter: brightness(0) saturate(100%) invert(54%) sepia(2%) saturate(2772%) hue-rotate(186deg)
                    brightness(90%) contrast(85%);
            }

            // &.active {
            // }
        }

        .options__button_favorite:before {
            content: "";
            position: inherit;
            /* top: 51%; */
            /* left: 50%; */
            transform: none;
            width: 22px;
            height: 22px;
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='18' fill='none' viewBox='0 0 22 18'%3E%3Cpath d='M11 1.81A6.48 6.48 0 0 0 6.5 0 6.5 6.5 0 0 0 0 6.5c0 6.368 6.97 9.885 9.814 11.055.766.315 1.607.315 2.372 0C15.03 16.385 22 12.868 22 6.5A6.5 6.5 0 0 0 15.5 0 6.48 6.48 0 0 0 11 1.81z' fill='%23ef466f'/%3E%3C/svg%3E")
                no-repeat 50% 50% / 100% auto;
            opacity: 0;
            transition: opacity 0.2s;
            filter: brightness(0) saturate(100%) invert(50%) sepia(11%) saturate(1721%) hue-rotate(7deg) brightness(92%)
                contrast(88%);
        }

        .options__button_favorite2:before {
            // content: "";
            // position: inherit;
            /* top: 51%; */
            /* left: 50%; */
            transform: none;
            width: 22px;
            height: 22px;
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='18' fill='none' viewBox='0 0 22 18'%3E%3Cpath d='M11 1.81A6.48 6.48 0 0 0 6.5 0 6.5 6.5 0 0 0 0 6.5c0 6.368 6.97 9.885 9.814 11.055.766.315 1.607.315 2.372 0C15.03 16.385 22 12.868 22 6.5A6.5 6.5 0 0 0 15.5 0 6.48 6.48 0 0 0 11 1.81z' fill='%23ef466f'/%3E%3C/svg%3E")
                no-repeat 50% 50% / 100% auto;
            opacity: 0;
            transition: opacity 0.2s;
            filter: brightness(0) saturate(100%) invert(50%) sepia(11%) saturate(1721%) hue-rotate(7deg) brightness(92%)
                contrast(88%);
        }

        .btn__share {
            .current-color {
                color: #777e90;
            }
        }
    }

    &__avatar,
    &__icon {
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        margin-right: 8px;
    }

    &__avatar {
        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        background: $primary3;
        border-radius: 50%;

        .icon {
            width: 24px;
            height: 24px;
            fill: $neutrals8;
        }
    }

    &__description {
        flex-grow: 1;
    }
    &__description-pc {
        @include m {
            display: none;
        }
    }

    &__category {
        @include caption-2;
        color: $neutrals4;
        text-align: start;

        @include dark {
            color: $neutrals5;
        }
    }

    &__row_gap {
        display: flex;
        gap: 5px;
        align-items: center;

        &.align-bottom {
            align-items: end;
            @include m {
                justify-content: center;
            }

            .main__price {
                margin-bottom: 2px;
            }
        }
    }

    &__text {
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1; /* number of lines to show */
        line-clamp: 1;
        color: $primary1;

        @include dark {
            color: $primary2;
        }
    }

    &__usdPrice {
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1; /* number of lines to show */
        line-clamp: 1;
        color: $primary3;

        @include t {
            display: none;
        }

        @include dark {
            color: #ae881e;
        }
    }

    &__wrap {
        margin: 40px 0;
        padding: 0 16px 30px 16px;
        // box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12);
        border: 1px solid $neutrals6;
        text-align: center;

        @include d {
            margin-bottom: 32px;
            /*padding: 24px 16px;*/
        }

        @include dark {
            background: $neutrals1;
            border-color: $neutrals2;
        }
    }

    &__info {
        @include body-bold-2;
    }

    &__info-mobile {
        @include body-bold-2;
        font-size: 12px;
        color: #e4e5e9;
    }

    &__currency {
        @include main-font;
        font-weight: 700;
        font-size: 24px;
        line-height: (56/48);
        letter-spacing: -0.02em;
    }

    &__currency-mobile {
        @include m {
            display: flex;
            font-size: 14px;
            margin-top: 2px;
            color: $white;
        }
    }

    &__currency-mobile2 {
        color: $neutrals4;
        font-size: 13px;
    }

    &__price {
        // margin-bottom: 24px;
        @include body-bold-1;
        font-size: 12px !important;
        color: $neutrals4;
    }

    &__timer {
        display: flex;
        justify-content: center;
        margin-top: 8px;
    }

    &__timer-mobile {
        margin-top: 2px !important;
    }

    &__box {
        /*display: flex;*/
        /*flex-direction: column;*/
        align-items: flex-end;
        flex-shrink: 0;
        width: 40px;
        text-align: center;

        &:not(:last-child) {
            margin-right: 0px;
        }

        @include m {
            width: 40px;
        }
    }

    &__box-mobile {
        @include m {
            width: 30px !important;
        }
    }

    &__number {
        @include main-font;
        font-size: 20px;
        font-weight: 700;
        line-height: (40/32);
        letter-spacing: -0.01em;

        @include m {
            font-size: 20px;
        }
    }

    &__number-mobile {
        font-size: 16px !important;
        color: $white;
    }

    &__time {
        @include body-bold-2;
        color: $neutrals4;
        font-size: 14px;
        font-weight: 600;

        @include m {
            font-size: 16px;
        }
    }

    &__time-mobile {
        font-size: 12px !important;
    }

    &__box2 {
        align-items: flex-end;
        flex-shrink: 0;
        text-align: center;
        color: $primary2;
        margin-right: 17px;

        @include dark {
            color: $primary1;
        }

        @include m {
            margin-right: 8px;
        }

        &:last-child {
            margin-right: 0px;
        }
    }

    &__number2 {
        @include main-font;
        @include body-bold-1;

        @include m {
            font-size: 20px;
            font-weight: 600;
        }
    }

    &__time2 {
        font-size: 18px;
        font-weight: 600;
        color: $neutrals4;

        @include m {
            font-size: 16px;
            font-weight: 600;
        }
    }
    //for nft cards
    &__box3 {
        align-items: flex-end;
        flex-shrink: 0;
        text-align: center;
        color: $primary2;

        @include dark {
            color: $primary1;
        }

        &:not(:last-child) {
            margin-right: 0px;
        }
    }

    &__number3 {
        @include main-font;
        font-size: 13px;
        font-weight: 700;
        line-height: (40/32);
        letter-spacing: -0.01em;

        @include m {
            font-size: 12px;
        }
    }

    &__time3 {
        @include body-bold-2;
        /*color: $neutrals4;*/
        font-size: 10px;
        font-weight: 500;
        color: $neutrals4;
        /* @include m {
            font-size: 12px;
        }*/
    }

    &__btns {
        display: flex;
        flex-direction: column;

        .main__button {
            width: 100%;
            height: 64px;
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;

            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }

        .main__button-disable {
            opacity: 0.5;
            cursor: default;
        }
    }

    &__slider {
        position: static;
        visibility: hidden;

        &.slick-initialized {
            visibility: visible;
        }
    }

    .slick-list {
        overflow: hidden;
        /*overflow: visible;*/
    }

    .slick-slide {
        padding: 0 12px;
        opacity: 0;
        transition: opacity 0.4s;

        &.slick-active {
            opacity: 1;
        }
    }

    .slick-arrow {
        bottom: 8px;
        background: $neutrals8;

        @include dark {
            background: transparent;
        }
    }

    .slick-prev {
        right: 324px;

        @include d {
            right: 276px;
        }

        @include t {
            right: auto;
            left: calc(50% - 56px);
        }

        @include m {
            left: calc(50% - 44px);
        }
    }

    .slick-next {
        right: 276px;

        @include d {
            right: 228px;
        }

        @include t {
            right: calc(50% - 56px);
        }

        @include m {
            right: calc(50% - 44px);
        }
    }

    // &.back-banner {
    //   /*background-image: url('../../images/Frame 2188.png');
    //       background-repeat: no-repeat;
    //       background-position: 50% 50%;
    //       background-size: cover;*/
    // }

    .slick-dots {
        display: grid;
        justify-content: flex-end;
        margin-right: 30px;
        /*padding: 1rem 0;*/
        list-style-type: none;
        margin-top: -120px;
        position: absolute;
        right: 0;
        bottom: 0;

        @include t {
            display: flex;
            /*margin-top: -24px;*/
            margin-right: 0;
            /*margin-bottom: 40px;*/
            /*height: 80px;*/
            /*bottom: 65px;*/
            bottom: 0;
            margin: 0;
            right: 12px;
        }

        li {
            margin: 0.25rem 0;

            @include t {
                margin: 0 0.25rem;
            }
        }

        button {
            display: block;
            width: 12px;
            height: 12px;
            padding: 0;
            border: none;
            border-radius: 100%;
            background-color: $neutrals6;
            text-indent: -9999px;

            @include dark {
                background-color: $neutrals3;
            }

            @include t {
                width: 6px;
                height: 6px;
            }
        }

        li.slick-active button {
            background-color: $primary1;

            @include dark {
                background-color: $neutrals7;
            }
        }
    }

    &__button {
        @include main-font;
    }
    .holder-section {
        position: relative;
        height: 100%;
        display: block;
    }
}

.h-line {
    width: 0;
    height: 690px;
    /* right: 35px; */
    /* top: 80px; */
    border: 1px solid $neutrals6;
    margin-left: -25px;
    margin-right: 15px;
    position: absolute;
    right: 9px;
    top: 0;

    @include dark {
        border: 1px solid $neutrals3;
    }

    @include t {
        width: 230px;
        height: 0;
        border: 1px solid #e6e8ec;
        margin-left: 0;
        margin-right: 0;
        margin-top: -72px;
        top: 100%;
        left: 0;
        display: none;
        /*position: inherit;*/
    }
}

.w-line {
    /*width: 230px;*/
    height: 0;
    border: 1px solid $neutrals6;
    margin-top: -75px;
    margin-left: 12px;
    display: none;

    @include dark {
        border: 1px solid $neutrals3;
    }

    @include t {
        display: block;
        width: 485px;
    }

    @include m {
        width: 230px;
    }
}

.landscape {
    display: grid;

    &__photo {
        height: 694px;

        @include m {
            width: 100%;
            height: 100%;
        }
    }

    &__detail {
        display: block;

        @include m {
            display: none;
        }
    }

    &__detailMobile {
        display: none;

        @include m {
            display: block;
        }
    }

    &__main {
        &_details {
            display: flex;
            flex-shrink: 0;
            //width: 560px;
            margin: -100px 100px 0;
            z-index: 1;
            padding-bottom: 80px;
            background-color: $white;
            padding: 40px;
            //box-shadow: 0px 12px 60px rgba(0 0 0 / 10%);
            box-shadow: 0px 12px 60px rgba(0, 0, 0, 0.1);
            margin-bottom: 25px;

            @include dark {
                background-color: $neutrals1;

                @include t {
                    background-color: transparent;
                }
            }
            /*@include x {
            margin-left: 58px;
        }*/

            @include d {
                // width: 470px;
                // margin-left: -55px;
                // padding-bottom: 64px;
                margin: -100px auto 0px;
                padding: 30px;
                width: 90%;
            }

            @include t {
                width: 100%;
                margin: 48px 0 0;
                /*padding-bottom: 80px;*/
                padding: 0px;
                box-shadow: none;
            }

            @include m {
                display: block;
                margin-top: 32px;
            }
        }

        &_titel {
            @include dm-sans;
            font-style: normal;
            font-weight: 700;
            font-size: 48px;
            line-height: 56px;
        }

        &_row {
            display: grid;
            align-items: center;
            position: relative;
        }

        &_wrap {
            // margin: 40px 0;
            width: 50%;
            padding: 0 16px 30px 16px;
            // box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12);
            border: 1px solid $neutrals6;
            text-align: center;

            @include m {
                width: 100%;
                margin-bottom: 20px;
            }

            @include dark {
                border: 1px solid $neutrals2;
            }
        }

        &_right {
            width: 47%;
            margin-left: 30px;
            display: grid;

            @include m {
                width: 100%;
                margin-left: 0;
            }
        }

        &_btns {
            display: flex;
            // flex-direction: column;
            gap: 10px;

            .main__button {
                width: 100%;
                height: 64px;
                font-weight: 500;
                font-size: 16px;
                line-height: 16px;

                @include m {
                    height: 45px;
                }

                &:not(:last-child) {
                    margin-bottom: 8px;
                }
            }

            @include m {
                display: block;
            }
        }
    }
}

.topcollectors {
    .swiper {
        position: unset;
        .swiper-pagination-bullets {
            width: unset;
            height: 30px;
            top: 95px;
            // left: 418px;
            right: -57%;

            @include r(1601) {
                right: -51%;
            }

            // @include w {
            //   left: -51%;
            // }

            @include x {
                right: -40%;
            }

            @include d {
                right: -33%;
            }

            @include t {
                right: -30%;
            }
            .swiper-pagination-bullet {
                width: 13px;
                height: 13px;
                background-color: $neutrals3;

                @include dark {
                    background-color: $neutrals6;
                }
            }
            .swiper-pagination-bullet-active {
                background: $primary1 !important;

                @include dark {
                    background: $primary2 !important;
                }
            }

            @include t {
                display: none;
            }
        }

        .swiper-pagination-progressbar {
            width: unset;
            height: 10px;
            top: unset;
            bottom: -86px;
            margin: 15px;
            height: 10px;
            border-radius: 50px;
            // left: 418px;
            right: 0;
            // @include d {
            //   left: -30%;
            // }
            @include m {
                bottom: 40px;
            }
            .swiper-pagination-bullet {
                width: 13px;
                height: 13px;
            }
            .swiper-pagination-progressbar-fill {
                background: $primary1 !important;
                border-radius: 50px;
                height: 10px;

                @include dark {
                    background: $primary2 !important;
                }
            }

            @include rmin(1023) {
                display: none;
            }
        }
    }
}

.testimonials {
    .testimonial__collector {
        height: unset !important;
    }
}

.recently__center {
    // slider
    .swiper-slide {
        width: fit-content !important;
        margin: 0 8px;
        @include m {
            width: 100% !important;
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .landscape {
        min-width: 500px;
        @include m {
            min-width: unset;
            max-width: 350px !important;
        }
    }
}

.colsetting {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.trending {
    &.slider {
        height: auto;
    }
    .swiper-pagination-bullet {
        display: block;
        width: 12px !important;
        height: 12px !important;
        padding: 0;
        border: 0;
        border-radius: 100%;
        text-indent: -9999px;
        margin-bottom: 10px !important;
        background: $neutrals6;
        @include dark {
            background: $neutrals3;
        }
        opacity: unset;
    }

    .swiper-pagination {
        display: flex;
        flex-direction: column;
        justify-content: center;
        top: 0;
        right: 15px;
        bottom: 15px !important;
        height: unset;
        @include t {
            flex-direction: row;
            bottom: 61px !important;
        }
        @include m {
            bottom: 59px !important;
        }
    }

    .main__btns {
        .main__button {
            padding: 0;
        }
    }
    .w-line {
        position: absolute;
        bottom: 75px;
        margin: 0;
        padding: 0;
        z-index: -1;
    }
    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
        left: unset !important;
        width: unset !important;
    }

    .main__details {
        box-shadow: 0 12px 60px rgba(0, 0, 0, 0.1);
        width: 470px;

        @include d {
            width: 370px;
        }

        @include t {
            width: 100%;
            box-shadow: none;
        }
        @include m {
            box-shadow: none;
            width: 100%;
        }
    }

    .swiper-scrollbar {
        display: none;
    }

    .swiper-pagination-bullet-active {
        background: $primary1 !important;

        @include dark {
            background: $primary2 !important;
        }
    }

    .popular__wrapper {
        .swiper {
            position: unset;
        }
        .swiper-pagination {
            display: unset;
            right: 225px;
            top: -50px !important;
            height: 100%;
        }
    }
    .likeTooltip {
        @include m {
            display: none;
        }
    }
}

.trending {
    &.slider {
        height: auto;
    }

    .main__btns {
        .main__button {
            padding: 0;
        }
    }
    .w-line {
        position: absolute;
        bottom: 75px;
        margin: 0;
        padding: 0;
        z-index: -1;
    }

    .main__details {
        box-shadow: 0 12px 60px rgba(0, 0, 0, 0.1);
        width: 470px;

        @include d {
            width: 370px;
        }

        @include t {
            width: 100%;
            box-shadow: none;
        }
        @include m {
            box-shadow: none;
            width: 100%;
        }
    }

    .popular__wrapper {
        .swiper {
            position: unset;
        }
        .swiper-pagination {
            display: unset;
            right: 225px;
            top: -50px !important;
            height: 100%;
        }
    }

    .likeTooltip {
        @include m {
            display: none;
        }
    }

    .like_count {
        display: none;
    }
}

.slider {
    box-shadow: none !important;
    background: unset !important;
}

.slider::before {
    display: none;
}
.slider::after {
    display: none;
}
