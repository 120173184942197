.popup {
  position: relative;
  max-width: 448px;
  margin: 15px auto;
  padding: 32px;
  background: $neutrals8;
  border-radius: 0px;

  @include dark {
    background: $neutrals1;
    box-shadow: inset 0 0 0 1px $neutrals2;
  }

  &.gi-box {
    background: $primary2;
    border-radius: 5px;
    box-shadow: inset 0 0 0 2px $primary3;
    @include dark {
      background: $primary1;
    }
  }

  &__referral {
    max-width: 800px;
    padding: 120px 90px;

    @include m {
      /*width: 100%;*/
      padding: 60px 42px;
    }
  }

  &__rate-modal {
    padding: 70px 30px;
    max-width: 635px;
    width: 635px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include m {
      width: 100%;
    }
  }

  &__video {
    max-width: 700px;

    &_content {
      margin: auto;

      video {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;

        @include m {
          width: 100%;
        }
      }
    }
  }

  &__buy_crypto {
    max-width: 700px;
    height: 600px;
  }

  &__lock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*position: fixed;*/
    /*background: rgba($primary2, 0.5);*/
    /*backdrop-filter: blur(8px);*/
    /*z-index: 20;*/
    gap: 17px;
    /*top: 0;*/
    max-width: 1120px;
    /*left: 0;*/
    /*height: 100vh;*/
    /*overflow: hidden;*/
    /*@include dark {
            background: $primary1;
        }*/

    &_row {
      width: 33.33%;

      &:not(:first-child) {
        border-left: 1px solid $neutrals5;
        padding-left: 16px;
      }
    }

    &.landscape {
      max-width: 1120px;

      @include m {
        max-width: 100%;
      }

      .popup__lock_right,
      .popup__lock_left {
        flex: 0 0 50%;
        display: flex;
        align-items: flex-start;

        .popup__lock_photo {
          height: 100%;
        }
      }
    }

    &.portrait {
      max-width: 880px;
      padding: 32px 32px 32px 32px;
      @include m {
        max-width: 100%;
      }

      .popup__lock_right {
        flex: 0 0 60%;
      }

      .popup__lock_left {
        flex: 0 0 40%;
        .popup__lock_photo {
          height: 100%;
        }
      }
    }

    &_section {
      width: 100%;
      height: 100%;
      //display: flex;
      justify-content: center;
      align-items: center;
    }

    &_title {
      @include dm-sans;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      color: $neutrals2;
      margin-bottom: 24px;

      @include dark {
        color: $neutrals7;
      }
    }

    &_body {
      display: inline-flex;
      padding: 0px;

      @include m {
        display: block;
      }
    }

    &_left {
      padding-right: 30px;
      &.landscape {
        /*flex: 1 0 50%;*/
        height: 100%;
      }

      &.portrait {
        //flex: 1 0 50%;
        height: 406px;
        /*object-fit: cover;*/
      }

      @include m {
        padding-right: 0px;
        padding-bottom: 16px;
      }
    }

    &_photo {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: flex;
      align-items: center;
    }

    &_name {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: $neutrals2;

      @include dark {
        color: $neutrals7;
      }
    }

    &_box {
      box-sizing: border-box;
      /* Auto layout */
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px;
      gap: 16px;
      border: 1px solid $neutrals6;

      @include dark {
        border: 1px solid $neutrals3;
      }
    }

    &_table {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 16px;
      width: 448px;
      height: 44px;
      width: 100%;
    }

    &_right {
      flex: 0 0 50%;
      display: flex;
      flex-direction: column;
      padding: 0px;
      gap: 36px;
    }

    .lock-btn {
      display: flex;
      gap: 8px;

      img {
        @include dark {
          @include filter-primary1;
        }
      }
    }

    &_ckeckBox {
      display: flex;
      @include main-font;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $neutrals1;
      gap: 12px;

      @include dark {
        color: $neutrals8;
      }
    }

    &_titleInfo {
      @include main-font;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: $neutrals4;

      @include dark {
        color: $neutrals5;
      }
    }

    &_info {
      @include main-font;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $neutrals2;

      @include dark {
        color: $neutrals7;
      }
    }

    &_hr {
      width: 100%;
      border: 1px dashed $neutrals6;

      @include dark {
        border: 1px dashed $neutrals3;
      }
    }

    &_detail {
      @include main-font;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: $neutrals4;

      @include dark {
        color: $neutrals5;
      }
    }

    &_closeBox {
      position: absolute;
      top: 32px;
      right: 32px;
    }

    &_close {
      width: 40px;
      height: 40px;
      fill: $neutrals6;
      cursor: pointer;
      border: 2px solid $neutrals6;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: border-color 0.2s;

      @include dark {
        border: 2px solid $neutrals3;

        img {
          @include filter-neutrals6;
        }
      }

      &:hover {
        border-color: $primary1;

        @include dark {
          border-color: $primary2;
        }
      }
    }
  }

  &__bridge {
    max-width: 640px;
    padding: 87px 130px 70px;

    @include m {
      /*width: 100%;*/
      padding: 60px 42px;
    }

    &_content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;
    }

    &_title {
      @include dm-sans;
      @include hairline-4;
    }

    &_artwork {
      padding: 10px;
      border-radius: 10px;
      background: $neutrals7;
      width: 100%;

      @include dark {
        background: $neutrals2;
      }

      .artwork_info {
        display: flex;
        gap: 10px;
        align-items: center;
      }

      .artwork_image {
        width: 55px;
        height: 40px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
      }

      .artwork_name {
        @include body-2;
        color: $neutrals3;

        @include dark {
          color: $neutrals6;
        }
      }

      .artwork_hashs {
        display: flex;
        align-items: center;
        margin-top: 10px;

        @include m {
          display: block;
        }

        .hash_row {
          display: flex;
          gap: 4px;
          align-items: center;

          &:first-child {
            margin-right: auto;
          }

          .hash_title {
            @include caption-2;
            color: $neutrals3;

            @include dark {
              color: $neutrals6;
            }
          }

          .hash_link {
            @include caption-2;
            color: $primary3;
          }
        }
      }
    }

    &_form {
      display: flex;
      flex-direction: column;
      gap: 22px;
      width: 100%;
    }

    &_row {
      display: flex;
      align-items: center;

      &.column {
        flex-direction: column;
      }

      .row_title {
        margin-right: auto;
        @include caption-1;
        color: $neutrals4;
      }

      .row_item {
        display: flex;
        align-items: center;
        gap: 12px;
        @include caption-bold-1;

        .chain_icon {
          width: 25px;
          height: 25px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .chain_name {
          color: $neutrals3;

          @include dark {
            color: $neutrals6;
          }
        }
      }

      .row_link {
        color: $primary3;
        @include caption-bold-1;
      }
    }

    &_btn {
      width: 100%;

      button {
        width: 100%;
      }
    }
  }

  &__menu {
    width: 100%;
    max-width: 100%;
    min-height: 100vh;
    padding: 50px;

    .mfp-close {
      top: 50px !important;
      right: 50px !important;
    }

    &__title {
      margin-bottom: 65px;
    }

    &__list {
      margin: 135px 0;
    }

    &__button {
      height: 56px;
      width: 100%;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      @include poppins;
    }

    &__icon {
      flex-shrink: 0;
      width: 20px;
      margin-right: 8px;

      .icon {
        width: 20px;
        height: 20px;
        fill: $neutrals4;
        transition: fill 0.2s;
      }

      img {
        width: 20px;
        height: 20px;
        transition: fill 0.2s;
        @include filter-neutrals3;

        @include dark {
          @include filter-neutrals4;
        }
      }
    }

    &__link {
      display: flex;
      align-items: center;
      padding: 0px 0 30px;
      @include button-3;
      /*@include dm-sans;*/
      color: $neutrals3;

      @include dark {
        color: $neutrals4;
      }
      /*&:not(:last-child) {
                border-bottom: 1px solid $neutrals6;

                @include dark {
                    border-color: $neutrals3;
                }
            }*/

      &:hover {
        color: $primary3;

        .icon {
          fill: $primary3;
        }

        img {
          @include filter-primary3;
        }
      }

      &.logout-btn {
        width: 100%;

        &:hover {
          color: $primary3;

          .icon {
            fill: $primary3;
          }
        }
      }
    }

    &__copyright {
      text-align: center;
      @include caption-2;
    }
  }

  &__item {
    display: none;
  }

  &__title {
    margin-bottom: 32px;
  }

  &_title2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
  }

  &__info {
    margin-bottom: 32px;
    @include body-2;

    strong {
      font-weight: 500;
    }
  }

  &__row {
    display: flex;
    padding-top: 12px;

    @include m {
      display: block;
    }

    &:first-child {
      padding: 0 0 12px;
      /*border-bottom: 1px solid $neutrals6;*/
      font-weight: 500;

      @include dark {
        border-color: $neutrals3;
      }
    }

    &.like_first {
      padding: 12px 0 12px;
      border-bottom: 1px solid $neutrals6;
      font-weight: 500;

      @include dark {
        border-color: $neutrals3;
      }
    }
  }

  &__col {
    @include body-2;

    &:first-child {
      color: $neutrals4;
    }

    &:nth-child(2) {
      margin-left: auto;
      padding-left: 20px;
      font-weight: 500;
    }
  }

  &__divider {
    margin: 40px 0;
    color: $neutrals4;
  }

  &__switch {
    display: flex;
    margin-bottom: 30px;
    align-items: center;
    color: #dcdcdc;
  }

  &__col2 {
    @include body-2;
    /*&:first-child {
            color: $neutrals4;
        }*/

    &:nth-child(2) {
      /*margin-left: auto;*/
      /*padding-left: 20px;*/
      font-weight: 500;
    }
  }

  &__notes {
    padding: 32px 16px;
    color: $primary1;

    @include dark {
      color: $primary2;
    }

    b {
      text-transform: uppercase;
    }
  }

  &__btns {
    margin-top: 32px;
    margin-bottom: 32px;

    .full {
      width: 100%;
    }

    .spiner {
      transition: transform 0.2s;

      img {
        transform: rotate(1333deg);
      }
    }

    .buttun:disabled {
      background-color: #babdc7 !important;
    }
  }

  &__btn_action {
    display: flex;
    gap: 12px;
    margin-top: 20px;
    margin-bottom: 32px;

    .button {
      width: 50%;
    }

    .signs {
      font-weight: 500;
      font-size: 22px;
    }
  }

  &__btns &__button {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__attention {
    display: flex;
    align-items: center;
    margin-top: 32px;
    padding: 24px;
    border-radius: 8px;
    background: rgba($pink, 0.08);
    color: $pink;

    @include m {
      align-items: stretch;
    }
  }

  &__preview {
    flex-shrink: 0;
    width: 32px;
    margin-right: 16px;

    .icon {
      width: 32px;
      height: 32px;
      fill: $pink;
    }
  }

  &__details {
    flex-grow: 1;
  }

  &__category {
    @include body-bold-2;
  }

  &__text {
    @include caption-2;
  }

  &__avatar {
    width: 48px;
    height: 48px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__footer {
    display: flex;
    padding-bottom: 12px;

    &_title {
      @include body-bold-2;
      margin-right: auto;
    }

    &_price {
      color: $neutrals4;
      @include body-2;

      &_bnb {
        color: $primary1;

        @include dark {
          color: $primary2;
        }
      }
    }

    &:last-child {
      border-top: 1px solid $neutrals6;
      padding-top: 12px;

      @include dark {
        border-color: $neutrals3;
      }
    }
  }

  &__attention &__avatar {
    flex-shrink: 0;
    margin-left: 16px;
  }

  &__img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    margin: 72px auto 32px;
    border-radius: 50%;
    background: $purple;

    .icon {
      width: 24px;
      height: 24px;
      fill: $neutrals8;
    }
  }

  &__description {
    margin-bottom: 32px;
    text-align: center;
    @include body-bold-2;
  }

  &__subtitle {
    margin-bottom: 16px;
    @include body-bold-1;
  }

  &__line {
    display: flex;
    margin-bottom: 32px;

    strong {
      white-space: nowrap;
      font-weight: 500;
    }
  }

  &__icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    margin-right: 16px;
    background: $purple;
    border: 2px solid $purple;
    border-radius: 50%;
    transition: all 0.2s;

    .icon {
      width: 24px;
      height: 24px;
      fill: $neutrals8;
      transition: fill 0.2s;
    }

    .loader {
      position: absolute;
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
    }
  }

  &__box {
    flex-grow: 1;
  }

  &__note {
    margin-bottom: 32px;
    @include body-2;
    color: $neutrals4;
  }

  &__input {
    width: 100%;
    height: 48px;
    border-bottom: 1px solid $neutrals6;
    background: none;
    border-radius: 0;
    @include main-font;
    @include body-bold-2;
    color: $neutrals2;
    transform: border-color 0.2s;

    @include dark {
      border-color: $neutrals3;
      color: $neutrals8;
    }

    @include placeholder {
      color: $neutrals4;
    }

    &:focus {
      border-color: $neutrals4;

      @include dark {
        border-color: $neutrals4;
      }
    }
  }

  &__bid {
    display: flex;
    align-items: center;
  }

  &__rate {
    min-width: 100px;
    height: 24px;
    background: none;
    border: none;
    text-align: right;
    @include main-font;
    font-weight: 500;
    font-size: 16px;

    @include dark {
      color: $neutrals7;
    }
  }

  &__rate2 {
    min-width: 100px;
    height: 24px;
    background: none;
    border: none;
    text-align: left;
    @include main-font;
    font-weight: 500;
    font-size: 16px;

    @include dark {
      color: $neutrals7;
    }

    &.bid {
      color: $primary1;
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;

      @include dark {
        color: $primary2;
      }
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
    &[type="number"] {
      appearance: textfield;
      -moz-appearance: textfield;
    }
  }

  &__bid &__currency {
    margin-left: 4px;
  }

  &__currency {
    color: $neutrals4;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }

  .switch {
    flex-shrink: 0;
    margin-left: 16px;
  }

  .mfp-close {
    position: absolute;
    top: 32px;
    right: 32px;
    width: 40px;
    height: 40px;
    border: 2px solid $neutrals6;
    border-radius: 50%;
    line-height: normal;
    opacity: 1;
    background: $neutrals8
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='15' fill='none' viewBox='0 0 14 15'%3E%3Cpath fill-rule='evenodd' d='M.293 1.289a1 1 0 0 1 1.414 0L7 6.582l5.293-5.293a1 1 0 0 1 1.414 1.414L8.414 7.996l5.293 5.293a1 1 0 0 1-1.414 1.414L7 9.41l-5.293 5.293a1 1 0 0 1-1.414 0 1 1 0 0 1 0-1.414l5.293-5.293L.293 2.703a1 1 0 0 1 0-1.414z' fill='%2323262F'/%3E%3C/svg%3E")
      no-repeat 50% 48% / 14px auto;
    font-size: 0;
    transition: all 0.2s;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    z-index: 1;

    @include dark {
      border-color: $neutrals3;
      background-color: $neutrals1;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Cpath fill='%23FCFCFD' d='M1.613.2l.094.083L5 3.585 8.293.293a1 1 0 0 1 1.414 0 1 1 0 0 1 .083 1.32l-.083.094L6.415 5l3.292 3.293a1 1 0 0 1-1.32 1.497l-.094-.083L5 6.415 1.707 9.707A1 1 0 0 1 .21 8.387l.083-.094L3.585 5 .293 1.707a1 1 0 0 1 0-1.414A1 1 0 0 1 1.613.21z'/%3E%3C/svg%3E");
    }

    &:hover {
      border-color: $neutrals2;

      @include dark {
        border-color: $neutrals8;
      }
    }
  }

  &_price {
    .field__input,
    .field__currency {
      font-size: 24px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  &__sign {
    border-radius: 40px;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;

    &.default {
      background-color: $neutrals6;
    }

    &.ok {
      background-color: $primary3;
    }

    &.wrong {
      background-color: #d0342c;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      color: $primary2;
    }

    .icon {
      width: 21px;
      height: 21px;
      fill: $neutrals4;
    }
  }

  &__state {
    &__want {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: $neutrals4;
      margin-top: 5px;
    }
  }

  &__referred {
    padding: 0px !important;

    .popup__follow {
      margin-top: 54px;
    }

    .form-control {
      width: 86%;
      margin: auto;

      @include m {
        width: 100%;
      }
    }
  }

  &__ConnectWallet {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 100px;
      height: 100px;
    }

    &_btn {
      width: 100% !important;
    }

    &_address {
      margin-top: 16px;
      width: unset !important;

      form {
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
      }
    }

    &_tutorial {
      @include main-font;
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      color: $eth;

      @include dark {
        color: $neutrals8;
      }
    }

    &_desc {
      @include main-font;
      font-size: 12px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: $neutrals4;
    }
  }
  &__share {
    img {
      @include filter-primary2;
      @include dark {
        @include filter-primary1;
      }
    }
    &:hover {
      img {
        @include filter-primary2;
      }
    }
    &_icon {
      @include filter-neutrals4;
    }
  }
}

.popup-address {
  max-width: 900px !important;
}

.popup_search {
  position: fixed;
  width: 100%;
  padding: 10px;
  top: 105px;
  left: 0;
  z-index: 10;

  &_form {
    padding: 86px 32px;
    background: $neutrals8;
    border-radius: 0px;
    z-index: 10;
    box-shadow: 0px 12px 60px rgba(0, 0, 0, 10%);

    @include dark {
      background: $neutrals1;
      box-shadow: inset 0 0 0 1px $neutrals2;
    }
  }

  .mfp-close {
    position: absolute;
    top: 40px;
    right: 75px;
    width: 40px;
    height: 40px;
    border: 2px solid $neutrals6;
    border-radius: 50%;
    line-height: normal;
    opacity: 1;
    background: $neutrals8
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='15' fill='none' viewBox='0 0 14 15'%3E%3Cpath fill-rule='evenodd' d='M.293 1.289a1 1 0 0 1 1.414 0L7 6.582l5.293-5.293a1 1 0 0 1 1.414 1.414L8.414 7.996l5.293 5.293a1 1 0 0 1-1.414 1.414L7 9.41l-5.293 5.293a1 1 0 0 1-1.414 0 1 1 0 0 1 0-1.414l5.293-5.293L.293 2.703a1 1 0 0 1 0-1.414z' fill='%2323262F'/%3E%3C/svg%3E")
      no-repeat 50% 48% / 14px auto;
    font-size: 0;
    transition: all 0.2s;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    @include dark {
      border-color: $neutrals3;
      background-color: $neutrals1;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Cpath fill='%23FCFCFD' d='M1.613.2l.094.083L5 3.585 8.293.293a1 1 0 0 1 1.414 0 1 1 0 0 1 .083 1.32l-.083.094L6.415 5l3.292 3.293a1 1 0 0 1-1.32 1.497l-.094-.083L5 6.415 1.707 9.707A1 1 0 0 1 .21 8.387l.083-.094L3.585 5 .293 1.707a1 1 0 0 1 0-1.414A1 1 0 0 1 1.613.21z'/%3E%3C/svg%3E");
    }

    &:hover {
      border-color: $neutrals2;

      @include dark {
        border-color: $neutrals8;
      }
    }
  }
}

.mfp-container {
  padding: 20px;

  @include t {
    padding: 0;
  }
}

@include t {
  .popup__menu {
    margin: 0;
  }
}

@media (max-width: 767px) {
  .mfp-container {
    .popup {
      margin: 0 16px;

      &.popup__menu {
        margin: 0;
      }
    }
    /*padding: 0 16px;*/
  }
}

.mfp-bg {
  background: rgba($neutrals1, 0.9);

  @include dark {
    background: rgba($neutrals2, 0.9);
  }
}

.mfp-zoom-in {
  .popup {
    opacity: 0;
    transition: all 0.2s ease-in-out;
    transform: scale(0.8);
  }

  &.mfp-bg {
    opacity: 0;
    transition: all 0.2s ease-out;
  }

  &.mfp-ready {
    .popup {
      opacity: 1;
      transform: scale(1);
    }

    &.mfp-bg {
      opacity: 1;
    }
  }

  &.mfp-removing {
    .popup {
      transform: scale(0.8);
      opacity: 0;
    }

    &.mfp-bg {
      opacity: 0;
    }
  }
}

/* 

====== Move-from-top effect ======

*/
.mfp-move-from-top {
  .mfp-container {
    padding: 0px;
  }

  .mfp-content {
    vertical-align: top;
  }
  /* start state */
  .popup,
  .popup_search {
    opacity: 0;
    transition: all 0.2s;
    transform: translateY(-100px);
  }

  &.mfp-bg {
    opacity: 0;
    transition: all 0.2s;
  }
  /* animate in */
  &.mfp-ready {
    .popup,
    .popup_search {
      opacity: 1;
      transform: translateY(82px);
    }

    &.mfp-bg {
      opacity: 0.2;
    }
  }
  /* animate out */
  &.mfp-removing {
    .popup,
    .popup_search {
      transform: translateY(-50px);
      opacity: 0;
    }

    &.mfp-bg {
      opacity: 0;
    }
  }
}

.mfp-3d-unfold {
  /*.popup {
        perspective: 2000px;
    }*/

  .popup {
    opacity: 0;
    transition: all 0.3s ease-in-out;
    transform-style: preserve-3d;
    transform: rotateY(-60deg);
  }

  &.mfp-bg {
    opacity: 0;
    transition: all 0.5s;
  }
  /* animate in */
  &.mfp-ready {
    .popup {
      opacity: 1;
      transform: rotateY(0deg);
    }

    &.mfp-bg {
      opacity: 0.8;
    }
  }
  /* animate out */
  &.mfp-removing {
    .popup {
      transform: rotateY(60deg);
      opacity: 0;
    }

    &.mfp-bg {
      opacity: 0;
    }
  }
}

#popup-wallet,
.popup-wallet {
  text-align: center;
  max-width: 580px;

  // .wallet_list_container {
  //   display: flex;
  //   flex-wrap: wrap;
  //   @include m {
  //     flex-direction: column;
  //   }
  // }

  @include t {
    hr {
      display: none;
    }
  }

  a,
  .choice_container {
    cursor: pointer;
    transition: 0.3s ease-in-out;
    color: $primary1;
    flex: 1 0 50%;

    @include dark {
      color: $primary2;
    }

    @include t {
      &.metamask {
        display: none;
      }
    }
  }
}

// pupup new
.burnpopup {
  text-align: center;

  &__logo {
    img {
      width: 100px;
      height: 100px;
    }
  }

  &__image {
    width: 200px;
    height: 200px;
    margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    margin: 15px 0;
    @include dm-sans;
  }

  &__subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 24px;
    color: $neutrals4;
    width: 310px;
    margin: auto;
  }

  &__btn {
    margin-top: 20px;
    display: flex;
    gap: 20px;

    @include m {
      flex-direction: column;
    }

    &.revers_flex {
      @include m {
        flex-direction: column-reverse;
      }
    }

    .button {
      /*margin: 0 10px 10px 10px;*/
      width: 100%;

      &.glassy {
        background: transparent;
        color: $primary1;

        @include dark {
          color: $primary2;
        }

        &:hover {
          background: $primary3;
          color: $primary2;
        }
      }
    }
  }

  &__shareinput {
    position: relative;
    /*display: flex;*/
    max-width: calc(100% - 52px);

    .link_text {
      @include poppins;
      height: 50px;
      background-color: $neutrals7;
      border-radius: 30px;
      padding-left: 10px;
      cursor: pointer;
      padding-right: 40px;
      display: flex;
      align-items: center;
      color: $primary1;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(100% - 54px);
        @include m {
          max-width: calc(100% - 8px);
        }
      }
    }
  }

  &__sharelink {
    gap: 20px;
    display: flex;
    margin-bottom: 32px;
    position: relative;
    max-width: 100%;
    align-items: center;

    img {
      position: absolute;
      right: 10px;
      top: 17px;
      width: 18px;
      height: 18px;
    }
  }

  &__label {
    //font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
  }

  &__sharebtn {
    display: flex;
    padding-bottom: 30px;

    p {
      // font-family: 'DM Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      margin: auto 30px auto 0;
      align-content: center;
    }

    img {
      width: 55px;
    }
  }
}

#btnShareArtWorkInfo {
  display: block;

  .button {
    margin-left: 0;
  }
}

/*spinner animation*/
.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 29px;
  left: 39px;
  width: 2px;
  height: 6px;
  border-radius: 20%;
  background: $primary2;
  /*@include dark {
        background: $primary2;
    }*/
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.popupmenu {
  &__title {
    padding-bottom: 48px;
  }

  &__list {
    padding: 38px 0;
  }

  &__copyright {
    text-align: center;
  }
}

.win_point {
  &__container {
    width: 100%;
    height: 100vh;
    background: rgba($neutrals1, 0.9);
    /*opacity: 0.9;*/
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;

    @include m {
      padding: 131px 0;
      overflow: auto;
    }
  }

  &__popup {
    max-width: 635px;
    position: relative;
    border-radius: 8px;
    background: $primary2;

    @include dark {
      background: $primary1;
    }

    @include m {
      width: 95%;
      margin-top: 560px;
    }
  }

  &__head {
    height: 238px;
    background: $primary1;
    border-radius: 8px 8px 155px 155px / 8px 8px 35px 35px;
    text-align: center;
    width: 100%;
    position: relative;

    @include dark {
      background: $primary2;
    }
  }

  &__hooray {
    position: absolute;
    left: 0;
    top: -102px;
    width: 100%;
  }

  &__coin {
    margin-top: -55px;
  }

  &__number {
    font-weight: 700;
    font-size: 64px;
    line-height: 64px;
    @include dm-sans;
    color: $primary2;
    text-align: center;
    margin-top: 14px;

    @include dark {
      color: $primary1;
    }
  }

  &__content {
    padding: 40px 30px;

    @include m {
      padding: 40px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 64px;
    text-align: center;
    @include dm-sans;
    margin-bottom: 30px;

    @include m {
      font-size: 28px;
    }
  }

  &__text {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $neutrals5;
    margin-top: 8px;
    margin-bottom: 30px;
    // margin-bottom: 72px;
  }

  &__button {
    width: 100%;
    /*background: $primary1;
        color: $primary2;*/
  }
}

.Insufficent {
  text-align: center;
}

.popup-buy {
  .popup__btns {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .popup__btns-portrait {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 0px !important;
  }

  &__Image-portrait {
    @include m {
      margin-top: 35px;
    }

    img {
      border-radius: 10px;
    }
  }

  &__Image {
    margin-top: 35px;

    img {
      border-radius: 10px;
    }
  }

  &_closeButton {
    top: 16px !important;
    right: 16px !important;
  }

  &_title {
    @include dm-sans;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.01em;
    text-align: center;
    margin: 30px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include m {
      font-size: 20px;
    }
  }

  &_priceBnb {
    @include poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: $neutrals3 !important;

    @include dark {
      color: $neutrals6 !important;
    }
  }

  &_flex {
    display: flex;
  }

  &_flex2 {
    display: flex;
    justify-content: space-between;
  }

  &_flex3 {
    @include m {
      display: flex;
      justify-content: center;
    }
  }

  &_value-price {
    @include poppins;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: right;
    margin-right: 4px;
  }

  &_marginRight {
    margin-right: 8px;
  }

  &_portrait-flex {
    display: flex;
    align-items: center;

    @include m {
      display: grid;
    }
  }

  &_title-portrait {
    @include dm-sans;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.01em;
    text-align: center;
    margin: 30px 0px 0px 0px;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include m {
      font-size: 20px;
    }
  }

  &_portrait-box {
    flex: 0 50%;
    gap: 52px;
    display: grid;

    @include m {
      gap: 22px;
    }

    img {
      max-width: 263px;
      max-height: 392px;
      border-radius: 10px;
    }
  }

  &__width {
    max-width: 707px !important;
    padding: 50px !important;

    @include m {
      max-width: 450px !important;
      padding: 32px !important;
    }
  }
}

//search
.popup_search2 {
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  padding: 10px;
  top: 50px;
  left: 0;
  z-index: 10;

  &_form2 {
    // overflow-y: scroll;
    // height: 85vh;
    margin: 15px;
    // width: 50%;
    max-width: 750px;
    background: $neutrals8;
    border-radius: 8px;
    border: 1px solid $neutrals6;
    z-index: 10;
    box-shadow: 0px 12px 60px rgba(0, 0, 0, 10%);

    @include rmin(1023) {
      width: 750px;
    }

    @include m {
      width: 100%;
    }

    @include dark {
      background: $neutrals1;
      box-shadow: inset 0 0 0 1px $neutrals2;
      border: 1px solid $neutrals3;
    }
  }

  .mfp-close2 {
    margin: 15px;
    width: 40px;
    height: 40px;
    border: 2px solid $neutrals6;
    border-radius: 50%;
    line-height: normal;
    opacity: 1;
    background: $primary2
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='15' fill='none' viewBox='0 0 14 15'%3E%3Cpath fill-rule='evenodd' d='M.293 1.289a1 1 0 0 1 1.414 0L7 6.582l5.293-5.293a1 1 0 0 1 1.414 1.414L8.414 7.996l5.293 5.293a1 1 0 0 1-1.414 1.414L7 9.41l-5.293 5.293a1 1 0 0 1-1.414 0 1 1 0 0 1 0-1.414l5.293-5.293L.293 2.703a1 1 0 0 1 0-1.414z' fill='%2323262F'/%3E%3C/svg%3E")
      no-repeat 50% 48% / 14px auto;
    font-size: 0;
    background-color: $primary2;
    transition: all 0.2s;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    @include m {
      position: fixed;
      top: 0;
      right: 10px;
    }

    @include dark {
      border-color: $neutrals3;
      background-color: $neutrals1;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Cpath fill='%23FCFCFD' d='M1.613.2l.094.083L5 3.585 8.293.293a1 1 0 0 1 1.414 0 1 1 0 0 1 .083 1.32l-.083.094L6.415 5l3.292 3.293a1 1 0 0 1-1.32 1.497l-.094-.083L5 6.415 1.707 9.707A1 1 0 0 1 .21 8.387l.083-.094L3.585 5 .293 1.707a1 1 0 0 1 0-1.414A1 1 0 0 1 1.613.21z'/%3E%3C/svg%3E");
    }

    &:hover {
      border-color: $neutrals2;

      @include dark {
        border-color: $neutrals8;
      }
    }
  }
}

.Line_search2 {
  border: 0px;
  height: 1px;
  background: $neutrals6;
  padding: 0px;
  margin: 0px;

  @include dark {
    background: $neutrals3;
  }
}

.Line_upload {
  border: 0px;
  height: 1px;
  background: $neutrals6;
  padding: 0px;
  margin: 0px;
  margin-bottom: 30px;

  @include dark {
    background: $neutrals3;
  }
}

.popup-search-padding {
  padding: 30px;
}

.popup-search-empty {
  display: flex;
  justify-content: center;
  min-width: 106px;
  min-height: 34px;
}

.popup-search-title {
  @include poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.popup-global-search {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0px 40px 0px;
}

.popup-global-flex {
  display: flex;
  align-items: center;
}

.popup-global-search-link {
  flex: 0 0 33.33%;
}

.popup-search-tag {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;
  min-width: 106px;
  min-height: 34px;
  padding: 8px 8px 6px 8px;
  gap: 10px;
  border-radius: 4px;
  // opacity: 0px;
  background: $primary2;
  border: 1px solid $primary3;
  @include poppins;
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  text-align: left;
  color: $primary3;

  @include dark {
    background: $primary1;
  }
}

.popup-search-artist {
  @include poppins;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: $neutrals5;
  margin-bottom: 10px;
  margin-right: 30px;

  @include dark {
    color: $neutrals5;
  }
}

.popup-search-artist-image {
  border-radius: 50%;
  margin-right: 10px;
  margin-bottom: 10px;
  object-fit: cover;
}

.popup-search-artworks-image {
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
  object-fit: cover;
}

.popup-search-notFound {
  @include poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.01em;
  text-align: left;
}

.popup-search-notFound-text {
  @include poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.01em;
  text-align: left;
  color: $neutrals5;
}

.popup-search-notFound-img {
  margin-bottom: 15px;
  // @include dark {
  //   filter: brightness(0) saturate(100%) invert(100%) sepia(12%) saturate(0%)
  //     hue-rotate(357deg) brightness(106%) contrast(101%);
  // }
}

.YourHighlightClass {
  color: $primary1 !important;
  background-color: $neutrals8 !important;

  @include dark {
    color: $primary2 !important;
    background-color: $neutrals1 !important;
  }
}

.popup-collection {
  position: relative;
  max-width: 630px;
  margin: 15px auto;
  padding: 32px;
  background: $neutrals8;
  border-radius: 0px;

  @include dark {
    background: $neutrals1;
    box-shadow: inset 0 0 0 1px $neutrals2;
  }

  .mfp-close {
    position: absolute;
    top: 32px;
    right: 32px;
    width: 40px;
    height: 40px;
    border: 2px solid $neutrals6;
    border-radius: 50%;
    line-height: normal;
    opacity: 1;
    background: $neutrals8
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='15' fill='none' viewBox='0 0 14 15'%3E%3Cpath fill-rule='evenodd' d='M.293 1.289a1 1 0 0 1 1.414 0L7 6.582l5.293-5.293a1 1 0 0 1 1.414 1.414L8.414 7.996l5.293 5.293a1 1 0 0 1-1.414 1.414L7 9.41l-5.293 5.293a1 1 0 0 1-1.414 0 1 1 0 0 1 0-1.414l5.293-5.293L.293 2.703a1 1 0 0 1 0-1.414z' fill='%2323262F'/%3E%3C/svg%3E")
      no-repeat 50% 48% / 14px auto;
    font-size: 0;
    transition: all 0.2s;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    z-index: 1;

    @include dark {
      border-color: $neutrals3;
      background-color: $neutrals1;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Cpath fill='%23FCFCFD' d='M1.613.2l.094.083L5 3.585 8.293.293a1 1 0 0 1 1.414 0 1 1 0 0 1 .083 1.32l-.083.094L6.415 5l3.292 3.293a1 1 0 0 1-1.32 1.497l-.094-.083L5 6.415 1.707 9.707A1 1 0 0 1 .21 8.387l.083-.094L3.585 5 .293 1.707a1 1 0 0 1 0-1.414A1 1 0 0 1 1.613.21z'/%3E%3C/svg%3E");
    }

    &:hover {
      border-color: $neutrals2;

      @include dark {
        border-color: $neutrals8;
      }
    }
  }
  &_form {
    padding: 40px 85px;
    padding-top: 55px;

    @include m {
      padding: 10px;
      padding-top: 55px;
    }
  }

  &_form2 {
    padding: 40px 85px;
    padding-top: 55px;
    display: grid;
    justify-content: center;

    @include m {
      padding: 10px;
      padding-top: 55px;
    }
  }

  &_header {
    display: grid;
    justify-content: center;
    gap: 30px;
  }

  &_header2 {
    display: grid;
    justify-content: center;
    gap: 30px;
    max-width: 386px;
  }

  &_title {
    @include dm-sans;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.01em;
    text-align: center;

    @include m {
      font-size: 20px;
      line-height: 30px;
    }
  }

  &_text {
    display: flex;
    justify-content: center;
    @include poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: $neutrals3;

    @include dark {
      color: $neutrals6;
    }
  }

  &__btns {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 32px 0px;
    gap: 8px;

    button {
      width: 100%;
    }
  }

  &__N0collction {
    display: flex;
    padding-top: 10px;
  }

  &__note {
    @include poppins;
    font-size: 12px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
  }

  &__note-color {
    color: $primary4 !important;
  }
}

.popup-GetPrivateKey {
  position: relative;
  max-width: 646px !important;

  &__text {
    max-width: 386px !important;
    color: $neutrals4 !important;

    @include dark {
      color: $neutrals5 !important;
    }
  }
}
