.header {
  /*position: relative;*/
  position: sticky;
  top: 0px;
  z-index: 20;
  padding: 20px 0;
  border-bottom: 1px solid $neutrals6;
  background: rgba($primary2, 0.8);
  backdrop-filter: blur(30px);

  @include m {
    padding: 24px 0 24px;
    border: none;
    height: 97px;
  }

  @include dark {
    border-color: $neutrals3;
    background: rgba($primary1, 0.8);
  }

  &__center {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__upload {
    margin-right: 12px;

    @include w {
      margin-left: 5px;
    }

    @include m {
      display: none;
    }
  }

  &__logo {
    position: relative;
    z-index: 12;
    display: inline-block;
    flex-shrink: 0;
    width: 128px;
    margin-right: 32px;

    @include d {
      margin-right: auto;
    }

    img {
      width: 100%;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    flex-grow: 1;

    @include d {
      flex-grow: 0;
    }

    @include m {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      flex-direction: column;
      align-items: stretch;
      min-height: calc(var(vh, 1vh) * 100);
      margin: 0;
      padding: 140px 32px 40px;
      box-shadow: 0px 64px 64px rgba(31, 47, 70, 0.2);
      background: $neutrals8;
      visibility: hidden;
      opacity: 0;
      transition: all 0.2s;

      @include dark {
        background: $neutrals1;
      }

      &.visible {
        visibility: visible;
        opacity: 1;
      }
    }

    .header__button {
      display: none;

      @include m {
        display: inline-flex;
        margin-top: 16px;
      }
    }

    .header__connect {
      @include rmin(768) {
        display: none !important;
      }
    }
  }

  &__nav {
    display: flex;
    margin-right: auto;
    border-left: 1px solid $neutrals6;
    align-items: center;

    @include d {
      display: none;
    }

    @include m {
      display: flex;
      flex-direction: column;
      margin: 0 0 40px;
      border: none;
    }

    @include dark {
      border-color: $neutrals3;

      .theme2 {
        border: none;
      }
    }

    .theme2 {
      border: none;
    }
  }

  &__nav &__link {
    margin-left: 32px;
    @include main-font;
    line-height: 40px;
    color: $neutrals4;
    transition: color 0.2s;

    @include w {
      margin-left: 20px;
    }

    @include m {
      margin-left: 0;
      font-size: 24px;
      line-height: 64px;
    }

    &:hover,
    &.active {
      color: $primary3;
    }

    false {
      display: none;

      @include m {
        display: block;
      }
    }
  }

  &__search {
    position: relative;
    flex-shrink: 0;
    width: 256px;
    margin-right: 24px;

    @include t {
      display: none;
    }

    @include m {
      display: block;
      width: 100%;
      margin: auto 0 0;
    }
  }

  &__text {
    margin-right: auto;
  }

  &__search2 {
    position: relative;
    flex-shrink: 0;
    width: 70%;
    /*margin-right: 24px;*/
    margin: 0 auto;
    /*@include t {
            display: none;
        }*/

    @include m {
      display: block;
      width: 100%;
      margin: auto 0 0;
    }
  }

  &__search3 {
    position: relative;
    display: flex;
    align-items: center;

    @include m {
      display: block;
      width: 100%;
      margin: auto 0 0;
    }
  }

  &__input {
    width: 100%;
    height: 40px;
    padding: 0 42px 0 16px;
    background: none;
    border: 2px solid $neutrals6;
    border-radius: 0px;
    @include main-font;
    @include caption-2;
    transition: border-color 0.2s;

    @include placeholder {
      color: $neutrals4;
    }

    @include dark {
      border-color: $neutrals3;
      color: $neutrals8;
    }

    &:focus {
      border-color: $neutrals4;

      @include dark {
        border-color: $neutrals4;
      }
    }
  }

  &__input2 {
    width: 100%;
    height: 56px;
    padding: 0 42px 0 16px;
    background: none;
    border: 2px solid $neutrals6;
    border-radius: 0px;
    @include main-font;
    @include body-2;
    transition: border-color 0.2s;

    @include placeholder {
      color: $neutrals4;
    }

    @include dark {
      border-color: $neutrals3;
      color: $neutrals8;
    }

    &:focus {
      border-color: $neutrals4;

      @include dark {
        border-color: $neutrals4;
      }
    }
  }

  &__result {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 42px;

    .icon {
      width: 20px;
      height: 20px;
      fill: $neutrals4;
      transition: fill 0.2s;
    }

    &:hover {
      .icon {
        fill: $primary3;
      }
    }
  }

  &__result2 {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin-left: 32px;
    width: 42px;
    margin: 8px;
    background: $primary1;
    color: $primary2;
    padding: 0 16px;
    min-width: 115px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 9px;
    @include main-font;
    transition: 0.2s;

    @include dark {
      background: $primary2;
      color: $primary1;
    }

    .icon {
      width: 20px;
      height: 20px;
      fill: $primary2;

      @include dark {
        fill: $primary1;
      }
    }

    &:hover {
      .icon {
        fill: $primary2;
      }
      color: $primary2;
      background: $primary3;
    }
  }

  &__result3 {
    // position: absolute;
    // top: 0;
    // right: 0;
    // bottom: 0;
    // margin-left: 32px;
    /*width: 42px;*/
    margin: 8px;
    background: $primary1;
    color: $primary2;
    padding: 0 16px;
    min-width: 115px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 9px;
    @include main-font;
    transition: 0.2s;

    @include dark {
      background: $primary2;
      color: $primary1;
    }

    .icon {
      width: 20px;
      height: 20px;
      fill: $primary2;

      @include dark {
        fill: $primary1;
      }
    }

    &:hover {
      .icon {
        fill: $primary2;
      }
      color: $primary2;
      background: $primary3;
    }
  }

  &__popup {
    /*position: absolute;
        top: 0;
        right: 0;
        bottom: 0;*/
    margin-left: 20px;
    width: 42px;

    @include w {
      margin-left: 0px;
    }

    @include t {
      margin-right: 12px;
      margin-left: 0px;
    }

    .icon {
      width: 20px;
      height: 20px;
      fill: $neutrals4;
      transition: fill 0.2s;

      @include t {
        width: 24px;
        height: 24px;
      }
    }

    &:hover {
      .icon {
        fill: $primary3;
      }
    }
  }

  &__item {
    position: relative;

    @include m {
      position: static;
    }

    &_notification {
      /*display: none;*/
      margin-right: 24px;

      @include m {
        margin-right: 20px;
      }
    }

    &_notification2 {
      /*display: none;*/
      margin-right: 0px;
      width: 56px;
      height: 56px;
      /*border: 1px solid $neutrals6;*/
      box-shadow: inset 0 0 0 2px $neutrals6;
      display: flex;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      margin-left: 10px;

      &:hover {
        box-shadow: inset 0 0 0 2px $primary3;
      }

      @include dark {
        /*border: 1px solid $neutrals2;*/
        box-shadow: inset 0 0 0 2px $neutrals3;

        &:hover {
          box-shadow: inset 0 0 0 2px $primary3;
        }
      }

      @include w {
        margin-right: 0px;
      }

      @include t {
        display: none;
      }

      @include m {
        margin-right: 20px;
        width: 48px;
        height: 48px;
      }
    }

    &_chain {
      /*display: none;*/
      margin-right: 10px;
      width: 56px;
      height: 56px;
      /*border: 1px solid $neutrals6;*/
      box-shadow: inset 0 0 0 2px $neutrals6;
      display: flex;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      margin-left: 10px;

      &:hover {
        box-shadow: inset 0 0 0 2px $primary3;
      }

      img {
        // width: 30px;
        width: auto;
        height: auto;
      }

      @include w {
        margin-right: 10px;
      }

      @include t {
        img {
          width: 22px;
          height: 22px;
        }
      }

      @include dark {
        /*border: 1px solid $neutrals2;*/
        box-shadow: inset 0 0 0 2px $neutrals3;

        &:hover {
          box-shadow: inset 0 0 0 2px $primary3;
        }
      }

      @include m {
        margin-right: 12px;
        width: 40px;
        height: 40px;
      }
    }

    &_user {
      /*display: none;*/

      @include t {
        margin-right: 12px;
      }
    }
  }

  .actions__body {
    margin-top: 15px;
    margin-left: calc(100% - 110%);

    @include t {
      margin-top: 17px;
      margin-left: -16%;

      &:before {
        left: 77%;
      }
    }

    @include m {
      margin-top: 17px;
      margin-left: -18%;

      &:before {
        left: 50%;
      }
    }
  }

  &__item_notification &__head {
    position: relative;
    width: 40px;
    height: 40px;

    .icon {
      width: 24px;
      height: 24px;
      fill: $neutrals4;
      transition: fill 0.2s;
    }

    &:hover {
      .icon {
        fill: $primary3;
      }
    }

    &.active {
      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: $primary3;
      }
    }
  }

  &__item_notification2 &__head2 {
    position: relative;
    width: 40px;
    height: 40px;

    .icon {
      width: 24px;
      height: 24px;
      fill: $neutrals4;
      transition: fill 0.2s;
    }

    &:hover {
      .icon {
        fill: $primary3;
      }
    }

    .current-color {
      color: #121330;

      @include dark {
        color: $neutrals8;
      }
    }

    &.active {
      &:before {
        content: "";
        position: absolute;
        top: -8px;
        right: 0;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: $primary3;
      }
    }
  }

  &__item_user &__head {
    position: relative;
    display: flex;
    align-items: center;
    padding: 4px 16px 4px 4px;
    border-radius: 25px;
    box-shadow: inset 0 0 0 2px $neutrals6;
    @include button-2;
    cursor: pointer;
    transition: box-shadow 0.2s;

    @include m {
      padding: 0;
      border-radius: 0;
      box-shadow: none;
    }

    @include dark {
      box-shadow: inset 0 0 0 2px $neutrals3;

      @include m {
        box-shadow: none;
      }
    }

    &:hover {
      box-shadow: inset 0 0 0 2px $primary3;

      @include m {
        box-shadow: none;
      }

      @include dark {
        box-shadow: inset 0 0 0 2px $primary3;

        @include m {
          box-shadow: none;
        }
      }
    }
  }

  &__item_user &__head2 {
    position: relative;
    display: flex;
    align-items: center;
    padding: 4px 16px 4px 4px;
    border-radius: 75px;
    box-shadow: inset 0 0 0 2px $neutrals6;
    @include button-2;
    cursor: pointer;
    transition: box-shadow 0.2s;
    height: 56px;

    @include m {
      padding: 0;
      border-radius: 0;
      box-shadow: none;
      height: 48px;
    }

    @include dark {
      box-shadow: inset 0 0 0 2px $neutrals3;

      @include m {
        box-shadow: none;
      }
    }

    &:hover {
      box-shadow: inset 0 0 0 2px $primary3;

      @include m {
        box-shadow: none;
      }

      @include dark {
        box-shadow: inset 0 0 0 2px $primary3;

        @include m {
          box-shadow: none;
        }
      }
    }
  }

  &__avatar {
    flex-shrink: 0;
    width: 32px;
    height: 32px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  &__avatar2 {
    flex-shrink: 0;
    width: 48px;
    height: 48px;

    @include m {
      width: 40px;
      height: 40px;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__wallet {
    margin-left: 12px;

    @include m {
      display: none;
    }

    @include dark {
      color: $neutrals8;
    }
  }

  &__currency {
    color: $primary3;

    &.bnb {
      color: #f3ba2f;
    }

    &.matic {
      color: #8247e5;
    }

    &.sui {
      color: #6fbcf0;
    }

    &.eth {
      color: #393939;

      @include dark {
        color: $primary2;
      }
    }
  }

  &__body {
    position: absolute;
    left: 50%;
    /*box-shadow: 0px 16px 64px 0 rgba(31, 47, 70, 0.4);*/
    box-shadow: 0px 12px 60px rgba(0, 0, 0, 0.1);
    background: $neutrals8;
    opacity: 0;
    visibility: hidden;
    transform: scale(0.9);
    transform-origin: 50% 50px;
    will-change: transform;
    transition: all 0.4s;

    @include m {
      left: 16px;
      right: 16px;
      box-shadow: 0px 16px 32px 0 rgba(31, 47, 70, 0.4);
    }

    &:before {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 100%;
      width: 32px;
      height: 13px;
      transform: translateX(-50%);
      background: url("../../images/Vector 1.svg") no-repeat 100% 50% / 100% auto;

      @include dark {
        @include filter-neutrals1;
      }

      @include m {
        display: none;
      }
    }

    @include dark {
      box-shadow: 0px 16px 32px 0 rgba($neutrals2, 0.3);
    }
  }

  &__item_notification &__body {
    top: calc(100% + 44px);
    width: 396px;
    margin-left: -198px;
    padding: 40px 32px 32px;
    border-radius: 24px;

    @include m {
      top: calc(100% + 16px);
      width: auto;
      margin: 0;
      padding: 32px 16px;
    }

    @include dark {
      background: $neutrals1;
      /*&:before {
                background-image: url("data:image/svg+xml,%3Csvg width='32' height='13' viewBox='0 0 32 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.3432 2.65685L0 13H32L21.6569 2.65686C18.5327 -0.467339 13.4673 -0.467344 10.3432 2.65685Z' fill='%23141416'/%3E%3C/svg%3E%0A");
            }*/
    }
  }

  &__item_notification2 &__body {
    top: calc(100% + 28px);
    width: 396px;
    margin-left: -198px;
    padding: 40px 32px 32px;
    border-radius: 24px;

    @include m {
      top: calc(100% + 16px);
      width: auto;
      margin: 0;
      padding: 32px 16px;
    }

    @include dark {
      background: $neutrals1;
      /*&:before {
                background-image: url("data:image/svg+xml,%3Csvg width='32' height='13' viewBox='0 0 32 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.3432 2.65685L0 13H32L21.6569 2.65686C18.5327 -0.467339 13.4673 -0.467344 10.3432 2.65685Z' fill='%23141416'/%3E%3C/svg%3E%0A");
            }*/
    }
  }

  &__item_user &__body {
    top: calc(100% + 20px);
    width: 256px;
    margin-left: -128px;
    padding: 32px 16px 20px;
    border-radius: 12px;

    @include t {
      left: auto;
      right: 0;
    }

    @include m {
      left: 16px;
      right: 16px;
      top: calc(100% + 16px);
      width: auto;
      margin: 0;
    }

    @include dark {
      background: $neutrals2;

      &:before {
        background-image: url("data:image/svg+xml,%3Csvg width='32' height='13' viewBox='0 0 32 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.3432 2.65685L0 13H32L21.6569 2.65686C18.5327 -0.467339 13.4673 -0.467344 10.3432 2.65685Z' fill='%2323262F'/%3E%3C/svg%3E%0A");
      }
    }
  }

  &__item.active &__body {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }

  &__title {
    margin-bottom: 16px;

    @include m {
      margin: 0 16px;
    }
  }

  &__list {
    margin: 0 -16px;

    @include m {
      margin: 0;
    }
  }

  &__notif2 {
    cursor: pointer;
  }

  &__notification {
    position: relative;
    display: flex;
    align-items: center;
    padding: 12px 40px 12px 16px;
    border-radius: 16px;
    transition: background 0.2s;

    &:hover {
      background: $neutrals7;

      @include dark {
        background: $neutrals2;
      }
    }

    &_readall {
      @include caption-bold-2;
      color: $primary3;
      position: absolute;
      top: 51px;
      right: 32px;
      @include main-font;
    }

    &_wraper {
      display: flex;
      margin-top: 32px;
    }

    &_button {
      width: 100%;
      background: $primary1;
      padding: 16px 0;
      color: $primary2;
      text-align: center;

      img {
        margin-left: 6px;
      }

      @include dark {
        background: $primary2;
        color: $primary1;

        img {
          @include filter-primary1;
        }

        &:hover {
          background: $primary3;
          color: $primary2;

          img {
            @include filter-neutrals6;
          }
        }
      }

      &:hover {
        background: $primary3;
        color: $primary2;
      }
    }

    &_Visited {
      display: flex;
      flex-direction: column;
    }

    &_icon {
      max-width: 100%;
      margin: 32px auto 16px;
    }

    &_description {
      @include body-2;
      color: $primary1;
      text-align: center;

      @include dark {
        color: $primary2;
      }
    }
  }

  &__preview {
    flex-shrink: 0;
    width: 50px;
    height: 50px;
    margin-right: 24px;

    @include m {
      margin-right: 16px;
    }

    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
    }
  }

  &__details {
    flex-grow: 1;
    @include wrap-text2(2);
  }

  &__subtitle {
    @include body-bold-2;
    color: $neutrals1;

    @include dark {
      color: $neutrals8;
    }
  }

  &__price {
    font-weight: 500;
    color: $neutrals3;

    @include dark {
      color: $neutrals6;
    }
  }

  &__date {
    @include caption-2;
    color: $neutrals4;
  }

  &__status {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: $primary3;
  }

  &__item_notification &__button {
    position: absolute;
    top: 40px;
    right: 32px;

    @include m {
      position: static;
      width: 100%;
      margin-top: 20px;
    }
  }

  &__item_notification2 &__button {
    position: absolute;
    top: 40px;
    right: 32px;

    @include m {
      position: static;
      width: 100%;
      margin-top: 20px;
    }
  }

  &__code {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  &__number {
    font-weight: 500;
    color: $neutrals4;
  }

  &__copy {
    margin-left: 8px;

    .icon {
      width: 16px;
      height: 16px;
      fill: $black;
      transition: fill 0.2s;

      @include dark {
        fill: $white;
      }
    }

    &:hover {
      .icon {
        fill: darken($primary3, 10);
      }
    }
  }

  &__wrap {
    margin-bottom: 7px;
    padding: 8px;
    border-radius: 16px;
    box-shadow: 0px 24px 24px -8px rgba(15, 15, 15, 0.2);
  }

  &__line {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  &__img {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin-right: 16px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  &__info {
    @include caption-2;
    color: $neutrals4;
  }

  &__money {
    @include body-bold-1;
  }

  &__item_user &__button {
    width: 100%;
    height: 32px;
    padding: 0 10px;
    font-size: 14px;
  }

  &__menu &__link {
    display: flex;
    align-items: center;
    padding: 17px 0 12px;
    @include button-2;
    color: $neutrals4;

    &:not(:last-child) {
      border-bottom: 1px solid $neutrals6;

      @include dark {
        border-color: $neutrals3;
      }
    }

    &[href] {
      &:hover {
        color: $primary3;

        .icon {
          fill: $primary3;
        }
      }
    }
  }

  &__icon {
    flex-shrink: 0;
    width: 20px;
    margin-right: 8px;

    .icon {
      width: 20px;
      height: 20px;
      fill: $neutrals4;
      transition: fill 0.2s;
    }
  }

  .theme {
    margin-left: auto;
  }

  &__burger {
    display: none;

    @include d {
      display: block;
      position: relative;
      width: 32px;
      height: 32px;
      background: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      /*&.active {
                &:before {
                    transform: translateY(0) rotate(-45deg);
                }

                &:after {
                    transform: translateY(0) rotate(45deg);
                }
            }

            &:before,
            &:after {
                content: '';
                position: absolute;
                top: 16px;
                left: 6px;
                width: 20px;
                height: 2px;
                background: $neutrals4;
                border-radius: 2px;
                transition: transform .2s;
            }

            &:before {
                transform: translateY(-4px);
            }

            &:after {
                transform: translateY(3px);
            }*/

      img {
        width: 100%;
      }
    }
  }

  &__center > &__connect {
    @include t {
      display: none !important;
    }
  }

  &.registered {
    .header__connect {
      display: none;
    }

    .header__item_notification,
    .header__item_user {
      display: block;
    }

    .header__item_notification2,
    .header__item_user {
      display: block;
    }
  }
}

.header3 {
  padding: 20px 0;
  border-bottom: 1px solid $neutrals6;
  background: $primary2;

  @include m {
    padding: 24px 0 24px;
    border: none;
  }

  @include dark {
    border-color: $neutrals3;
    background: $primary1;
  }

  &__details {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*margin-left: 15px;*/

    .bnb__price {
      display: flex;
      gap: 9px;
      align-items: center;

      img {
        width: 24px;
        height: 24px;
      }
    }

    .bnb__price_value {
      display: flex;
      gap: 3px;
      align-items: center;

      img {
        width: 24px;
        height: 24px;
      }
    }

    .value {
      @include poppins;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;

      @include m {
        font-size: 14px;
      }
    }

    .text {
      &_bnb {
        color: $bnb;
        @include poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;

        @include m {
          font-size: 14px;
        }
      }

      &_eth {
        color: $eth;
        @include poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;

        @include m {
          font-size: 14px;
        }

        @include dark {
          color: $primary2;
        }
      }

      &_Polygon {
        color: $matic;
        @include poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;

        @include m {
          font-size: 14px;
        }
      }

      &_seed {
        color: $primary3;
        @include poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;

        @include m {
          font-size: 14px;
        }
      }
    }
  }

  &__hr {
    margin-right: 31px;
    width: 16px;
    height: 1px;
    border: 0px;
    background: #b1b5c3;
    transform: rotate(90deg);
  }

  &__button {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    margin: 12px 0;

    .icon {
      margin: 0 !important;
      width: 20px;
      height: 20px;
      @include filter-primary2;

      @include dark {
        @include filter-primary1;
      }
    }

    .plus {
      @include dark {
        @include filter-neutrals1;
      }
    }

    &:hover {
      .icon {
        @include filter-primary2;
      }

      .plus {
        filter: brightness(0) saturate(100%) invert(99%) sepia(10%) saturate(729%) hue-rotate(177deg) brightness(96%)
          contrast(93%) !important;
      }
    }

    .seed-badge {
      width: 20px;
      height: 20px;
    }

    .label {
      @include dm-sans;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      text-align: center;
      display: flex;
      align-items: center;
      gap: 5px;

      &_img2 {
        width: 15px;
        height: 15px;

        @include dark {
          @include filter-neutrals1;
        }
      }
    }
  }

  &__spacer {
    height: 40px;
  }

  &__flex {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__count {
    color: $primary3;
  }

  &__connection {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border: 1px solid $neutrals6;
    width: 173px;
    /*margin: 0 auto;*/
    border-radius: 40px;
    padding: 4px;
    background-color: $primary2;
    /*margin-left: 10px;*/

    @include dark {
      background-color: $primary1;
      border: 1px solid $neutrals3;
    }

    .status {
      width: 12px;
      height: 12px;
      border-radius: 20px;
      padding: 0;
      margin-left: 10px;

      &.correct {
        background: $links;
      }

      &.wrong {
        background: #e41937;
      }
    }
  }

  &__icon2 {
    flex-shrink: 0;
    width: 20px;
    display: flex;
    justify-content: center;

    .icon {
      width: 20px;
      height: 20px;
      fill: $neutrals4;
      transition: fill 0.2s;
    }

    img {
      width: 20px;
      height: 20px;
      transition: fill 0.2s;
      @include filter-neutrals6;

      @include dark {
        @include filter-neutrals4;
      }
    }
  }

  &__icon {
    flex-shrink: 0;
    width: 20px;
    margin-right: 8px;

    .icon {
      width: 20px;
      height: 20px;
      fill: $neutrals4;
      transition: fill 0.2s;
    }

    img {
      width: 20px;
      height: 20px;
      transition: fill 0.2s;
      @include filter-neutrals3;

      @include dark {
        @include filter-neutrals4;
      }
    }
  }

  &__name {
    @include body-bold-1;
  }

  &__menu &__link {
    display: flex;
    align-items: center;
    padding: 17px 0 12px;
    @include button-2;
    @include dm-sans;
    color: $neutrals4;
    cursor: pointer;
    width: 100%;

    img {
      @include filter-neutrals4;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $neutrals6;

      @include dark {
        border-color: $neutrals3;
      }
    }

    &:hover {
      color: $primary3;

      .icon {
        fill: $primary3;
      }

      img {
        filter: brightness(0) saturate(100%) invert(46%) sepia(57%) saturate(363%) hue-rotate(7deg) brightness(92%)
          contrast(91%);
      }
    }

    &[href] {
      &:hover {
        color: $primary3;

        .icon {
          fill: $primary3;
        }

        img {
          filter: brightness(0) saturate(100%) invert(46%) sepia(57%) saturate(363%) hue-rotate(7deg) brightness(92%)
            contrast(91%);
        }
      }
    }

    &.logout-btn {
      width: 100%;

      &:hover {
        color: $primary3;

        .icon {
          fill: $primary3;
        }
      }
    }
  }

  &__body {
    will-change: transform;
    transition: all 0.4s;
    padding: 16px;

    @include m {
      left: 16px;
      right: 16px;
      box-shadow: 0px 16px 32px 0 rgba(31, 47, 70, 0.4);
    }

    .theme {
      margin-left: auto;
    }

    .profile2__membership {
      width: 200px;
      margin: 0 !important;
    }

    p {
      font-size: 60px;
    }
  }

  &__wrap {
    margin-bottom: 7px;
    // padding: 8px;
    border-radius: 16px;
    // box-shadow: 0px 24px 24px -8px rgba(15, 15, 15, 0.2);

    .main__item {
      margin: 10px 0;
    }
  }

  &__line {
    display: block;
    align-items: center;
    margin-bottom: 10px;
  }

  &__tips {
    border-top: 1px solid $neutrals6;
    padding-bottom: 20px;
    padding-top: 40px;
    margin-top: 40px;

    @include dark {
      border-top: 1px solid $neutrals3;
    }

    h3 {
      font-size: 20px;
    }

    &_header {
      display: flex;
      align-items: center;

      .title {
        margin-right: auto;
      }

      .more {
        color: $primary1;

        @include dark {
          color: $primary2;
        }
      }
    }
  }

  &__tipstext {
    display: flex;
    align-items: center;
    margin: 5px 0;

    h6 {
      margin-left: 5px;
      font-size: 12px;
      color: $neutrals4;
      cursor: pointer;
    }

    &:hover {
      h6 {
        color: $primary3;
      }
    }
  }

  &__tooltip {
    height: 16px !important;
    width: 16px !important;
    display: flex;
  }

  &__profileNav {
    @include main-font;
    color: $primary3;
  }
}

.profile__sidebar {
  position: fixed;
  max-width: 448px;
  margin: 15px auto;
  padding: 30px;
  border-radius: 0;
  right: 0;
  top: 81px;
  width: 450px;
  height: 90vh;
  box-shadow: -6px 1px 12px #0000001a;
  overflow-y: auto;
  background: rgba($neutrals8, 0.87);
  z-index: 11;
  backdrop-filter: blur(55px);
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  @include m {
    width: 100%;
    padding: 0px;
  }

  @include dark {
    box-shadow: 0px 16px 32px 0 rgba($neutrals2, 0.3);
    background: rgba($neutrals1, 0.87);
  }

  &_container {
    position: relative;

    .sidebar__close {
      position: absolute;
      right: 15px;
      top: 30px;
      /*display: none;*/
      z-index: 5;
      cursor: pointer;

      &__icon {
        flex-shrink: 0;
        width: 30px;
        margin-right: 8px;

        .icon {
          width: 30px;
          height: 30px;
          fill: $neutrals4;
          transition: fill 0.2s;
        }
      }

      @include t {
        display: block;
      }
    }
  }
}

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
