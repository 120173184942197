﻿.chosen {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 20px;

  @include m {
    gap: 16px;
  }
  /*@include t {
        flex-wrap: nowrap;
        overflow-x: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        -webkit-overflow-scrolling: touch;
        justify-content: flex-start;

        &::-webkit-scrollbar {
            display: none;
        }
    }*/

  &__card {
    border-radius: 4px;
    padding: 16px;
    box-shadow: 0px 2.07386px 31.1079px rgba(20, 20, 38, 0.14);
    background-color: $white;

    @include dark {
      background-color: $neutrals1;
    }

    @include m {
      padding: 12px;
    }

    &.portrait {
      flex: 0 0 38%;
      max-width: 38%;
      /*margin: 0 10px 20px;*/
      /*margin: 0 0px 20px 20px;*/
      /*@include d {
                flex: 0 0 100%;
                max-width: 100%;
            }*/
    }

    &.landscape {
      flex: 0 0 56%;
      max-width: 56%;
      /*margin: 0 10px 20px;*/
      /*margin: 0 0px 20px 20px;*/
      /*@include d {
                flex: 0 0 100%;
                max-width: 100%;
            }*/
    }

    &.equal-size {
      flex: 0 0 46%;
      max-width: 46%;
      /*margin: 0 10px 20px;*/
      margin: 0 0px 20px 20px;

      @include d {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    /*@include d {
            &:nth-child(3) {
                display: none;
            }

            &:nth-child(4) {
                display: none;
            }
        }*/
    /*@include t {
            &:nth-child(2) {
                display: block;
            }

            &:nth-child(3) {
                display: block;
            }

            &:nth-child(4) {
                display: block;
            }
        }*/
  }


  
  &__cardSleleton {
    border-radius: 4px;
    padding: 16px;
    box-shadow: 0px 2.07386px 31.1079px rgba(20, 20, 38, 0.14);
    background-color: $white;

    @include dark {
      background-color: $neutrals1;
    }

    &.portrait {
      flex: 0 0 45%;
      max-width: 45%;
      /*margin: 0 10px 20px;*/
      /*margin: 0 0px 20px 20px;*/
      /*@include d {
                flex: 0 0 100%;
                max-width: 100%;
            }*/
    }

  }

  &__cardSleleton {
    border-radius: 4px;
    padding: 16px;
    box-shadow: 0px 2.07386px 31.1079px rgba(20, 20, 38, 0.14);
    background-color: $white;

    @include dark {
      background-color: $neutrals1;
    }

    &.portrait1 {
      flex: 0 0 40%;
      max-width: 40%;
    }

    &.portrait2 {
      flex: 0 0 55%;
      max-width: 55%;
    }

  }

  &__preview {
    position: relative;
    border-radius: 0px;
    overflow: hidden;
    height: 250px;

    @include m {
      height: 130px;
    }

    & > img {
      width: 100%;
      height: 100%;
      transition: transform 1s;
      object-fit: cover;
    }

    & > video {
      width: 100%;
      height: 100%;
      transition: transform 1s;
      object-fit: cover;
    }

    &:hover {
      .chosen__creator,
      .card2__creator {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .card2__creator {
    transition: opacity 0.2s;
    visibility: hidden;
    opacity: 0;
  }

  &__title {
    font-size: 14px;
    margin-top: 15px;
    font-weight: 700;
    color: $neutrals2;

    @include dark {
      color: $white;
    }

    @include m {
      font-weight: 500;
    }
  }

  &__creator {
    position: absolute;
    top: 10px;
    left: 8px;
    padding: 4px 10px 4px 4px;
    border-radius: 50px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(80px);
    display: flex;
    align-items: center;
    max-width: 90%;
    height: 40px;
    visibility: hidden;
    opacity: 0;

    img {
      width: 35px;
      height: 35px;
      object-fit: cover;
      border-radius: 50px;
      border: 2px solid #fff;
      margin-right: 5px;
    }

    .creator__name {
      color: #fff;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1; /* number of lines to show */
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}



